import { FC } from 'react';

import { ExpandableFormItem } from '@form/item/ExpandableFormItem/ExpandableFormItem';

import { OrderIdSelect } from '@components/accounting/transactions/ui/selects/OrderIdSelect';
import { AdditionalFieldProps } from '@filters/types';
import { DateRangeInput } from '@form/inputs/dateRange/DateRangeInput';
import { CustomFormItem } from '@form/item/FormItem';
import { SubLocationSelectInput } from '@inventory/inventoryManagement/products/inputs/SublocationSelect';
import { BusinessLocationsSelect } from '@ui/form/BusinessLocationsSelect';
import { ClassSelect } from '@ui/form/ClassSelect/ClassSelect';
import { CompositeProductSelect } from '@ui/form/CompositeProductSelect/CompositeProductSelect';
import EmployeeSelect from '@ui/form/EmployeeSelect';
import { MembershipSelect } from '@ui/form/MembershipSelect/MembershipSelect';
import { ProductSelect } from '@ui/form/ProductSelect/ProductSelect';
import { SalesItemReportTypeSelect } from '@ui/form/SalesItemReportTypeSelect';
import { ServiceSelect } from '@ui/form/ServiceSelect/ServiceSelect';
import { Col, Row } from 'antd';
import { ProductTypeSelect } from 'src/components/inventory/inventoryManagement/products/inputs/ProductTypeSelect';

const customInputStyles = { margin: 0 };

export const SalesItemAdditionalFields: FC<
  AdditionalFieldProps & { entityName?: string }
> = ({ values, entityName }) => {
  return (
    <>
      <ExpandableFormItem
        name={'orderId'}
        defaultOpen={!!values?.orderId}
        label={`Order Id`}
      >
        <OrderIdSelect />
      </ExpandableFormItem>
      <ExpandableFormItem
        withDivider
        name={'type'}
        label={'Item Type'}
        defaultOpen={!!values?.type}
      >
        <SalesItemReportTypeSelect />
      </ExpandableFormItem>
      <ExpandableFormItem
        withDivider
        label={'Product'}
        defaultOpen={!!values?.productId || !!values?.productType}
      >
        <Row gutter={[0, 24]}>
          <Col span={24}>
            <CustomFormItem name={'productId'} style={customInputStyles}>
              <ProductSelect value={values?.productId} />
            </CustomFormItem>
          </Col>
          <Col span={24}>
            <CustomFormItem name={'productType'} style={customInputStyles}>
              <ProductTypeSelect
                initialValue={values?.productType}
                editable={false}
              />
            </CustomFormItem>
          </Col>
        </Row>
      </ExpandableFormItem>
      <ExpandableFormItem
        withDivider
        label={'Composite Product'}
        name={'compositeProductId'}
        defaultOpen={!!values?.compositeProductId}
      >
        <CompositeProductSelect value={values?.compositeProductId} />
      </ExpandableFormItem>
      <ExpandableFormItem
        withDivider
        label={'Service'}
        name={'serviceId'}
        defaultOpen={!!values?.serviceId}
      >
        <ServiceSelect value={values?.serviceId} />
      </ExpandableFormItem>
      <ExpandableFormItem
        withDivider
        label={'Membership'}
        name={'membershipId'}
        defaultOpen={!!values?.membershipId}
      >
        <MembershipSelect value={values?.membershipId} />
      </ExpandableFormItem>
      <ExpandableFormItem
        withDivider
        label={'Class'}
        name={'classId'}
        defaultOpen={!!values?.classId}
      >
        <ClassSelect value={values?.classId} />
      </ExpandableFormItem>
      <ExpandableFormItem
        withDivider
        label={'Business Location'}
        defaultOpen={!!values?.locationIds || !!values?.sublocation}
      >
        <Row gutter={[0, 24]}>
          <Col span={24}>
            <CustomFormItem
              label={'Business Location'}
              name={'locationIds'}
              style={customInputStyles}
            >
              <BusinessLocationsSelect placeholder={'Choose location'} />
            </CustomFormItem>
          </Col>
          <Col span={24}>
            <CustomFormItem
              label={'Sublocation'}
              name={'sublocation'}
              style={customInputStyles}
            >
              <SubLocationSelectInput
                businessLocationId={values?.locationIds}
                initialValue={values?.sublocation ?? undefined}
                placeholder={'Choose sublocation'}
                editable={false}
              />
            </CustomFormItem>
          </Col>
        </Row>
      </ExpandableFormItem>
      <ExpandableFormItem
        withDivider
        name={'sales'}
        label={'Employee'}
        defaultOpen={!!values?.sales}
      >
        <EmployeeSelect shouldApplyDefaultValue={false} />
      </ExpandableFormItem>
      <ExpandableFormItem
        withDivider
        label={'Due Date'}
        name={'dueDate'}
        defaultOpen={!!values?.dueDate}
      >
        <DateRangeInput style={{ width: '100%' }} />
      </ExpandableFormItem>
    </>
  );
};
