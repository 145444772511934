export interface ImportResult<T = any> {
  completedImports: T[];
  unvalidatedImports: T[];
  needChangeCreations?: T[];
}

export interface ImportProcess<T = any> {
  maxNotesCount: any;
  result: ImportResult<T>;
  isLoading: boolean;
  maxImagesCount?: number;
  maxProductsCount?: number;
  customFieldsColumns?: string[];
}

export interface ImportingProcesses {
  contacts: ImportProcess;
  products: ImportProcess;
  orders: ImportProcess;
}

export interface ParsedObject<T = any> {
  completedCreations?: T[];
  spoiledCreations?: T[];
  needChangeCreations?: T[];
  customFieldsArr?: string[];
  maxNotesCount?: number;
  maxProductsCount?: number;
  maxImagesCount?: number;
}

export type ConfigType = 'contacts' | 'orders' | 'products';

export enum ActionType {
  START_IMPORT = 'START_IMPORT',
  FINISH_IMPORT = 'FINISH_IMPORT',
  SET_CUSTOM_FIELDS = 'SET_CUSTOM_FIELDS',
  SET_MAX_COUNTS = 'SET_MAX_COUNTS',
  SET_LOADING = 'SET_LOADING',
  REMOVE_ITEM = 'REMOVE_ITEM',
  FORCE_SET_RESULT_TABLE = 'FORCE_SET_RESULT_TABLE',
}

export type Action =
  | { type: ActionType.START_IMPORT; importType: ConfigType }
  | {
      type: ActionType.FINISH_IMPORT;
      importType: ConfigType;
      payload: ImportResult;
    }
  | {
      type: ActionType.SET_CUSTOM_FIELDS;
      importType: Extract<ConfigType, 'contacts'>;
      payload: string[];
    }
  | {
      type: ActionType.SET_MAX_COUNTS;
      importType: Extract<ConfigType, 'products'>;
      payload: {
        maxNotesCount: any;
        maxProductsCount: number;
        maxImagesCount: number;
      };
    }
  | {
      type: ActionType.SET_LOADING;
      importType: ConfigType;
      isLoading: boolean;
    }
  | {
      type: ActionType.REMOVE_ITEM;
      importType: ConfigType;
      tableType: keyof ImportResult;
      localId: string;
    }
  | {
      type: ActionType.FORCE_SET_RESULT_TABLE;
      importType: ConfigType;
      tableType: keyof ImportResult;
      forceUpdatingArr: any[];
    };

export type ImportResultKeys = keyof ImportResult;
