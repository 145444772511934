import {
  Action,
  ActionType,
  ImportingProcesses,
} from '@app/ImportingContext/types/types';
import { OrderDataWithErrors } from '@components/dashboard/dashboardSettings/SettingsModuleFactory/sections/importEntity/orders/types/types';
import { AnyObject } from 'antd/es/_util/type';

export const importingReducer = (
  state: ImportingProcesses,
  action: Action,
): ImportingProcesses => {
  switch (action.type) {
    case ActionType.START_IMPORT: {
      const newState = { ...state };
      newState[action.importType].isLoading = true;
      newState[action.importType].result.unvalidatedImports = [];
      return newState;
    }
    case ActionType.FINISH_IMPORT: {
      const newState = { ...state };
      newState[action.importType].result = {
        ...newState[action.importType].result,
        ...action.payload,
      };
      newState[action.importType].isLoading = false;
      return newState;
    }
    case ActionType.SET_CUSTOM_FIELDS: {
      const newState = { ...state };
      newState.contacts.customFieldsColumns = action.payload;
      return newState;
    }
    case ActionType.SET_MAX_COUNTS: {
      const newState = { ...state };
      if (action?.payload?.maxProductsCount)
        newState.products.maxProductsCount = action.payload.maxProductsCount;
      if (action?.payload?.maxImagesCount)
        newState.products.maxImagesCount = action.payload.maxImagesCount;
      if (action.payload.maxNotesCount)
        newState.contacts.maxNotesCount = action.payload.maxNotesCount;
      return newState;
    }
    case ActionType.SET_LOADING: {
      const newState = { ...state };
      newState[action.importType].isLoading = action.isLoading;
      return newState;
    }
    case ActionType.REMOVE_ITEM: {
      const { importType, tableType, localId } = action;
      const newState = { ...state };

      if (
        newState?.[importType]?.result?.[tableType] &&
        Array.isArray(newState[importType].result[tableType])
      ) {
        newState[importType].result[tableType] = (
          newState[importType].result[tableType] as AnyObject
        ).filter((el: OrderDataWithErrors) => el.localId !== localId);
      }
      return newState;
    }
    case ActionType.FORCE_SET_RESULT_TABLE: {
      const { importType, tableType, forceUpdatingArr } = action;
      const newState = { ...state };
      newState[importType].result[tableType] = forceUpdatingArr;
      return newState;
    }
    default:
      return state;
  }
};

export const initialState: ImportingProcesses = {
  contacts: {
    result: {
      completedImports: [],
      unvalidatedImports: [],
      needChangeCreations: [],
    },
    isLoading: false,
    customFieldsColumns: [],
    maxNotesCount: 0,
  },
  products: {
    result: {
      completedImports: [],
      unvalidatedImports: [],
      needChangeCreations: [],
    },
    isLoading: false,
    maxImagesCount: 0,
    maxProductsCount: 0,
    maxNotesCount: 0,
  },
  orders: {
    result: {
      completedImports: [],
      unvalidatedImports: [],
    },
    isLoading: false,
    maxImagesCount: 0,
    maxProductsCount: 0,
    maxNotesCount: 0,
  },
};
