import {
  FilterFunction,
  LocationSublocationFilterInput,
  ShopProductFilterInput,
} from '@filters/types';

export const createSalesItemReportOrderFilter: FilterFunction<string> = (
  orderId?: string,
): SalesItemReportFiltersInput => {
  return orderId
    ? {
        order: {
          id: {
            eq: orderId,
          },
        },
      }
    : {};
};

export const createSalesItemReportShopItemFilter: FilterFunction<
  ShopProductFilterInput
> = ({
  productId,
  productType,
  compositeProductId,
  serviceId,
  membershipId,
  classId,
} = {}): SalesItemReportFiltersInput => {
  const productFilter =
    productId || productType
      ? {
          productOrderItem: {
            product: {
              product: {
                id: {
                  eq: productId,
                },
                productType: {
                  id: {
                    eq: productType,
                  },
                },
              },
            },
          },
        }
      : {};

  const compositeProductFilter = compositeProductId
    ? {
        compositeProductOrderItem: {
          compositeProduct: {
            compositeProduct: {
              id: {
                eq: compositeProductId,
              },
            },
          },
        },
      }
    : {};

  const serviceFilter = serviceId
    ? {
        serviceOrderItem: {
          service: {
            serviceLocationInfo: {
              service: {
                id: {
                  eq: serviceId,
                },
              },
            },
          },
        },
      }
    : {};

  const membershipFilter = membershipId
    ? {
        membershipOrderItem: {
          membership: {
            id: {
              eq: membershipId,
            },
          },
        },
      }
    : {};

  const classFilter = classId
    ? {
        classOrderItem: {
          class: {
            classLocationInfo: {
              class: {
                id: {
                  eq: classId,
                },
              },
            },
          },
        },
      }
    : {};

  return {
    or: [
      productFilter,
      compositeProductFilter,
      serviceFilter,
      membershipFilter,
      classFilter,
    ].filter((filterObject) => Object.keys(filterObject).length > 0),
  };
};

export const createSalesItemReportTypeFilter: FilterFunction<string> = (
  orderType?: string,
): SalesItemReportFiltersInput => {
  return orderType
    ? {
        type: {
          eq: orderType,
        },
      }
    : {};
};

export const createSalesItemReportBusinessLocationFilter: FilterFunction<
  LocationSublocationFilterInput
> = ({ locationIds, sublocation } = {}): SalesItemReportFiltersInput => {
  return locationIds?.length !== 0 || sublocation
    ? ({
        businessLocation: {
          id: {
            in: locationIds,
          },
        },
        sublocation: {
          id: {
            eq: sublocation,
          },
        },
      } as SalesItemReportFiltersInput)
    : {};
};

export const createSalesItemReportSalesFilter: FilterFunction<string> = (
  employee?: string,
): SalesItemReportFiltersInput => {
  return employee
    ? {
        sales: {
          id: {
            eq: employee,
          },
        },
      }
    : {};
};
