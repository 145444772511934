import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

      export type PossibleTypesResultData = {
  "possibleTypes": {
    "FindSlugResponse": [
      "TenantEntityResponse"
    ],
    "GenericMorph": [
      "Activity",
      "Appraisal",
      "ApprovalMethod",
      "Article",
      "AuthLayout",
      "Backing",
      "BoxPaper",
      "BusinessLocation",
      "Call",
      "Campaign",
      "CampaignEnrolledContact",
      "CampaignEnrolledLead",
      "Carrier",
      "ChartAccount",
      "ChartCategory",
      "ChartSubcategory",
      "ChatNotification",
      "Class",
      "ClassLocationInfo",
      "ClassOrderItem",
      "ClassPerformer",
      "Company",
      "ComponentDataCellValue",
      "ComponentDataEntry",
      "ComponentDataGeometry",
      "ComponentDataGraphItem",
      "ComponentDataScheduledMessage",
      "ComponentDataSet",
      "ComponentDataStyle",
      "ComponentUiAuthContent",
      "ComponentUiCard",
      "ComponentUiCustomerReviewSection",
      "ComponentUiExtendedSection",
      "ComponentUiGrid",
      "ComponentUiHeadline",
      "ComponentUiLink",
      "ComponentUiParagraph",
      "ComponentUiProductTypesVisibility",
      "ComponentUiReviewCard",
      "ComponentUiSection",
      "ComponentUiSectionsVisibility",
      "ComponentUiShipmentCost",
      "ComponentUiTab",
      "ComponentUiText",
      "ComponentUiWebsiteContacts",
      "ComponentUiWorkingHours",
      "CompositeProduct",
      "CompositeProductItemInfo",
      "CompositeProductLocationInfo",
      "CompositeProductOrderItem",
      "ConditionType",
      "Contact",
      "Contract",
      "ContractTemplate",
      "Conversation",
      "Country",
      "CrmCustomFieldName",
      "CrmCustomFieldValue",
      "CustomPermission",
      "Deal",
      "DealTransaction",
      "DealTransactionReminder",
      "DesignStyle",
      "Dimension",
      "Discount",
      "DiscountUsageEvent",
      "DocumentPermission",
      "DownloadRecord",
      "EmailDesignerEmailTemplate",
      "EngravingType",
      "EnrolledContactCondition",
      "EnrolledLeadCondition",
      "FileItem",
      "Form",
      "FormTemplate",
      "GenderType",
      "Home",
      "InventoryAdjustment",
      "InventoryAdjustmentItem",
      "InventoryAudit",
      "InventoryAuditItem",
      "InventoryQuantityNotification",
      "InventorySerialize",
      "Invoice",
      "InvoiceShippingContact",
      "JewelryProduct",
      "JewelryProductType",
      "KnotStyle",
      "Layout",
      "Lead",
      "LinkStyle",
      "LinkType",
      "Location",
      "MailTemplate",
      "Maintenance",
      "MaintenanceEvent",
      "MaintenanceQuantityNotification",
      "ManufacturingProcess",
      "MarketingCustomersReport",
      "MarketingEmailTemplate",
      "MaterialGrade",
      "Membership",
      "MembershipItem",
      "MembershipOrderItem",
      "MetalFinishType",
      "MetalType",
      "Note",
      "NotificationMethod",
      "NotificationsNylasGrantExpire",
      "NylasConnection",
      "Onboarding",
      "OnboardingUser",
      "Order",
      "OrderStatusNotification",
      "PayRate",
      "Piece",
      "Platform",
      "PlattingType",
      "Product",
      "ProductAttribute",
      "ProductAttributeOption",
      "ProductBrand",
      "ProductGroup",
      "ProductGroupAttribute",
      "ProductGroupAttributeOption",
      "ProductGroupItem",
      "ProductInventoryItem",
      "ProductInventoryItemEvent",
      "ProductOrderItem",
      "ProductType",
      "PublicContract",
      "PublicForm",
      "PurchaseRequest",
      "PurchaseRequestShippingInfo",
      "QuantityDifferenceType",
      "Question",
      "Rate",
      "ReactIconsIconlibrary",
      "RentableData",
      "ReportsSchedule",
      "Resource",
      "ResourceCount",
      "ResourceInventoryItem",
      "Return",
      "ReturnItem",
      "ReturnMethod",
      "SalesItemReport",
      "SchedulingAppointment",
      "SchedulingRecurrence",
      "SequenceStep",
      "SequenceStepInfo",
      "Service",
      "ServiceLocationInfo",
      "ServiceOrderItem",
      "ServicePerformer",
      "Sessions",
      "ShankStyle",
      "Shipment",
      "ShipmentCard",
      "ShipmentCarrier",
      "ShipmentPackage",
      "ShippingMethod",
      "Size",
      "SlugifySlug",
      "SpecificType",
      "StagingLog",
      "Strand",
      "StrandsLength",
      "StripeOnboarding",
      "Sublocation",
      "SublocationItem",
      "Task",
      "TaskLocation",
      "TaskStage",
      "TaskType",
      "Tax",
      "TaxAuthority",
      "TaxReport",
      "Tenant",
      "TimePeriod",
      "Todo",
      "Transaction",
      "TransferOrder",
      "TransferOrderItem",
      "TwilioConnection",
      "UploadFile",
      "UploadFolder",
      "UserNotification",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser",
      "Website",
      "Weight"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "FindSlugResponse": [
      "TenantEntityResponse"
    ],
    "GenericMorph": [
      "Activity",
      "Appraisal",
      "ApprovalMethod",
      "Article",
      "AuthLayout",
      "Backing",
      "BoxPaper",
      "BusinessLocation",
      "Call",
      "Campaign",
      "CampaignEnrolledContact",
      "CampaignEnrolledLead",
      "Carrier",
      "ChartAccount",
      "ChartCategory",
      "ChartSubcategory",
      "ChatNotification",
      "Class",
      "ClassLocationInfo",
      "ClassOrderItem",
      "ClassPerformer",
      "Company",
      "ComponentDataCellValue",
      "ComponentDataEntry",
      "ComponentDataGeometry",
      "ComponentDataGraphItem",
      "ComponentDataScheduledMessage",
      "ComponentDataSet",
      "ComponentDataStyle",
      "ComponentUiAuthContent",
      "ComponentUiCard",
      "ComponentUiCustomerReviewSection",
      "ComponentUiExtendedSection",
      "ComponentUiGrid",
      "ComponentUiHeadline",
      "ComponentUiLink",
      "ComponentUiParagraph",
      "ComponentUiProductTypesVisibility",
      "ComponentUiReviewCard",
      "ComponentUiSection",
      "ComponentUiSectionsVisibility",
      "ComponentUiShipmentCost",
      "ComponentUiTab",
      "ComponentUiText",
      "ComponentUiWebsiteContacts",
      "ComponentUiWorkingHours",
      "CompositeProduct",
      "CompositeProductItemInfo",
      "CompositeProductLocationInfo",
      "CompositeProductOrderItem",
      "ConditionType",
      "Contact",
      "Contract",
      "ContractTemplate",
      "Conversation",
      "Country",
      "CrmCustomFieldName",
      "CrmCustomFieldValue",
      "CustomPermission",
      "Deal",
      "DealTransaction",
      "DealTransactionReminder",
      "DesignStyle",
      "Dimension",
      "Discount",
      "DiscountUsageEvent",
      "DocumentPermission",
      "DownloadRecord",
      "EmailDesignerEmailTemplate",
      "EngravingType",
      "EnrolledContactCondition",
      "EnrolledLeadCondition",
      "FileItem",
      "Form",
      "FormTemplate",
      "GenderType",
      "Home",
      "InventoryAdjustment",
      "InventoryAdjustmentItem",
      "InventoryAudit",
      "InventoryAuditItem",
      "InventoryQuantityNotification",
      "InventorySerialize",
      "Invoice",
      "InvoiceShippingContact",
      "JewelryProduct",
      "JewelryProductType",
      "KnotStyle",
      "Layout",
      "Lead",
      "LinkStyle",
      "LinkType",
      "Location",
      "MailTemplate",
      "Maintenance",
      "MaintenanceEvent",
      "MaintenanceQuantityNotification",
      "ManufacturingProcess",
      "MarketingCustomersReport",
      "MarketingEmailTemplate",
      "MaterialGrade",
      "Membership",
      "MembershipItem",
      "MembershipOrderItem",
      "MetalFinishType",
      "MetalType",
      "Note",
      "NotificationMethod",
      "NotificationsNylasGrantExpire",
      "NylasConnection",
      "Onboarding",
      "OnboardingUser",
      "Order",
      "OrderStatusNotification",
      "PayRate",
      "Piece",
      "Platform",
      "PlattingType",
      "Product",
      "ProductAttribute",
      "ProductAttributeOption",
      "ProductBrand",
      "ProductGroup",
      "ProductGroupAttribute",
      "ProductGroupAttributeOption",
      "ProductGroupItem",
      "ProductInventoryItem",
      "ProductInventoryItemEvent",
      "ProductOrderItem",
      "ProductType",
      "PublicContract",
      "PublicForm",
      "PurchaseRequest",
      "PurchaseRequestShippingInfo",
      "QuantityDifferenceType",
      "Question",
      "Rate",
      "ReactIconsIconlibrary",
      "RentableData",
      "ReportsSchedule",
      "Resource",
      "ResourceCount",
      "ResourceInventoryItem",
      "Return",
      "ReturnItem",
      "ReturnMethod",
      "SalesItemReport",
      "SchedulingAppointment",
      "SchedulingRecurrence",
      "SequenceStep",
      "SequenceStepInfo",
      "Service",
      "ServiceLocationInfo",
      "ServiceOrderItem",
      "ServicePerformer",
      "Sessions",
      "ShankStyle",
      "Shipment",
      "ShipmentCard",
      "ShipmentCarrier",
      "ShipmentPackage",
      "ShippingMethod",
      "Size",
      "SlugifySlug",
      "SpecificType",
      "StagingLog",
      "Strand",
      "StrandsLength",
      "StripeOnboarding",
      "Sublocation",
      "SublocationItem",
      "Task",
      "TaskLocation",
      "TaskStage",
      "TaskType",
      "Tax",
      "TaxAuthority",
      "TaxReport",
      "Tenant",
      "TimePeriod",
      "Todo",
      "Transaction",
      "TransferOrder",
      "TransferOrderItem",
      "TwilioConnection",
      "UploadFile",
      "UploadFolder",
      "UserNotification",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser",
      "Website",
      "Weight"
    ]
  }
};
      export default result;
    
export const ActivityMinFragmentDoc = gql`
    fragment ActivityMin on ActivityEntity {
  id
  attributes {
    title
    description
    due_date
    notes
    amount
    type
    completed
    priority
  }
}
    `;
export const FileFragmentDoc = gql`
    fragment File on UploadFileEntity {
  id
  attributes {
    previewUrl
    alternativeText
    url
    size
    name
  }
}
    `;
export const SchedulingAppointmentMinFragmentDoc = gql`
    fragment SchedulingAppointmentMin on SchedulingAppointmentEntity {
  id
  attributes {
    startTime
    endTime
    createdAt
  }
}
    `;
export const UserMinFragmentDoc = gql`
    fragment UserMin on UsersPermissionsUserEntity {
  id
  attributes {
    fullName
    email
    jobTitle
    avatar {
      data {
        ...File
      }
    }
    schedulingAppointments {
      data {
        ...SchedulingAppointmentMin
      }
    }
  }
}
    `;
export const ContactMinFragmentDoc = gql`
    fragment ContactMin on ContactEntity {
  id
  attributes {
    uuid
    email
    fullName
    phoneNumber
    leadSource
    getCreateDate
    amountSpent
    amountOwes
    avatar {
      data {
        ...File
      }
    }
    address
    points
    birthdayDate
    jobTitle
    anniversaryDate
  }
}
    `;
export const RoleFragmentDoc = gql`
    fragment Role on UsersPermissionsRoleEntityResponse {
  data {
    id
    attributes {
      name
      description
      type
    }
  }
}
    `;
export const LocationFragmentDoc = gql`
    fragment Location on LocationEntity {
  id
  attributes {
    address
    zipcode
  }
}
    `;
export const TwilioConnectionFragmentDoc = gql`
    fragment TwilioConnection on TwilioConnectionEntity {
  id
  attributes {
    token
  }
}
    `;
export const TenantFragmentDoc = gql`
    fragment Tenant on TenantEntity {
  id
  attributes {
    companyName
    slug
    email
    phoneNumber
    websiteUrl
    logo {
      data {
        ...File
      }
    }
    mainLocation {
      data {
        ...Location
      }
    }
    twilioConnection {
      data {
        ...TwilioConnection
      }
    }
  }
}
    `;
export const MailTemplateMinFragmentDoc = gql`
    fragment MailTemplateMin on MailTemplateEntity {
  id
  attributes {
    name
    text
  }
}
    `;
export const MailTemplateFragmentDoc = gql`
    fragment MailTemplate on MailTemplateEntity {
  ...MailTemplateMin
  id
  attributes {
    owner {
      data {
        ...UserMin
      }
    }
  }
}
    `;
export const TaxMinFragmentDoc = gql`
    fragment TaxMin on TaxEntity {
  id
  attributes {
    name
    rate
    createdAt
    fixedFee
    perUnitFee
  }
}
    `;
export const SubLocationMinFragmentDoc = gql`
    fragment SubLocationMin on SublocationEntity {
  id
  attributes {
    name
    regexedId
  }
}
    `;
export const BusinessLocationFragmentDoc = gql`
    fragment BusinessLocation on BusinessLocationEntity {
  id
  attributes {
    type
    name
    email
    phoneNumber
    graphItem {
      cellValue {
        cellID
        label
        storeID
        shapeType
      }
      style {
        fillColor
        fontFamily
        fontSize
        ignoreDefaultStyle
        image
        labelPadding
        overflow
        rotation
        shape
        spacing
        strokeColor
        strokeWidth
        whiteSpace
      }
      geometry {
        height
        width
        x
        y
      }
    }
    location {
      data {
        ...Location
      }
    }
    tax {
      data {
        ...TaxMin
      }
    }
    businessLocationId
    sublocations {
      data {
        ...SubLocationMin
      }
    }
    createdAt
    uuid
    archived
  }
}
    `;
export const RateMinFragmentDoc = gql`
    fragment RateMin on RateEntity {
  id
  attributes {
    createdAt
    suggestions
    isRecommended
    isLoved
    evaluation
  }
}
    `;
export const PayRateFragmentDoc = gql`
    fragment PayRate on PayRateEntity {
  id
  attributes {
    rate
    period
  }
}
    `;
export const ReportsScheduleMinFragmentDoc = gql`
    fragment ReportsScheduleMin on ReportsScheduleEntity {
  id
  attributes {
    scheduleCustomersReport
    scheduleInventoryReport
    scheduleMarketingReport
    scheduleSalesReport
    scheduleSalesItemReport
    scheduleTaxesReport
  }
}
    `;
export const OnboardingUserFragmentDoc = gql`
    fragment OnboardingUser on OnboardingUserEntity {
  id
  attributes {
    isCompleted
    isNylasConnected
    isPasswordChanged
  }
}
    `;
export const UserFragmentDoc = gql`
    fragment User on UsersPermissionsUserEntity {
  ...UserMin
  id
  attributes {
    confirmed
    firstName
    lastName
    phoneNumber
    createdAt
    blocked
    fullName
    jobTitle
    contact {
      data {
        ...ContactMin
      }
    }
    role {
      ...Role
    }
    tenant {
      data {
        ...Tenant
      }
    }
    mail_templates {
      data {
        ...MailTemplate
      }
    }
    businessLocation {
      data {
        ...BusinessLocation
      }
    }
    rate {
      data {
        ...RateMin
      }
    }
    payRate {
      data {
        ...PayRate
      }
    }
    reportsSchedule {
      data {
        ...ReportsScheduleMin
      }
    }
    nylasConnection {
      data {
        id
        attributes {
          status
          attachedEmail
        }
      }
    }
    onboardingUser {
      data {
        ...OnboardingUser
      }
    }
  }
}
    `;
export const CompanyMinFragmentDoc = gql`
    fragment CompanyMin on CompanyEntity {
  id
  attributes {
    name
    avatar {
      data {
        ...File
      }
    }
    uuid
    website
    address
    email
    phoneNumber
    type
    industry
    createdAt
    points
  }
}
    `;
export const LeadMinFragmentDoc = gql`
    fragment LeadMin on LeadEntity {
  id
  attributes {
    uuid
    email
    fullName
    phoneNumber
    address
    leadSource
    leadStage
    avatar {
      data {
        ...File
      }
    }
    createdAt
  }
}
    `;
export const LeadFragmentDoc = gql`
    fragment Lead on LeadEntity {
  ...LeadMin
  id
  attributes {
    user {
      data {
        ...User
      }
    }
    leadOwner {
      data {
        ...User
      }
    }
    fileItems {
      data {
        id
      }
    }
    customFields
  }
}
    `;
export const TaskMinFragmentDoc = gql`
    fragment TaskMin on TaskEntity {
  id
  attributes {
    createdAt
    name
    dueDate
    note
    priority
    description
    completed
    approval
    approvalDueDate
  }
}
    `;
export const TaskLocationMinFragmentDoc = gql`
    fragment TaskLocationMin on TaskLocationEntity {
  id
  attributes {
    createdAt
    name
  }
}
    `;
export const TaskTypeMinFragmentDoc = gql`
    fragment TaskTypeMin on TaskTypeEntity {
  id
  attributes {
    createdAt
    name
  }
}
    `;
export const TaskTypeFragmentDoc = gql`
    fragment TaskType on TaskTypeEntity {
  ...TaskTypeMin
  id
  attributes {
    createdAt
  }
}
    `;
export const TaskStageMinFragmentDoc = gql`
    fragment TaskStageMin on TaskStageEntity {
  id
  attributes {
    name
    createdAt
  }
}
    `;
export const TaskStageFragmentDoc = gql`
    fragment TaskStage on TaskStageEntity {
  ...TaskStageMin
  id
  attributes {
    createdAt
  }
}
    `;
export const ApprovalMethodMinFragmentDoc = gql`
    fragment ApprovalMethodMin on ApprovalMethodEntity {
  id
  attributes {
    createdAt
    name
  }
}
    `;
export const OrderMinFragmentDoc = gql`
    fragment OrderMin on OrderEntity {
  id
  attributes {
    orderId
    createdAt
    total
    subTotal
    dueDate
    completed
    status
    paymentMethod
    deliveryMethod
    discount
    shipment
    tax
    tip
    recurringAmount
    recurringPeriod
    recurringPeriodCount
    points
    itemsAmount
    paidSummary
    type
    createdAt
    rentDueDate
    isWarranty
    memo
    getCreateDate
    expiryDate
  }
}
    `;
export const TaskFragmentDoc = gql`
    fragment Task on TaskEntity {
  ...TaskMin
  id
  attributes {
    assignees {
      data {
        ...UserMin
      }
    }
    lead {
      data {
        ...LeadMin
      }
    }
    company {
      data {
        ...CompanyMin
      }
    }
    contact {
      data {
        ...ContactMin
      }
    }
    taskLocation {
      data {
        ...TaskLocationMin
      }
    }
    taskType {
      data {
        ...TaskType
      }
    }
    taskStage {
      data {
        ...TaskStage
      }
    }
    approvalMethods {
      data {
        ...ApprovalMethodMin
      }
    }
    files {
      data {
        ...File
      }
    }
    forCompanies {
      data {
        ...CompanyMin
      }
    }
    currentVendor {
      data {
        ...CompanyMin
      }
    }
    order {
      data {
        ...OrderMin
      }
    }
  }
}
    `;
export const ProductOrderItemMinFragmentDoc = gql`
    fragment ProductOrderItemMin on ProductOrderItemEntity {
  id
  attributes {
    quantity
    purchaseType
    itemId
    price
    isShowInvoiceNote
    isVisibleInDocs
    isCompositeProductItem
  }
}
    `;
export const SerializeFragmentDoc = gql`
    fragment Serialize on InventorySerializeEntity {
  id
  attributes {
    editable
    name
  }
}
    `;
export const DiscountMinFragmentDoc = gql`
    fragment DiscountMin on DiscountEntity {
  id
  attributes {
    name
    notes
    description
    code
    usageLimit
    usagesLeft
    startDate
    endDate
    active
    amount
    type
    uuid
  }
}
    `;
export const SubLocationItemMinFragmentDoc = gql`
    fragment SubLocationItemMin on SublocationItemEntity {
  id
  attributes {
    quantity
    actualQty
    scannedQty
  }
}
    `;
export const DimensionFragmentDoc = gql`
    fragment Dimension on DimensionEntity {
  id
  attributes {
    height
    length
    width
    unit
  }
}
    `;
export const WeightFragmentDoc = gql`
    fragment Weight on WeightEntity {
  id
  attributes {
    value
    unit
  }
}
    `;
export const ProductBrandFragmentDoc = gql`
    fragment ProductBrand on ProductBrandEntity {
  id
  attributes {
    name
    editable
  }
}
    `;
export const ProductTypeFragmentDoc = gql`
    fragment ProductType on ProductTypeEntity {
  id
  attributes {
    name
    editable
  }
}
    `;
export const RentableDataFragmentDoc = gql`
    fragment RentableData on RentableDataEntity {
  id
  attributes {
    pricePerPeriod
    lostFee
    minimumRentalPeriod
    period
    enabled
  }
}
    `;
export const ProductMinFragmentDoc = gql`
    fragment ProductMin on ProductEntity {
  id
  attributes {
    defaultPrice
    productId
    name
    barcode
    uuid
    favorite
    model
    serialNumber
    SKU
    UPC
    MPN
    EAN
    ISBN
    partsWarranty
    laborWarranty
    ecommerceName
    note
    appraisalDescription
    businessUseOnly
    bundleUseOnly
    expiryDate
    returnable
    packagingProduct
    active
    isNegativeCount
    multiplier
    wholeSaleMultiplier
    files {
      data {
        ...File
      }
    }
    dimension {
      data {
        ...Dimension
      }
    }
    weight {
      data {
        ...Weight
      }
    }
    brand {
      data {
        ...ProductBrand
      }
    }
    productType {
      data {
        ...ProductType
      }
    }
    rentableData {
      data {
        ...RentableData
      }
    }
  }
}
    `;
export const TaxAuthorityMinFragmentDoc = gql`
    fragment TaxAuthorityMin on TaxAuthorityEntity {
  id
  attributes {
    name
  }
}
    `;
export const TaxFragmentDoc = gql`
    fragment Tax on TaxEntity {
  id
  attributes {
    name
    rate
    createdAt
    taxAuthority {
      data {
        ...TaxAuthorityMin
      }
    }
    fixedFee
    perUnitFee
  }
}
    `;
export const ProductInventoryItemFragmentDoc = gql`
    fragment ProductInventoryItem on ProductInventoryItemEntity {
  id
  attributes {
    price
    quantity
    lowQuantity
    maxQuantity
    minOrderQuantity
    pointsGiven
    pointsRedeemed
    storageNotes
    favorite
    isNegativeCount
    isSerializedInventory
    uuid
    active
    rentalPrice
    wholeSalePrice
    productOrderItems {
      data {
        ...ProductOrderItemMin
      }
    }
    vendor {
      data {
        ...CompanyMin
      }
    }
    sublocation {
      data {
        ...SubLocationMin
      }
    }
    sublocationItems {
      data {
        ...SubLocationItemMin
      }
    }
    product {
      data {
        ...ProductMin
      }
    }
    serializes {
      data {
        ...Serialize
      }
    }
    businessLocation {
      data {
        ...BusinessLocation
      }
    }
    tax {
      data {
        ...Tax
      }
    }
  }
}
    `;
export const ProductOrderItemFragmentDoc = gql`
    fragment ProductOrderItem on ProductOrderItemEntity {
  ...ProductOrderItemMin
  id
  attributes {
    __typename
    status
    note
    rentStart
    rentEnd
    serializes {
      data {
        ...Serialize
      }
    }
    discounts {
      data {
        ...DiscountMin
      }
    }
    product {
      data {
        ...ProductInventoryItem
      }
    }
    order {
      data {
        ...OrderMin
      }
    }
    tax {
      data {
        ...TaxMin
      }
    }
    totalPricePerItem
    discountAmountPerItem
    sublocations {
      data {
        ...SubLocationMin
      }
    }
    sublocationItems {
      data {
        ...SubLocationItemMin
      }
    }
  }
}
    `;
export const CompositeProductLocationInfoMinFragmentDoc = gql`
    fragment CompositeProductLocationInfoMin on CompositeProductLocationInfoEntity {
  id
  attributes {
    businessLocation {
      data {
        ...BusinessLocation
      }
    }
    price
    favorite
    active
  }
}
    `;
export const CompositeProductMinFragmentDoc = gql`
    fragment CompositeProductMin on CompositeProductEntity {
  id
  attributes {
    name
    code
    description
    defaultPrice
    active
    notes
    uuid
    favorite
    createdAt
  }
}
    `;
export const ProductAttributeOptionMinFragmentDoc = gql`
    fragment ProductAttributeOptionMin on ProductAttributeOptionEntity {
  id
  attributes {
    name
    createdAt
  }
}
    `;
export const ProductAttributeMinFragmentDoc = gql`
    fragment ProductAttributeMin on ProductAttributeEntity {
  id
  attributes {
    name
  }
}
    `;
export const DesignStyleFragmentDoc = gql`
    fragment DesignStyle on DesignStyleEntity {
  id
  attributes {
    name
  }
}
    `;
export const ShankStyleFragmentDoc = gql`
    fragment ShankStyle on ShankStyleEntity {
  id
  attributes {
    name
  }
}
    `;
export const SizeFragmentDoc = gql`
    fragment Size on SizeEntity {
  id
  attributes {
    name
  }
}
    `;
export const JewelryProductTypeFragmentDoc = gql`
    fragment JewelryProductType on JewelryProductTypeEntity {
  id
  attributes {
    name
  }
}
    `;
export const SpecificTypeFragmentDoc = gql`
    fragment SpecificType on SpecificTypeEntity {
  id
  attributes {
    name
  }
}
    `;
export const EngravingTypeFragmentDoc = gql`
    fragment EngravingType on EngravingTypeEntity {
  id
  attributes {
    name
  }
}
    `;
export const TimePeriodFragmentDoc = gql`
    fragment TimePeriod on TimePeriodEntity {
  id
  attributes {
    name
  }
}
    `;
export const MetalFinishTypeFragmentDoc = gql`
    fragment MetalFinishType on MetalFinishTypeEntity {
  id
  attributes {
    name
  }
}
    `;
export const MaterialGradeFragmentDoc = gql`
    fragment MaterialGrade on MaterialGradeEntity {
  id
  attributes {
    name
  }
}
    `;
export const MetalTypeFragmentDoc = gql`
    fragment MetalType on MetalTypeEntity {
  id
  attributes {
    name
    materialGrades {
      data {
        ...MaterialGrade
      }
    }
  }
}
    `;
export const JewelryGenderTypeFragmentDoc = gql`
    fragment JewelryGenderType on GenderTypeEntity {
  id
  attributes {
    name
  }
}
    `;
export const JewelryConditionTypeFragmentDoc = gql`
    fragment JewelryConditionType on ConditionTypeEntity {
  id
  attributes {
    name
  }
}
    `;
export const PlattingTypeFragmentDoc = gql`
    fragment PlattingType on PlattingTypeEntity {
  id
  attributes {
    name
  }
}
    `;
export const ManufacturingProcessFragmentDoc = gql`
    fragment ManufacturingProcess on ManufacturingProcessEntity {
  id
  attributes {
    name
  }
}
    `;
export const PieceFragmentDoc = gql`
    fragment Piece on PieceEntity {
  id
  attributes {
    name
  }
}
    `;
export const JewelryProductMinFragmentDoc = gql`
    fragment JewelryProductMin on JewelryProductEntity {
  id
  attributes {
    uuid
    name
    marginCost
    files {
      data {
        ...File
      }
    }
    description
    createdAt
    barcode
    defaultPrice
    designStyle {
      data {
        ...DesignStyle
      }
    }
    shankStyle {
      data {
        ...ShankStyle
      }
    }
    size {
      data {
        ...Size
      }
    }
    model
    jewelryProductType {
      data {
        ...JewelryProductType
      }
    }
    specificType {
      data {
        ...SpecificType
      }
    }
    engravingType {
      data {
        ...EngravingType
      }
    }
    timePeriod {
      data {
        ...TimePeriod
      }
    }
    metalFinishType {
      data {
        ...MetalFinishType
      }
    }
    metalType {
      data {
        ...MetalType
      }
    }
    materialGradeType {
      data {
        ...MaterialGrade
      }
    }
    genderType {
      data {
        ...JewelryGenderType
      }
    }
    conditionType {
      data {
        ...JewelryConditionType
      }
    }
    platting {
      data {
        ...PlattingType
      }
    }
    process {
      data {
        ...ManufacturingProcess
      }
    }
    pieces {
      data {
        ...Piece
      }
    }
    serialNumber
    brand {
      data {
        ...ProductBrand
      }
    }
    rentableData {
      data {
        ...RentableData
      }
    }
    partsWarranty
    returnable
    bundleUseOnly
    businessUseOnly
    laborWarranty
    packagingProduct
    tagProductName
    expiryDate
    dimension {
      data {
        ...Dimension
      }
    }
    weight {
      data {
        ...Weight
      }
    }
    SKU
    ISBN
    MPN
    UPC
    EAN
  }
}
    `;
export const ProductAttributeOptionFragmentDoc = gql`
    fragment ProductAttributeOption on ProductAttributeOptionEntity {
  ...ProductAttributeOptionMin
  id
  attributes {
    productAttribute {
      data {
        ...ProductAttributeMin
      }
    }
    jewelryProducts {
      data {
        ...JewelryProductMin
      }
    }
    products {
      data {
        ...ProductMin
      }
    }
  }
}
    `;
export const ProductFragmentDoc = gql`
    fragment Product on ProductEntity {
  ...ProductMin
  id
  attributes {
    productInventoryItems {
      data {
        ...ProductInventoryItem
      }
    }
    productAttributeOptions {
      data {
        ...ProductAttributeOption
      }
    }
  }
}
    `;
export const CompositeProductItemInfoMinFragmentDoc = gql`
    fragment CompositeProductItemInfoMin on CompositeProductItemInfoEntity {
  id
  attributes {
    createdAt
    quantity
  }
}
    `;
export const CompositeProductItemInfoFragmentDoc = gql`
    fragment CompositeProductItemInfo on CompositeProductItemInfoEntity {
  ...CompositeProductItemInfoMin
  id
  attributes {
    product {
      data {
        ...ProductMin
      }
    }
  }
}
    `;
export const CompositeProductFragmentDoc = gql`
    fragment CompositeProduct on CompositeProductEntity {
  ...CompositeProductMin
  id
  attributes {
    products {
      data {
        ...Product
      }
    }
    compositeProductLocationInfos {
      data {
        ...CompositeProductLocationInfoMin
      }
    }
    compositeProductItems {
      data {
        ...CompositeProductItemInfo
      }
    }
  }
}
    `;
export const CompositeProductLocationInfoFragmentDoc = gql`
    fragment CompositeProductLocationInfo on CompositeProductLocationInfoEntity {
  ...CompositeProductLocationInfoMin
  id
  attributes {
    compositeProduct {
      data {
        ...CompositeProduct
      }
    }
  }
}
    `;
export const CompositeProductOrderItemFragmentDoc = gql`
    fragment CompositeProductOrderItem on CompositeProductOrderItemEntity {
  id
  attributes {
    quantity
    purchaseType
    note
    itemId
    price
    status
    isShowInvoiceNote
    isVisibleInDocs
    discounts {
      data {
        ...DiscountMin
      }
    }
    compositeProduct {
      data {
        ...CompositeProductLocationInfo
      }
    }
    order {
      data {
        ...OrderMin
      }
    }
    tax {
      data {
        ...TaxMin
      }
    }
    productOrderItems {
      data {
        id
        attributes {
          __typename
          quantity
          product {
            data {
              id
              attributes {
                product {
                  data {
                    ...ProductMin
                  }
                }
              }
            }
          }
        }
      }
    }
    __typename
    discountAmountPerItem
  }
}
    `;
export const ServiceMinFragmentDoc = gql`
    fragment ServiceMin on ServiceEntity {
  id
  attributes {
    name
    description
    defaultPrice
    uuid
    favorite
    createdAt
    active
    serviceId
    files {
      data {
        ...File
      }
    }
    houseCall
    tax {
      data {
        ...Tax
      }
    }
  }
}
    `;
export const ResourceMinFragmentDoc = gql`
    fragment ResourceMin on ResourceEntity {
  id
  attributes {
    name
    maintenanceNeeded
    createdAt
    uuid
  }
}
    `;
export const ResourceInventoryItemFragmentDoc = gql`
    fragment ResourceInventoryItem on ResourceInventoryItemEntity {
  id
  attributes {
    store {
      data {
        ...BusinessLocation
      }
    }
    quantity
    resource {
      data {
        ...ResourceMin
      }
    }
  }
}
    `;
export const ResourceCountFragmentDoc = gql`
    fragment ResourceCount on ResourceCountEntity {
  id
  attributes {
    count
    resourceInventoryItem {
      data {
        ...ResourceInventoryItem
      }
    }
  }
}
    `;
export const ServiceLocationInfoMinFragmentDoc = gql`
    fragment ServiceLocationInfoMin on ServiceLocationInfoEntity {
  id
  attributes {
    favorite
    service {
      data {
        ...ServiceMin
      }
    }
    businessLocation {
      data {
        ...BusinessLocation
      }
    }
    resourceCounts {
      data {
        ...ResourceCount
      }
    }
  }
}
    `;
export const ServicePerformerFragmentDoc = gql`
    fragment ServicePerformer on ServicePerformerEntity {
  id
  attributes {
    price
    uuid
    performer {
      data {
        ...UserMin
      }
    }
    serviceLocationInfo {
      data {
        ...ServiceLocationInfoMin
      }
    }
    duration
    pointsGiven
    active
  }
}
    `;
export const ServiceOrderItemFragmentDoc = gql`
    fragment ServiceOrderItem on ServiceOrderItemEntity {
  id
  attributes {
    quantity
    purchaseType
    status
    note
    itemId
    price
    dueDate
    isShowInvoiceNote
    isVisibleInDocs
    discounts {
      data {
        ...DiscountMin
      }
    }
    service {
      data {
        ...ServicePerformer
      }
    }
    order {
      data {
        ...OrderMin
      }
    }
    tax {
      data {
        ...TaxMin
      }
    }
    __typename
    discountAmountPerItem
  }
}
    `;
export const ClassMinFragmentDoc = gql`
    fragment ClassMin on ClassEntity {
  id
  attributes {
    name
    description
    uuid
    favorite
    defaultPrice
    createdAt
    showOnline
    workshop
    active
    classId
    tax {
      data {
        ...Tax
      }
    }
    files {
      data {
        ...File
      }
    }
  }
}
    `;
export const ClassLocationInfoMinFragmentDoc = gql`
    fragment ClassLocationInfoMin on ClassLocationInfoEntity {
  id
  attributes {
    favorite
    class {
      data {
        ...ClassMin
      }
    }
    businessLocation {
      data {
        ...BusinessLocation
      }
    }
    resourceCounts {
      data {
        ...ResourceCount
      }
    }
  }
}
    `;
export const ClassPerformerFragmentDoc = gql`
    fragment ClassPerformer on ClassPerformerEntity {
  id
  attributes {
    duration
    pointsGiven
    pointsRedeem
    price
    numberOfSessions
    liveStreamPrice
    uuid
    active
    performer {
      data {
        ...UserMin
      }
    }
    classLocationInfo {
      data {
        ...ClassLocationInfoMin
      }
    }
    createdAt
  }
}
    `;
export const ClassOrderItemFragmentDoc = gql`
    fragment ClassOrderItem on ClassOrderItemEntity {
  id
  attributes {
    quantity
    itemId
    note
    price
    status
    isShowInvoiceNote
    isVisibleInDocs
    discounts {
      data {
        ...DiscountMin
      }
    }
    order {
      data {
        ...OrderMin
      }
    }
    class {
      data {
        ...ClassPerformer
      }
    }
    tax {
      data {
        ...TaxMin
      }
    }
    __typename
    discountAmountPerItem
  }
}
    `;
export const MembershipMinFragmentDoc = gql`
    fragment MembershipMin on MembershipEntity {
  id
  attributes {
    name
    description
    price
    rewardPoints
    durationCount
    durationPeriod
    active
    membershipId
    tax {
      data {
        ...Tax
      }
    }
    favorite
    uuid
  }
}
    `;
export const MembershipOrderItemFragmentDoc = gql`
    fragment MembershipOrderItem on MembershipOrderItemEntity {
  id
  attributes {
    quantity
    itemId
    note
    status
    price
    isShowInvoiceNote
    isVisibleInDocs
    discounts {
      data {
        ...DiscountMin
      }
    }
    membership {
      data {
        ...MembershipMin
      }
    }
    tax {
      data {
        ...TaxMin
      }
    }
    __typename
    discountAmountPerItem
  }
}
    `;
export const OrderWithItemsFragmentDoc = gql`
    fragment OrderWithItems on OrderEntity {
  id
  attributes {
    orderId
    products {
      data {
        ...ProductOrderItem
      }
    }
    compositeProducts {
      data {
        ...CompositeProductOrderItem
      }
    }
    services {
      data {
        ...ServiceOrderItem
      }
    }
    classes {
      data {
        ...ClassOrderItem
      }
    }
    memberships {
      data {
        ...MembershipOrderItem
      }
    }
  }
}
    `;
export const ContactDynamicValuesFragmentDoc = gql`
    fragment ContactDynamicValues on ContactEntity {
  id
  attributes {
    totalItemsPurchased
    calculatedSpent
    lastPurchaseDate
    annualRevenue
    numberOfTransactions
    biggestTransaction
    calculatedOwes
    calculatedCustomFields
  }
}
    `;
export const ContactFragmentDoc = gql`
    fragment Contact on ContactEntity {
  ...ContactMin
  ...ContactDynamicValues
  id
  attributes {
    lead {
      data {
        ...Lead
      }
    }
    company {
      data {
        ...CompanyMin
      }
    }
    leadOwner {
      data {
        ...User
      }
    }
    avatar {
      data {
        ...File
      }
    }
    fileItems {
      data {
        id
      }
    }
  }
}
    `;
export const CompanyFragmentDoc = gql`
    fragment Company on CompanyEntity {
  ...CompanyMin
  id
  attributes {
    contacts {
      data {
        ...ContactMin
      }
    }
    leadOwner {
      data {
        ...User
      }
    }
    fileItems {
      data {
        id
      }
    }
    customFields
  }
}
    `;
export const DealTransactionMinFragmentDoc = gql`
    fragment DealTransactionMin on DealTransactionEntity {
  id
  attributes {
    dueDate
    paid
    status
    summary
    createdAt
    updatedAt
    repetitive
    note
    paymentMethod
    dealTransactionId
  }
}
    `;
export const InvoiceMinFragmentDoc = gql`
    fragment InvoiceMin on InvoiceEntity {
  id
  attributes {
    createdAt
    uuid
    invoiceId
    clientMessage
    comment
    terms
    isShowOrderItemsImages
  }
}
    `;
export const InvoiceShippingContactFragmentDoc = gql`
    fragment InvoiceShippingContact on InvoiceShippingContactEntity {
  id
  attributes {
    name
    address
    email
    phoneNumber
  }
}
    `;
export const FileItemMinFragmentDoc = gql`
    fragment FileItemMin on FileItemEntity {
  id
  attributes {
    createdAt
    name
    type
    isFavourite
    attachedFile {
      data {
        ...File
      }
    }
  }
}
    `;
export const FileItemFragmentDoc = gql`
    fragment FileItem on FileItemEntity {
  ...FileItemMin
  id
  attributes {
    uploadedBy {
      data {
        ...UserMin
      }
    }
  }
}
    `;
export const InvoiceWithFileFragmentDoc = gql`
    fragment InvoiceWithFile on InvoiceEntity {
  ...InvoiceMin
  id
  attributes {
    shippingContact {
      data {
        ...InvoiceShippingContact
      }
    }
    fileItem {
      data {
        ...FileItem
      }
    }
  }
}
    `;
export const PurchaseRequestMinFragmentDoc = gql`
    fragment PurchaseRequestMin on PurchaseRequestEntity {
  id
  attributes {
    createdAt
    uuid
    requestId
    terms
  }
}
    `;
export const PurchaseRequestShippingInfoMinFragmentDoc = gql`
    fragment PurchaseRequestShippingInfoMin on PurchaseRequestShippingInfoEntity {
  id
  attributes {
    createdAt
    name
    email
    address
    phoneNumber
  }
}
    `;
export const PurchaseRequestWithFileFragmentDoc = gql`
    fragment PurchaseRequestWithFile on PurchaseRequestEntity {
  ...PurchaseRequestMin
  id
  attributes {
    tenant {
      data {
        ...Tenant
      }
    }
    shippingInfo {
      data {
        ...PurchaseRequestShippingInfoMin
      }
    }
    fileItem {
      data {
        ...FileItem
      }
    }
  }
}
    `;
export const SalesItemReportMinFragmentDoc = gql`
    fragment SalesItemReportMin on SalesItemReportEntity {
  id
  attributes {
    createdAt
    type
    price
    grossMargin
    currentPaid
    soldDate
    dueDate
    age
  }
}
    `;
export const OrderFragmentDoc = gql`
    fragment Order on OrderEntity {
  ...OrderMin
  ...OrderWithItems
  id
  attributes {
    contact {
      data {
        ...Contact
      }
    }
    company {
      data {
        ...Company
      }
    }
    sales {
      data {
        ...User
      }
    }
    businessLocation {
      data {
        ...BusinessLocation
      }
    }
    discounts {
      data {
        ...DiscountMin
      }
    }
    files {
      data {
        ...File
      }
    }
    dealTransactions {
      data {
        ...DealTransactionMin
      }
    }
    invoice {
      data {
        ...InvoiceWithFile
      }
    }
    purchaseRequest {
      data {
        ...PurchaseRequestWithFile
      }
    }
    tasks {
      data {
        ...TaskMin
      }
    }
    salesItemReports {
      data {
        ...SalesItemReportMin
      }
    }
  }
}
    `;
export const ActivityFragmentDoc = gql`
    fragment Activity on ActivityEntity {
  ...ActivityMin
  id
  attributes {
    assignees {
      data {
        ...User
      }
    }
    company_id {
      data {
        ...CompanyMin
      }
    }
    contact_id {
      data {
        ...ContactMin
      }
    }
    lead_id {
      data {
        ...Lead
      }
    }
    owner {
      data {
        ...User
      }
    }
    task {
      data {
        ...Task
      }
    }
    order {
      data {
        ...Order
      }
    }
  }
}
    `;
export const ApprovalMethodFragmentDoc = gql`
    fragment ApprovalMethod on ApprovalMethodEntity {
  ...ApprovalMethodMin
  id
  attributes {
    createdAt
  }
}
    `;
export const ArticleMinFragmentDoc = gql`
    fragment ArticleMin on ArticleEntity {
  id
  attributes {
    title
    body
    active
    sentToSubscribers
    image {
      data {
        ...File
      }
    }
    createdAt
    updatedAt
  }
}
    `;
export const ArticleFragmentDoc = gql`
    fragment Article on ArticleEntity {
  ...ArticleMin
  id
  attributes {
    owner {
      data {
        id
      }
    }
  }
}
    `;
export const HeadlineFragmentDoc = gql`
    fragment Headline on ComponentUiHeadline {
  id
  title
  subtitle
}
    `;
export const AuthContentFragmentDoc = gql`
    fragment AuthContent on ComponentUiAuthContent {
  id
  background {
    data {
      ...File
    }
  }
  headline {
    ...Headline
  }
  advantages {
    ...Headline
  }
}
    `;
export const CampaignMinFragmentDoc = gql`
    fragment CampaignMin on CampaignEntity {
  id
  attributes {
    createdAt
    uuid
    description
    name
  }
}
    `;
export const SequenceStepsInfoFragmentDoc = gql`
    fragment SequenceStepsInfo on SequenceStepInfoEntity {
  id
  attributes {
    title
    description
  }
}
    `;
export const MarketingEmailTemplateMinFragmentDoc = gql`
    fragment MarketingEmailTemplateMin on MarketingEmailTemplateEntity {
  id
  attributes {
    createdAt
    name
    subject
    uuid
    templateJSON
    templateHtml
  }
}
    `;
export const SequenceStepFragmentDoc = gql`
    fragment SequenceStep on SequenceStepEntity {
  id
  attributes {
    name
    type
    sequentialNumber
    content
    delay
    info {
      data {
        ...SequenceStepsInfo
      }
    }
    createdAt
    publishedAt
    campaign {
      data {
        ...CampaignMin
      }
    }
    emailTemplate {
      data {
        ...MarketingEmailTemplateMin
      }
    }
  }
}
    `;
export const CampaignEnrolledContactFragmentDoc = gql`
    fragment CampaignEnrolledContact on CampaignEnrolledContactEntity {
  id
  attributes {
    type
    isUnsubscribed
    sequenceStep
    createdAt
    token
    campaign {
      data {
        ...CampaignMin
      }
    }
    contact {
      data {
        ...ContactMin
      }
    }
  }
}
    `;
export const EnrollContactConditionFragmentDoc = gql`
    fragment EnrollContactCondition on EnrolledContactConditionEntity {
  id
  attributes {
    conditionId
    operator
    value
    periodStart
    periodEnd
    periodOperator
    periodValue
    campaign {
      data {
        ...CampaignMin
      }
    }
  }
}
    `;
export const CampaignFragmentDoc = gql`
    fragment Campaign on CampaignEntity {
  ...CampaignMin
  id
  attributes {
    campaignOwner {
      data {
        ...UserMin
      }
    }
    tenant {
      data {
        ...Tenant
      }
    }
    sequenceSteps {
      data {
        ...SequenceStep
      }
    }
    enrolledContacts {
      data {
        ...CampaignEnrolledContact
      }
    }
    enrolledContactConditions {
      data {
        ...EnrollContactCondition
      }
    }
  }
}
    `;
export const CampaignEnrolledLeadFragmentDoc = gql`
    fragment CampaignEnrolledLead on CampaignEnrolledLeadEntity {
  id
  attributes {
    type
    sequenceStep
    isUnsubscribed
    createdAt
    token
    campaign {
      data {
        ...CampaignMin
      }
    }
    lead {
      data {
        ...LeadMin
      }
    }
  }
}
    `;
export const EnrollLeadConditionFragmentDoc = gql`
    fragment EnrollLeadCondition on EnrolledLeadConditionEntity {
  id
  attributes {
    conditionId
    value
    operator
    createdAt
    campaign {
      data {
        ...CampaignMin
      }
    }
  }
}
    `;
export const ChatNotificationMinFragmentDoc = gql`
    fragment ChatNotificationMin on ChatNotificationEntity {
  id
  attributes {
    isActive
    createdAt
  }
}
    `;
export const ConversationMinFragmentDoc = gql`
    fragment ConversationMin on ConversationEntity {
  id
  attributes {
    createdAt
    updatedAt
    conversationSid
    uuid
    type
    name
    replyTo
  }
}
    `;
export const ChatNotificationFragmentDoc = gql`
    fragment ChatNotification on ChatNotificationEntity {
  ...ChatNotificationMin
  id
  attributes {
    tenant {
      data {
        ...Tenant
      }
    }
    conversation {
      data {
        ...ConversationMin
      }
    }
  }
}
    `;
export const ClassLocationInfoFragmentDoc = gql`
    fragment ClassLocationInfo on ClassLocationInfoEntity {
  ...ClassLocationInfoMin
  id
  attributes {
    classPerformers {
      data {
        ...ClassPerformer
      }
    }
    favorite
    uuid
  }
}
    `;
export const ClassFragmentDoc = gql`
    fragment Class on ClassEntity {
  ...ClassMin
  id
  attributes {
    classLocationInfos {
      data {
        ...ClassLocationInfo
      }
    }
  }
}
    `;
export const ClassWithSoldRevenueFragmentDoc = gql`
    fragment ClassWithSoldRevenue on ClassEntity {
  ...Class
  __typename
  id
  attributes {
    soldRevenue(
      input: {startDate: $startDate, endDate: $endDate, businessLocationId: $businessLocationId}
    )
  }
}
    `;
export const CardFragmentDoc = gql`
    fragment Card on ComponentUiCard {
  id
  title
  subtitle
  description
  media {
    data {
      ...File
    }
  }
}
    `;
export const CompositeProductLocationInfoWithQuantityFragmentDoc = gql`
    fragment CompositeProductLocationInfoWithQuantity on CompositeProductLocationInfoEntity {
  ...CompositeProductLocationInfo
  id
  attributes {
    quantity(businessLocationId: $businessLocationId)
  }
}
    `;
export const CompositeProductWithSoldRevenueFragmentDoc = gql`
    fragment CompositeProductWithSoldRevenue on CompositeProductEntity {
  ...CompositeProduct
  __typename
  id
  attributes {
    soldRevenue(
      input: {startDate: $startDate, endDate: $endDate, businessLocationId: $businessLocationId}
    )
  }
}
    `;
export const ProductWithLocationDataFragmentDoc = gql`
    fragment ProductWithLocationData on ProductEntity {
  ...Product
  id
  attributes {
    quantity(businessLocationId: $businessLocationId)
    quantitySoldLastWeek(businessLocationId: $businessLocationId)
  }
}
    `;
export const CompositeProductsWithLocationDataFragmentDoc = gql`
    fragment CompositeProductsWithLocationData on CompositeProductEntity {
  ...CompositeProductMin
  id
  attributes {
    products {
      data {
        ...ProductWithLocationData
      }
    }
  }
}
    `;
export const ContactsDataByPeriodFragmentDoc = gql`
    fragment ContactsDataByPeriod on ContactEntity {
  id
  ...ContactMin
  attributes {
    fullName
    calculatedSpent
    amountSpentInPeriod(data: $period)
    lastPurchaseDate
    numberOfOrders
    biggestOrderValue
    totalItemsPurchased
    lastPurchaseDate
    itemsPurchasedInPeriod(data: $period)
    leadSource
  }
}
    `;
export const CrmSingleStatisticCardFragmentDoc = gql`
    fragment CrmSingleStatisticCard on CrmSingleStatisticCard {
  id
  value
  stage
  badgeType
  valueFormat
  link
}
    `;
export const AppraisalFragmentDoc = gql`
    fragment Appraisal on AppraisalEntity {
  id
  attributes {
    employee {
      data {
        ...User
      }
    }
    name
    retailValue
    product {
      data {
        ...Product
      }
    }
    title
    signature
    description
    uuid
    contact {
      data {
        ...ContactMin
      }
    }
    pdf {
      data {
        ...File
      }
    }
    files {
      data {
        ...File
      }
    }
    createdAt
    appraisalId
    documentDate
    terms
  }
}
    `;
export const PublicContractFragmentDoc = gql`
    fragment PublicContract on PublicContractEntity {
  id
  attributes {
    body
    companySignature
    clientSignature
    status
    clientSignDate
    companySignDate
    companySignName
    clientSignName
  }
}
    `;
export const ContractFragmentDoc = gql`
    fragment Contract on ContractEntity {
  id
  attributes {
    createdAt
    name
    contractId
    uuid
    contact {
      data {
        ...Contact
      }
    }
    publicContract {
      data {
        ...PublicContract
      }
    }
  }
}
    `;
export const ContractTemplateFragmentDoc = gql`
    fragment ContractTemplate on ContractTemplateEntity {
  id
  attributes {
    name
    createdAt
    body
    uuid
    templateId
    companySignature
    companySignName
    companySignDate
  }
}
    `;
export const PublicFormFragmentDoc = gql`
    fragment PublicForm on PublicFormEntity {
  id
  attributes {
    body
    uuid
    title
    description
    signature
    submitted
    customerName
    file {
      data {
        ...File
      }
    }
  }
}
    `;
export const FormFragmentDoc = gql`
    fragment Form on FormEntity {
  id
  attributes {
    createdAt
    uuid
    name
    link
    sendTo
    formId
    publicForm {
      data {
        ...PublicForm
      }
    }
  }
}
    `;
export const FormTemplateFragmentDoc = gql`
    fragment FormTemplate on FormTemplateEntity {
  id
  attributes {
    body
    name
    uuid
    createdAt
    title
    description
    templateId
    file {
      data {
        ...File
      }
    }
  }
}
    `;
export const ConversationFragmentDoc = gql`
    fragment Conversation on ConversationEntity {
  ...ConversationMin
  id
  attributes {
    scheduled {
      id
      attachments
      body
      closeTime
    }
    contact {
      data {
        ...Contact
      }
    }
    lead {
      data {
        ...Lead
      }
    }
    company {
      data {
        ...Company
      }
    }
    user {
      data {
        ...UserMin
      }
    }
    tenant {
      data {
        ...Tenant
      }
    }
    chatNotifications {
      data {
        ...ChatNotificationMin
      }
    }
  }
}
    `;
export const CrmCustomFieldNameMinFragmentDoc = gql`
    fragment CrmCustomFieldNameMin on CrmCustomFieldNameEntity {
  id
  attributes {
    createdAt
    name
    crmType
  }
}
    `;
export const CrmCustomFieldNameFragmentDoc = gql`
    fragment CrmCustomFieldName on CrmCustomFieldNameEntity {
  ...CrmCustomFieldNameMin
  id
  attributes {
    createdAt
  }
}
    `;
export const CrmCustomFieldValueMinFragmentDoc = gql`
    fragment CrmCustomFieldValueMin on CrmCustomFieldValueEntity {
  id
  attributes {
    createdAt
  }
}
    `;
export const CrmCustomFieldValueFragmentDoc = gql`
    fragment CrmCustomFieldValue on CrmCustomFieldValueEntity {
  ...CrmCustomFieldValueMin
  id
  attributes {
    createdAt
  }
}
    `;
export const CustomPermissionMinFragmentDoc = gql`
    fragment CustomPermissionMin on CustomPermissionEntity {
  id
  attributes {
    createdAt
    permissions
  }
}
    `;
export const CustomPermissionFragmentDoc = gql`
    fragment CustomPermission on CustomPermissionEntity {
  ...CustomPermissionMin
  id
  attributes {
    tenant {
      data {
        ...Tenant
      }
    }
  }
}
    `;
export const DealMinFragmentDoc = gql`
    fragment DealMin on DealEntity {
  id
  attributes {
    createdAt
    createdAt
    name
    budget
    stage
    notes
    startDate
  }
}
    `;
export const DealFragmentDoc = gql`
    fragment Deal on DealEntity {
  ...DealMin
  id
  attributes {
    products {
      data {
        ...Product
      }
    }
    lead {
      data {
        ...Lead
      }
    }
    contact {
      data {
        ...Contact
      }
    }
    company {
      data {
        ...Company
      }
    }
  }
}
    `;
export const ChartAccountMinFragmentDoc = gql`
    fragment ChartAccountMin on ChartAccountEntity {
  id
  attributes {
    name
    description
    type
  }
}
    `;
export const ChartCategoryMinFragmentDoc = gql`
    fragment ChartCategoryMin on ChartCategoryEntity {
  id
  attributes {
    name
    description
  }
}
    `;
export const ChartSubcategoryMinFragmentDoc = gql`
    fragment ChartSubcategoryMin on ChartSubcategoryEntity {
  id
  attributes {
    name
    description
  }
}
    `;
export const ChartCategoryFragmentDoc = gql`
    fragment ChartCategory on ChartCategoryEntity {
  ...ChartCategoryMin
  id
  attributes {
    chartSubcategories {
      data {
        ...ChartSubcategoryMin
      }
    }
    chartAccount {
      data {
        ...ChartAccountMin
      }
    }
    tenant {
      data {
        ...Tenant
      }
    }
  }
}
    `;
export const ChartAccountFragmentDoc = gql`
    fragment ChartAccount on ChartAccountEntity {
  ...ChartAccountMin
  id
  attributes {
    chartCategories {
      data {
        ...ChartCategory
      }
    }
  }
}
    `;
export const ChartSubcategoryFragmentDoc = gql`
    fragment ChartSubcategory on ChartSubcategoryEntity {
  ...ChartSubcategoryMin
  id
  attributes {
    chartCategory {
      data {
        ...ChartCategoryMin
        attributes {
          chartAccount {
            data {
              ...ChartAccountMin
            }
          }
        }
      }
    }
    tenant {
      data {
        ...Tenant
      }
    }
  }
}
    `;
export const DealTransactionFragmentDoc = gql`
    fragment DealTransaction on DealTransactionEntity {
  ...DealTransactionMin
  id
  attributes {
    sellingOrder {
      data {
        ...OrderMin
      }
    }
    contact {
      data {
        ...Contact
      }
    }
    chartAccount {
      data {
        ...ChartAccount
      }
    }
    chartCategory {
      data {
        ...ChartCategory
      }
    }
    chartSubcategory {
      data {
        ...ChartSubcategory
      }
    }
    company {
      data {
        ...Company
      }
    }
    files {
      data {
        ...File
      }
    }
    businessLocation {
      data {
        ...BusinessLocation
      }
    }
  }
}
    `;
export const DealTransactionReminderMinFragmentDoc = gql`
    fragment DealTransactionReminderMin on DealTransactionReminderEntity {
  id
  attributes {
    createdAt
    timing
    isActive
    daysAmount
  }
}
    `;
export const DealTransactionReminderFragmentDoc = gql`
    fragment DealTransactionReminder on DealTransactionReminderEntity {
  ...DealTransactionReminderMin
  id
}
    `;
export const ServiceLocationInfoFragmentDoc = gql`
    fragment ServiceLocationInfo on ServiceLocationInfoEntity {
  ...ServiceLocationInfoMin
  id
  attributes {
    servicePerformers {
      data {
        ...ServicePerformer
      }
    }
    favorite
    uuid
  }
}
    `;
export const ServiceFragmentDoc = gql`
    fragment Service on ServiceEntity {
  ...ServiceMin
  id
  attributes {
    serviceLocationInfos {
      data {
        ...ServiceLocationInfo
      }
    }
  }
}
    `;
export const MembershipItemFragmentDoc = gql`
    fragment MembershipItem on MembershipItemEntity {
  id
  attributes {
    itemsQuantity
    membership {
      data {
        ...MembershipMin
      }
    }
    compositeProduct {
      data {
        ...CompositeProduct
      }
    }
    product {
      data {
        ...Product
      }
    }
    service {
      data {
        ...Service
      }
    }
    class {
      data {
        ...Class
      }
    }
  }
}
    `;
export const MembershipFragmentDoc = gql`
    fragment Membership on MembershipEntity {
  ...MembershipMin
  id
  attributes {
    membershipItems {
      data {
        ...MembershipItem
      }
    }
  }
}
    `;
export const DiscountFragmentDoc = gql`
    fragment Discount on DiscountEntity {
  ...DiscountMin
  id
  attributes {
    applicableProducts {
      data {
        ...Product
      }
    }
    applicableServices {
      data {
        ...Service
      }
    }
    applicableClasses {
      data {
        ...Class
      }
    }
    applicableMemberships {
      data {
        ...Membership
      }
    }
    applicableCompositeProducts {
      data {
        ...CompositeProduct
      }
    }
    applicableStores {
      data {
        ...BusinessLocation
      }
    }
    excludedProducts {
      data {
        ...Product
      }
    }
    excludedServices {
      data {
        ...Service
      }
    }
    excludedClasses {
      data {
        ...Class
      }
    }
    excludedMemberships {
      data {
        ...Membership
      }
    }
    excludedCompositeProducts {
      data {
        ...CompositeProduct
      }
    }
  }
}
    `;
export const DocumentPermissionFragmentDoc = gql`
    fragment DocumentPermission on DocumentPermissionEntity {
  id
  attributes {
    taskNotificationContent
    purchaseTerms
    invoiceTerms
    appraisalTerms
    invoiceClientMessage
    isAppraisalTermsEnabled
    isInvoiceClientMessageEnabled
    isInvoiceTermsEnabled
    isShowOrderItemsImages
    isPurchaseTermsEnabled
    isInvoiceCreationDateEnabled
    isInvoiceDiscountEnabled
    isInvoiceTaxEnabled
    isInvoiceTipEnabled
    isPurchaseCreationDateEnabled
    isPurchaseDiscountEnabled
    isPurchaseTaxEnabled
    isPurchaseTipEnabled
  }
}
    `;
export const DownloadRecordMinFragmentDoc = gql`
    fragment DownloadRecordMin on DownloadRecordEntity {
  id
  attributes {
    createdAt
  }
}
    `;
export const DownloadRecordFragmentDoc = gql`
    fragment DownloadRecord on DownloadRecordEntity {
  ...DownloadRecordMin
  id
  attributes {
    downloadedBy {
      data {
        ...UserMin
      }
    }
    fileItem {
      data {
        ...FileItemMin
      }
    }
  }
}
    `;
export const InventoryAdjustmentMinFragmentDoc = gql`
    fragment InventoryAdjustmentMin on InventoryAdjustmentEntity {
  id
  attributes {
    reason
    uuid
    adjustmentDate
    description
    adjustmentId
    createdAt
  }
}
    `;
export const InventoryAdjustmentItemFragmentDoc = gql`
    fragment InventoryAdjustmentItem on InventoryAdjustmentItemEntity {
  id
  attributes {
    product {
      data {
        ...ProductMin
      }
    }
    serializes {
      data {
        ...Serialize
      }
    }
    adjustedQuantity
    quantityAvailable
    quantityLeft
    createdAt
  }
}
    `;
export const InventoryAdjustmentFragmentDoc = gql`
    fragment InventoryAdjustment on InventoryAdjustmentEntity {
  id
  ...InventoryAdjustmentMin
  attributes {
    inventoryAdjustmentItems {
      data {
        ...InventoryAdjustmentItem
      }
    }
    location {
      data {
        ...BusinessLocation
      }
    }
    sublocation {
      data {
        ...SubLocationMin
      }
    }
    files {
      data {
        ...File
      }
    }
    employee {
      data {
        ...User
      }
    }
  }
}
    `;
export const InventoryAuditMinFragmentDoc = gql`
    fragment InventoryAuditMin on InventoryAuditEntity {
  id
  attributes {
    uuid
    auditDate
    auditId
    name
    finalize
    adjusted
  }
}
    `;
export const InventoryAuditItemMinFragmentDoc = gql`
    fragment InventoryAuditItemMin on InventoryAuditItemEntity {
  id
  attributes {
    inventoryQty
    actualQty
    scannedQty
    adjusted
    auditItemId
  }
}
    `;
export const SubLocationItemFragmentDoc = gql`
    fragment SubLocationItem on SublocationItemEntity {
  id
  ...SubLocationItemMin
  attributes {
    sublocation {
      data {
        ...SubLocationMin
      }
    }
    productInventoryItem {
      data {
        ...ProductInventoryItem
      }
    }
  }
}
    `;
export const InventoryAuditItemFragmentDoc = gql`
    fragment InventoryAuditItem on InventoryAuditItemEntity {
  id
  ...InventoryAuditItemMin
  attributes {
    productInventoryItem {
      data {
        ...ProductInventoryItem
      }
    }
    businessLocation {
      data {
        ...BusinessLocation
      }
    }
    sublocation {
      data {
        ...SubLocationMin
      }
    }
    sublocationItems {
      data {
        ...SubLocationItem
      }
    }
  }
}
    `;
export const SubLocationFragmentDoc = gql`
    fragment SubLocation on SublocationEntity {
  id
  ...SubLocationMin
  attributes {
    businessLocation {
      data {
        ...BusinessLocation
      }
    }
    sublocationItems {
      data {
        ...SubLocationItem
      }
    }
  }
}
    `;
export const InventoryAuditFragmentDoc = gql`
    fragment InventoryAudit on InventoryAuditEntity {
  id
  ...InventoryAuditMin
  attributes {
    inventoryAuditItems {
      data {
        ...InventoryAuditItem
      }
    }
    businessLocation {
      data {
        ...BusinessLocation
      }
    }
    sublocation {
      data {
        ...SubLocation
      }
    }
    employee {
      data {
        ...User
      }
    }
  }
}
    `;
export const InvoiceFragmentDoc = gql`
    fragment Invoice on InvoiceEntity {
  ...InvoiceMin
  id
  attributes {
    orderId {
      data {
        ...Order
      }
    }
    shippingContact {
      data {
        ...InvoiceShippingContact
      }
    }
    fileItem {
      data {
        ...FileItem
      }
    }
    tenant {
      data {
        ...Tenant
      }
    }
  }
}
    `;
export const BackingFragmentDoc = gql`
    fragment Backing on BackingEntity {
  id
  attributes {
    name
  }
}
    `;
export const BoxPaperFragmentDoc = gql`
    fragment BoxPaper on BoxPaperEntity {
  id
  attributes {
    name
  }
}
    `;
export const CountryFragmentDoc = gql`
    fragment Country on CountryEntity {
  id
  attributes {
    name
  }
}
    `;
export const JewelryProductFragmentDoc = gql`
    fragment JewelryProduct on JewelryProductEntity {
  ...JewelryProductMin
  id
  attributes {
    productInventoryItems {
      data {
        ...ProductInventoryItem
      }
    }
    productAttributeOptions {
      data {
        ...ProductAttributeOption
      }
    }
  }
}
    `;
export const KnotStyleFragmentDoc = gql`
    fragment KnotStyle on KnotStyleEntity {
  id
  attributes {
    name
  }
}
    `;
export const LinkStyleFragmentDoc = gql`
    fragment LinkStyle on LinkStyleEntity {
  id
  attributes {
    name
  }
}
    `;
export const LinkTypeFragmentDoc = gql`
    fragment LinkType on LinkTypeEntity {
  id
  attributes {
    name
  }
}
    `;
export const StrandFragmentDoc = gql`
    fragment Strand on StrandEntity {
  id
  attributes {
    name
  }
}
    `;
export const StrandsLengthFragmentDoc = gql`
    fragment StrandsLength on StrandsLengthEntity {
  id
  attributes {
    name
  }
}
    `;
export const MaintenanceEventFragmentDoc = gql`
    fragment MaintenanceEvent on MaintenanceEventEntity {
  id
  attributes {
    title
    notes
    type
    dateReported
    reportedBy {
      data {
        ...UserMin
      }
    }
    files {
      data {
        ...File
      }
    }
  }
}
    `;
export const MarketingCustomersReportMinFragmentDoc = gql`
    fragment MarketingCustomersReportMin on MarketingCustomersReportEntity {
  id
  attributes {
    createdAt
    EMAILsent
    SMSsent
  }
}
    `;
export const MarketingCustomersReportFragmentDoc = gql`
    fragment MarketingCustomersReport on MarketingCustomersReportEntity {
  ...MarketingCustomersReportMin
  id
  attributes {
    enrolledContact {
      data {
        id
        attributes {
          campaign {
            data {
              id
              attributes {
                name
              }
            }
          }
          contact {
            data {
              id
              attributes {
                fullName
              }
            }
          }
        }
      }
    }
    enrolledLead {
      data {
        id
        attributes {
          campaign {
            data {
              id
              attributes {
                name
              }
            }
          }
          lead {
            data {
              id
              attributes {
                fullName
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const MarketingEmailTemplateFragmentDoc = gql`
    fragment MarketingEmailTemplate on MarketingEmailTemplateEntity {
  ...MarketingEmailTemplateMin
  id
  attributes {
    tenant {
      data {
        ...Tenant
      }
    }
  }
}
    `;
export const MembershipWithSoldRevenueFragmentDoc = gql`
    fragment MembershipWithSoldRevenue on MembershipEntity {
  ...Membership
  __typename
  id
  attributes {
    soldRevenue(
      input: {startDate: $startDate, endDate: $endDate, businessLocationId: $businessLocationId}
    )
  }
}
    `;
export const NoteFragmentDoc = gql`
    fragment Note on NoteEntity {
  id
  attributes {
    description
    createdAt
  }
}
    `;
export const InventoryQuantityNotificationFragmentDoc = gql`
    fragment InventoryQuantityNotification on InventoryQuantityNotificationEntity {
  id
  attributes {
    type
    productInventoryItem {
      data {
        ...ProductInventoryItem
      }
    }
  }
}
    `;
export const MaintenanceFragmentDoc = gql`
    fragment Maintenance on MaintenanceEntity {
  id
  attributes {
    notes
    uuid
    active
    frequencyDays
    files {
      data {
        ...File
      }
    }
    resourceInventoryItem {
      data {
        ...ResourceInventoryItem
      }
    }
    maintainedBy {
      data {
        ...UserMin
      }
    }
    lastMaintenanceDate
    nextMaintenanceDate
    status
  }
}
    `;
export const MaintenanceQuantityNotificationFragmentDoc = gql`
    fragment MaintenanceQuantityNotification on MaintenanceQuantityNotificationEntity {
  id
  attributes {
    type
    createdAt
    maintenance {
      data {
        ...Maintenance
      }
    }
  }
}
    `;
export const NylasGrantExpireNotificationFragmentDoc = gql`
    fragment NylasGrantExpireNotification on NotificationsNylasGrantExpireEntity {
  id
  attributes {
    createdAt
  }
}
    `;
export const UserNotificationFragmentDoc = gql`
    fragment UserNotification on UserNotificationEntity {
  id
  attributes {
    hasBeenSeen
    createdAt
    type
    inventoryQuantityNotification {
      data {
        ...InventoryQuantityNotification
      }
    }
    maintenanceQuantityNotification {
      data {
        ...MaintenanceQuantityNotification
      }
    }
    nylasGrantExpire {
      data {
        ...NylasGrantExpireNotification
      }
    }
  }
}
    `;
export const OnboardingFragmentDoc = gql`
    fragment Onboarding on OnboardingEntity {
  id
  attributes {
    isCompleted
    isLogoUpload
    isMainLocation
    isEmailAndPhone
    isStoreAdded
    isEmployeeAdded
    isCustomerImported
    isProductsImported
    isTwilioConnected
    isStripeConnected
    isQuickBooksConnected
  }
}
    `;
export const OrderTaxReportFragmentDoc = gql`
    fragment OrderTaxReport on OrderEntity {
  id
  attributes {
    orderId
    total
    tax
    taxPortion
    specifiedTaxPortions
    businessLocation {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
}
    `;
export const OrderMinWithCustomerFragmentDoc = gql`
    fragment OrderMinWithCustomer on OrderEntity {
  id
  attributes {
    orderId
    createdAt
    total
    subTotal
    dueDate
    completed
    status
    paymentMethod
    deliveryMethod
    discount
    shipment
    tax
    tip
    recurringAmount
    recurringPeriod
    recurringPeriodCount
    points
    itemsAmount
    paidSummary
    type
    createdAt
    rentDueDate
    isWarranty
    memo
    getCreateDate
    expiryDate
    contact {
      data {
        ...ContactMin
      }
    }
    company {
      data {
        ...CompanyMin
      }
    }
  }
}
    `;
export const ProductOrderItemWithCustomerFragmentDoc = gql`
    fragment ProductOrderItemWithCustomer on ProductOrderItemEntity {
  id
  attributes {
    __typename
    quantity
    purchaseType
    status
    note
    itemId
    rentStart
    rentEnd
    price
    isShowInvoiceNote
    isVisibleInDocs
    discounts {
      data {
        ...DiscountMin
      }
    }
    product {
      data {
        ...ProductInventoryItem
      }
    }
    order {
      data {
        ...OrderMinWithCustomer
      }
    }
    tax {
      data {
        ...TaxMin
      }
    }
    totalPricePerItem
    discountAmountPerItem
    sublocations {
      data {
        ...SubLocationMin
      }
    }
  }
}
    `;
export const PaginationFragmentDoc = gql`
    fragment Pagination on Pagination {
  page
  pageCount
  pageSize
  total
}
    `;
export const MetaFragmentDoc = gql`
    fragment Meta on ResponseCollectionMeta {
  pagination {
    ...Pagination
  }
}
    `;
export const ProductInventoryItemEventFragmentDoc = gql`
    fragment ProductInventoryItemEvent on ProductInventoryItemEventEntity {
  id
  attributes {
    createdAt
    change
    remainingQuantity
    eventType
    relationId
    relationUuid
    itemCost
    receiveDate
    expiryDate
    memo
    laidAway
    itemVendor {
      data {
        ...Company
      }
    }
    businessLocation {
      data {
        ...BusinessLocation
      }
    }
    addedBy {
      data {
        ...User
      }
    }
    order {
      data {
        ...OrderMin
      }
    }
    itemContactVendor {
      data {
        ...Contact
      }
    }
    productInventoryItem {
      data {
        ...ProductInventoryItem
      }
    }
  }
}
    `;
export const ProductInventoryItemEventMinFragmentDoc = gql`
    fragment ProductInventoryItemEventMin on ProductInventoryItemEventEntity {
  id
  attributes {
    createdAt
    change
    remainingQuantity
    eventType
    relationId
    relationUuid
    itemCost
    receiveDate
    expiryDate
    memo
    laidAway
    itemVendor {
      data {
        ...Company
      }
    }
    businessLocation {
      data {
        ...BusinessLocation
      }
    }
    addedBy {
      data {
        ...User
      }
    }
    order {
      data {
        ...OrderMin
      }
    }
  }
}
    `;
export const ProductReportFragmentDoc = gql`
    fragment ProductReport on ProductEntity {
  ...Product
  id
  attributes {
    quantity
    totalQuantitySold
    numberLocationsPresented
    grossMargin
  }
}
    `;
export const ProductWithSoldRevenueFragmentDoc = gql`
    fragment ProductWithSoldRevenue on ProductEntity {
  ...Product
  __typename
  id
  attributes {
    quantity(businessLocationId: $businessLocationId)
    soldRevenue(
      input: {startDate: $startDate, endDate: $endDate, businessLocationId: $businessLocationId}
    )
  }
}
    `;
export const ProductAttributeFragmentDoc = gql`
    fragment ProductAttribute on ProductAttributeEntity {
  ...ProductAttributeMin
  id
  attributes {
    productAttributeOptions {
      data {
        ...ProductAttributeOption
      }
    }
    productTypes {
      data {
        ...ProductType
      }
    }
    jewelryProductTypes {
      data {
        ...JewelryProductType
      }
    }
  }
}
    `;
export const ProductGroupMinFragmentDoc = gql`
    fragment ProductGroupMin on ProductGroupEntity {
  id
  attributes {
    name
    description
    createdAt
    uuid
  }
}
    `;
export const ProductGroupAttributeMinFragmentDoc = gql`
    fragment ProductGroupAttributeMin on ProductGroupAttributeEntity {
  id
  attributes {
    name
  }
}
    `;
export const ProductGroupAttributeOptionMinFragmentDoc = gql`
    fragment ProductGroupAttributeOptionMin on ProductGroupAttributeOptionEntity {
  id
  attributes {
    name
    createdAt
  }
}
    `;
export const ProductGroupAttributeFragmentDoc = gql`
    fragment ProductGroupAttribute on ProductGroupAttributeEntity {
  ...ProductGroupAttributeMin
  id
  attributes {
    productGroupAttributeOptions {
      data {
        ...ProductGroupAttributeOptionMin
      }
    }
  }
}
    `;
export const ProductGroupAttributeOptionFragmentDoc = gql`
    fragment ProductGroupAttributeOption on ProductGroupAttributeOptionEntity {
  ...ProductGroupAttributeOptionMin
  id
  attributes {
    productGroupAttribute {
      data {
        ...ProductGroupAttributeMin
      }
    }
  }
}
    `;
export const ProductGroupItemFragmentDoc = gql`
    fragment ProductGroupItem on ProductGroupItemEntity {
  id
  attributes {
    product {
      data {
        ...Product
      }
    }
    productGroupAttributeOptions {
      data {
        ...ProductGroupAttributeOption
      }
    }
    productGroup {
      data {
        ...ProductGroupMin
      }
    }
  }
}
    `;
export const ProductGroupFragmentDoc = gql`
    fragment ProductGroup on ProductGroupEntity {
  ...ProductGroupMin
  id
  attributes {
    productGroupAttributes {
      data {
        ...ProductGroupAttribute
      }
    }
    productGroupItems {
      data {
        ...ProductGroupItem
      }
    }
  }
}
    `;
export const PurchaseRequestFragmentDoc = gql`
    fragment PurchaseRequest on PurchaseRequestEntity {
  ...PurchaseRequestMin
  id
  attributes {
    orderId {
      data {
        ...Order
      }
    }
    tenant {
      data {
        ...Tenant
      }
    }
    shippingInfo {
      data {
        ...PurchaseRequestShippingInfoMin
      }
    }
    fileItem {
      data {
        ...FileItem
      }
    }
  }
}
    `;
export const QuestionMinFragmentDoc = gql`
    fragment QuestionMin on QuestionEntity {
  id
  attributes {
    createdAt
  }
}
    `;
export const QuestionFragmentDoc = gql`
    fragment Question on QuestionEntity {
  ...QuestionMin
  id
  attributes {
    title
    answer
    active
    owner {
      data {
        id
      }
    }
    createdAt
  }
}
    `;
export const RateFragmentDoc = gql`
    fragment Rate on RateEntity {
  ...RateMin
  id
  attributes {
    user {
      data {
        ...UserMin
      }
    }
  }
}
    `;
export const ReportsScheduleFragmentDoc = gql`
    fragment ReportsSchedule on ReportsScheduleEntity {
  ...ReportsScheduleMin
  id
  attributes {
    user {
      data {
        ...UserMin
      }
    }
  }
}
    `;
export const ResourceFragmentDoc = gql`
    fragment Resource on ResourceEntity {
  ...ResourceMin
  id
  attributes {
    creator {
      data {
        ...UserMin
      }
    }
    resourceInventoryItems {
      data {
        ...ResourceInventoryItem
      }
    }
  }
}
    `;
export const ReturnMinFragmentDoc = gql`
    fragment ReturnMin on ReturnEntity {
  id
  attributes {
    uuid
    reason
    returnDate
    notes
    type
  }
}
    `;
export const ReturnMethodFragmentDoc = gql`
    fragment ReturnMethod on ReturnMethodEntity {
  id
  attributes {
    name
  }
}
    `;
export const ReturnItemFragmentDoc = gql`
    fragment ReturnItem on ReturnItemEntity {
  id
  attributes {
    productOrderItem {
      data {
        ...ProductOrderItem
      }
    }
    serializes {
      data {
        ...Serialize
      }
    }
    quantityReturned
  }
}
    `;
export const ReturnFragmentDoc = gql`
    fragment Return on ReturnEntity {
  id
  ...ReturnMin
  attributes {
    businessLocation {
      data {
        ...BusinessLocation
      }
    }
    sublocation {
      data {
        ...SubLocation
      }
    }
    order {
      data {
        ...OrderMin
        attributes {
          contact {
            data {
              ...ContactMin
            }
          }
          company {
            data {
              ...CompanyMin
            }
          }
        }
      }
    }
    returnMethod {
      data {
        ...ReturnMethod
      }
    }
    returnItems {
      data {
        ...ReturnItem
      }
    }
    employee {
      data {
        ...User
      }
    }
  }
}
    `;
export const SalesItemReportFragmentDoc = gql`
    fragment SalesItemReport on SalesItemReportEntity {
  ...SalesItemReportMin
  id
  attributes {
    order {
      data {
        ...OrderMin
      }
    }
    productOrderItem {
      data {
        ...ProductOrderItem
      }
    }
    compositeProductOrderItem {
      data {
        ...CompositeProductOrderItem
      }
    }
    serviceOrderItem {
      data {
        ...ServiceOrderItem
      }
    }
    membershipOrderItem {
      data {
        ...MembershipOrderItem
      }
    }
    classOrderItem {
      data {
        ...ClassOrderItem
      }
    }
    contact {
      data {
        ...Contact
      }
    }
    company {
      data {
        ...Company
      }
    }
    sales {
      data {
        ...User
      }
    }
    businessLocation {
      data {
        ...BusinessLocation
      }
    }
    sublocation {
      data {
        ...SubLocationMin
      }
    }
  }
}
    `;
export const SchedulingAppointmentFragmentDoc = gql`
    fragment SchedulingAppointment on SchedulingAppointmentEntity {
  ...SchedulingAppointmentMin
  id
  attributes {
    appointmentId
    startTime
    endTime
    isRecurrence
    recurrenceRule
    users {
      data {
        ...UserMin
      }
    }
    company {
      data {
        ...CompanyMin
      }
    }
    lead {
      data {
        ...LeadMin
      }
    }
    contact {
      data {
        ...ContactMin
      }
    }
    service {
      data {
        ...Service
      }
    }
  }
}
    `;
export const SchedulingRecurrenceMinFragmentDoc = gql`
    fragment SchedulingRecurrenceMin on SchedulingRecurrenceEntity {
  id
  attributes {
    recurrenceDate
    createdAt
  }
}
    `;
export const SchedulingRecurrenceFragmentDoc = gql`
    fragment SchedulingRecurrence on SchedulingRecurrenceEntity {
  ...SchedulingRecurrenceMin
  id
  attributes {
    createdAt
  }
}
    `;
export const ServiceWithSoldRevenueFragmentDoc = gql`
    fragment ServiceWithSoldRevenue on ServiceEntity {
  ...Service
  __typename
  id
  attributes {
    soldRevenue(
      input: {startDate: $startDate, endDate: $endDate, businessLocationId: $businessLocationId}
    )
  }
}
    `;
export const ShipmentCarrierFragmentDoc = gql`
    fragment ShipmentCarrier on ShipmentCarrierEntity {
  id
  attributes {
    name
  }
}
    `;
export const ShipmentFragmentDoc = gql`
    fragment Shipment on ShipmentEntity {
  id
  attributes {
    order {
      data {
        ...OrderMin
      }
    }
    status
    createdAt
    shipmentDate
    charge
    trackingNumber
    trackingUrl
    carrier {
      data {
        ...ShipmentCarrier
      }
    }
    notes
    contact {
      data {
        ...ContactMin
      }
    }
    company {
      data {
        ...CompanyMin
      }
    }
    uuid
  }
}
    `;
export const TaskLocationFragmentDoc = gql`
    fragment TaskLocation on TaskLocationEntity {
  ...TaskLocationMin
  id
  attributes {
    createdAt
    tenant {
      data {
        id
      }
    }
  }
}
    `;
export const TaxAuthorityFragmentDoc = gql`
    fragment TaxAuthority on TaxAuthorityEntity {
  id
  attributes {
    name
  }
}
    `;
export const TaxReportMinFragmentDoc = gql`
    fragment TaxReportMin on TaxReportEntity {
  id
  attributes {
    createdAt
    collectedTax
  }
}
    `;
export const TaxReportFragmentDoc = gql`
    fragment TaxReport on TaxReportEntity {
  ...TaxReportMin
  id
  attributes {
    dealTransaction {
      data {
        id
        attributes {
          paid
          dealTransactionId
          summary
        }
      }
    }
    order {
      data {
        id
        attributes {
          orderId
          total
          tax
          taxPortion
          businessLocation {
            data {
              id
              attributes {
                name
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const TodoMinFragmentDoc = gql`
    fragment TodoMin on TodoEntity {
  id
  attributes {
    createdAt
    description
    completed
  }
}
    `;
export const TodoFragmentDoc = gql`
    fragment Todo on TodoEntity {
  ...TodoMin
  id
  attributes {
    wishableProduct {
      data {
        ...ProductMin
      }
    }
  }
}
    `;
export const TransferOrderMinFragmentDoc = gql`
    fragment TransferOrderMin on TransferOrderEntity {
  attributes {
    reason
    transferId
    createdAt
    orderDate
    uuid
  }
}
    `;
export const TransferOrderItemMinFragmentDoc = gql`
    fragment TransferOrderItemMin on TransferOrderItemEntity {
  attributes {
    quantityFrom
    quantityTo
    transferQuantity
  }
}
    `;
export const TransferOrderItemFragmentDoc = gql`
    fragment TransferOrderItem on TransferOrderItemEntity {
  id
  ...TransferOrderItemMin
  attributes {
    product {
      data {
        ...Product
      }
    }
    sublocationItem {
      data {
        ...SubLocationItem
      }
    }
    serializes {
      data {
        ...Serialize
      }
    }
  }
}
    `;
export const TransferOrderFragmentDoc = gql`
    fragment TransferOrder on TransferOrderEntity {
  id
  ...TransferOrderMin
  attributes {
    locationTo {
      data {
        ...BusinessLocation
      }
    }
    sublocationTo {
      data {
        ...SubLocationMin
      }
    }
    locationFrom {
      data {
        ...BusinessLocation
      }
    }
    sublocationFrom {
      data {
        ...SubLocationMin
      }
    }
    transferOrderItems {
      data {
        ...TransferOrderItem
      }
    }
    files {
      data {
        ...File
      }
    }
    employee {
      data {
        ...User
      }
    }
  }
}
    `;
export const TwilioConnectionMinFragmentDoc = gql`
    fragment TwilioConnectionMin on TwilioConnectionEntity {
  id
  attributes {
    friendlyName
    phoneNumber
  }
}
    `;
export const ParagraphFragmentDoc = gql`
    fragment Paragraph on ComponentUiParagraph {
  id
  title
  value
}
    `;
export const ReviewSectionFragmentDoc = gql`
    fragment ReviewSection on ComponentUiCustomerReviewSection {
  id
  title
  description
  reviews(pagination: {limit: 100}) {
    id
    name
    text
    rating
  }
  visible
}
    `;
export const SectionFragmentDoc = gql`
    fragment Section on ComponentUiSection {
  id
  visible
  heading {
    id
    title
    subtitle
    description
    media {
      data {
        ...File
      }
    }
  }
  button {
    id
    title
    url
    target
  }
}
    `;
export const ExtendedSectionFragmentDoc = gql`
    fragment ExtendedSection on ComponentUiExtendedSection {
  id
  visible
  heading {
    id
    title
    subtitle
  }
  media {
    data {
      ...File
    }
  }
  button {
    id
    title
    url
    icon
  }
  image {
    id
    title
    subtitle
    media {
      data {
        ...File
      }
    }
  }
}
    `;
export const SectionsVisibilityFragmentDoc = gql`
    fragment SectionsVisibility on ComponentUiSectionsVisibility {
  id
  pageTitle
  isFooterVisible
  isCustomerReviewVisible
  isFollowUsVisible
  isSubscribeNewsLetterVisible
  isBlogVisible
}
    `;
export const ProductTypesVisibilityFragmentDoc = gql`
    fragment ProductTypesVisibility on ComponentUiProductTypesVisibility {
  id
  Product
  Class
  Service
  Membership
  CompositeProduct
}
    `;
export const ShipmentCostFragmentDoc = gql`
    fragment ShipmentCost on ComponentUiShipmentCost {
  id
  isFree
  shipmentPrice
  isFreeAfterAmount
  freeShipmentAfter
}
    `;
export const WebsiteContactsFragmentDoc = gql`
    fragment WebsiteContacts on ComponentUiWebsiteContacts {
  id
  email
  phoneNumber
  address
}
    `;
export const WebsiteWorkingHoursFragmentDoc = gql`
    fragment WebsiteWorkingHours on ComponentUiWorkingHours {
  id
  monday
  tuesday
  wednesday
  thursday
  friday
  saturday
  sunday
}
    `;
export const WebsiteFragmentDoc = gql`
    fragment Website on WebsiteEntity {
  id
  attributes {
    tenant {
      data {
        ...Tenant
      }
    }
    subdomain
    privacyPolicy {
      ...Paragraph
    }
    termsConditions {
      ...Paragraph
    }
    customerReview {
      ...ReviewSection
    }
    shopHero {
      ...Section
    }
    blogHero {
      ...Section
    }
    initialSettings {
      ...ExtendedSection
    }
    footer {
      ...ExtendedSection
    }
    subscribeNewsletter {
      ...ExtendedSection
    }
    followUs {
      ...ExtendedSection
    }
    hero {
      ...ExtendedSection
    }
    services {
      ...ExtendedSection
    }
    aboutUs {
      ...ExtendedSection
    }
    aboutUsHero {
      ...ExtendedSection
    }
    statistics {
      ...ExtendedSection
    }
    benefits {
      ...ExtendedSection
    }
    aboutUsHero {
      ...ExtendedSection
    }
    statistics {
      ...ExtendedSection
    }
    benefits {
      ...ExtendedSection
    }
    faqHero {
      ...ExtendedSection
    }
    sectionsVisibility {
      ...SectionsVisibility
    }
    ProductTypesVisibility {
      ...ProductTypesVisibility
    }
    shipmentCost {
      ...ShipmentCost
    }
    websiteContacts {
      ...WebsiteContacts
    }
    workingHours {
      ...WebsiteWorkingHours
    }
  }
}
    `;
export const CreateActivityDocument = gql`
    mutation createActivity($input: ActivityInput!) {
  createActivity(data: $input) {
    data {
      ...Activity
    }
  }
}
    ${ActivityFragmentDoc}
${ActivityMinFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ContactMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${BusinessLocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}
${CompanyMinFragmentDoc}
${LeadFragmentDoc}
${LeadMinFragmentDoc}
${TaskFragmentDoc}
${TaskMinFragmentDoc}
${TaskLocationMinFragmentDoc}
${TaskTypeFragmentDoc}
${TaskTypeMinFragmentDoc}
${TaskStageFragmentDoc}
${TaskStageMinFragmentDoc}
${ApprovalMethodMinFragmentDoc}
${OrderMinFragmentDoc}
${OrderFragmentDoc}
${OrderWithItemsFragmentDoc}
${ProductOrderItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${SerializeFragmentDoc}
${DiscountMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${SubLocationItemMinFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${CompositeProductOrderItemFragmentDoc}
${CompositeProductLocationInfoFragmentDoc}
${CompositeProductLocationInfoMinFragmentDoc}
${CompositeProductFragmentDoc}
${CompositeProductMinFragmentDoc}
${ProductFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${CompositeProductItemInfoFragmentDoc}
${CompositeProductItemInfoMinFragmentDoc}
${ServiceOrderItemFragmentDoc}
${ServicePerformerFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${ServiceMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}
${ClassOrderItemFragmentDoc}
${ClassPerformerFragmentDoc}
${ClassLocationInfoMinFragmentDoc}
${ClassMinFragmentDoc}
${MembershipOrderItemFragmentDoc}
${MembershipMinFragmentDoc}
${ContactFragmentDoc}
${ContactDynamicValuesFragmentDoc}
${CompanyFragmentDoc}
${DealTransactionMinFragmentDoc}
${InvoiceWithFileFragmentDoc}
${InvoiceMinFragmentDoc}
${InvoiceShippingContactFragmentDoc}
${FileItemFragmentDoc}
${FileItemMinFragmentDoc}
${PurchaseRequestWithFileFragmentDoc}
${PurchaseRequestMinFragmentDoc}
${PurchaseRequestShippingInfoMinFragmentDoc}
${SalesItemReportMinFragmentDoc}`;
export type CreateActivityMutationFn = Apollo.MutationFunction<CreateActivityMutation, CreateActivityMutationVariables>;
export function useCreateActivityMutation(baseOptions?: Apollo.MutationHookOptions<CreateActivityMutation, CreateActivityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateActivityMutation, CreateActivityMutationVariables>(CreateActivityDocument, options);
      }
export type CreateActivityMutationHookResult = ReturnType<typeof useCreateActivityMutation>;
export type CreateActivityMutationResult = Apollo.MutationResult<CreateActivityMutation>;
export const DeleteActivityDocument = gql`
    mutation deleteActivity($id: ID!) {
  deleteActivity(id: $id) {
    data {
      ...Activity
    }
  }
}
    ${ActivityFragmentDoc}
${ActivityMinFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ContactMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${BusinessLocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}
${CompanyMinFragmentDoc}
${LeadFragmentDoc}
${LeadMinFragmentDoc}
${TaskFragmentDoc}
${TaskMinFragmentDoc}
${TaskLocationMinFragmentDoc}
${TaskTypeFragmentDoc}
${TaskTypeMinFragmentDoc}
${TaskStageFragmentDoc}
${TaskStageMinFragmentDoc}
${ApprovalMethodMinFragmentDoc}
${OrderMinFragmentDoc}
${OrderFragmentDoc}
${OrderWithItemsFragmentDoc}
${ProductOrderItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${SerializeFragmentDoc}
${DiscountMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${SubLocationItemMinFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${CompositeProductOrderItemFragmentDoc}
${CompositeProductLocationInfoFragmentDoc}
${CompositeProductLocationInfoMinFragmentDoc}
${CompositeProductFragmentDoc}
${CompositeProductMinFragmentDoc}
${ProductFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${CompositeProductItemInfoFragmentDoc}
${CompositeProductItemInfoMinFragmentDoc}
${ServiceOrderItemFragmentDoc}
${ServicePerformerFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${ServiceMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}
${ClassOrderItemFragmentDoc}
${ClassPerformerFragmentDoc}
${ClassLocationInfoMinFragmentDoc}
${ClassMinFragmentDoc}
${MembershipOrderItemFragmentDoc}
${MembershipMinFragmentDoc}
${ContactFragmentDoc}
${ContactDynamicValuesFragmentDoc}
${CompanyFragmentDoc}
${DealTransactionMinFragmentDoc}
${InvoiceWithFileFragmentDoc}
${InvoiceMinFragmentDoc}
${InvoiceShippingContactFragmentDoc}
${FileItemFragmentDoc}
${FileItemMinFragmentDoc}
${PurchaseRequestWithFileFragmentDoc}
${PurchaseRequestMinFragmentDoc}
${PurchaseRequestShippingInfoMinFragmentDoc}
${SalesItemReportMinFragmentDoc}`;
export type DeleteActivityMutationFn = Apollo.MutationFunction<DeleteActivityMutation, DeleteActivityMutationVariables>;
export function useDeleteActivityMutation(baseOptions?: Apollo.MutationHookOptions<DeleteActivityMutation, DeleteActivityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteActivityMutation, DeleteActivityMutationVariables>(DeleteActivityDocument, options);
      }
export type DeleteActivityMutationHookResult = ReturnType<typeof useDeleteActivityMutation>;
export type DeleteActivityMutationResult = Apollo.MutationResult<DeleteActivityMutation>;
export const UpdateActivityDocument = gql`
    mutation updateActivity($id: ID!, $input: ActivityInput!) {
  updateActivity(id: $id, data: $input) {
    data {
      ...Activity
    }
  }
}
    ${ActivityFragmentDoc}
${ActivityMinFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ContactMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${BusinessLocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}
${CompanyMinFragmentDoc}
${LeadFragmentDoc}
${LeadMinFragmentDoc}
${TaskFragmentDoc}
${TaskMinFragmentDoc}
${TaskLocationMinFragmentDoc}
${TaskTypeFragmentDoc}
${TaskTypeMinFragmentDoc}
${TaskStageFragmentDoc}
${TaskStageMinFragmentDoc}
${ApprovalMethodMinFragmentDoc}
${OrderMinFragmentDoc}
${OrderFragmentDoc}
${OrderWithItemsFragmentDoc}
${ProductOrderItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${SerializeFragmentDoc}
${DiscountMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${SubLocationItemMinFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${CompositeProductOrderItemFragmentDoc}
${CompositeProductLocationInfoFragmentDoc}
${CompositeProductLocationInfoMinFragmentDoc}
${CompositeProductFragmentDoc}
${CompositeProductMinFragmentDoc}
${ProductFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${CompositeProductItemInfoFragmentDoc}
${CompositeProductItemInfoMinFragmentDoc}
${ServiceOrderItemFragmentDoc}
${ServicePerformerFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${ServiceMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}
${ClassOrderItemFragmentDoc}
${ClassPerformerFragmentDoc}
${ClassLocationInfoMinFragmentDoc}
${ClassMinFragmentDoc}
${MembershipOrderItemFragmentDoc}
${MembershipMinFragmentDoc}
${ContactFragmentDoc}
${ContactDynamicValuesFragmentDoc}
${CompanyFragmentDoc}
${DealTransactionMinFragmentDoc}
${InvoiceWithFileFragmentDoc}
${InvoiceMinFragmentDoc}
${InvoiceShippingContactFragmentDoc}
${FileItemFragmentDoc}
${FileItemMinFragmentDoc}
${PurchaseRequestWithFileFragmentDoc}
${PurchaseRequestMinFragmentDoc}
${PurchaseRequestShippingInfoMinFragmentDoc}
${SalesItemReportMinFragmentDoc}`;
export type UpdateActivityMutationFn = Apollo.MutationFunction<UpdateActivityMutation, UpdateActivityMutationVariables>;
export function useUpdateActivityMutation(baseOptions?: Apollo.MutationHookOptions<UpdateActivityMutation, UpdateActivityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateActivityMutation, UpdateActivityMutationVariables>(UpdateActivityDocument, options);
      }
export type UpdateActivityMutationHookResult = ReturnType<typeof useUpdateActivityMutation>;
export type UpdateActivityMutationResult = Apollo.MutationResult<UpdateActivityMutation>;
export const ActivityDocument = gql`
    query activity($filters: ActivityFiltersInput = {}, $pagination: PaginationArg = {}) {
  activities(filters: $filters, sort: ["due_date:desc"], pagination: $pagination) {
    data {
      ...Activity
    }
    meta {
      ...Meta
    }
  }
}
    ${ActivityFragmentDoc}
${ActivityMinFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ContactMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${BusinessLocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}
${CompanyMinFragmentDoc}
${LeadFragmentDoc}
${LeadMinFragmentDoc}
${TaskFragmentDoc}
${TaskMinFragmentDoc}
${TaskLocationMinFragmentDoc}
${TaskTypeFragmentDoc}
${TaskTypeMinFragmentDoc}
${TaskStageFragmentDoc}
${TaskStageMinFragmentDoc}
${ApprovalMethodMinFragmentDoc}
${OrderMinFragmentDoc}
${OrderFragmentDoc}
${OrderWithItemsFragmentDoc}
${ProductOrderItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${SerializeFragmentDoc}
${DiscountMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${SubLocationItemMinFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${CompositeProductOrderItemFragmentDoc}
${CompositeProductLocationInfoFragmentDoc}
${CompositeProductLocationInfoMinFragmentDoc}
${CompositeProductFragmentDoc}
${CompositeProductMinFragmentDoc}
${ProductFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${CompositeProductItemInfoFragmentDoc}
${CompositeProductItemInfoMinFragmentDoc}
${ServiceOrderItemFragmentDoc}
${ServicePerformerFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${ServiceMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}
${ClassOrderItemFragmentDoc}
${ClassPerformerFragmentDoc}
${ClassLocationInfoMinFragmentDoc}
${ClassMinFragmentDoc}
${MembershipOrderItemFragmentDoc}
${MembershipMinFragmentDoc}
${ContactFragmentDoc}
${ContactDynamicValuesFragmentDoc}
${CompanyFragmentDoc}
${DealTransactionMinFragmentDoc}
${InvoiceWithFileFragmentDoc}
${InvoiceMinFragmentDoc}
${InvoiceShippingContactFragmentDoc}
${FileItemFragmentDoc}
${FileItemMinFragmentDoc}
${PurchaseRequestWithFileFragmentDoc}
${PurchaseRequestMinFragmentDoc}
${PurchaseRequestShippingInfoMinFragmentDoc}
${SalesItemReportMinFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useActivityQuery(baseOptions?: Apollo.QueryHookOptions<ActivityQuery, ActivityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActivityQuery, ActivityQueryVariables>(ActivityDocument, options);
      }
export function useActivityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActivityQuery, ActivityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActivityQuery, ActivityQueryVariables>(ActivityDocument, options);
        }
export type ActivityQueryHookResult = ReturnType<typeof useActivityQuery>;
export type ActivityLazyQueryHookResult = ReturnType<typeof useActivityLazyQuery>;
export type ActivityQueryResult = Apollo.QueryResult<ActivityQuery, ActivityQueryVariables>;
export const CreateApprovalMethodDocument = gql`
    mutation createApprovalMethod($input: ApprovalMethodInput!) {
  createApprovalMethod(data: $input) {
    data {
      ...ApprovalMethod
    }
  }
}
    ${ApprovalMethodFragmentDoc}
${ApprovalMethodMinFragmentDoc}`;
export type CreateApprovalMethodMutationFn = Apollo.MutationFunction<CreateApprovalMethodMutation, CreateApprovalMethodMutationVariables>;
export function useCreateApprovalMethodMutation(baseOptions?: Apollo.MutationHookOptions<CreateApprovalMethodMutation, CreateApprovalMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateApprovalMethodMutation, CreateApprovalMethodMutationVariables>(CreateApprovalMethodDocument, options);
      }
export type CreateApprovalMethodMutationHookResult = ReturnType<typeof useCreateApprovalMethodMutation>;
export type CreateApprovalMethodMutationResult = Apollo.MutationResult<CreateApprovalMethodMutation>;
export const DeleteApprovalMethodDocument = gql`
    mutation deleteApprovalMethod($id: ID!) {
  deleteApprovalMethod(id: $id) {
    data {
      ...ApprovalMethod
    }
  }
}
    ${ApprovalMethodFragmentDoc}
${ApprovalMethodMinFragmentDoc}`;
export type DeleteApprovalMethodMutationFn = Apollo.MutationFunction<DeleteApprovalMethodMutation, DeleteApprovalMethodMutationVariables>;
export function useDeleteApprovalMethodMutation(baseOptions?: Apollo.MutationHookOptions<DeleteApprovalMethodMutation, DeleteApprovalMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteApprovalMethodMutation, DeleteApprovalMethodMutationVariables>(DeleteApprovalMethodDocument, options);
      }
export type DeleteApprovalMethodMutationHookResult = ReturnType<typeof useDeleteApprovalMethodMutation>;
export type DeleteApprovalMethodMutationResult = Apollo.MutationResult<DeleteApprovalMethodMutation>;
export const UpdateApprovalMethodDocument = gql`
    mutation updateApprovalMethod($id: ID!, $input: ApprovalMethodInput!) {
  updateApprovalMethod(id: $id, data: $input) {
    data {
      ...ApprovalMethod
    }
  }
}
    ${ApprovalMethodFragmentDoc}
${ApprovalMethodMinFragmentDoc}`;
export type UpdateApprovalMethodMutationFn = Apollo.MutationFunction<UpdateApprovalMethodMutation, UpdateApprovalMethodMutationVariables>;
export function useUpdateApprovalMethodMutation(baseOptions?: Apollo.MutationHookOptions<UpdateApprovalMethodMutation, UpdateApprovalMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateApprovalMethodMutation, UpdateApprovalMethodMutationVariables>(UpdateApprovalMethodDocument, options);
      }
export type UpdateApprovalMethodMutationHookResult = ReturnType<typeof useUpdateApprovalMethodMutation>;
export type UpdateApprovalMethodMutationResult = Apollo.MutationResult<UpdateApprovalMethodMutation>;
export const ApprovalMethodDocument = gql`
    query approvalMethod($id: ID!) {
  approvalMethod(id: $id) {
    data {
      ...ApprovalMethod
    }
  }
}
    ${ApprovalMethodFragmentDoc}
${ApprovalMethodMinFragmentDoc}`;
export function useApprovalMethodQuery(baseOptions: Apollo.QueryHookOptions<ApprovalMethodQuery, ApprovalMethodQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApprovalMethodQuery, ApprovalMethodQueryVariables>(ApprovalMethodDocument, options);
      }
export function useApprovalMethodLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApprovalMethodQuery, ApprovalMethodQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApprovalMethodQuery, ApprovalMethodQueryVariables>(ApprovalMethodDocument, options);
        }
export type ApprovalMethodQueryHookResult = ReturnType<typeof useApprovalMethodQuery>;
export type ApprovalMethodLazyQueryHookResult = ReturnType<typeof useApprovalMethodLazyQuery>;
export type ApprovalMethodQueryResult = Apollo.QueryResult<ApprovalMethodQuery, ApprovalMethodQueryVariables>;
export const ApprovalMethodsDocument = gql`
    query approvalMethods($filters: ApprovalMethodFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  approvalMethods(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...ApprovalMethod
    }
    meta {
      ...Meta
    }
  }
}
    ${ApprovalMethodFragmentDoc}
${ApprovalMethodMinFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useApprovalMethodsQuery(baseOptions?: Apollo.QueryHookOptions<ApprovalMethodsQuery, ApprovalMethodsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApprovalMethodsQuery, ApprovalMethodsQueryVariables>(ApprovalMethodsDocument, options);
      }
export function useApprovalMethodsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApprovalMethodsQuery, ApprovalMethodsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApprovalMethodsQuery, ApprovalMethodsQueryVariables>(ApprovalMethodsDocument, options);
        }
export type ApprovalMethodsQueryHookResult = ReturnType<typeof useApprovalMethodsQuery>;
export type ApprovalMethodsLazyQueryHookResult = ReturnType<typeof useApprovalMethodsLazyQuery>;
export type ApprovalMethodsQueryResult = Apollo.QueryResult<ApprovalMethodsQuery, ApprovalMethodsQueryVariables>;
export const CreateArticleDocument = gql`
    mutation createArticle($input: ArticleInput!) {
  createArticle(data: $input) {
    data {
      ...Article
    }
  }
}
    ${ArticleFragmentDoc}
${ArticleMinFragmentDoc}
${FileFragmentDoc}`;
export type CreateArticleMutationFn = Apollo.MutationFunction<CreateArticleMutation, CreateArticleMutationVariables>;
export function useCreateArticleMutation(baseOptions?: Apollo.MutationHookOptions<CreateArticleMutation, CreateArticleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateArticleMutation, CreateArticleMutationVariables>(CreateArticleDocument, options);
      }
export type CreateArticleMutationHookResult = ReturnType<typeof useCreateArticleMutation>;
export type CreateArticleMutationResult = Apollo.MutationResult<CreateArticleMutation>;
export const DeleteArticleDocument = gql`
    mutation deleteArticle($id: ID!) {
  deleteArticle(id: $id) {
    data {
      ...Article
    }
  }
}
    ${ArticleFragmentDoc}
${ArticleMinFragmentDoc}
${FileFragmentDoc}`;
export type DeleteArticleMutationFn = Apollo.MutationFunction<DeleteArticleMutation, DeleteArticleMutationVariables>;
export function useDeleteArticleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteArticleMutation, DeleteArticleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteArticleMutation, DeleteArticleMutationVariables>(DeleteArticleDocument, options);
      }
export type DeleteArticleMutationHookResult = ReturnType<typeof useDeleteArticleMutation>;
export type DeleteArticleMutationResult = Apollo.MutationResult<DeleteArticleMutation>;
export const SendArticleByEmailDocument = gql`
    mutation sendArticleByEmail($articleId: Int!) {
  sendArticleByEmail(articleId: $articleId)
}
    `;
export type SendArticleByEmailMutationFn = Apollo.MutationFunction<SendArticleByEmailMutation, SendArticleByEmailMutationVariables>;
export function useSendArticleByEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendArticleByEmailMutation, SendArticleByEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendArticleByEmailMutation, SendArticleByEmailMutationVariables>(SendArticleByEmailDocument, options);
      }
export type SendArticleByEmailMutationHookResult = ReturnType<typeof useSendArticleByEmailMutation>;
export type SendArticleByEmailMutationResult = Apollo.MutationResult<SendArticleByEmailMutation>;
export const UpdateArticleDocument = gql`
    mutation updateArticle($id: ID!, $input: ArticleInput!) {
  updateArticle(id: $id, data: $input) {
    data {
      ...Article
    }
  }
}
    ${ArticleFragmentDoc}
${ArticleMinFragmentDoc}
${FileFragmentDoc}`;
export type UpdateArticleMutationFn = Apollo.MutationFunction<UpdateArticleMutation, UpdateArticleMutationVariables>;
export function useUpdateArticleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateArticleMutation, UpdateArticleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateArticleMutation, UpdateArticleMutationVariables>(UpdateArticleDocument, options);
      }
export type UpdateArticleMutationHookResult = ReturnType<typeof useUpdateArticleMutation>;
export type UpdateArticleMutationResult = Apollo.MutationResult<UpdateArticleMutation>;
export const ArticleDocument = gql`
    query article($id: ID!) {
  article(id: $id) {
    data {
      ...Article
    }
  }
}
    ${ArticleFragmentDoc}
${ArticleMinFragmentDoc}
${FileFragmentDoc}`;
export function useArticleQuery(baseOptions: Apollo.QueryHookOptions<ArticleQuery, ArticleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ArticleQuery, ArticleQueryVariables>(ArticleDocument, options);
      }
export function useArticleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ArticleQuery, ArticleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ArticleQuery, ArticleQueryVariables>(ArticleDocument, options);
        }
export type ArticleQueryHookResult = ReturnType<typeof useArticleQuery>;
export type ArticleLazyQueryHookResult = ReturnType<typeof useArticleLazyQuery>;
export type ArticleQueryResult = Apollo.QueryResult<ArticleQuery, ArticleQueryVariables>;
export const ArticlesDocument = gql`
    query articles($filters: ArticleFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  articles(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...Article
    }
    meta {
      ...Meta
    }
  }
}
    ${ArticleFragmentDoc}
${ArticleMinFragmentDoc}
${FileFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useArticlesQuery(baseOptions?: Apollo.QueryHookOptions<ArticlesQuery, ArticlesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ArticlesQuery, ArticlesQueryVariables>(ArticlesDocument, options);
      }
export function useArticlesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ArticlesQuery, ArticlesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ArticlesQuery, ArticlesQueryVariables>(ArticlesDocument, options);
        }
export type ArticlesQueryHookResult = ReturnType<typeof useArticlesQuery>;
export type ArticlesLazyQueryHookResult = ReturnType<typeof useArticlesLazyQuery>;
export type ArticlesQueryResult = Apollo.QueryResult<ArticlesQuery, ArticlesQueryVariables>;
export const ChangePasswordDocument = gql`
    mutation changePassword($currentPassword: String!, $password: String!, $passwordConfirmation: String!) {
  changePassword(
    currentPassword: $currentPassword
    password: $password
    passwordConfirmation: $passwordConfirmation
  ) {
    jwt
  }
}
    `;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export const ConfirmEmailDocument = gql`
    mutation confirmEmail($confirmation: String!) {
  emailConfirmation(confirmation: $confirmation) {
    user {
      id
    }
  }
}
    `;
export type ConfirmEmailMutationFn = Apollo.MutationFunction<ConfirmEmailMutation, ConfirmEmailMutationVariables>;
export function useConfirmEmailMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmEmailMutation, ConfirmEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmEmailMutation, ConfirmEmailMutationVariables>(ConfirmEmailDocument, options);
      }
export type ConfirmEmailMutationHookResult = ReturnType<typeof useConfirmEmailMutation>;
export type ConfirmEmailMutationResult = Apollo.MutationResult<ConfirmEmailMutation>;
export const ForgotPasswordDocument = gql`
    mutation forgotPassword($email: String!) {
  forgotPassword(email: $email) {
    ok
  }
}
    `;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export const LoginDocument = gql`
    mutation login($input: UsersPermissionsLoginInput!) {
  login(input: $input) {
    user {
      id
    }
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export const RegisterDocument = gql`
    mutation register($input: UsersPermissionsRegisterInput!) {
  register(input: $input) {
    user {
      id
      username
      email
      confirmed
      blocked
      role {
        id
        name
        description
        type
      }
    }
  }
}
    `;
export type RegisterMutationFn = Apollo.MutationFunction<RegisterMutation, RegisterMutationVariables>;
export function useRegisterMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, options);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export const RegisterCustomerDocument = gql`
    mutation registerCustomer($input: CustomerUserRegisterInput!) {
  registerCustomer(input: $input) {
    jwt
    user {
      id
      username
      email
      confirmed
      blocked
      role {
        id
        name
        description
        type
      }
    }
  }
}
    `;
export type RegisterCustomerMutationFn = Apollo.MutationFunction<RegisterCustomerMutation, RegisterCustomerMutationVariables>;
export function useRegisterCustomerMutation(baseOptions?: Apollo.MutationHookOptions<RegisterCustomerMutation, RegisterCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterCustomerMutation, RegisterCustomerMutationVariables>(RegisterCustomerDocument, options);
      }
export type RegisterCustomerMutationHookResult = ReturnType<typeof useRegisterCustomerMutation>;
export type RegisterCustomerMutationResult = Apollo.MutationResult<RegisterCustomerMutation>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($code: String!, $password: String!, $passwordConfirmation: String!) {
  resetPassword(
    code: $code
    password: $password
    passwordConfirmation: $passwordConfirmation
  ) {
    user {
      id
    }
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export const SendEmailDocument = gql`
    mutation sendEmail($data: SendEmailInput!) {
  sendEmail(data: $data)
}
    `;
export type SendEmailMutationFn = Apollo.MutationFunction<SendEmailMutation, SendEmailMutationVariables>;
export function useSendEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendEmailMutation, SendEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendEmailMutation, SendEmailMutationVariables>(SendEmailDocument, options);
      }
export type SendEmailMutationHookResult = ReturnType<typeof useSendEmailMutation>;
export type SendEmailMutationResult = Apollo.MutationResult<SendEmailMutation>;
export const AuthLayoutContentDocument = gql`
    query authLayoutContent {
  authLayout {
    data {
      attributes {
        authContent {
          ...AuthContent
        }
      }
    }
  }
}
    ${AuthContentFragmentDoc}
${FileFragmentDoc}
${HeadlineFragmentDoc}`;
export function useAuthLayoutContentQuery(baseOptions?: Apollo.QueryHookOptions<AuthLayoutContentQuery, AuthLayoutContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AuthLayoutContentQuery, AuthLayoutContentQueryVariables>(AuthLayoutContentDocument, options);
      }
export function useAuthLayoutContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AuthLayoutContentQuery, AuthLayoutContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AuthLayoutContentQuery, AuthLayoutContentQueryVariables>(AuthLayoutContentDocument, options);
        }
export type AuthLayoutContentQueryHookResult = ReturnType<typeof useAuthLayoutContentQuery>;
export type AuthLayoutContentLazyQueryHookResult = ReturnType<typeof useAuthLayoutContentLazyQuery>;
export type AuthLayoutContentQueryResult = Apollo.QueryResult<AuthLayoutContentQuery, AuthLayoutContentQueryVariables>;
export const AuthLayoutHeadlineDocument = gql`
    query authLayoutHeadline {
  authLayout {
    data {
      attributes {
        headline {
          ...Headline
        }
      }
    }
  }
}
    ${HeadlineFragmentDoc}`;
export function useAuthLayoutHeadlineQuery(baseOptions?: Apollo.QueryHookOptions<AuthLayoutHeadlineQuery, AuthLayoutHeadlineQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AuthLayoutHeadlineQuery, AuthLayoutHeadlineQueryVariables>(AuthLayoutHeadlineDocument, options);
      }
export function useAuthLayoutHeadlineLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AuthLayoutHeadlineQuery, AuthLayoutHeadlineQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AuthLayoutHeadlineQuery, AuthLayoutHeadlineQueryVariables>(AuthLayoutHeadlineDocument, options);
        }
export type AuthLayoutHeadlineQueryHookResult = ReturnType<typeof useAuthLayoutHeadlineQuery>;
export type AuthLayoutHeadlineLazyQueryHookResult = ReturnType<typeof useAuthLayoutHeadlineLazyQuery>;
export type AuthLayoutHeadlineQueryResult = Apollo.QueryResult<AuthLayoutHeadlineQuery, AuthLayoutHeadlineQueryVariables>;
export const MeDocument = gql`
    query me {
  me {
    ...User
  }
}
    ${UserFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ContactMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${BusinessLocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}`;
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const PasswordTokenValidDocument = gql`
    query passwordTokenValid($code: String!, $email: String!) {
  passwordTokenValid(code: $code, email: $email) {
    isValid
  }
}
    `;
export function usePasswordTokenValidQuery(baseOptions: Apollo.QueryHookOptions<PasswordTokenValidQuery, PasswordTokenValidQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PasswordTokenValidQuery, PasswordTokenValidQueryVariables>(PasswordTokenValidDocument, options);
      }
export function usePasswordTokenValidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PasswordTokenValidQuery, PasswordTokenValidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PasswordTokenValidQuery, PasswordTokenValidQueryVariables>(PasswordTokenValidDocument, options);
        }
export type PasswordTokenValidQueryHookResult = ReturnType<typeof usePasswordTokenValidQuery>;
export type PasswordTokenValidLazyQueryHookResult = ReturnType<typeof usePasswordTokenValidLazyQuery>;
export type PasswordTokenValidQueryResult = Apollo.QueryResult<PasswordTokenValidQuery, PasswordTokenValidQueryVariables>;
export const CreateBusinessLocationDocument = gql`
    mutation createBusinessLocation($input: BusinessLocationInput!) {
  createBusinessLocation(data: $input) {
    data {
      ...BusinessLocation
    }
  }
}
    ${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}`;
export type CreateBusinessLocationMutationFn = Apollo.MutationFunction<CreateBusinessLocationMutation, CreateBusinessLocationMutationVariables>;
export function useCreateBusinessLocationMutation(baseOptions?: Apollo.MutationHookOptions<CreateBusinessLocationMutation, CreateBusinessLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBusinessLocationMutation, CreateBusinessLocationMutationVariables>(CreateBusinessLocationDocument, options);
      }
export type CreateBusinessLocationMutationHookResult = ReturnType<typeof useCreateBusinessLocationMutation>;
export type CreateBusinessLocationMutationResult = Apollo.MutationResult<CreateBusinessLocationMutation>;
export const CreateSubLocationDocument = gql`
    mutation createSubLocation($input: SublocationInput!) {
  createSublocation(data: $input) {
    data {
      ...SubLocation
    }
  }
}
    ${SubLocationFragmentDoc}
${SubLocationMinFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationItemFragmentDoc}
${SubLocationItemMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${FileFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${SerializeFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}`;
export type CreateSubLocationMutationFn = Apollo.MutationFunction<CreateSubLocationMutation, CreateSubLocationMutationVariables>;
export function useCreateSubLocationMutation(baseOptions?: Apollo.MutationHookOptions<CreateSubLocationMutation, CreateSubLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSubLocationMutation, CreateSubLocationMutationVariables>(CreateSubLocationDocument, options);
      }
export type CreateSubLocationMutationHookResult = ReturnType<typeof useCreateSubLocationMutation>;
export type CreateSubLocationMutationResult = Apollo.MutationResult<CreateSubLocationMutation>;
export const CreateSubLocationItemDocument = gql`
    mutation createSubLocationItem($input: SublocationItemInput!) {
  createSublocationItem(data: $input) {
    data {
      ...SubLocationItem
    }
  }
}
    ${SubLocationItemFragmentDoc}
${SubLocationItemMinFragmentDoc}
${SubLocationMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${FileFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${SerializeFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}`;
export type CreateSubLocationItemMutationFn = Apollo.MutationFunction<CreateSubLocationItemMutation, CreateSubLocationItemMutationVariables>;
export function useCreateSubLocationItemMutation(baseOptions?: Apollo.MutationHookOptions<CreateSubLocationItemMutation, CreateSubLocationItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSubLocationItemMutation, CreateSubLocationItemMutationVariables>(CreateSubLocationItemDocument, options);
      }
export type CreateSubLocationItemMutationHookResult = ReturnType<typeof useCreateSubLocationItemMutation>;
export type CreateSubLocationItemMutationResult = Apollo.MutationResult<CreateSubLocationItemMutation>;
export const DecrementSubLocationItemsQntyDocument = gql`
    mutation decrementSubLocationItemsQnty($input: DecrementSubLocationItemsInput!) {
  decrementSubLocationItemsQnty(input: $input) {
    data {
      ...SubLocation
    }
  }
}
    ${SubLocationFragmentDoc}
${SubLocationMinFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationItemFragmentDoc}
${SubLocationItemMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${FileFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${SerializeFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}`;
export type DecrementSubLocationItemsQntyMutationFn = Apollo.MutationFunction<DecrementSubLocationItemsQntyMutation, DecrementSubLocationItemsQntyMutationVariables>;
export function useDecrementSubLocationItemsQntyMutation(baseOptions?: Apollo.MutationHookOptions<DecrementSubLocationItemsQntyMutation, DecrementSubLocationItemsQntyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DecrementSubLocationItemsQntyMutation, DecrementSubLocationItemsQntyMutationVariables>(DecrementSubLocationItemsQntyDocument, options);
      }
export type DecrementSubLocationItemsQntyMutationHookResult = ReturnType<typeof useDecrementSubLocationItemsQntyMutation>;
export type DecrementSubLocationItemsQntyMutationResult = Apollo.MutationResult<DecrementSubLocationItemsQntyMutation>;
export const UpdateBusinessLocationDocument = gql`
    mutation updateBusinessLocation($id: ID!, $input: BusinessLocationInput!) {
  updateBusinessLocation(id: $id, data: $input) {
    data {
      ...BusinessLocation
    }
  }
}
    ${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}`;
export type UpdateBusinessLocationMutationFn = Apollo.MutationFunction<UpdateBusinessLocationMutation, UpdateBusinessLocationMutationVariables>;
export function useUpdateBusinessLocationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBusinessLocationMutation, UpdateBusinessLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBusinessLocationMutation, UpdateBusinessLocationMutationVariables>(UpdateBusinessLocationDocument, options);
      }
export type UpdateBusinessLocationMutationHookResult = ReturnType<typeof useUpdateBusinessLocationMutation>;
export type UpdateBusinessLocationMutationResult = Apollo.MutationResult<UpdateBusinessLocationMutation>;
export const UpdateSubLocationItemsDocument = gql`
    mutation updateSubLocationItems($input: UpdateSubLocationItemsInput!) {
  updateSubLocationItems(input: $input) {
    data {
      ...SubLocation
    }
  }
}
    ${SubLocationFragmentDoc}
${SubLocationMinFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationItemFragmentDoc}
${SubLocationItemMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${FileFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${SerializeFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}`;
export type UpdateSubLocationItemsMutationFn = Apollo.MutationFunction<UpdateSubLocationItemsMutation, UpdateSubLocationItemsMutationVariables>;
export function useUpdateSubLocationItemsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSubLocationItemsMutation, UpdateSubLocationItemsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSubLocationItemsMutation, UpdateSubLocationItemsMutationVariables>(UpdateSubLocationItemsDocument, options);
      }
export type UpdateSubLocationItemsMutationHookResult = ReturnType<typeof useUpdateSubLocationItemsMutation>;
export type UpdateSubLocationItemsMutationResult = Apollo.MutationResult<UpdateSubLocationItemsMutation>;
export const UpdateSublocationItemDocument = gql`
    mutation updateSublocationItem($id: ID!, $input: SublocationItemInput!) {
  updateSublocationItem(id: $id, data: $input) {
    data {
      ...SubLocationItem
    }
  }
}
    ${SubLocationItemFragmentDoc}
${SubLocationItemMinFragmentDoc}
${SubLocationMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${FileFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${SerializeFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}`;
export type UpdateSublocationItemMutationFn = Apollo.MutationFunction<UpdateSublocationItemMutation, UpdateSublocationItemMutationVariables>;
export function useUpdateSublocationItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSublocationItemMutation, UpdateSublocationItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSublocationItemMutation, UpdateSublocationItemMutationVariables>(UpdateSublocationItemDocument, options);
      }
export type UpdateSublocationItemMutationHookResult = ReturnType<typeof useUpdateSublocationItemMutation>;
export type UpdateSublocationItemMutationResult = Apollo.MutationResult<UpdateSublocationItemMutation>;
export const BusinessLocationDocument = gql`
    query businessLocation($id: ID!) {
  businessLocation(id: $id) {
    data {
      ...BusinessLocation
    }
  }
}
    ${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}`;
export function useBusinessLocationQuery(baseOptions: Apollo.QueryHookOptions<BusinessLocationQuery, BusinessLocationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BusinessLocationQuery, BusinessLocationQueryVariables>(BusinessLocationDocument, options);
      }
export function useBusinessLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusinessLocationQuery, BusinessLocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BusinessLocationQuery, BusinessLocationQueryVariables>(BusinessLocationDocument, options);
        }
export type BusinessLocationQueryHookResult = ReturnType<typeof useBusinessLocationQuery>;
export type BusinessLocationLazyQueryHookResult = ReturnType<typeof useBusinessLocationLazyQuery>;
export type BusinessLocationQueryResult = Apollo.QueryResult<BusinessLocationQuery, BusinessLocationQueryVariables>;
export const BusinessLocationsDocument = gql`
    query businessLocations($filters: BusinessLocationFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  businessLocations(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...BusinessLocation
    }
    meta {
      ...Meta
    }
  }
}
    ${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useBusinessLocationsQuery(baseOptions?: Apollo.QueryHookOptions<BusinessLocationsQuery, BusinessLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BusinessLocationsQuery, BusinessLocationsQueryVariables>(BusinessLocationsDocument, options);
      }
export function useBusinessLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BusinessLocationsQuery, BusinessLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BusinessLocationsQuery, BusinessLocationsQueryVariables>(BusinessLocationsDocument, options);
        }
export type BusinessLocationsQueryHookResult = ReturnType<typeof useBusinessLocationsQuery>;
export type BusinessLocationsLazyQueryHookResult = ReturnType<typeof useBusinessLocationsLazyQuery>;
export type BusinessLocationsQueryResult = Apollo.QueryResult<BusinessLocationsQuery, BusinessLocationsQueryVariables>;
export const SublocationDocument = gql`
    query sublocation($id: ID!) {
  sublocation(id: $id) {
    data {
      ...SubLocation
    }
  }
}
    ${SubLocationFragmentDoc}
${SubLocationMinFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationItemFragmentDoc}
${SubLocationItemMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${FileFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${SerializeFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}`;
export function useSublocationQuery(baseOptions: Apollo.QueryHookOptions<SublocationQuery, SublocationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SublocationQuery, SublocationQueryVariables>(SublocationDocument, options);
      }
export function useSublocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SublocationQuery, SublocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SublocationQuery, SublocationQueryVariables>(SublocationDocument, options);
        }
export type SublocationQueryHookResult = ReturnType<typeof useSublocationQuery>;
export type SublocationLazyQueryHookResult = ReturnType<typeof useSublocationLazyQuery>;
export type SublocationQueryResult = Apollo.QueryResult<SublocationQuery, SublocationQueryVariables>;
export const SublocationItemDocument = gql`
    query sublocationItem($id: ID!) {
  sublocationItem(id: $id) {
    data {
      ...SubLocationItem
    }
  }
}
    ${SubLocationItemFragmentDoc}
${SubLocationItemMinFragmentDoc}
${SubLocationMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${FileFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${SerializeFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}`;
export function useSublocationItemQuery(baseOptions: Apollo.QueryHookOptions<SublocationItemQuery, SublocationItemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SublocationItemQuery, SublocationItemQueryVariables>(SublocationItemDocument, options);
      }
export function useSublocationItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SublocationItemQuery, SublocationItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SublocationItemQuery, SublocationItemQueryVariables>(SublocationItemDocument, options);
        }
export type SublocationItemQueryHookResult = ReturnType<typeof useSublocationItemQuery>;
export type SublocationItemLazyQueryHookResult = ReturnType<typeof useSublocationItemLazyQuery>;
export type SublocationItemQueryResult = Apollo.QueryResult<SublocationItemQuery, SublocationItemQueryVariables>;
export const SublocationItemsDocument = gql`
    query sublocationItems($filters: SublocationItemFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  sublocationItems(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...SubLocationItem
    }
    meta {
      ...Meta
    }
  }
}
    ${SubLocationItemFragmentDoc}
${SubLocationItemMinFragmentDoc}
${SubLocationMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${FileFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${SerializeFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useSublocationItemsQuery(baseOptions?: Apollo.QueryHookOptions<SublocationItemsQuery, SublocationItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SublocationItemsQuery, SublocationItemsQueryVariables>(SublocationItemsDocument, options);
      }
export function useSublocationItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SublocationItemsQuery, SublocationItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SublocationItemsQuery, SublocationItemsQueryVariables>(SublocationItemsDocument, options);
        }
export type SublocationItemsQueryHookResult = ReturnType<typeof useSublocationItemsQuery>;
export type SublocationItemsLazyQueryHookResult = ReturnType<typeof useSublocationItemsLazyQuery>;
export type SublocationItemsQueryResult = Apollo.QueryResult<SublocationItemsQuery, SublocationItemsQueryVariables>;
export const SubLocationsDocument = gql`
    query subLocations($filters: SublocationFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  sublocations(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...SubLocation
    }
    meta {
      ...Meta
    }
  }
}
    ${SubLocationFragmentDoc}
${SubLocationMinFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationItemFragmentDoc}
${SubLocationItemMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${FileFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${SerializeFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useSubLocationsQuery(baseOptions?: Apollo.QueryHookOptions<SubLocationsQuery, SubLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubLocationsQuery, SubLocationsQueryVariables>(SubLocationsDocument, options);
      }
export function useSubLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubLocationsQuery, SubLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubLocationsQuery, SubLocationsQueryVariables>(SubLocationsDocument, options);
        }
export type SubLocationsQueryHookResult = ReturnType<typeof useSubLocationsQuery>;
export type SubLocationsLazyQueryHookResult = ReturnType<typeof useSubLocationsLazyQuery>;
export type SubLocationsQueryResult = Apollo.QueryResult<SubLocationsQuery, SubLocationsQueryVariables>;
export const AutoEnrollContactsDocument = gql`
    mutation autoEnrollContacts($input: CampaignEnrollContactsInput!) {
  autoEnrollContacts(input: $input) {
    data {
      ...CampaignMin
    }
  }
}
    ${CampaignMinFragmentDoc}`;
export type AutoEnrollContactsMutationFn = Apollo.MutationFunction<AutoEnrollContactsMutation, AutoEnrollContactsMutationVariables>;
export function useAutoEnrollContactsMutation(baseOptions?: Apollo.MutationHookOptions<AutoEnrollContactsMutation, AutoEnrollContactsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AutoEnrollContactsMutation, AutoEnrollContactsMutationVariables>(AutoEnrollContactsDocument, options);
      }
export type AutoEnrollContactsMutationHookResult = ReturnType<typeof useAutoEnrollContactsMutation>;
export type AutoEnrollContactsMutationResult = Apollo.MutationResult<AutoEnrollContactsMutation>;
export const AutoEnrollLeadsDocument = gql`
    mutation autoEnrollLeads($input: CampaignEnrollLeadsInput!) {
  autoEnrollLeads(input: $input) {
    data {
      ...CampaignMin
    }
  }
}
    ${CampaignMinFragmentDoc}`;
export type AutoEnrollLeadsMutationFn = Apollo.MutationFunction<AutoEnrollLeadsMutation, AutoEnrollLeadsMutationVariables>;
export function useAutoEnrollLeadsMutation(baseOptions?: Apollo.MutationHookOptions<AutoEnrollLeadsMutation, AutoEnrollLeadsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AutoEnrollLeadsMutation, AutoEnrollLeadsMutationVariables>(AutoEnrollLeadsDocument, options);
      }
export type AutoEnrollLeadsMutationHookResult = ReturnType<typeof useAutoEnrollLeadsMutation>;
export type AutoEnrollLeadsMutationResult = Apollo.MutationResult<AutoEnrollLeadsMutation>;
export const CreateCampaignDocument = gql`
    mutation createCampaign($input: CampaignInput!) {
  createCampaign(data: $input) {
    data {
      ...Campaign
    }
  }
}
    ${CampaignFragmentDoc}
${CampaignMinFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${TenantFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}
${SequenceStepFragmentDoc}
${SequenceStepsInfoFragmentDoc}
${MarketingEmailTemplateMinFragmentDoc}
${CampaignEnrolledContactFragmentDoc}
${ContactMinFragmentDoc}
${EnrollContactConditionFragmentDoc}`;
export type CreateCampaignMutationFn = Apollo.MutationFunction<CreateCampaignMutation, CreateCampaignMutationVariables>;
export function useCreateCampaignMutation(baseOptions?: Apollo.MutationHookOptions<CreateCampaignMutation, CreateCampaignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCampaignMutation, CreateCampaignMutationVariables>(CreateCampaignDocument, options);
      }
export type CreateCampaignMutationHookResult = ReturnType<typeof useCreateCampaignMutation>;
export type CreateCampaignMutationResult = Apollo.MutationResult<CreateCampaignMutation>;
export const CreateCampaignEnrolledContactDocument = gql`
    mutation createCampaignEnrolledContact($input: CampaignEnrolledContactInput!) {
  createCampaignEnrolledContact(data: $input) {
    data {
      ...CampaignEnrolledContact
    }
  }
}
    ${CampaignEnrolledContactFragmentDoc}
${CampaignMinFragmentDoc}
${ContactMinFragmentDoc}
${FileFragmentDoc}`;
export type CreateCampaignEnrolledContactMutationFn = Apollo.MutationFunction<CreateCampaignEnrolledContactMutation, CreateCampaignEnrolledContactMutationVariables>;
export function useCreateCampaignEnrolledContactMutation(baseOptions?: Apollo.MutationHookOptions<CreateCampaignEnrolledContactMutation, CreateCampaignEnrolledContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCampaignEnrolledContactMutation, CreateCampaignEnrolledContactMutationVariables>(CreateCampaignEnrolledContactDocument, options);
      }
export type CreateCampaignEnrolledContactMutationHookResult = ReturnType<typeof useCreateCampaignEnrolledContactMutation>;
export type CreateCampaignEnrolledContactMutationResult = Apollo.MutationResult<CreateCampaignEnrolledContactMutation>;
export const CreateCampaignEnrolledLeadDocument = gql`
    mutation createCampaignEnrolledLead($input: CampaignEnrolledLeadInput!) {
  createCampaignEnrolledLead(data: $input) {
    data {
      ...CampaignEnrolledLead
    }
  }
}
    ${CampaignEnrolledLeadFragmentDoc}
${CampaignMinFragmentDoc}
${LeadMinFragmentDoc}
${FileFragmentDoc}`;
export type CreateCampaignEnrolledLeadMutationFn = Apollo.MutationFunction<CreateCampaignEnrolledLeadMutation, CreateCampaignEnrolledLeadMutationVariables>;
export function useCreateCampaignEnrolledLeadMutation(baseOptions?: Apollo.MutationHookOptions<CreateCampaignEnrolledLeadMutation, CreateCampaignEnrolledLeadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCampaignEnrolledLeadMutation, CreateCampaignEnrolledLeadMutationVariables>(CreateCampaignEnrolledLeadDocument, options);
      }
export type CreateCampaignEnrolledLeadMutationHookResult = ReturnType<typeof useCreateCampaignEnrolledLeadMutation>;
export type CreateCampaignEnrolledLeadMutationResult = Apollo.MutationResult<CreateCampaignEnrolledLeadMutation>;
export const CreateSequenceStepDocument = gql`
    mutation createSequenceStep($input: SequenceStepInput!) {
  createSequenceStep(data: $input) {
    data {
      ...SequenceStep
    }
  }
}
    ${SequenceStepFragmentDoc}
${SequenceStepsInfoFragmentDoc}
${CampaignMinFragmentDoc}
${MarketingEmailTemplateMinFragmentDoc}`;
export type CreateSequenceStepMutationFn = Apollo.MutationFunction<CreateSequenceStepMutation, CreateSequenceStepMutationVariables>;
export function useCreateSequenceStepMutation(baseOptions?: Apollo.MutationHookOptions<CreateSequenceStepMutation, CreateSequenceStepMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSequenceStepMutation, CreateSequenceStepMutationVariables>(CreateSequenceStepDocument, options);
      }
export type CreateSequenceStepMutationHookResult = ReturnType<typeof useCreateSequenceStepMutation>;
export type CreateSequenceStepMutationResult = Apollo.MutationResult<CreateSequenceStepMutation>;
export const CreateSequenceStepInfoDocument = gql`
    mutation createSequenceStepInfo($input: SequenceStepInfoInput!) {
  createSequenceStepInfo(data: $input) {
    data {
      ...SequenceStepsInfo
    }
  }
}
    ${SequenceStepsInfoFragmentDoc}`;
export type CreateSequenceStepInfoMutationFn = Apollo.MutationFunction<CreateSequenceStepInfoMutation, CreateSequenceStepInfoMutationVariables>;
export function useCreateSequenceStepInfoMutation(baseOptions?: Apollo.MutationHookOptions<CreateSequenceStepInfoMutation, CreateSequenceStepInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSequenceStepInfoMutation, CreateSequenceStepInfoMutationVariables>(CreateSequenceStepInfoDocument, options);
      }
export type CreateSequenceStepInfoMutationHookResult = ReturnType<typeof useCreateSequenceStepInfoMutation>;
export type CreateSequenceStepInfoMutationResult = Apollo.MutationResult<CreateSequenceStepInfoMutation>;
export const DeleteCampaignDocument = gql`
    mutation deleteCampaign($id: ID!) {
  deleteCampaign(id: $id) {
    data {
      ...Campaign
    }
  }
}
    ${CampaignFragmentDoc}
${CampaignMinFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${TenantFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}
${SequenceStepFragmentDoc}
${SequenceStepsInfoFragmentDoc}
${MarketingEmailTemplateMinFragmentDoc}
${CampaignEnrolledContactFragmentDoc}
${ContactMinFragmentDoc}
${EnrollContactConditionFragmentDoc}`;
export type DeleteCampaignMutationFn = Apollo.MutationFunction<DeleteCampaignMutation, DeleteCampaignMutationVariables>;
export function useDeleteCampaignMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCampaignMutation, DeleteCampaignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCampaignMutation, DeleteCampaignMutationVariables>(DeleteCampaignDocument, options);
      }
export type DeleteCampaignMutationHookResult = ReturnType<typeof useDeleteCampaignMutation>;
export type DeleteCampaignMutationResult = Apollo.MutationResult<DeleteCampaignMutation>;
export const DeleteCampaignEnrolledContactDocument = gql`
    mutation deleteCampaignEnrolledContact($id: ID!) {
  deleteCampaignEnrolledContact(id: $id) {
    data {
      ...CampaignEnrolledContact
    }
  }
}
    ${CampaignEnrolledContactFragmentDoc}
${CampaignMinFragmentDoc}
${ContactMinFragmentDoc}
${FileFragmentDoc}`;
export type DeleteCampaignEnrolledContactMutationFn = Apollo.MutationFunction<DeleteCampaignEnrolledContactMutation, DeleteCampaignEnrolledContactMutationVariables>;
export function useDeleteCampaignEnrolledContactMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCampaignEnrolledContactMutation, DeleteCampaignEnrolledContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCampaignEnrolledContactMutation, DeleteCampaignEnrolledContactMutationVariables>(DeleteCampaignEnrolledContactDocument, options);
      }
export type DeleteCampaignEnrolledContactMutationHookResult = ReturnType<typeof useDeleteCampaignEnrolledContactMutation>;
export type DeleteCampaignEnrolledContactMutationResult = Apollo.MutationResult<DeleteCampaignEnrolledContactMutation>;
export const DeleteCampaignEnrolledLeadDocument = gql`
    mutation deleteCampaignEnrolledLead($id: ID!) {
  deleteCampaignEnrolledLead(id: $id) {
    data {
      ...CampaignEnrolledLead
    }
  }
}
    ${CampaignEnrolledLeadFragmentDoc}
${CampaignMinFragmentDoc}
${LeadMinFragmentDoc}
${FileFragmentDoc}`;
export type DeleteCampaignEnrolledLeadMutationFn = Apollo.MutationFunction<DeleteCampaignEnrolledLeadMutation, DeleteCampaignEnrolledLeadMutationVariables>;
export function useDeleteCampaignEnrolledLeadMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCampaignEnrolledLeadMutation, DeleteCampaignEnrolledLeadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCampaignEnrolledLeadMutation, DeleteCampaignEnrolledLeadMutationVariables>(DeleteCampaignEnrolledLeadDocument, options);
      }
export type DeleteCampaignEnrolledLeadMutationHookResult = ReturnType<typeof useDeleteCampaignEnrolledLeadMutation>;
export type DeleteCampaignEnrolledLeadMutationResult = Apollo.MutationResult<DeleteCampaignEnrolledLeadMutation>;
export const DeleteSequenceStepDocument = gql`
    mutation deleteSequenceStep($id: ID!) {
  deleteSequenceStep(id: $id) {
    data {
      ...SequenceStep
    }
  }
}
    ${SequenceStepFragmentDoc}
${SequenceStepsInfoFragmentDoc}
${CampaignMinFragmentDoc}
${MarketingEmailTemplateMinFragmentDoc}`;
export type DeleteSequenceStepMutationFn = Apollo.MutationFunction<DeleteSequenceStepMutation, DeleteSequenceStepMutationVariables>;
export function useDeleteSequenceStepMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSequenceStepMutation, DeleteSequenceStepMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSequenceStepMutation, DeleteSequenceStepMutationVariables>(DeleteSequenceStepDocument, options);
      }
export type DeleteSequenceStepMutationHookResult = ReturnType<typeof useDeleteSequenceStepMutation>;
export type DeleteSequenceStepMutationResult = Apollo.MutationResult<DeleteSequenceStepMutation>;
export const PublishCampaignDraftDocument = gql`
    mutation publishCampaignDraft($id: ID!) {
  publishCampaignDraft(campaignId: $id)
}
    `;
export type PublishCampaignDraftMutationFn = Apollo.MutationFunction<PublishCampaignDraftMutation, PublishCampaignDraftMutationVariables>;
export function usePublishCampaignDraftMutation(baseOptions?: Apollo.MutationHookOptions<PublishCampaignDraftMutation, PublishCampaignDraftMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PublishCampaignDraftMutation, PublishCampaignDraftMutationVariables>(PublishCampaignDraftDocument, options);
      }
export type PublishCampaignDraftMutationHookResult = ReturnType<typeof usePublishCampaignDraftMutation>;
export type PublishCampaignDraftMutationResult = Apollo.MutationResult<PublishCampaignDraftMutation>;
export const UpdateCampaignDocument = gql`
    mutation updateCampaign($id: ID!, $input: CampaignInput!) {
  updateCampaign(id: $id, data: $input) {
    data {
      ...Campaign
    }
  }
}
    ${CampaignFragmentDoc}
${CampaignMinFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${TenantFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}
${SequenceStepFragmentDoc}
${SequenceStepsInfoFragmentDoc}
${MarketingEmailTemplateMinFragmentDoc}
${CampaignEnrolledContactFragmentDoc}
${ContactMinFragmentDoc}
${EnrollContactConditionFragmentDoc}`;
export type UpdateCampaignMutationFn = Apollo.MutationFunction<UpdateCampaignMutation, UpdateCampaignMutationVariables>;
export function useUpdateCampaignMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCampaignMutation, UpdateCampaignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCampaignMutation, UpdateCampaignMutationVariables>(UpdateCampaignDocument, options);
      }
export type UpdateCampaignMutationHookResult = ReturnType<typeof useUpdateCampaignMutation>;
export type UpdateCampaignMutationResult = Apollo.MutationResult<UpdateCampaignMutation>;
export const UpdateCampaignEnrolledContactDocument = gql`
    mutation updateCampaignEnrolledContact($id: ID!, $input: CampaignEnrolledContactInput!) {
  updateCampaignEnrolledContact(id: $id, data: $input) {
    data {
      ...CampaignEnrolledContact
    }
  }
}
    ${CampaignEnrolledContactFragmentDoc}
${CampaignMinFragmentDoc}
${ContactMinFragmentDoc}
${FileFragmentDoc}`;
export type UpdateCampaignEnrolledContactMutationFn = Apollo.MutationFunction<UpdateCampaignEnrolledContactMutation, UpdateCampaignEnrolledContactMutationVariables>;
export function useUpdateCampaignEnrolledContactMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCampaignEnrolledContactMutation, UpdateCampaignEnrolledContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCampaignEnrolledContactMutation, UpdateCampaignEnrolledContactMutationVariables>(UpdateCampaignEnrolledContactDocument, options);
      }
export type UpdateCampaignEnrolledContactMutationHookResult = ReturnType<typeof useUpdateCampaignEnrolledContactMutation>;
export type UpdateCampaignEnrolledContactMutationResult = Apollo.MutationResult<UpdateCampaignEnrolledContactMutation>;
export const UpdateCampaignEnrolledLeadDocument = gql`
    mutation updateCampaignEnrolledLead($id: ID!, $input: CampaignEnrolledLeadInput!) {
  updateCampaignEnrolledLead(id: $id, data: $input) {
    data {
      ...CampaignEnrolledLead
    }
  }
}
    ${CampaignEnrolledLeadFragmentDoc}
${CampaignMinFragmentDoc}
${LeadMinFragmentDoc}
${FileFragmentDoc}`;
export type UpdateCampaignEnrolledLeadMutationFn = Apollo.MutationFunction<UpdateCampaignEnrolledLeadMutation, UpdateCampaignEnrolledLeadMutationVariables>;
export function useUpdateCampaignEnrolledLeadMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCampaignEnrolledLeadMutation, UpdateCampaignEnrolledLeadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCampaignEnrolledLeadMutation, UpdateCampaignEnrolledLeadMutationVariables>(UpdateCampaignEnrolledLeadDocument, options);
      }
export type UpdateCampaignEnrolledLeadMutationHookResult = ReturnType<typeof useUpdateCampaignEnrolledLeadMutation>;
export type UpdateCampaignEnrolledLeadMutationResult = Apollo.MutationResult<UpdateCampaignEnrolledLeadMutation>;
export const UpdateSequenceStepDocument = gql`
    mutation updateSequenceStep($id: ID!, $input: SequenceStepInput!) {
  updateSequenceStep(id: $id, data: $input) {
    data {
      ...SequenceStep
    }
  }
}
    ${SequenceStepFragmentDoc}
${SequenceStepsInfoFragmentDoc}
${CampaignMinFragmentDoc}
${MarketingEmailTemplateMinFragmentDoc}`;
export type UpdateSequenceStepMutationFn = Apollo.MutationFunction<UpdateSequenceStepMutation, UpdateSequenceStepMutationVariables>;
export function useUpdateSequenceStepMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSequenceStepMutation, UpdateSequenceStepMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSequenceStepMutation, UpdateSequenceStepMutationVariables>(UpdateSequenceStepDocument, options);
      }
export type UpdateSequenceStepMutationHookResult = ReturnType<typeof useUpdateSequenceStepMutation>;
export type UpdateSequenceStepMutationResult = Apollo.MutationResult<UpdateSequenceStepMutation>;
export const UpdateSequenceStepInfoDocument = gql`
    mutation updateSequenceStepInfo($id: ID!, $input: SequenceStepInfoInput!) {
  updateSequenceStepInfo(id: $id, data: $input) {
    data {
      ...SequenceStepsInfo
    }
  }
}
    ${SequenceStepsInfoFragmentDoc}`;
export type UpdateSequenceStepInfoMutationFn = Apollo.MutationFunction<UpdateSequenceStepInfoMutation, UpdateSequenceStepInfoMutationVariables>;
export function useUpdateSequenceStepInfoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSequenceStepInfoMutation, UpdateSequenceStepInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSequenceStepInfoMutation, UpdateSequenceStepInfoMutationVariables>(UpdateSequenceStepInfoDocument, options);
      }
export type UpdateSequenceStepInfoMutationHookResult = ReturnType<typeof useUpdateSequenceStepInfoMutation>;
export type UpdateSequenceStepInfoMutationResult = Apollo.MutationResult<UpdateSequenceStepInfoMutation>;
export const CampaignDocument = gql`
    query campaign($id: ID!) {
  campaign(id: $id) {
    data {
      ...Campaign
    }
  }
}
    ${CampaignFragmentDoc}
${CampaignMinFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${TenantFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}
${SequenceStepFragmentDoc}
${SequenceStepsInfoFragmentDoc}
${MarketingEmailTemplateMinFragmentDoc}
${CampaignEnrolledContactFragmentDoc}
${ContactMinFragmentDoc}
${EnrollContactConditionFragmentDoc}`;
export function useCampaignQuery(baseOptions: Apollo.QueryHookOptions<CampaignQuery, CampaignQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CampaignQuery, CampaignQueryVariables>(CampaignDocument, options);
      }
export function useCampaignLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampaignQuery, CampaignQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CampaignQuery, CampaignQueryVariables>(CampaignDocument, options);
        }
export type CampaignQueryHookResult = ReturnType<typeof useCampaignQuery>;
export type CampaignLazyQueryHookResult = ReturnType<typeof useCampaignLazyQuery>;
export type CampaignQueryResult = Apollo.QueryResult<CampaignQuery, CampaignQueryVariables>;
export const CampaignByUuidDocument = gql`
    query campaignByUuid($uuid: String!) {
  campaigns(filters: {uuid: {eq: $uuid}}) {
    data {
      ...Campaign
    }
  }
}
    ${CampaignFragmentDoc}
${CampaignMinFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${TenantFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}
${SequenceStepFragmentDoc}
${SequenceStepsInfoFragmentDoc}
${MarketingEmailTemplateMinFragmentDoc}
${CampaignEnrolledContactFragmentDoc}
${ContactMinFragmentDoc}
${EnrollContactConditionFragmentDoc}`;
export function useCampaignByUuidQuery(baseOptions: Apollo.QueryHookOptions<CampaignByUuidQuery, CampaignByUuidQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CampaignByUuidQuery, CampaignByUuidQueryVariables>(CampaignByUuidDocument, options);
      }
export function useCampaignByUuidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampaignByUuidQuery, CampaignByUuidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CampaignByUuidQuery, CampaignByUuidQueryVariables>(CampaignByUuidDocument, options);
        }
export type CampaignByUuidQueryHookResult = ReturnType<typeof useCampaignByUuidQuery>;
export type CampaignByUuidLazyQueryHookResult = ReturnType<typeof useCampaignByUuidLazyQuery>;
export type CampaignByUuidQueryResult = Apollo.QueryResult<CampaignByUuidQuery, CampaignByUuidQueryVariables>;
export const CampaignEnrolledContactsDocument = gql`
    query campaignEnrolledContacts($filters: CampaignEnrolledContactFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  campaignEnrolledContacts(
    filters: $filters
    pagination: $pagination
    sort: $sort
  ) {
    data {
      ...CampaignEnrolledContact
    }
    meta {
      ...Meta
    }
  }
}
    ${CampaignEnrolledContactFragmentDoc}
${CampaignMinFragmentDoc}
${ContactMinFragmentDoc}
${FileFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useCampaignEnrolledContactsQuery(baseOptions?: Apollo.QueryHookOptions<CampaignEnrolledContactsQuery, CampaignEnrolledContactsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CampaignEnrolledContactsQuery, CampaignEnrolledContactsQueryVariables>(CampaignEnrolledContactsDocument, options);
      }
export function useCampaignEnrolledContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampaignEnrolledContactsQuery, CampaignEnrolledContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CampaignEnrolledContactsQuery, CampaignEnrolledContactsQueryVariables>(CampaignEnrolledContactsDocument, options);
        }
export type CampaignEnrolledContactsQueryHookResult = ReturnType<typeof useCampaignEnrolledContactsQuery>;
export type CampaignEnrolledContactsLazyQueryHookResult = ReturnType<typeof useCampaignEnrolledContactsLazyQuery>;
export type CampaignEnrolledContactsQueryResult = Apollo.QueryResult<CampaignEnrolledContactsQuery, CampaignEnrolledContactsQueryVariables>;
export const CampaignEnrolledContactByTokenDocument = gql`
    query campaignEnrolledContactByToken($token: String!) {
  campaignEnrolledContacts(filters: {token: {eq: $token}}) {
    data {
      id
      attributes {
        token
      }
    }
  }
}
    `;
export function useCampaignEnrolledContactByTokenQuery(baseOptions: Apollo.QueryHookOptions<CampaignEnrolledContactByTokenQuery, CampaignEnrolledContactByTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CampaignEnrolledContactByTokenQuery, CampaignEnrolledContactByTokenQueryVariables>(CampaignEnrolledContactByTokenDocument, options);
      }
export function useCampaignEnrolledContactByTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampaignEnrolledContactByTokenQuery, CampaignEnrolledContactByTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CampaignEnrolledContactByTokenQuery, CampaignEnrolledContactByTokenQueryVariables>(CampaignEnrolledContactByTokenDocument, options);
        }
export type CampaignEnrolledContactByTokenQueryHookResult = ReturnType<typeof useCampaignEnrolledContactByTokenQuery>;
export type CampaignEnrolledContactByTokenLazyQueryHookResult = ReturnType<typeof useCampaignEnrolledContactByTokenLazyQuery>;
export type CampaignEnrolledContactByTokenQueryResult = Apollo.QueryResult<CampaignEnrolledContactByTokenQuery, CampaignEnrolledContactByTokenQueryVariables>;
export const CampaignEnrolledLeadByTokenDocument = gql`
    query campaignEnrolledLeadByToken($token: String!) {
  campaignEnrolledLeads(filters: {token: {eq: $token}}) {
    data {
      id
      attributes {
        token
      }
    }
  }
}
    `;
export function useCampaignEnrolledLeadByTokenQuery(baseOptions: Apollo.QueryHookOptions<CampaignEnrolledLeadByTokenQuery, CampaignEnrolledLeadByTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CampaignEnrolledLeadByTokenQuery, CampaignEnrolledLeadByTokenQueryVariables>(CampaignEnrolledLeadByTokenDocument, options);
      }
export function useCampaignEnrolledLeadByTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampaignEnrolledLeadByTokenQuery, CampaignEnrolledLeadByTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CampaignEnrolledLeadByTokenQuery, CampaignEnrolledLeadByTokenQueryVariables>(CampaignEnrolledLeadByTokenDocument, options);
        }
export type CampaignEnrolledLeadByTokenQueryHookResult = ReturnType<typeof useCampaignEnrolledLeadByTokenQuery>;
export type CampaignEnrolledLeadByTokenLazyQueryHookResult = ReturnType<typeof useCampaignEnrolledLeadByTokenLazyQuery>;
export type CampaignEnrolledLeadByTokenQueryResult = Apollo.QueryResult<CampaignEnrolledLeadByTokenQuery, CampaignEnrolledLeadByTokenQueryVariables>;
export const CampaignEnrolledLeadsDocument = gql`
    query campaignEnrolledLeads($filters: CampaignEnrolledLeadFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  campaignEnrolledLeads(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...CampaignEnrolledLead
    }
    meta {
      ...Meta
    }
  }
}
    ${CampaignEnrolledLeadFragmentDoc}
${CampaignMinFragmentDoc}
${LeadMinFragmentDoc}
${FileFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useCampaignEnrolledLeadsQuery(baseOptions?: Apollo.QueryHookOptions<CampaignEnrolledLeadsQuery, CampaignEnrolledLeadsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CampaignEnrolledLeadsQuery, CampaignEnrolledLeadsQueryVariables>(CampaignEnrolledLeadsDocument, options);
      }
export function useCampaignEnrolledLeadsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampaignEnrolledLeadsQuery, CampaignEnrolledLeadsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CampaignEnrolledLeadsQuery, CampaignEnrolledLeadsQueryVariables>(CampaignEnrolledLeadsDocument, options);
        }
export type CampaignEnrolledLeadsQueryHookResult = ReturnType<typeof useCampaignEnrolledLeadsQuery>;
export type CampaignEnrolledLeadsLazyQueryHookResult = ReturnType<typeof useCampaignEnrolledLeadsLazyQuery>;
export type CampaignEnrolledLeadsQueryResult = Apollo.QueryResult<CampaignEnrolledLeadsQuery, CampaignEnrolledLeadsQueryVariables>;
export const CampaignTitleDocument = gql`
    query campaignTitle($uuid: String!) {
  campaigns(filters: {uuid: {eq: $uuid}}) {
    data {
      id
      attributes {
        name
        description
      }
    }
  }
}
    `;
export function useCampaignTitleQuery(baseOptions: Apollo.QueryHookOptions<CampaignTitleQuery, CampaignTitleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CampaignTitleQuery, CampaignTitleQueryVariables>(CampaignTitleDocument, options);
      }
export function useCampaignTitleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampaignTitleQuery, CampaignTitleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CampaignTitleQuery, CampaignTitleQueryVariables>(CampaignTitleDocument, options);
        }
export type CampaignTitleQueryHookResult = ReturnType<typeof useCampaignTitleQuery>;
export type CampaignTitleLazyQueryHookResult = ReturnType<typeof useCampaignTitleLazyQuery>;
export type CampaignTitleQueryResult = Apollo.QueryResult<CampaignTitleQuery, CampaignTitleQueryVariables>;
export const CampaignsDocument = gql`
    query campaigns($filters: CampaignFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  campaigns(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...Campaign
    }
    meta {
      ...Meta
    }
  }
}
    ${CampaignFragmentDoc}
${CampaignMinFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${TenantFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}
${SequenceStepFragmentDoc}
${SequenceStepsInfoFragmentDoc}
${MarketingEmailTemplateMinFragmentDoc}
${CampaignEnrolledContactFragmentDoc}
${ContactMinFragmentDoc}
${EnrollContactConditionFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useCampaignsQuery(baseOptions?: Apollo.QueryHookOptions<CampaignsQuery, CampaignsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CampaignsQuery, CampaignsQueryVariables>(CampaignsDocument, options);
      }
export function useCampaignsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampaignsQuery, CampaignsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CampaignsQuery, CampaignsQueryVariables>(CampaignsDocument, options);
        }
export type CampaignsQueryHookResult = ReturnType<typeof useCampaignsQuery>;
export type CampaignsLazyQueryHookResult = ReturnType<typeof useCampaignsLazyQuery>;
export type CampaignsQueryResult = Apollo.QueryResult<CampaignsQuery, CampaignsQueryVariables>;
export const CampaignsTitleDocument = gql`
    query campaignsTitle($filters: CampaignFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  campaigns(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      id
      attributes {
        name
        description
        uuid
        isActive
      }
    }
    meta {
      ...Meta
    }
  }
}
    ${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useCampaignsTitleQuery(baseOptions?: Apollo.QueryHookOptions<CampaignsTitleQuery, CampaignsTitleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CampaignsTitleQuery, CampaignsTitleQueryVariables>(CampaignsTitleDocument, options);
      }
export function useCampaignsTitleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampaignsTitleQuery, CampaignsTitleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CampaignsTitleQuery, CampaignsTitleQueryVariables>(CampaignsTitleDocument, options);
        }
export type CampaignsTitleQueryHookResult = ReturnType<typeof useCampaignsTitleQuery>;
export type CampaignsTitleLazyQueryHookResult = ReturnType<typeof useCampaignsTitleLazyQuery>;
export type CampaignsTitleQueryResult = Apollo.QueryResult<CampaignsTitleQuery, CampaignsTitleQueryVariables>;
export const EnrollContactConditionsDocument = gql`
    query enrollContactConditions($filters: EnrolledContactConditionFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  enrolledContactConditions(
    filters: $filters
    pagination: $pagination
    sort: $sort
  ) {
    data {
      ...EnrollContactCondition
    }
  }
}
    ${EnrollContactConditionFragmentDoc}
${CampaignMinFragmentDoc}`;
export function useEnrollContactConditionsQuery(baseOptions?: Apollo.QueryHookOptions<EnrollContactConditionsQuery, EnrollContactConditionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EnrollContactConditionsQuery, EnrollContactConditionsQueryVariables>(EnrollContactConditionsDocument, options);
      }
export function useEnrollContactConditionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EnrollContactConditionsQuery, EnrollContactConditionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EnrollContactConditionsQuery, EnrollContactConditionsQueryVariables>(EnrollContactConditionsDocument, options);
        }
export type EnrollContactConditionsQueryHookResult = ReturnType<typeof useEnrollContactConditionsQuery>;
export type EnrollContactConditionsLazyQueryHookResult = ReturnType<typeof useEnrollContactConditionsLazyQuery>;
export type EnrollContactConditionsQueryResult = Apollo.QueryResult<EnrollContactConditionsQuery, EnrollContactConditionsQueryVariables>;
export const EnrollLeadConditionsDocument = gql`
    query enrollLeadConditions($filters: EnrolledLeadConditionFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  enrolledLeadConditions(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...EnrollLeadCondition
    }
  }
}
    ${EnrollLeadConditionFragmentDoc}
${CampaignMinFragmentDoc}`;
export function useEnrollLeadConditionsQuery(baseOptions?: Apollo.QueryHookOptions<EnrollLeadConditionsQuery, EnrollLeadConditionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EnrollLeadConditionsQuery, EnrollLeadConditionsQueryVariables>(EnrollLeadConditionsDocument, options);
      }
export function useEnrollLeadConditionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EnrollLeadConditionsQuery, EnrollLeadConditionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EnrollLeadConditionsQuery, EnrollLeadConditionsQueryVariables>(EnrollLeadConditionsDocument, options);
        }
export type EnrollLeadConditionsQueryHookResult = ReturnType<typeof useEnrollLeadConditionsQuery>;
export type EnrollLeadConditionsLazyQueryHookResult = ReturnType<typeof useEnrollLeadConditionsLazyQuery>;
export type EnrollLeadConditionsQueryResult = Apollo.QueryResult<EnrollLeadConditionsQuery, EnrollLeadConditionsQueryVariables>;
export const SequenceStepDocument = gql`
    query sequenceStep($id: ID!) {
  sequenceStep(id: $id) {
    data {
      ...SequenceStep
    }
  }
}
    ${SequenceStepFragmentDoc}
${SequenceStepsInfoFragmentDoc}
${CampaignMinFragmentDoc}
${MarketingEmailTemplateMinFragmentDoc}`;
export function useSequenceStepQuery(baseOptions: Apollo.QueryHookOptions<SequenceStepQuery, SequenceStepQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SequenceStepQuery, SequenceStepQueryVariables>(SequenceStepDocument, options);
      }
export function useSequenceStepLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SequenceStepQuery, SequenceStepQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SequenceStepQuery, SequenceStepQueryVariables>(SequenceStepDocument, options);
        }
export type SequenceStepQueryHookResult = ReturnType<typeof useSequenceStepQuery>;
export type SequenceStepLazyQueryHookResult = ReturnType<typeof useSequenceStepLazyQuery>;
export type SequenceStepQueryResult = Apollo.QueryResult<SequenceStepQuery, SequenceStepQueryVariables>;
export const SequenceStepsDocument = gql`
    query sequenceSteps($filters: SequenceStepFiltersInput, $pagination: PaginationArg, $sort: [String], $publication: PublicationState = PREVIEW) {
  sequenceSteps(
    filters: $filters
    pagination: $pagination
    sort: $sort
    publicationState: $publication
  ) {
    data {
      ...SequenceStep
    }
  }
}
    ${SequenceStepFragmentDoc}
${SequenceStepsInfoFragmentDoc}
${CampaignMinFragmentDoc}
${MarketingEmailTemplateMinFragmentDoc}`;
export function useSequenceStepsQuery(baseOptions?: Apollo.QueryHookOptions<SequenceStepsQuery, SequenceStepsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SequenceStepsQuery, SequenceStepsQueryVariables>(SequenceStepsDocument, options);
      }
export function useSequenceStepsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SequenceStepsQuery, SequenceStepsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SequenceStepsQuery, SequenceStepsQueryVariables>(SequenceStepsDocument, options);
        }
export type SequenceStepsQueryHookResult = ReturnType<typeof useSequenceStepsQuery>;
export type SequenceStepsLazyQueryHookResult = ReturnType<typeof useSequenceStepsLazyQuery>;
export type SequenceStepsQueryResult = Apollo.QueryResult<SequenceStepsQuery, SequenceStepsQueryVariables>;
export const CreateChartAccountDocument = gql`
    mutation createChartAccount($data: ChartAccountInput!) {
  createChartAccount(data: $data) {
    data {
      ...ChartAccount
    }
  }
}
    ${ChartAccountFragmentDoc}
${ChartAccountMinFragmentDoc}
${ChartCategoryFragmentDoc}
${ChartCategoryMinFragmentDoc}
${ChartSubcategoryMinFragmentDoc}
${TenantFragmentDoc}
${FileFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}`;
export type CreateChartAccountMutationFn = Apollo.MutationFunction<CreateChartAccountMutation, CreateChartAccountMutationVariables>;
export function useCreateChartAccountMutation(baseOptions?: Apollo.MutationHookOptions<CreateChartAccountMutation, CreateChartAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateChartAccountMutation, CreateChartAccountMutationVariables>(CreateChartAccountDocument, options);
      }
export type CreateChartAccountMutationHookResult = ReturnType<typeof useCreateChartAccountMutation>;
export type CreateChartAccountMutationResult = Apollo.MutationResult<CreateChartAccountMutation>;
export const DeleteChartAccountDocument = gql`
    mutation deleteChartAccount($id: ID!) {
  deleteChartAccount(id: $id) {
    data {
      ...ChartAccount
    }
  }
}
    ${ChartAccountFragmentDoc}
${ChartAccountMinFragmentDoc}
${ChartCategoryFragmentDoc}
${ChartCategoryMinFragmentDoc}
${ChartSubcategoryMinFragmentDoc}
${TenantFragmentDoc}
${FileFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}`;
export type DeleteChartAccountMutationFn = Apollo.MutationFunction<DeleteChartAccountMutation, DeleteChartAccountMutationVariables>;
export function useDeleteChartAccountMutation(baseOptions?: Apollo.MutationHookOptions<DeleteChartAccountMutation, DeleteChartAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteChartAccountMutation, DeleteChartAccountMutationVariables>(DeleteChartAccountDocument, options);
      }
export type DeleteChartAccountMutationHookResult = ReturnType<typeof useDeleteChartAccountMutation>;
export type DeleteChartAccountMutationResult = Apollo.MutationResult<DeleteChartAccountMutation>;
export const UpdateChartAccountDocument = gql`
    mutation updateChartAccount($id: ID!, $data: ChartAccountInput!) {
  updateChartAccount(id: $id, data: $data) {
    data {
      ...ChartAccount
    }
  }
}
    ${ChartAccountFragmentDoc}
${ChartAccountMinFragmentDoc}
${ChartCategoryFragmentDoc}
${ChartCategoryMinFragmentDoc}
${ChartSubcategoryMinFragmentDoc}
${TenantFragmentDoc}
${FileFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}`;
export type UpdateChartAccountMutationFn = Apollo.MutationFunction<UpdateChartAccountMutation, UpdateChartAccountMutationVariables>;
export function useUpdateChartAccountMutation(baseOptions?: Apollo.MutationHookOptions<UpdateChartAccountMutation, UpdateChartAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateChartAccountMutation, UpdateChartAccountMutationVariables>(UpdateChartAccountDocument, options);
      }
export type UpdateChartAccountMutationHookResult = ReturnType<typeof useUpdateChartAccountMutation>;
export type UpdateChartAccountMutationResult = Apollo.MutationResult<UpdateChartAccountMutation>;
export const ChartAccountsDocument = gql`
    query chartAccounts($filters: ChartAccountFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  chartAccounts(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...ChartAccount
    }
    meta {
      ...Meta
    }
  }
}
    ${ChartAccountFragmentDoc}
${ChartAccountMinFragmentDoc}
${ChartCategoryFragmentDoc}
${ChartCategoryMinFragmentDoc}
${ChartSubcategoryMinFragmentDoc}
${TenantFragmentDoc}
${FileFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useChartAccountsQuery(baseOptions?: Apollo.QueryHookOptions<ChartAccountsQuery, ChartAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChartAccountsQuery, ChartAccountsQueryVariables>(ChartAccountsDocument, options);
      }
export function useChartAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChartAccountsQuery, ChartAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChartAccountsQuery, ChartAccountsQueryVariables>(ChartAccountsDocument, options);
        }
export type ChartAccountsQueryHookResult = ReturnType<typeof useChartAccountsQuery>;
export type ChartAccountsLazyQueryHookResult = ReturnType<typeof useChartAccountsLazyQuery>;
export type ChartAccountsQueryResult = Apollo.QueryResult<ChartAccountsQuery, ChartAccountsQueryVariables>;
export const ChartTreeSelectDocument = gql`
    query chartTreeSelect($filtersChartAccount: ChartAccountFiltersInput! = {}, $filtersChartCategory: ChartCategoryFiltersInput! = {}, $filtersChartSubcategory: ChartSubcategoryFiltersInput! = {}) {
  chartAccounts(filters: $filtersChartAccount, pagination: {limit: -1}) {
    data {
      id
      attributes {
        name
      }
    }
  }
  chartCategories(filters: $filtersChartCategory, pagination: {limit: -1}) {
    data {
      id
      attributes {
        name
        chartAccount {
          data {
            id
          }
        }
      }
    }
  }
  chartSubcategories(filters: $filtersChartSubcategory, pagination: {limit: -1}) {
    data {
      id
      attributes {
        name
        chartCategory {
          data {
            id
          }
        }
      }
    }
  }
}
    `;
export function useChartTreeSelectQuery(baseOptions?: Apollo.QueryHookOptions<ChartTreeSelectQuery, ChartTreeSelectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChartTreeSelectQuery, ChartTreeSelectQueryVariables>(ChartTreeSelectDocument, options);
      }
export function useChartTreeSelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChartTreeSelectQuery, ChartTreeSelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChartTreeSelectQuery, ChartTreeSelectQueryVariables>(ChartTreeSelectDocument, options);
        }
export type ChartTreeSelectQueryHookResult = ReturnType<typeof useChartTreeSelectQuery>;
export type ChartTreeSelectLazyQueryHookResult = ReturnType<typeof useChartTreeSelectLazyQuery>;
export type ChartTreeSelectQueryResult = Apollo.QueryResult<ChartTreeSelectQuery, ChartTreeSelectQueryVariables>;
export const CheckIsCorrectAccountsDocument = gql`
    query checkIsCorrectAccounts {
  checkIsCorrectAccounts {
    isCorrect
  }
}
    `;
export function useCheckIsCorrectAccountsQuery(baseOptions?: Apollo.QueryHookOptions<CheckIsCorrectAccountsQuery, CheckIsCorrectAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckIsCorrectAccountsQuery, CheckIsCorrectAccountsQueryVariables>(CheckIsCorrectAccountsDocument, options);
      }
export function useCheckIsCorrectAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckIsCorrectAccountsQuery, CheckIsCorrectAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckIsCorrectAccountsQuery, CheckIsCorrectAccountsQueryVariables>(CheckIsCorrectAccountsDocument, options);
        }
export type CheckIsCorrectAccountsQueryHookResult = ReturnType<typeof useCheckIsCorrectAccountsQuery>;
export type CheckIsCorrectAccountsLazyQueryHookResult = ReturnType<typeof useCheckIsCorrectAccountsLazyQuery>;
export type CheckIsCorrectAccountsQueryResult = Apollo.QueryResult<CheckIsCorrectAccountsQuery, CheckIsCorrectAccountsQueryVariables>;
export const CreateChartCategoryDocument = gql`
    mutation createChartCategory($data: ChartCategoryInput!) {
  createChartCategory(data: $data) {
    data {
      ...ChartCategory
    }
  }
}
    ${ChartCategoryFragmentDoc}
${ChartCategoryMinFragmentDoc}
${ChartSubcategoryMinFragmentDoc}
${ChartAccountMinFragmentDoc}
${TenantFragmentDoc}
${FileFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}`;
export type CreateChartCategoryMutationFn = Apollo.MutationFunction<CreateChartCategoryMutation, CreateChartCategoryMutationVariables>;
export function useCreateChartCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateChartCategoryMutation, CreateChartCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateChartCategoryMutation, CreateChartCategoryMutationVariables>(CreateChartCategoryDocument, options);
      }
export type CreateChartCategoryMutationHookResult = ReturnType<typeof useCreateChartCategoryMutation>;
export type CreateChartCategoryMutationResult = Apollo.MutationResult<CreateChartCategoryMutation>;
export const DeleteChartCategoryDocument = gql`
    mutation deleteChartCategory($id: ID!) {
  deleteChartCategory(id: $id) {
    data {
      ...ChartCategory
    }
  }
}
    ${ChartCategoryFragmentDoc}
${ChartCategoryMinFragmentDoc}
${ChartSubcategoryMinFragmentDoc}
${ChartAccountMinFragmentDoc}
${TenantFragmentDoc}
${FileFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}`;
export type DeleteChartCategoryMutationFn = Apollo.MutationFunction<DeleteChartCategoryMutation, DeleteChartCategoryMutationVariables>;
export function useDeleteChartCategoryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteChartCategoryMutation, DeleteChartCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteChartCategoryMutation, DeleteChartCategoryMutationVariables>(DeleteChartCategoryDocument, options);
      }
export type DeleteChartCategoryMutationHookResult = ReturnType<typeof useDeleteChartCategoryMutation>;
export type DeleteChartCategoryMutationResult = Apollo.MutationResult<DeleteChartCategoryMutation>;
export const UpdateChartCategoryDocument = gql`
    mutation updateChartCategory($id: ID!, $data: ChartCategoryInput!) {
  updateChartCategory(id: $id, data: $data) {
    data {
      ...ChartCategory
    }
  }
}
    ${ChartCategoryFragmentDoc}
${ChartCategoryMinFragmentDoc}
${ChartSubcategoryMinFragmentDoc}
${ChartAccountMinFragmentDoc}
${TenantFragmentDoc}
${FileFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}`;
export type UpdateChartCategoryMutationFn = Apollo.MutationFunction<UpdateChartCategoryMutation, UpdateChartCategoryMutationVariables>;
export function useUpdateChartCategoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateChartCategoryMutation, UpdateChartCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateChartCategoryMutation, UpdateChartCategoryMutationVariables>(UpdateChartCategoryDocument, options);
      }
export type UpdateChartCategoryMutationHookResult = ReturnType<typeof useUpdateChartCategoryMutation>;
export type UpdateChartCategoryMutationResult = Apollo.MutationResult<UpdateChartCategoryMutation>;
export const ChartCategoriesDocument = gql`
    query chartCategories($filters: ChartCategoryFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  chartCategories(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...ChartCategory
    }
    meta {
      ...Meta
    }
  }
}
    ${ChartCategoryFragmentDoc}
${ChartCategoryMinFragmentDoc}
${ChartSubcategoryMinFragmentDoc}
${ChartAccountMinFragmentDoc}
${TenantFragmentDoc}
${FileFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useChartCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<ChartCategoriesQuery, ChartCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChartCategoriesQuery, ChartCategoriesQueryVariables>(ChartCategoriesDocument, options);
      }
export function useChartCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChartCategoriesQuery, ChartCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChartCategoriesQuery, ChartCategoriesQueryVariables>(ChartCategoriesDocument, options);
        }
export type ChartCategoriesQueryHookResult = ReturnType<typeof useChartCategoriesQuery>;
export type ChartCategoriesLazyQueryHookResult = ReturnType<typeof useChartCategoriesLazyQuery>;
export type ChartCategoriesQueryResult = Apollo.QueryResult<ChartCategoriesQuery, ChartCategoriesQueryVariables>;
export const CreateChartSubcategoryDocument = gql`
    mutation createChartSubcategory($data: ChartSubcategoryInput!) {
  createChartSubcategory(data: $data) {
    data {
      ...ChartSubcategory
    }
  }
}
    ${ChartSubcategoryFragmentDoc}
${ChartSubcategoryMinFragmentDoc}
${ChartCategoryMinFragmentDoc}
${ChartAccountMinFragmentDoc}
${TenantFragmentDoc}
${FileFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}`;
export type CreateChartSubcategoryMutationFn = Apollo.MutationFunction<CreateChartSubcategoryMutation, CreateChartSubcategoryMutationVariables>;
export function useCreateChartSubcategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateChartSubcategoryMutation, CreateChartSubcategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateChartSubcategoryMutation, CreateChartSubcategoryMutationVariables>(CreateChartSubcategoryDocument, options);
      }
export type CreateChartSubcategoryMutationHookResult = ReturnType<typeof useCreateChartSubcategoryMutation>;
export type CreateChartSubcategoryMutationResult = Apollo.MutationResult<CreateChartSubcategoryMutation>;
export const DeleteChartSubcategoryDocument = gql`
    mutation deleteChartSubcategory($id: ID!) {
  deleteChartSubcategory(id: $id) {
    data {
      ...ChartSubcategory
    }
  }
}
    ${ChartSubcategoryFragmentDoc}
${ChartSubcategoryMinFragmentDoc}
${ChartCategoryMinFragmentDoc}
${ChartAccountMinFragmentDoc}
${TenantFragmentDoc}
${FileFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}`;
export type DeleteChartSubcategoryMutationFn = Apollo.MutationFunction<DeleteChartSubcategoryMutation, DeleteChartSubcategoryMutationVariables>;
export function useDeleteChartSubcategoryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteChartSubcategoryMutation, DeleteChartSubcategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteChartSubcategoryMutation, DeleteChartSubcategoryMutationVariables>(DeleteChartSubcategoryDocument, options);
      }
export type DeleteChartSubcategoryMutationHookResult = ReturnType<typeof useDeleteChartSubcategoryMutation>;
export type DeleteChartSubcategoryMutationResult = Apollo.MutationResult<DeleteChartSubcategoryMutation>;
export const UpdateChartSubcategoryDocument = gql`
    mutation updateChartSubcategory($id: ID!, $data: ChartSubcategoryInput!) {
  updateChartSubcategory(id: $id, data: $data) {
    data {
      ...ChartSubcategory
    }
  }
}
    ${ChartSubcategoryFragmentDoc}
${ChartSubcategoryMinFragmentDoc}
${ChartCategoryMinFragmentDoc}
${ChartAccountMinFragmentDoc}
${TenantFragmentDoc}
${FileFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}`;
export type UpdateChartSubcategoryMutationFn = Apollo.MutationFunction<UpdateChartSubcategoryMutation, UpdateChartSubcategoryMutationVariables>;
export function useUpdateChartSubcategoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateChartSubcategoryMutation, UpdateChartSubcategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateChartSubcategoryMutation, UpdateChartSubcategoryMutationVariables>(UpdateChartSubcategoryDocument, options);
      }
export type UpdateChartSubcategoryMutationHookResult = ReturnType<typeof useUpdateChartSubcategoryMutation>;
export type UpdateChartSubcategoryMutationResult = Apollo.MutationResult<UpdateChartSubcategoryMutation>;
export const ChartSubcategoriesDocument = gql`
    query chartSubcategories($filters: ChartSubcategoryFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  chartSubcategories(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...ChartSubcategory
    }
    meta {
      ...Meta
    }
  }
}
    ${ChartSubcategoryFragmentDoc}
${ChartSubcategoryMinFragmentDoc}
${ChartCategoryMinFragmentDoc}
${ChartAccountMinFragmentDoc}
${TenantFragmentDoc}
${FileFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useChartSubcategoriesQuery(baseOptions?: Apollo.QueryHookOptions<ChartSubcategoriesQuery, ChartSubcategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChartSubcategoriesQuery, ChartSubcategoriesQueryVariables>(ChartSubcategoriesDocument, options);
      }
export function useChartSubcategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChartSubcategoriesQuery, ChartSubcategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChartSubcategoriesQuery, ChartSubcategoriesQueryVariables>(ChartSubcategoriesDocument, options);
        }
export type ChartSubcategoriesQueryHookResult = ReturnType<typeof useChartSubcategoriesQuery>;
export type ChartSubcategoriesLazyQueryHookResult = ReturnType<typeof useChartSubcategoriesLazyQuery>;
export type ChartSubcategoriesQueryResult = Apollo.QueryResult<ChartSubcategoriesQuery, ChartSubcategoriesQueryVariables>;
export const DeleteChatNotificationDocument = gql`
    mutation deleteChatNotification($id: ID!) {
  deleteChatNotification(id: $id) {
    data {
      ...ChatNotificationMin
    }
  }
}
    ${ChatNotificationMinFragmentDoc}`;
export type DeleteChatNotificationMutationFn = Apollo.MutationFunction<DeleteChatNotificationMutation, DeleteChatNotificationMutationVariables>;
export function useDeleteChatNotificationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteChatNotificationMutation, DeleteChatNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteChatNotificationMutation, DeleteChatNotificationMutationVariables>(DeleteChatNotificationDocument, options);
      }
export type DeleteChatNotificationMutationHookResult = ReturnType<typeof useDeleteChatNotificationMutation>;
export type DeleteChatNotificationMutationResult = Apollo.MutationResult<DeleteChatNotificationMutation>;
export const UpdateChatNotificationDocument = gql`
    mutation updateChatNotification($id: ID!, $input: ChatNotificationInput!) {
  updateChatNotification(id: $id, data: $input) {
    data {
      ...ChatNotificationMin
    }
  }
}
    ${ChatNotificationMinFragmentDoc}`;
export type UpdateChatNotificationMutationFn = Apollo.MutationFunction<UpdateChatNotificationMutation, UpdateChatNotificationMutationVariables>;
export function useUpdateChatNotificationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateChatNotificationMutation, UpdateChatNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateChatNotificationMutation, UpdateChatNotificationMutationVariables>(UpdateChatNotificationDocument, options);
      }
export type UpdateChatNotificationMutationHookResult = ReturnType<typeof useUpdateChatNotificationMutation>;
export type UpdateChatNotificationMutationResult = Apollo.MutationResult<UpdateChatNotificationMutation>;
export const ChatNotificationsDocument = gql`
    query chatNotifications($filters: ChatNotificationFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  chatNotifications(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...ChatNotificationMin
    }
    meta {
      ...Meta
    }
  }
}
    ${ChatNotificationMinFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useChatNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<ChatNotificationsQuery, ChatNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChatNotificationsQuery, ChatNotificationsQueryVariables>(ChatNotificationsDocument, options);
      }
export function useChatNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChatNotificationsQuery, ChatNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChatNotificationsQuery, ChatNotificationsQueryVariables>(ChatNotificationsDocument, options);
        }
export type ChatNotificationsQueryHookResult = ReturnType<typeof useChatNotificationsQuery>;
export type ChatNotificationsLazyQueryHookResult = ReturnType<typeof useChatNotificationsLazyQuery>;
export type ChatNotificationsQueryResult = Apollo.QueryResult<ChatNotificationsQuery, ChatNotificationsQueryVariables>;
export const CreateClassDocument = gql`
    mutation createClass($input: ClassInput!) {
  createClass(data: $input) {
    data {
      ...Class
    }
  }
}
    ${ClassFragmentDoc}
${ClassMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${FileFragmentDoc}
${ClassLocationInfoFragmentDoc}
${ClassLocationInfoMinFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}
${ClassPerformerFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}`;
export type CreateClassMutationFn = Apollo.MutationFunction<CreateClassMutation, CreateClassMutationVariables>;
export function useCreateClassMutation(baseOptions?: Apollo.MutationHookOptions<CreateClassMutation, CreateClassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateClassMutation, CreateClassMutationVariables>(CreateClassDocument, options);
      }
export type CreateClassMutationHookResult = ReturnType<typeof useCreateClassMutation>;
export type CreateClassMutationResult = Apollo.MutationResult<CreateClassMutation>;
export const CreateClassLocationInfoDocument = gql`
    mutation createClassLocationInfo($input: ClassLocationInfoInput!) {
  createClassLocationInfo(data: $input) {
    data {
      ...ClassLocationInfo
    }
  }
}
    ${ClassLocationInfoFragmentDoc}
${ClassLocationInfoMinFragmentDoc}
${ClassMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${FileFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}
${ClassPerformerFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}`;
export type CreateClassLocationInfoMutationFn = Apollo.MutationFunction<CreateClassLocationInfoMutation, CreateClassLocationInfoMutationVariables>;
export function useCreateClassLocationInfoMutation(baseOptions?: Apollo.MutationHookOptions<CreateClassLocationInfoMutation, CreateClassLocationInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateClassLocationInfoMutation, CreateClassLocationInfoMutationVariables>(CreateClassLocationInfoDocument, options);
      }
export type CreateClassLocationInfoMutationHookResult = ReturnType<typeof useCreateClassLocationInfoMutation>;
export type CreateClassLocationInfoMutationResult = Apollo.MutationResult<CreateClassLocationInfoMutation>;
export const CreateClassPerformerDocument = gql`
    mutation createClassPerformer($input: ClassPerformerInput!) {
  createClassPerformer(data: $input) {
    data {
      ...ClassPerformer
    }
  }
}
    ${ClassPerformerFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ClassLocationInfoMinFragmentDoc}
${ClassMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}`;
export type CreateClassPerformerMutationFn = Apollo.MutationFunction<CreateClassPerformerMutation, CreateClassPerformerMutationVariables>;
export function useCreateClassPerformerMutation(baseOptions?: Apollo.MutationHookOptions<CreateClassPerformerMutation, CreateClassPerformerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateClassPerformerMutation, CreateClassPerformerMutationVariables>(CreateClassPerformerDocument, options);
      }
export type CreateClassPerformerMutationHookResult = ReturnType<typeof useCreateClassPerformerMutation>;
export type CreateClassPerformerMutationResult = Apollo.MutationResult<CreateClassPerformerMutation>;
export const DeleteClassDocument = gql`
    mutation deleteClass($id: ID!) {
  deleteClass(id: $id) {
    data {
      ...Class
    }
  }
}
    ${ClassFragmentDoc}
${ClassMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${FileFragmentDoc}
${ClassLocationInfoFragmentDoc}
${ClassLocationInfoMinFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}
${ClassPerformerFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}`;
export type DeleteClassMutationFn = Apollo.MutationFunction<DeleteClassMutation, DeleteClassMutationVariables>;
export function useDeleteClassMutation(baseOptions?: Apollo.MutationHookOptions<DeleteClassMutation, DeleteClassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteClassMutation, DeleteClassMutationVariables>(DeleteClassDocument, options);
      }
export type DeleteClassMutationHookResult = ReturnType<typeof useDeleteClassMutation>;
export type DeleteClassMutationResult = Apollo.MutationResult<DeleteClassMutation>;
export const DeleteClassPerformerDocument = gql`
    mutation deleteClassPerformer($id: ID!) {
  deleteClassPerformer(id: $id) {
    data {
      ...ClassPerformer
    }
  }
}
    ${ClassPerformerFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ClassLocationInfoMinFragmentDoc}
${ClassMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}`;
export type DeleteClassPerformerMutationFn = Apollo.MutationFunction<DeleteClassPerformerMutation, DeleteClassPerformerMutationVariables>;
export function useDeleteClassPerformerMutation(baseOptions?: Apollo.MutationHookOptions<DeleteClassPerformerMutation, DeleteClassPerformerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteClassPerformerMutation, DeleteClassPerformerMutationVariables>(DeleteClassPerformerDocument, options);
      }
export type DeleteClassPerformerMutationHookResult = ReturnType<typeof useDeleteClassPerformerMutation>;
export type DeleteClassPerformerMutationResult = Apollo.MutationResult<DeleteClassPerformerMutation>;
export const UpdateClassDocument = gql`
    mutation updateClass($id: ID!, $input: ClassInput!) {
  updateClass(id: $id, data: $input) {
    data {
      ...Class
    }
  }
}
    ${ClassFragmentDoc}
${ClassMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${FileFragmentDoc}
${ClassLocationInfoFragmentDoc}
${ClassLocationInfoMinFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}
${ClassPerformerFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}`;
export type UpdateClassMutationFn = Apollo.MutationFunction<UpdateClassMutation, UpdateClassMutationVariables>;
export function useUpdateClassMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClassMutation, UpdateClassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClassMutation, UpdateClassMutationVariables>(UpdateClassDocument, options);
      }
export type UpdateClassMutationHookResult = ReturnType<typeof useUpdateClassMutation>;
export type UpdateClassMutationResult = Apollo.MutationResult<UpdateClassMutation>;
export const UpdateClassLocationInfoDocument = gql`
    mutation updateClassLocationInfo($id: ID!, $input: ClassLocationInfoInput!) {
  updateClassLocationInfo(id: $id, data: $input) {
    data {
      ...ClassLocationInfoMin
    }
  }
}
    ${ClassLocationInfoMinFragmentDoc}
${ClassMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${FileFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}`;
export type UpdateClassLocationInfoMutationFn = Apollo.MutationFunction<UpdateClassLocationInfoMutation, UpdateClassLocationInfoMutationVariables>;
export function useUpdateClassLocationInfoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClassLocationInfoMutation, UpdateClassLocationInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClassLocationInfoMutation, UpdateClassLocationInfoMutationVariables>(UpdateClassLocationInfoDocument, options);
      }
export type UpdateClassLocationInfoMutationHookResult = ReturnType<typeof useUpdateClassLocationInfoMutation>;
export type UpdateClassLocationInfoMutationResult = Apollo.MutationResult<UpdateClassLocationInfoMutation>;
export const UpdateClassPerformerDocument = gql`
    mutation updateClassPerformer($id: ID!, $input: ClassPerformerInput!) {
  updateClassPerformer(id: $id, data: $input) {
    data {
      ...ClassPerformer
    }
  }
}
    ${ClassPerformerFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ClassLocationInfoMinFragmentDoc}
${ClassMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}`;
export type UpdateClassPerformerMutationFn = Apollo.MutationFunction<UpdateClassPerformerMutation, UpdateClassPerformerMutationVariables>;
export function useUpdateClassPerformerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClassPerformerMutation, UpdateClassPerformerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClassPerformerMutation, UpdateClassPerformerMutationVariables>(UpdateClassPerformerDocument, options);
      }
export type UpdateClassPerformerMutationHookResult = ReturnType<typeof useUpdateClassPerformerMutation>;
export type UpdateClassPerformerMutationResult = Apollo.MutationResult<UpdateClassPerformerMutation>;
export const ClassByUuidDocument = gql`
    query classByUuid($uuid: String!) {
  classes(filters: {uuid: {eq: $uuid}}) {
    data {
      ...Class
    }
  }
}
    ${ClassFragmentDoc}
${ClassMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${FileFragmentDoc}
${ClassLocationInfoFragmentDoc}
${ClassLocationInfoMinFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}
${ClassPerformerFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}`;
export function useClassByUuidQuery(baseOptions: Apollo.QueryHookOptions<ClassByUuidQuery, ClassByUuidQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClassByUuidQuery, ClassByUuidQueryVariables>(ClassByUuidDocument, options);
      }
export function useClassByUuidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClassByUuidQuery, ClassByUuidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClassByUuidQuery, ClassByUuidQueryVariables>(ClassByUuidDocument, options);
        }
export type ClassByUuidQueryHookResult = ReturnType<typeof useClassByUuidQuery>;
export type ClassByUuidLazyQueryHookResult = ReturnType<typeof useClassByUuidLazyQuery>;
export type ClassByUuidQueryResult = Apollo.QueryResult<ClassByUuidQuery, ClassByUuidQueryVariables>;
export const ClassPerformersDocument = gql`
    query classPerformers($filters: ClassPerformerFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  classPerformers(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...ClassPerformer
    }
    meta {
      ...Meta
    }
  }
}
    ${ClassPerformerFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ClassLocationInfoMinFragmentDoc}
${ClassMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useClassPerformersQuery(baseOptions?: Apollo.QueryHookOptions<ClassPerformersQuery, ClassPerformersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClassPerformersQuery, ClassPerformersQueryVariables>(ClassPerformersDocument, options);
      }
export function useClassPerformersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClassPerformersQuery, ClassPerformersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClassPerformersQuery, ClassPerformersQueryVariables>(ClassPerformersDocument, options);
        }
export type ClassPerformersQueryHookResult = ReturnType<typeof useClassPerformersQuery>;
export type ClassPerformersLazyQueryHookResult = ReturnType<typeof useClassPerformersLazyQuery>;
export type ClassPerformersQueryResult = Apollo.QueryResult<ClassPerformersQuery, ClassPerformersQueryVariables>;
export const ClassesDocument = gql`
    query classes($filters: ClassFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  classes(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...Class
    }
    meta {
      ...Meta
    }
  }
}
    ${ClassFragmentDoc}
${ClassMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${FileFragmentDoc}
${ClassLocationInfoFragmentDoc}
${ClassLocationInfoMinFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}
${ClassPerformerFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useClassesQuery(baseOptions?: Apollo.QueryHookOptions<ClassesQuery, ClassesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClassesQuery, ClassesQueryVariables>(ClassesDocument, options);
      }
export function useClassesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClassesQuery, ClassesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClassesQuery, ClassesQueryVariables>(ClassesDocument, options);
        }
export type ClassesQueryHookResult = ReturnType<typeof useClassesQuery>;
export type ClassesLazyQueryHookResult = ReturnType<typeof useClassesLazyQuery>;
export type ClassesQueryResult = Apollo.QueryResult<ClassesQuery, ClassesQueryVariables>;
export const ClassesWithSoldRevenueDocument = gql`
    query classesWithSoldRevenue($filters: ClassFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = [], $startDate: DateTime!, $endDate: DateTime!, $businessLocationId: Int) {
  classes(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...ClassWithSoldRevenue
    }
    meta {
      ...Meta
    }
  }
}
    ${ClassWithSoldRevenueFragmentDoc}
${ClassFragmentDoc}
${ClassMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${FileFragmentDoc}
${ClassLocationInfoFragmentDoc}
${ClassLocationInfoMinFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}
${ClassPerformerFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useClassesWithSoldRevenueQuery(baseOptions: Apollo.QueryHookOptions<ClassesWithSoldRevenueQuery, ClassesWithSoldRevenueQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClassesWithSoldRevenueQuery, ClassesWithSoldRevenueQueryVariables>(ClassesWithSoldRevenueDocument, options);
      }
export function useClassesWithSoldRevenueLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClassesWithSoldRevenueQuery, ClassesWithSoldRevenueQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClassesWithSoldRevenueQuery, ClassesWithSoldRevenueQueryVariables>(ClassesWithSoldRevenueDocument, options);
        }
export type ClassesWithSoldRevenueQueryHookResult = ReturnType<typeof useClassesWithSoldRevenueQuery>;
export type ClassesWithSoldRevenueLazyQueryHookResult = ReturnType<typeof useClassesWithSoldRevenueLazyQuery>;
export type ClassesWithSoldRevenueQueryResult = Apollo.QueryResult<ClassesWithSoldRevenueQuery, ClassesWithSoldRevenueQueryVariables>;
export const CreateCompanyDocument = gql`
    mutation createCompany($input: CompanyInput!) {
  createCompany(data: $input) {
    data {
      ...Company
    }
  }
}
    ${CompanyFragmentDoc}
${CompanyMinFragmentDoc}
${FileFragmentDoc}
${ContactMinFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${BusinessLocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}`;
export type CreateCompanyMutationFn = Apollo.MutationFunction<CreateCompanyMutation, CreateCompanyMutationVariables>;
export function useCreateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<CreateCompanyMutation, CreateCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCompanyMutation, CreateCompanyMutationVariables>(CreateCompanyDocument, options);
      }
export type CreateCompanyMutationHookResult = ReturnType<typeof useCreateCompanyMutation>;
export type CreateCompanyMutationResult = Apollo.MutationResult<CreateCompanyMutation>;
export const DeleteCompanyDocument = gql`
    mutation deleteCompany($id: ID!) {
  deleteCompany(id: $id) {
    data {
      ...Company
    }
  }
}
    ${CompanyFragmentDoc}
${CompanyMinFragmentDoc}
${FileFragmentDoc}
${ContactMinFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${BusinessLocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}`;
export type DeleteCompanyMutationFn = Apollo.MutationFunction<DeleteCompanyMutation, DeleteCompanyMutationVariables>;
export function useDeleteCompanyMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCompanyMutation, DeleteCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCompanyMutation, DeleteCompanyMutationVariables>(DeleteCompanyDocument, options);
      }
export type DeleteCompanyMutationHookResult = ReturnType<typeof useDeleteCompanyMutation>;
export type DeleteCompanyMutationResult = Apollo.MutationResult<DeleteCompanyMutation>;
export const UpdateCompanyDocument = gql`
    mutation updateCompany($id: ID!, $input: CompanyInput!) {
  updateCompany(id: $id, data: $input) {
    data {
      ...Company
    }
  }
}
    ${CompanyFragmentDoc}
${CompanyMinFragmentDoc}
${FileFragmentDoc}
${ContactMinFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${BusinessLocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}`;
export type UpdateCompanyMutationFn = Apollo.MutationFunction<UpdateCompanyMutation, UpdateCompanyMutationVariables>;
export function useUpdateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompanyMutation, UpdateCompanyMutationVariables>(UpdateCompanyDocument, options);
      }
export type UpdateCompanyMutationHookResult = ReturnType<typeof useUpdateCompanyMutation>;
export type UpdateCompanyMutationResult = Apollo.MutationResult<UpdateCompanyMutation>;
export const CompaniesDocument = gql`
    query companies($filters: CompanyFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  companies(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...Company
    }
    meta {
      ...Meta
    }
  }
}
    ${CompanyFragmentDoc}
${CompanyMinFragmentDoc}
${FileFragmentDoc}
${ContactMinFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${BusinessLocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useCompaniesQuery(baseOptions?: Apollo.QueryHookOptions<CompaniesQuery, CompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompaniesQuery, CompaniesQueryVariables>(CompaniesDocument, options);
      }
export function useCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompaniesQuery, CompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompaniesQuery, CompaniesQueryVariables>(CompaniesDocument, options);
        }
export type CompaniesQueryHookResult = ReturnType<typeof useCompaniesQuery>;
export type CompaniesLazyQueryHookResult = ReturnType<typeof useCompaniesLazyQuery>;
export type CompaniesQueryResult = Apollo.QueryResult<CompaniesQuery, CompaniesQueryVariables>;
export const CompanyByUuidDocument = gql`
    query companyByUuid($uuid: String!) {
  companies(filters: {uuid: {eq: $uuid}}) {
    data {
      ...Company
    }
  }
}
    ${CompanyFragmentDoc}
${CompanyMinFragmentDoc}
${FileFragmentDoc}
${ContactMinFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${BusinessLocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}`;
export function useCompanyByUuidQuery(baseOptions: Apollo.QueryHookOptions<CompanyByUuidQuery, CompanyByUuidQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyByUuidQuery, CompanyByUuidQueryVariables>(CompanyByUuidDocument, options);
      }
export function useCompanyByUuidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyByUuidQuery, CompanyByUuidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyByUuidQuery, CompanyByUuidQueryVariables>(CompanyByUuidDocument, options);
        }
export type CompanyByUuidQueryHookResult = ReturnType<typeof useCompanyByUuidQuery>;
export type CompanyByUuidLazyQueryHookResult = ReturnType<typeof useCompanyByUuidLazyQuery>;
export type CompanyByUuidQueryResult = Apollo.QueryResult<CompanyByUuidQuery, CompanyByUuidQueryVariables>;
export const CompanyMinDocument = gql`
    query companyMin($filters: CompanyFiltersInput) {
  companies(filters: $filters) {
    data {
      ...CompanyMin
    }
  }
}
    ${CompanyMinFragmentDoc}
${FileFragmentDoc}`;
export function useCompanyMinQuery(baseOptions?: Apollo.QueryHookOptions<CompanyMinQuery, CompanyMinQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyMinQuery, CompanyMinQueryVariables>(CompanyMinDocument, options);
      }
export function useCompanyMinLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyMinQuery, CompanyMinQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyMinQuery, CompanyMinQueryVariables>(CompanyMinDocument, options);
        }
export type CompanyMinQueryHookResult = ReturnType<typeof useCompanyMinQuery>;
export type CompanyMinLazyQueryHookResult = ReturnType<typeof useCompanyMinLazyQuery>;
export type CompanyMinQueryResult = Apollo.QueryResult<CompanyMinQuery, CompanyMinQueryVariables>;
export const CreateCompositeProductDocument = gql`
    mutation createCompositeProduct($input: CompositeProductInput!) {
  createCompositeProduct(data: $input) {
    data {
      ...CompositeProduct
    }
  }
}
    ${CompositeProductFragmentDoc}
${CompositeProductMinFragmentDoc}
${ProductFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${SerializeFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${CompositeProductLocationInfoMinFragmentDoc}
${CompositeProductItemInfoFragmentDoc}
${CompositeProductItemInfoMinFragmentDoc}`;
export type CreateCompositeProductMutationFn = Apollo.MutationFunction<CreateCompositeProductMutation, CreateCompositeProductMutationVariables>;
export function useCreateCompositeProductMutation(baseOptions?: Apollo.MutationHookOptions<CreateCompositeProductMutation, CreateCompositeProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCompositeProductMutation, CreateCompositeProductMutationVariables>(CreateCompositeProductDocument, options);
      }
export type CreateCompositeProductMutationHookResult = ReturnType<typeof useCreateCompositeProductMutation>;
export type CreateCompositeProductMutationResult = Apollo.MutationResult<CreateCompositeProductMutation>;
export const CreateCompositeProductItemInfoDocument = gql`
    mutation createCompositeProductItemInfo($input: CompositeProductItemInfoInput!) {
  createCompositeProductItemInfo(data: $input) {
    data {
      ...CompositeProductItemInfo
    }
  }
}
    ${CompositeProductItemInfoFragmentDoc}
${CompositeProductItemInfoMinFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}`;
export type CreateCompositeProductItemInfoMutationFn = Apollo.MutationFunction<CreateCompositeProductItemInfoMutation, CreateCompositeProductItemInfoMutationVariables>;
export function useCreateCompositeProductItemInfoMutation(baseOptions?: Apollo.MutationHookOptions<CreateCompositeProductItemInfoMutation, CreateCompositeProductItemInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCompositeProductItemInfoMutation, CreateCompositeProductItemInfoMutationVariables>(CreateCompositeProductItemInfoDocument, options);
      }
export type CreateCompositeProductItemInfoMutationHookResult = ReturnType<typeof useCreateCompositeProductItemInfoMutation>;
export type CreateCompositeProductItemInfoMutationResult = Apollo.MutationResult<CreateCompositeProductItemInfoMutation>;
export const CreateCompositeProductLocationInfoDocument = gql`
    mutation createCompositeProductLocationInfo($input: CompositeProductLocationInfoInput!) {
  createCompositeProductLocationInfo(data: $input) {
    data {
      ...CompositeProductLocationInfo
    }
  }
}
    ${CompositeProductLocationInfoFragmentDoc}
${CompositeProductLocationInfoMinFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${CompositeProductFragmentDoc}
${CompositeProductMinFragmentDoc}
${ProductFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${SerializeFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${CompositeProductItemInfoFragmentDoc}
${CompositeProductItemInfoMinFragmentDoc}`;
export type CreateCompositeProductLocationInfoMutationFn = Apollo.MutationFunction<CreateCompositeProductLocationInfoMutation, CreateCompositeProductLocationInfoMutationVariables>;
export function useCreateCompositeProductLocationInfoMutation(baseOptions?: Apollo.MutationHookOptions<CreateCompositeProductLocationInfoMutation, CreateCompositeProductLocationInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCompositeProductLocationInfoMutation, CreateCompositeProductLocationInfoMutationVariables>(CreateCompositeProductLocationInfoDocument, options);
      }
export type CreateCompositeProductLocationInfoMutationHookResult = ReturnType<typeof useCreateCompositeProductLocationInfoMutation>;
export type CreateCompositeProductLocationInfoMutationResult = Apollo.MutationResult<CreateCompositeProductLocationInfoMutation>;
export const DeleteCompositeProductItemInfoDocument = gql`
    mutation deleteCompositeProductItemInfo($id: ID!) {
  deleteCompositeProductItemInfo(id: $id) {
    data {
      ...CompositeProductItemInfo
    }
  }
}
    ${CompositeProductItemInfoFragmentDoc}
${CompositeProductItemInfoMinFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}`;
export type DeleteCompositeProductItemInfoMutationFn = Apollo.MutationFunction<DeleteCompositeProductItemInfoMutation, DeleteCompositeProductItemInfoMutationVariables>;
export function useDeleteCompositeProductItemInfoMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCompositeProductItemInfoMutation, DeleteCompositeProductItemInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCompositeProductItemInfoMutation, DeleteCompositeProductItemInfoMutationVariables>(DeleteCompositeProductItemInfoDocument, options);
      }
export type DeleteCompositeProductItemInfoMutationHookResult = ReturnType<typeof useDeleteCompositeProductItemInfoMutation>;
export type DeleteCompositeProductItemInfoMutationResult = Apollo.MutationResult<DeleteCompositeProductItemInfoMutation>;
export const DeleteCompositeProductLocationInfoDocument = gql`
    mutation deleteCompositeProductLocationInfo($id: ID!) {
  deleteCompositeProductLocationInfo(id: $id) {
    data {
      ...CompositeProductLocationInfoMin
    }
  }
}
    ${CompositeProductLocationInfoMinFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}`;
export type DeleteCompositeProductLocationInfoMutationFn = Apollo.MutationFunction<DeleteCompositeProductLocationInfoMutation, DeleteCompositeProductLocationInfoMutationVariables>;
export function useDeleteCompositeProductLocationInfoMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCompositeProductLocationInfoMutation, DeleteCompositeProductLocationInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCompositeProductLocationInfoMutation, DeleteCompositeProductLocationInfoMutationVariables>(DeleteCompositeProductLocationInfoDocument, options);
      }
export type DeleteCompositeProductLocationInfoMutationHookResult = ReturnType<typeof useDeleteCompositeProductLocationInfoMutation>;
export type DeleteCompositeProductLocationInfoMutationResult = Apollo.MutationResult<DeleteCompositeProductLocationInfoMutation>;
export const UpdateCompositeProductDocument = gql`
    mutation updateCompositeProduct($id: ID!, $input: CompositeProductInput!) {
  updateCompositeProduct(id: $id, data: $input) {
    data {
      ...CompositeProduct
    }
  }
}
    ${CompositeProductFragmentDoc}
${CompositeProductMinFragmentDoc}
${ProductFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${SerializeFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${CompositeProductLocationInfoMinFragmentDoc}
${CompositeProductItemInfoFragmentDoc}
${CompositeProductItemInfoMinFragmentDoc}`;
export type UpdateCompositeProductMutationFn = Apollo.MutationFunction<UpdateCompositeProductMutation, UpdateCompositeProductMutationVariables>;
export function useUpdateCompositeProductMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompositeProductMutation, UpdateCompositeProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompositeProductMutation, UpdateCompositeProductMutationVariables>(UpdateCompositeProductDocument, options);
      }
export type UpdateCompositeProductMutationHookResult = ReturnType<typeof useUpdateCompositeProductMutation>;
export type UpdateCompositeProductMutationResult = Apollo.MutationResult<UpdateCompositeProductMutation>;
export const UpdateCompositeProductItemInfoDocument = gql`
    mutation updateCompositeProductItemInfo($id: ID!, $input: CompositeProductItemInfoInput!) {
  updateCompositeProductItemInfo(id: $id, data: $input) {
    data {
      ...CompositeProductItemInfo
    }
  }
}
    ${CompositeProductItemInfoFragmentDoc}
${CompositeProductItemInfoMinFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}`;
export type UpdateCompositeProductItemInfoMutationFn = Apollo.MutationFunction<UpdateCompositeProductItemInfoMutation, UpdateCompositeProductItemInfoMutationVariables>;
export function useUpdateCompositeProductItemInfoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompositeProductItemInfoMutation, UpdateCompositeProductItemInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompositeProductItemInfoMutation, UpdateCompositeProductItemInfoMutationVariables>(UpdateCompositeProductItemInfoDocument, options);
      }
export type UpdateCompositeProductItemInfoMutationHookResult = ReturnType<typeof useUpdateCompositeProductItemInfoMutation>;
export type UpdateCompositeProductItemInfoMutationResult = Apollo.MutationResult<UpdateCompositeProductItemInfoMutation>;
export const UpdateCompositeProductLocationInfoDocument = gql`
    mutation updateCompositeProductLocationInfo($id: ID!, $input: CompositeProductLocationInfoInput!) {
  updateCompositeProductLocationInfo(id: $id, data: $input) {
    data {
      ...CompositeProductLocationInfoMin
    }
  }
}
    ${CompositeProductLocationInfoMinFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}`;
export type UpdateCompositeProductLocationInfoMutationFn = Apollo.MutationFunction<UpdateCompositeProductLocationInfoMutation, UpdateCompositeProductLocationInfoMutationVariables>;
export function useUpdateCompositeProductLocationInfoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompositeProductLocationInfoMutation, UpdateCompositeProductLocationInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompositeProductLocationInfoMutation, UpdateCompositeProductLocationInfoMutationVariables>(UpdateCompositeProductLocationInfoDocument, options);
      }
export type UpdateCompositeProductLocationInfoMutationHookResult = ReturnType<typeof useUpdateCompositeProductLocationInfoMutation>;
export type UpdateCompositeProductLocationInfoMutationResult = Apollo.MutationResult<UpdateCompositeProductLocationInfoMutation>;
export const CompositeProductByUuidDocument = gql`
    query compositeProductByUuid($uuid: String!) {
  compositeProducts(filters: {uuid: {eq: $uuid}}) {
    data {
      ...CompositeProduct
    }
  }
}
    ${CompositeProductFragmentDoc}
${CompositeProductMinFragmentDoc}
${ProductFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${SerializeFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${CompositeProductLocationInfoMinFragmentDoc}
${CompositeProductItemInfoFragmentDoc}
${CompositeProductItemInfoMinFragmentDoc}`;
export function useCompositeProductByUuidQuery(baseOptions: Apollo.QueryHookOptions<CompositeProductByUuidQuery, CompositeProductByUuidQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompositeProductByUuidQuery, CompositeProductByUuidQueryVariables>(CompositeProductByUuidDocument, options);
      }
export function useCompositeProductByUuidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompositeProductByUuidQuery, CompositeProductByUuidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompositeProductByUuidQuery, CompositeProductByUuidQueryVariables>(CompositeProductByUuidDocument, options);
        }
export type CompositeProductByUuidQueryHookResult = ReturnType<typeof useCompositeProductByUuidQuery>;
export type CompositeProductByUuidLazyQueryHookResult = ReturnType<typeof useCompositeProductByUuidLazyQuery>;
export type CompositeProductByUuidQueryResult = Apollo.QueryResult<CompositeProductByUuidQuery, CompositeProductByUuidQueryVariables>;
export const CompositeProductItemInfoDocument = gql`
    query compositeProductItemInfo($id: ID!) {
  compositeProductItemInfo(id: $id) {
    data {
      ...CompositeProductItemInfo
    }
  }
}
    ${CompositeProductItemInfoFragmentDoc}
${CompositeProductItemInfoMinFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}`;
export function useCompositeProductItemInfoQuery(baseOptions: Apollo.QueryHookOptions<CompositeProductItemInfoQuery, CompositeProductItemInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompositeProductItemInfoQuery, CompositeProductItemInfoQueryVariables>(CompositeProductItemInfoDocument, options);
      }
export function useCompositeProductItemInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompositeProductItemInfoQuery, CompositeProductItemInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompositeProductItemInfoQuery, CompositeProductItemInfoQueryVariables>(CompositeProductItemInfoDocument, options);
        }
export type CompositeProductItemInfoQueryHookResult = ReturnType<typeof useCompositeProductItemInfoQuery>;
export type CompositeProductItemInfoLazyQueryHookResult = ReturnType<typeof useCompositeProductItemInfoLazyQuery>;
export type CompositeProductItemInfoQueryResult = Apollo.QueryResult<CompositeProductItemInfoQuery, CompositeProductItemInfoQueryVariables>;
export const CompositeProductItemInfosDocument = gql`
    query compositeProductItemInfos($filters: CompositeProductItemInfoFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  compositeProductItemInfos(
    filters: $filters
    pagination: $pagination
    sort: $sort
  ) {
    data {
      ...CompositeProductItemInfo
    }
    meta {
      ...Meta
    }
  }
}
    ${CompositeProductItemInfoFragmentDoc}
${CompositeProductItemInfoMinFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useCompositeProductItemInfosQuery(baseOptions?: Apollo.QueryHookOptions<CompositeProductItemInfosQuery, CompositeProductItemInfosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompositeProductItemInfosQuery, CompositeProductItemInfosQueryVariables>(CompositeProductItemInfosDocument, options);
      }
export function useCompositeProductItemInfosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompositeProductItemInfosQuery, CompositeProductItemInfosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompositeProductItemInfosQuery, CompositeProductItemInfosQueryVariables>(CompositeProductItemInfosDocument, options);
        }
export type CompositeProductItemInfosQueryHookResult = ReturnType<typeof useCompositeProductItemInfosQuery>;
export type CompositeProductItemInfosLazyQueryHookResult = ReturnType<typeof useCompositeProductItemInfosLazyQuery>;
export type CompositeProductItemInfosQueryResult = Apollo.QueryResult<CompositeProductItemInfosQuery, CompositeProductItemInfosQueryVariables>;
export const CompositeProductLocationInfosDocument = gql`
    query compositeProductLocationInfos($filters: CompositeProductLocationInfoFiltersInput, $pagination: PaginationArg = {}, $sort: [String], $businessLocationId: Int) {
  compositeProductLocationInfos(
    filters: $filters
    pagination: $pagination
    sort: $sort
  ) {
    data {
      ...CompositeProductLocationInfoWithQuantity
    }
    meta {
      ...Meta
    }
  }
}
    ${CompositeProductLocationInfoWithQuantityFragmentDoc}
${CompositeProductLocationInfoFragmentDoc}
${CompositeProductLocationInfoMinFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${CompositeProductFragmentDoc}
${CompositeProductMinFragmentDoc}
${ProductFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${SerializeFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${CompositeProductItemInfoFragmentDoc}
${CompositeProductItemInfoMinFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useCompositeProductLocationInfosQuery(baseOptions?: Apollo.QueryHookOptions<CompositeProductLocationInfosQuery, CompositeProductLocationInfosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompositeProductLocationInfosQuery, CompositeProductLocationInfosQueryVariables>(CompositeProductLocationInfosDocument, options);
      }
export function useCompositeProductLocationInfosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompositeProductLocationInfosQuery, CompositeProductLocationInfosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompositeProductLocationInfosQuery, CompositeProductLocationInfosQueryVariables>(CompositeProductLocationInfosDocument, options);
        }
export type CompositeProductLocationInfosQueryHookResult = ReturnType<typeof useCompositeProductLocationInfosQuery>;
export type CompositeProductLocationInfosLazyQueryHookResult = ReturnType<typeof useCompositeProductLocationInfosLazyQuery>;
export type CompositeProductLocationInfosQueryResult = Apollo.QueryResult<CompositeProductLocationInfosQuery, CompositeProductLocationInfosQueryVariables>;
export const CompositeProductsDocument = gql`
    query compositeProducts($filters: CompositeProductFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  compositeProducts(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...CompositeProduct
    }
    meta {
      ...Meta
    }
  }
}
    ${CompositeProductFragmentDoc}
${CompositeProductMinFragmentDoc}
${ProductFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${SerializeFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${CompositeProductLocationInfoMinFragmentDoc}
${CompositeProductItemInfoFragmentDoc}
${CompositeProductItemInfoMinFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useCompositeProductsQuery(baseOptions?: Apollo.QueryHookOptions<CompositeProductsQuery, CompositeProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompositeProductsQuery, CompositeProductsQueryVariables>(CompositeProductsDocument, options);
      }
export function useCompositeProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompositeProductsQuery, CompositeProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompositeProductsQuery, CompositeProductsQueryVariables>(CompositeProductsDocument, options);
        }
export type CompositeProductsQueryHookResult = ReturnType<typeof useCompositeProductsQuery>;
export type CompositeProductsLazyQueryHookResult = ReturnType<typeof useCompositeProductsLazyQuery>;
export type CompositeProductsQueryResult = Apollo.QueryResult<CompositeProductsQuery, CompositeProductsQueryVariables>;
export const CompositeProductsWithSoldRevenueDocument = gql`
    query compositeProductsWithSoldRevenue($filters: CompositeProductFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = [], $startDate: DateTime!, $endDate: DateTime!, $businessLocationId: Int) {
  compositeProducts(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...CompositeProductWithSoldRevenue
    }
    meta {
      ...Meta
    }
  }
}
    ${CompositeProductWithSoldRevenueFragmentDoc}
${CompositeProductFragmentDoc}
${CompositeProductMinFragmentDoc}
${ProductFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${SerializeFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${CompositeProductLocationInfoMinFragmentDoc}
${CompositeProductItemInfoFragmentDoc}
${CompositeProductItemInfoMinFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useCompositeProductsWithSoldRevenueQuery(baseOptions: Apollo.QueryHookOptions<CompositeProductsWithSoldRevenueQuery, CompositeProductsWithSoldRevenueQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompositeProductsWithSoldRevenueQuery, CompositeProductsWithSoldRevenueQueryVariables>(CompositeProductsWithSoldRevenueDocument, options);
      }
export function useCompositeProductsWithSoldRevenueLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompositeProductsWithSoldRevenueQuery, CompositeProductsWithSoldRevenueQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompositeProductsWithSoldRevenueQuery, CompositeProductsWithSoldRevenueQueryVariables>(CompositeProductsWithSoldRevenueDocument, options);
        }
export type CompositeProductsWithSoldRevenueQueryHookResult = ReturnType<typeof useCompositeProductsWithSoldRevenueQuery>;
export type CompositeProductsWithSoldRevenueLazyQueryHookResult = ReturnType<typeof useCompositeProductsWithSoldRevenueLazyQuery>;
export type CompositeProductsWithSoldRevenueQueryResult = Apollo.QueryResult<CompositeProductsWithSoldRevenueQuery, CompositeProductsWithSoldRevenueQueryVariables>;
export const CreateContactDocument = gql`
    mutation createContact($input: ContactInput!) {
  createContact(data: $input) {
    data {
      ...Contact
    }
  }
}
    ${ContactFragmentDoc}
${ContactMinFragmentDoc}
${FileFragmentDoc}
${ContactDynamicValuesFragmentDoc}
${LeadFragmentDoc}
${LeadMinFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${BusinessLocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}
${CompanyMinFragmentDoc}`;
export type CreateContactMutationFn = Apollo.MutationFunction<CreateContactMutation, CreateContactMutationVariables>;
export function useCreateContactMutation(baseOptions?: Apollo.MutationHookOptions<CreateContactMutation, CreateContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateContactMutation, CreateContactMutationVariables>(CreateContactDocument, options);
      }
export type CreateContactMutationHookResult = ReturnType<typeof useCreateContactMutation>;
export type CreateContactMutationResult = Apollo.MutationResult<CreateContactMutation>;
export const CreateContactsFromCsvDocument = gql`
    mutation createContactsFromCSV($input: CreateContactsFromCSVInput!) {
  createContactsFromCSV(input: $input)
}
    `;
export type CreateContactsFromCsvMutationFn = Apollo.MutationFunction<CreateContactsFromCsvMutation, CreateContactsFromCsvMutationVariables>;
export function useCreateContactsFromCsvMutation(baseOptions?: Apollo.MutationHookOptions<CreateContactsFromCsvMutation, CreateContactsFromCsvMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateContactsFromCsvMutation, CreateContactsFromCsvMutationVariables>(CreateContactsFromCsvDocument, options);
      }
export type CreateContactsFromCsvMutationHookResult = ReturnType<typeof useCreateContactsFromCsvMutation>;
export type CreateContactsFromCsvMutationResult = Apollo.MutationResult<CreateContactsFromCsvMutation>;
export const DeleteContactDocument = gql`
    mutation deleteContact($id: ID!) {
  deleteContact(id: $id) {
    data {
      ...ContactMin
    }
  }
}
    ${ContactMinFragmentDoc}
${FileFragmentDoc}`;
export type DeleteContactMutationFn = Apollo.MutationFunction<DeleteContactMutation, DeleteContactMutationVariables>;
export function useDeleteContactMutation(baseOptions?: Apollo.MutationHookOptions<DeleteContactMutation, DeleteContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteContactMutation, DeleteContactMutationVariables>(DeleteContactDocument, options);
      }
export type DeleteContactMutationHookResult = ReturnType<typeof useDeleteContactMutation>;
export type DeleteContactMutationResult = Apollo.MutationResult<DeleteContactMutation>;
export const FastUpdateAllContactsFromCsvDocument = gql`
    mutation fastUpdateAllContactsFromCSV($input: FastUpdateAllContactsFromCSVInput!) {
  fastUpdateAllContactsFromCSV(input: $input)
}
    `;
export type FastUpdateAllContactsFromCsvMutationFn = Apollo.MutationFunction<FastUpdateAllContactsFromCsvMutation, FastUpdateAllContactsFromCsvMutationVariables>;
export function useFastUpdateAllContactsFromCsvMutation(baseOptions?: Apollo.MutationHookOptions<FastUpdateAllContactsFromCsvMutation, FastUpdateAllContactsFromCsvMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FastUpdateAllContactsFromCsvMutation, FastUpdateAllContactsFromCsvMutationVariables>(FastUpdateAllContactsFromCsvDocument, options);
      }
export type FastUpdateAllContactsFromCsvMutationHookResult = ReturnType<typeof useFastUpdateAllContactsFromCsvMutation>;
export type FastUpdateAllContactsFromCsvMutationResult = Apollo.MutationResult<FastUpdateAllContactsFromCsvMutation>;
export const FastUpdateSingleContactDocument = gql`
    mutation fastUpdateSingleContact($input: FastUpdateSingleContactInput!) {
  fastUpdateSingleContact(input: $input)
}
    `;
export type FastUpdateSingleContactMutationFn = Apollo.MutationFunction<FastUpdateSingleContactMutation, FastUpdateSingleContactMutationVariables>;
export function useFastUpdateSingleContactMutation(baseOptions?: Apollo.MutationHookOptions<FastUpdateSingleContactMutation, FastUpdateSingleContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FastUpdateSingleContactMutation, FastUpdateSingleContactMutationVariables>(FastUpdateSingleContactDocument, options);
      }
export type FastUpdateSingleContactMutationHookResult = ReturnType<typeof useFastUpdateSingleContactMutation>;
export type FastUpdateSingleContactMutationResult = Apollo.MutationResult<FastUpdateSingleContactMutation>;
export const UpdateContactDocument = gql`
    mutation updateContact($id: ID!, $input: ContactInput!) {
  updateContact(id: $id, data: $input) {
    data {
      ...Contact
    }
  }
}
    ${ContactFragmentDoc}
${ContactMinFragmentDoc}
${FileFragmentDoc}
${ContactDynamicValuesFragmentDoc}
${LeadFragmentDoc}
${LeadMinFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${BusinessLocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}
${CompanyMinFragmentDoc}`;
export type UpdateContactMutationFn = Apollo.MutationFunction<UpdateContactMutation, UpdateContactMutationVariables>;
export function useUpdateContactMutation(baseOptions?: Apollo.MutationHookOptions<UpdateContactMutation, UpdateContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateContactMutation, UpdateContactMutationVariables>(UpdateContactDocument, options);
      }
export type UpdateContactMutationHookResult = ReturnType<typeof useUpdateContactMutation>;
export type UpdateContactMutationResult = Apollo.MutationResult<UpdateContactMutation>;
export const UpdateContactsCustomFieldsDocument = gql`
    mutation updateContactsCustomFields($input: CrmCustomFieldsInput!) {
  updateContactsCustomFields(input: $input) {
    data {
      ...Contact
    }
  }
}
    ${ContactFragmentDoc}
${ContactMinFragmentDoc}
${FileFragmentDoc}
${ContactDynamicValuesFragmentDoc}
${LeadFragmentDoc}
${LeadMinFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${BusinessLocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}
${CompanyMinFragmentDoc}`;
export type UpdateContactsCustomFieldsMutationFn = Apollo.MutationFunction<UpdateContactsCustomFieldsMutation, UpdateContactsCustomFieldsMutationVariables>;
export function useUpdateContactsCustomFieldsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateContactsCustomFieldsMutation, UpdateContactsCustomFieldsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateContactsCustomFieldsMutation, UpdateContactsCustomFieldsMutationVariables>(UpdateContactsCustomFieldsDocument, options);
      }
export type UpdateContactsCustomFieldsMutationHookResult = ReturnType<typeof useUpdateContactsCustomFieldsMutation>;
export type UpdateContactsCustomFieldsMutationResult = Apollo.MutationResult<UpdateContactsCustomFieldsMutation>;
export const AllContactsDocument = gql`
    query allContacts($filtersContacts: ContactFiltersInput! = {}, $filtersCompanies: CompanyFiltersInput! = {}, $pagination: PaginationArg) {
  contacts(filters: $filtersContacts, pagination: $pagination) {
    data {
      ...Contact
    }
  }
  companies(filters: $filtersCompanies, pagination: $pagination) {
    data {
      ...Company
    }
  }
}
    ${ContactFragmentDoc}
${ContactMinFragmentDoc}
${FileFragmentDoc}
${ContactDynamicValuesFragmentDoc}
${LeadFragmentDoc}
${LeadMinFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${BusinessLocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}
${CompanyMinFragmentDoc}
${CompanyFragmentDoc}`;
export function useAllContactsQuery(baseOptions?: Apollo.QueryHookOptions<AllContactsQuery, AllContactsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllContactsQuery, AllContactsQueryVariables>(AllContactsDocument, options);
      }
export function useAllContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllContactsQuery, AllContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllContactsQuery, AllContactsQueryVariables>(AllContactsDocument, options);
        }
export type AllContactsQueryHookResult = ReturnType<typeof useAllContactsQuery>;
export type AllContactsLazyQueryHookResult = ReturnType<typeof useAllContactsLazyQuery>;
export type AllContactsQueryResult = Apollo.QueryResult<AllContactsQuery, AllContactsQueryVariables>;
export const ContactByUuidDocument = gql`
    query contactByUuid($uuid: String!) {
  contacts(filters: {uuid: {eq: $uuid}}) {
    data {
      ...Contact
    }
  }
}
    ${ContactFragmentDoc}
${ContactMinFragmentDoc}
${FileFragmentDoc}
${ContactDynamicValuesFragmentDoc}
${LeadFragmentDoc}
${LeadMinFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${BusinessLocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}
${CompanyMinFragmentDoc}`;
export function useContactByUuidQuery(baseOptions: Apollo.QueryHookOptions<ContactByUuidQuery, ContactByUuidQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContactByUuidQuery, ContactByUuidQueryVariables>(ContactByUuidDocument, options);
      }
export function useContactByUuidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactByUuidQuery, ContactByUuidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContactByUuidQuery, ContactByUuidQueryVariables>(ContactByUuidDocument, options);
        }
export type ContactByUuidQueryHookResult = ReturnType<typeof useContactByUuidQuery>;
export type ContactByUuidLazyQueryHookResult = ReturnType<typeof useContactByUuidLazyQuery>;
export type ContactByUuidQueryResult = Apollo.QueryResult<ContactByUuidQuery, ContactByUuidQueryVariables>;
export const ContactsDocument = gql`
    query contacts($filters: ContactFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  contacts(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...Contact
    }
    meta {
      ...Meta
    }
  }
}
    ${ContactFragmentDoc}
${ContactMinFragmentDoc}
${FileFragmentDoc}
${ContactDynamicValuesFragmentDoc}
${LeadFragmentDoc}
${LeadMinFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${BusinessLocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}
${CompanyMinFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useContactsQuery(baseOptions?: Apollo.QueryHookOptions<ContactsQuery, ContactsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContactsQuery, ContactsQueryVariables>(ContactsDocument, options);
      }
export function useContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactsQuery, ContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContactsQuery, ContactsQueryVariables>(ContactsDocument, options);
        }
export type ContactsQueryHookResult = ReturnType<typeof useContactsQuery>;
export type ContactsLazyQueryHookResult = ReturnType<typeof useContactsLazyQuery>;
export type ContactsQueryResult = Apollo.QueryResult<ContactsQuery, ContactsQueryVariables>;
export const ContactsCompaniesLeadsDocument = gql`
    query contactsCompaniesLeads($filterContacts: ContactFiltersInput! = {}, $filterCompanies: CompanyFiltersInput! = {}, $filterLeads: LeadFiltersInput! = {}, $pagination: PaginationArg) {
  contacts(filters: $filterContacts, pagination: $pagination) {
    data {
      ...Contact
    }
  }
  companies(filters: $filterCompanies, pagination: $pagination) {
    data {
      ...Company
    }
  }
  leads(filters: $filterLeads, pagination: $pagination) {
    data {
      ...Lead
    }
  }
}
    ${ContactFragmentDoc}
${ContactMinFragmentDoc}
${FileFragmentDoc}
${ContactDynamicValuesFragmentDoc}
${LeadFragmentDoc}
${LeadMinFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${BusinessLocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}
${CompanyMinFragmentDoc}
${CompanyFragmentDoc}`;
export function useContactsCompaniesLeadsQuery(baseOptions?: Apollo.QueryHookOptions<ContactsCompaniesLeadsQuery, ContactsCompaniesLeadsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContactsCompaniesLeadsQuery, ContactsCompaniesLeadsQueryVariables>(ContactsCompaniesLeadsDocument, options);
      }
export function useContactsCompaniesLeadsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactsCompaniesLeadsQuery, ContactsCompaniesLeadsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContactsCompaniesLeadsQuery, ContactsCompaniesLeadsQueryVariables>(ContactsCompaniesLeadsDocument, options);
        }
export type ContactsCompaniesLeadsQueryHookResult = ReturnType<typeof useContactsCompaniesLeadsQuery>;
export type ContactsCompaniesLeadsLazyQueryHookResult = ReturnType<typeof useContactsCompaniesLeadsLazyQuery>;
export type ContactsCompaniesLeadsQueryResult = Apollo.QueryResult<ContactsCompaniesLeadsQuery, ContactsCompaniesLeadsQueryVariables>;
export const ContactsDataByPeriodDocument = gql`
    query contactsDataByPeriod($filters: ContactFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = [], $period: DatesList!) {
  contacts(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...ContactsDataByPeriod
    }
    meta {
      ...Meta
    }
  }
}
    ${ContactsDataByPeriodFragmentDoc}
${ContactMinFragmentDoc}
${FileFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useContactsDataByPeriodQuery(baseOptions: Apollo.QueryHookOptions<ContactsDataByPeriodQuery, ContactsDataByPeriodQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContactsDataByPeriodQuery, ContactsDataByPeriodQueryVariables>(ContactsDataByPeriodDocument, options);
      }
export function useContactsDataByPeriodLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactsDataByPeriodQuery, ContactsDataByPeriodQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContactsDataByPeriodQuery, ContactsDataByPeriodQueryVariables>(ContactsDataByPeriodDocument, options);
        }
export type ContactsDataByPeriodQueryHookResult = ReturnType<typeof useContactsDataByPeriodQuery>;
export type ContactsDataByPeriodLazyQueryHookResult = ReturnType<typeof useContactsDataByPeriodLazyQuery>;
export type ContactsDataByPeriodQueryResult = Apollo.QueryResult<ContactsDataByPeriodQuery, ContactsDataByPeriodQueryVariables>;
export const ContactsLeadsDocument = gql`
    query contactsLeads($filterContacts: ContactFiltersInput! = {}, $filterLeads: LeadFiltersInput! = {}, $pagination: PaginationArg) {
  contacts(filters: $filterContacts, pagination: $pagination) {
    data {
      ...Contact
    }
  }
  leads(filters: $filterLeads, pagination: $pagination) {
    data {
      ...Lead
    }
  }
}
    ${ContactFragmentDoc}
${ContactMinFragmentDoc}
${FileFragmentDoc}
${ContactDynamicValuesFragmentDoc}
${LeadFragmentDoc}
${LeadMinFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${BusinessLocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}
${CompanyMinFragmentDoc}`;
export function useContactsLeadsQuery(baseOptions?: Apollo.QueryHookOptions<ContactsLeadsQuery, ContactsLeadsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContactsLeadsQuery, ContactsLeadsQueryVariables>(ContactsLeadsDocument, options);
      }
export function useContactsLeadsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactsLeadsQuery, ContactsLeadsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContactsLeadsQuery, ContactsLeadsQueryVariables>(ContactsLeadsDocument, options);
        }
export type ContactsLeadsQueryHookResult = ReturnType<typeof useContactsLeadsQuery>;
export type ContactsLeadsLazyQueryHookResult = ReturnType<typeof useContactsLeadsLazyQuery>;
export type ContactsLeadsQueryResult = Apollo.QueryResult<ContactsLeadsQuery, ContactsLeadsQueryVariables>;
export const ContactsMinimalDocument = gql`
    query contactsMinimal($filters: ContactFiltersInput, $pagination: PaginationArg) {
  contacts(filters: $filters, pagination: $pagination) {
    data {
      ...ContactMin
    }
  }
}
    ${ContactMinFragmentDoc}
${FileFragmentDoc}`;
export function useContactsMinimalQuery(baseOptions?: Apollo.QueryHookOptions<ContactsMinimalQuery, ContactsMinimalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContactsMinimalQuery, ContactsMinimalQueryVariables>(ContactsMinimalDocument, options);
      }
export function useContactsMinimalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactsMinimalQuery, ContactsMinimalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContactsMinimalQuery, ContactsMinimalQueryVariables>(ContactsMinimalDocument, options);
        }
export type ContactsMinimalQueryHookResult = ReturnType<typeof useContactsMinimalQuery>;
export type ContactsMinimalLazyQueryHookResult = ReturnType<typeof useContactsMinimalLazyQuery>;
export type ContactsMinimalQueryResult = Apollo.QueryResult<ContactsMinimalQuery, ContactsMinimalQueryVariables>;
export const ContactsTotalDocument = gql`
    query contactsTotal($filtersContacts: ContactFiltersInput! = {}, $filtersCompanies: CompanyFiltersInput! = {}) {
  contacts(filters: $filtersContacts) {
    meta {
      pagination {
        total
      }
    }
  }
  companies(filters: $filtersCompanies) {
    meta {
      pagination {
        total
      }
    }
  }
}
    `;
export function useContactsTotalQuery(baseOptions?: Apollo.QueryHookOptions<ContactsTotalQuery, ContactsTotalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContactsTotalQuery, ContactsTotalQueryVariables>(ContactsTotalDocument, options);
      }
export function useContactsTotalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactsTotalQuery, ContactsTotalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContactsTotalQuery, ContactsTotalQueryVariables>(ContactsTotalDocument, options);
        }
export type ContactsTotalQueryHookResult = ReturnType<typeof useContactsTotalQuery>;
export type ContactsTotalLazyQueryHookResult = ReturnType<typeof useContactsTotalLazyQuery>;
export type ContactsTotalQueryResult = Apollo.QueryResult<ContactsTotalQuery, ContactsTotalQueryVariables>;
export const DashboardCrmCustomersDataDocument = gql`
    query dashboardCrmCustomersData {
  dashboardCrmCustomersData
}
    `;
export function useDashboardCrmCustomersDataQuery(baseOptions?: Apollo.QueryHookOptions<DashboardCrmCustomersDataQuery, DashboardCrmCustomersDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardCrmCustomersDataQuery, DashboardCrmCustomersDataQueryVariables>(DashboardCrmCustomersDataDocument, options);
      }
export function useDashboardCrmCustomersDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardCrmCustomersDataQuery, DashboardCrmCustomersDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardCrmCustomersDataQuery, DashboardCrmCustomersDataQueryVariables>(DashboardCrmCustomersDataDocument, options);
        }
export type DashboardCrmCustomersDataQueryHookResult = ReturnType<typeof useDashboardCrmCustomersDataQuery>;
export type DashboardCrmCustomersDataLazyQueryHookResult = ReturnType<typeof useDashboardCrmCustomersDataLazyQuery>;
export type DashboardCrmCustomersDataQueryResult = Apollo.QueryResult<DashboardCrmCustomersDataQuery, DashboardCrmCustomersDataQueryVariables>;
export const GetSingleCrmEntityStatisticForCrmCardsDocument = gql`
    query getSingleCrmEntityStatisticForCrmCards($input: CrmSingleCardTotalsInput!) {
  getSingleContactStatisticForCrmCards(input: $input) {
    invoices {
      ...CrmSingleStatisticCard
    }
    forms {
      ...CrmSingleStatisticCard
    }
    contracts {
      ...CrmSingleStatisticCard
    }
    appraisals {
      ...CrmSingleStatisticCard
    }
    purchaseRequests {
      ...CrmSingleStatisticCard
    }
    tasks {
      ...CrmSingleStatisticCard
    }
    orders {
      ...CrmSingleStatisticCard
    }
  }
}
    ${CrmSingleStatisticCardFragmentDoc}`;
export function useGetSingleCrmEntityStatisticForCrmCardsQuery(baseOptions: Apollo.QueryHookOptions<GetSingleCrmEntityStatisticForCrmCardsQuery, GetSingleCrmEntityStatisticForCrmCardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSingleCrmEntityStatisticForCrmCardsQuery, GetSingleCrmEntityStatisticForCrmCardsQueryVariables>(GetSingleCrmEntityStatisticForCrmCardsDocument, options);
      }
export function useGetSingleCrmEntityStatisticForCrmCardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSingleCrmEntityStatisticForCrmCardsQuery, GetSingleCrmEntityStatisticForCrmCardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSingleCrmEntityStatisticForCrmCardsQuery, GetSingleCrmEntityStatisticForCrmCardsQueryVariables>(GetSingleCrmEntityStatisticForCrmCardsDocument, options);
        }
export type GetSingleCrmEntityStatisticForCrmCardsQueryHookResult = ReturnType<typeof useGetSingleCrmEntityStatisticForCrmCardsQuery>;
export type GetSingleCrmEntityStatisticForCrmCardsLazyQueryHookResult = ReturnType<typeof useGetSingleCrmEntityStatisticForCrmCardsLazyQuery>;
export type GetSingleCrmEntityStatisticForCrmCardsQueryResult = Apollo.QueryResult<GetSingleCrmEntityStatisticForCrmCardsQuery, GetSingleCrmEntityStatisticForCrmCardsQueryVariables>;
export const CreateAppraisalDocument = gql`
    mutation createAppraisal($input: AppraisalInput!) {
  createAppraisal(data: $input) {
    data {
      ...Appraisal
    }
  }
}
    ${AppraisalFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ContactMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${BusinessLocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}
${ProductFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${SerializeFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}`;
export type CreateAppraisalMutationFn = Apollo.MutationFunction<CreateAppraisalMutation, CreateAppraisalMutationVariables>;
export function useCreateAppraisalMutation(baseOptions?: Apollo.MutationHookOptions<CreateAppraisalMutation, CreateAppraisalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAppraisalMutation, CreateAppraisalMutationVariables>(CreateAppraisalDocument, options);
      }
export type CreateAppraisalMutationHookResult = ReturnType<typeof useCreateAppraisalMutation>;
export type CreateAppraisalMutationResult = Apollo.MutationResult<CreateAppraisalMutation>;
export const CreateContractDocument = gql`
    mutation createContract($input: ContractInput!) {
  createContract(data: $input) {
    data {
      ...Contract
    }
  }
}
    ${ContractFragmentDoc}
${ContactFragmentDoc}
${ContactMinFragmentDoc}
${FileFragmentDoc}
${ContactDynamicValuesFragmentDoc}
${LeadFragmentDoc}
${LeadMinFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${BusinessLocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}
${CompanyMinFragmentDoc}
${PublicContractFragmentDoc}`;
export type CreateContractMutationFn = Apollo.MutationFunction<CreateContractMutation, CreateContractMutationVariables>;
export function useCreateContractMutation(baseOptions?: Apollo.MutationHookOptions<CreateContractMutation, CreateContractMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateContractMutation, CreateContractMutationVariables>(CreateContractDocument, options);
      }
export type CreateContractMutationHookResult = ReturnType<typeof useCreateContractMutation>;
export type CreateContractMutationResult = Apollo.MutationResult<CreateContractMutation>;
export const CreateContractTemplateDocument = gql`
    mutation createContractTemplate($input: ContractTemplateInput!) {
  createContractTemplate(data: $input) {
    data {
      ...ContractTemplate
    }
  }
}
    ${ContractTemplateFragmentDoc}`;
export type CreateContractTemplateMutationFn = Apollo.MutationFunction<CreateContractTemplateMutation, CreateContractTemplateMutationVariables>;
export function useCreateContractTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CreateContractTemplateMutation, CreateContractTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateContractTemplateMutation, CreateContractTemplateMutationVariables>(CreateContractTemplateDocument, options);
      }
export type CreateContractTemplateMutationHookResult = ReturnType<typeof useCreateContractTemplateMutation>;
export type CreateContractTemplateMutationResult = Apollo.MutationResult<CreateContractTemplateMutation>;
export const CreateFormTemplateDocument = gql`
    mutation createFormTemplate($input: FormTemplateInput!) {
  createFormTemplate(data: $input) {
    data {
      ...FormTemplate
    }
  }
}
    ${FormTemplateFragmentDoc}
${FileFragmentDoc}`;
export type CreateFormTemplateMutationFn = Apollo.MutationFunction<CreateFormTemplateMutation, CreateFormTemplateMutationVariables>;
export function useCreateFormTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CreateFormTemplateMutation, CreateFormTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFormTemplateMutation, CreateFormTemplateMutationVariables>(CreateFormTemplateDocument, options);
      }
export type CreateFormTemplateMutationHookResult = ReturnType<typeof useCreateFormTemplateMutation>;
export type CreateFormTemplateMutationResult = Apollo.MutationResult<CreateFormTemplateMutation>;
export const DeleteFormTemplateDocument = gql`
    mutation deleteFormTemplate($id: ID!) {
  deleteFormTemplate(id: $id) {
    data {
      ...FormTemplate
    }
  }
}
    ${FormTemplateFragmentDoc}
${FileFragmentDoc}`;
export type DeleteFormTemplateMutationFn = Apollo.MutationFunction<DeleteFormTemplateMutation, DeleteFormTemplateMutationVariables>;
export function useDeleteFormTemplateMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFormTemplateMutation, DeleteFormTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFormTemplateMutation, DeleteFormTemplateMutationVariables>(DeleteFormTemplateDocument, options);
      }
export type DeleteFormTemplateMutationHookResult = ReturnType<typeof useDeleteFormTemplateMutation>;
export type DeleteFormTemplateMutationResult = Apollo.MutationResult<DeleteFormTemplateMutation>;
export const DeleteContractTemplateDocument = gql`
    mutation deleteContractTemplate($id: ID!) {
  deleteContractTemplate(id: $id) {
    data {
      ...ContractTemplate
    }
  }
}
    ${ContractTemplateFragmentDoc}`;
export type DeleteContractTemplateMutationFn = Apollo.MutationFunction<DeleteContractTemplateMutation, DeleteContractTemplateMutationVariables>;
export function useDeleteContractTemplateMutation(baseOptions?: Apollo.MutationHookOptions<DeleteContractTemplateMutation, DeleteContractTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteContractTemplateMutation, DeleteContractTemplateMutationVariables>(DeleteContractTemplateDocument, options);
      }
export type DeleteContractTemplateMutationHookResult = ReturnType<typeof useDeleteContractTemplateMutation>;
export type DeleteContractTemplateMutationResult = Apollo.MutationResult<DeleteContractTemplateMutation>;
export const RemoveAppraisalDocument = gql`
    mutation removeAppraisal($id: ID!) {
  deleteAppraisal(id: $id) {
    data {
      ...Appraisal
    }
  }
}
    ${AppraisalFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ContactMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${BusinessLocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}
${ProductFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${SerializeFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}`;
export type RemoveAppraisalMutationFn = Apollo.MutationFunction<RemoveAppraisalMutation, RemoveAppraisalMutationVariables>;
export function useRemoveAppraisalMutation(baseOptions?: Apollo.MutationHookOptions<RemoveAppraisalMutation, RemoveAppraisalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveAppraisalMutation, RemoveAppraisalMutationVariables>(RemoveAppraisalDocument, options);
      }
export type RemoveAppraisalMutationHookResult = ReturnType<typeof useRemoveAppraisalMutation>;
export type RemoveAppraisalMutationResult = Apollo.MutationResult<RemoveAppraisalMutation>;
export const RemoveFormDocument = gql`
    mutation removeForm($id: ID!) {
  removeForm(formId: $id)
}
    `;
export type RemoveFormMutationFn = Apollo.MutationFunction<RemoveFormMutation, RemoveFormMutationVariables>;
export function useRemoveFormMutation(baseOptions?: Apollo.MutationHookOptions<RemoveFormMutation, RemoveFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveFormMutation, RemoveFormMutationVariables>(RemoveFormDocument, options);
      }
export type RemoveFormMutationHookResult = ReturnType<typeof useRemoveFormMutation>;
export type RemoveFormMutationResult = Apollo.MutationResult<RemoveFormMutation>;
export const SendAppraisalDocumentDocument = gql`
    mutation sendAppraisalDocument($appraisalId: ID!, $email: String!, $contactId: ID!) {
  sendAppraisalDocument(
    appraisalId: $appraisalId
    subjectEmail: $email
    contactId: $contactId
  )
}
    `;
export type SendAppraisalDocumentMutationFn = Apollo.MutationFunction<SendAppraisalDocumentMutation, SendAppraisalDocumentMutationVariables>;
export function useSendAppraisalDocumentMutation(baseOptions?: Apollo.MutationHookOptions<SendAppraisalDocumentMutation, SendAppraisalDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendAppraisalDocumentMutation, SendAppraisalDocumentMutationVariables>(SendAppraisalDocumentDocument, options);
      }
export type SendAppraisalDocumentMutationHookResult = ReturnType<typeof useSendAppraisalDocumentMutation>;
export type SendAppraisalDocumentMutationResult = Apollo.MutationResult<SendAppraisalDocumentMutation>;
export const SendContractDocument = gql`
    mutation sendContract($templateId: ID!, $contactId: ID!) {
  sendContract(templateId: $templateId, contactId: $contactId)
}
    `;
export type SendContractMutationFn = Apollo.MutationFunction<SendContractMutation, SendContractMutationVariables>;
export function useSendContractMutation(baseOptions?: Apollo.MutationHookOptions<SendContractMutation, SendContractMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendContractMutation, SendContractMutationVariables>(SendContractDocument, options);
      }
export type SendContractMutationHookResult = ReturnType<typeof useSendContractMutation>;
export type SendContractMutationResult = Apollo.MutationResult<SendContractMutation>;
export const SendFormDocument = gql`
    mutation sendForm($templateId: ID!, $email: String!, $contactId: ID) {
  sendForm(templateId: $templateId, subjectEmail: $email, contactId: $contactId)
}
    `;
export type SendFormMutationFn = Apollo.MutationFunction<SendFormMutation, SendFormMutationVariables>;
export function useSendFormMutation(baseOptions?: Apollo.MutationHookOptions<SendFormMutation, SendFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendFormMutation, SendFormMutationVariables>(SendFormDocument, options);
      }
export type SendFormMutationHookResult = ReturnType<typeof useSendFormMutation>;
export type SendFormMutationResult = Apollo.MutationResult<SendFormMutation>;
export const UpdateAppraisalDocument = gql`
    mutation updateAppraisal($id: ID!, $input: AppraisalInput!) {
  updateAppraisal(id: $id, data: $input) {
    data {
      ...Appraisal
    }
  }
}
    ${AppraisalFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ContactMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${BusinessLocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}
${ProductFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${SerializeFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}`;
export type UpdateAppraisalMutationFn = Apollo.MutationFunction<UpdateAppraisalMutation, UpdateAppraisalMutationVariables>;
export function useUpdateAppraisalMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAppraisalMutation, UpdateAppraisalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAppraisalMutation, UpdateAppraisalMutationVariables>(UpdateAppraisalDocument, options);
      }
export type UpdateAppraisalMutationHookResult = ReturnType<typeof useUpdateAppraisalMutation>;
export type UpdateAppraisalMutationResult = Apollo.MutationResult<UpdateAppraisalMutation>;
export const UpdateContractDocument = gql`
    mutation updateContract($id: ID!, $input: ContractInput!) {
  updateContract(id: $id, data: $input) {
    data {
      ...Contract
    }
  }
}
    ${ContractFragmentDoc}
${ContactFragmentDoc}
${ContactMinFragmentDoc}
${FileFragmentDoc}
${ContactDynamicValuesFragmentDoc}
${LeadFragmentDoc}
${LeadMinFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${BusinessLocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}
${CompanyMinFragmentDoc}
${PublicContractFragmentDoc}`;
export type UpdateContractMutationFn = Apollo.MutationFunction<UpdateContractMutation, UpdateContractMutationVariables>;
export function useUpdateContractMutation(baseOptions?: Apollo.MutationHookOptions<UpdateContractMutation, UpdateContractMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateContractMutation, UpdateContractMutationVariables>(UpdateContractDocument, options);
      }
export type UpdateContractMutationHookResult = ReturnType<typeof useUpdateContractMutation>;
export type UpdateContractMutationResult = Apollo.MutationResult<UpdateContractMutation>;
export const UpdateContractTemplateDocument = gql`
    mutation updateContractTemplate($id: ID!, $input: ContractTemplateInput!) {
  updateContractTemplate(id: $id, data: $input) {
    data {
      ...ContractTemplate
    }
  }
}
    ${ContractTemplateFragmentDoc}`;
export type UpdateContractTemplateMutationFn = Apollo.MutationFunction<UpdateContractTemplateMutation, UpdateContractTemplateMutationVariables>;
export function useUpdateContractTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateContractTemplateMutation, UpdateContractTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateContractTemplateMutation, UpdateContractTemplateMutationVariables>(UpdateContractTemplateDocument, options);
      }
export type UpdateContractTemplateMutationHookResult = ReturnType<typeof useUpdateContractTemplateMutation>;
export type UpdateContractTemplateMutationResult = Apollo.MutationResult<UpdateContractTemplateMutation>;
export const UpdateFormTemplateDocument = gql`
    mutation updateFormTemplate($id: ID!, $input: FormTemplateInput!) {
  updateFormTemplate(id: $id, data: $input) {
    data {
      ...FormTemplate
    }
  }
}
    ${FormTemplateFragmentDoc}
${FileFragmentDoc}`;
export type UpdateFormTemplateMutationFn = Apollo.MutationFunction<UpdateFormTemplateMutation, UpdateFormTemplateMutationVariables>;
export function useUpdateFormTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFormTemplateMutation, UpdateFormTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFormTemplateMutation, UpdateFormTemplateMutationVariables>(UpdateFormTemplateDocument, options);
      }
export type UpdateFormTemplateMutationHookResult = ReturnType<typeof useUpdateFormTemplateMutation>;
export type UpdateFormTemplateMutationResult = Apollo.MutationResult<UpdateFormTemplateMutation>;
export const UpdatePublicContractDocument = gql`
    mutation updatePublicContract($id: ID!, $input: PublicContractInput!) {
  updatePublicContract(id: $id, data: $input) {
    data {
      ...PublicContract
    }
  }
}
    ${PublicContractFragmentDoc}`;
export type UpdatePublicContractMutationFn = Apollo.MutationFunction<UpdatePublicContractMutation, UpdatePublicContractMutationVariables>;
export function useUpdatePublicContractMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePublicContractMutation, UpdatePublicContractMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePublicContractMutation, UpdatePublicContractMutationVariables>(UpdatePublicContractDocument, options);
      }
export type UpdatePublicContractMutationHookResult = ReturnType<typeof useUpdatePublicContractMutation>;
export type UpdatePublicContractMutationResult = Apollo.MutationResult<UpdatePublicContractMutation>;
export const UpdatePublicFormDocument = gql`
    mutation updatePublicForm($id: ID!, $input: PublicFormInput!) {
  updatePublicForm(id: $id, data: $input) {
    data {
      ...PublicForm
    }
  }
}
    ${PublicFormFragmentDoc}
${FileFragmentDoc}`;
export type UpdatePublicFormMutationFn = Apollo.MutationFunction<UpdatePublicFormMutation, UpdatePublicFormMutationVariables>;
export function useUpdatePublicFormMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePublicFormMutation, UpdatePublicFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePublicFormMutation, UpdatePublicFormMutationVariables>(UpdatePublicFormDocument, options);
      }
export type UpdatePublicFormMutationHookResult = ReturnType<typeof useUpdatePublicFormMutation>;
export type UpdatePublicFormMutationResult = Apollo.MutationResult<UpdatePublicFormMutation>;
export const AppraisalByUuidDocument = gql`
    query appraisalByUuid($uuid: String!) {
  appraisals(filters: {uuid: {eq: $uuid}}) {
    data {
      ...Appraisal
    }
  }
}
    ${AppraisalFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ContactMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${BusinessLocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}
${ProductFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${SerializeFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}`;
export function useAppraisalByUuidQuery(baseOptions: Apollo.QueryHookOptions<AppraisalByUuidQuery, AppraisalByUuidQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AppraisalByUuidQuery, AppraisalByUuidQueryVariables>(AppraisalByUuidDocument, options);
      }
export function useAppraisalByUuidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AppraisalByUuidQuery, AppraisalByUuidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AppraisalByUuidQuery, AppraisalByUuidQueryVariables>(AppraisalByUuidDocument, options);
        }
export type AppraisalByUuidQueryHookResult = ReturnType<typeof useAppraisalByUuidQuery>;
export type AppraisalByUuidLazyQueryHookResult = ReturnType<typeof useAppraisalByUuidLazyQuery>;
export type AppraisalByUuidQueryResult = Apollo.QueryResult<AppraisalByUuidQuery, AppraisalByUuidQueryVariables>;
export const ContractByUuidDocument = gql`
    query contractByUuid($uuid: String!) {
  contracts(filters: {uuid: {eq: $uuid}}) {
    data {
      ...Contract
    }
  }
}
    ${ContractFragmentDoc}
${ContactFragmentDoc}
${ContactMinFragmentDoc}
${FileFragmentDoc}
${ContactDynamicValuesFragmentDoc}
${LeadFragmentDoc}
${LeadMinFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${BusinessLocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}
${CompanyMinFragmentDoc}
${PublicContractFragmentDoc}`;
export function useContractByUuidQuery(baseOptions: Apollo.QueryHookOptions<ContractByUuidQuery, ContractByUuidQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContractByUuidQuery, ContractByUuidQueryVariables>(ContractByUuidDocument, options);
      }
export function useContractByUuidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContractByUuidQuery, ContractByUuidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContractByUuidQuery, ContractByUuidQueryVariables>(ContractByUuidDocument, options);
        }
export type ContractByUuidQueryHookResult = ReturnType<typeof useContractByUuidQuery>;
export type ContractByUuidLazyQueryHookResult = ReturnType<typeof useContractByUuidLazyQuery>;
export type ContractByUuidQueryResult = Apollo.QueryResult<ContractByUuidQuery, ContractByUuidQueryVariables>;
export const ContractTemplateByUuidDocument = gql`
    query contractTemplateByUuid($uuid: String!) {
  contractTemplates(filters: {uuid: {eq: $uuid}}) {
    data {
      ...ContractTemplate
    }
  }
}
    ${ContractTemplateFragmentDoc}`;
export function useContractTemplateByUuidQuery(baseOptions: Apollo.QueryHookOptions<ContractTemplateByUuidQuery, ContractTemplateByUuidQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContractTemplateByUuidQuery, ContractTemplateByUuidQueryVariables>(ContractTemplateByUuidDocument, options);
      }
export function useContractTemplateByUuidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContractTemplateByUuidQuery, ContractTemplateByUuidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContractTemplateByUuidQuery, ContractTemplateByUuidQueryVariables>(ContractTemplateByUuidDocument, options);
        }
export type ContractTemplateByUuidQueryHookResult = ReturnType<typeof useContractTemplateByUuidQuery>;
export type ContractTemplateByUuidLazyQueryHookResult = ReturnType<typeof useContractTemplateByUuidLazyQuery>;
export type ContractTemplateByUuidQueryResult = Apollo.QueryResult<ContractTemplateByUuidQuery, ContractTemplateByUuidQueryVariables>;
export const FormTemplateByUuidDocument = gql`
    query formTemplateByUuid($uuid: String!) {
  formTemplates(filters: {uuid: {eq: $uuid}}) {
    data {
      ...FormTemplate
    }
  }
}
    ${FormTemplateFragmentDoc}
${FileFragmentDoc}`;
export function useFormTemplateByUuidQuery(baseOptions: Apollo.QueryHookOptions<FormTemplateByUuidQuery, FormTemplateByUuidQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FormTemplateByUuidQuery, FormTemplateByUuidQueryVariables>(FormTemplateByUuidDocument, options);
      }
export function useFormTemplateByUuidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FormTemplateByUuidQuery, FormTemplateByUuidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FormTemplateByUuidQuery, FormTemplateByUuidQueryVariables>(FormTemplateByUuidDocument, options);
        }
export type FormTemplateByUuidQueryHookResult = ReturnType<typeof useFormTemplateByUuidQuery>;
export type FormTemplateByUuidLazyQueryHookResult = ReturnType<typeof useFormTemplateByUuidLazyQuery>;
export type FormTemplateByUuidQueryResult = Apollo.QueryResult<FormTemplateByUuidQuery, FormTemplateByUuidQueryVariables>;
export const PublicContractByUuidDocument = gql`
    query publicContractByUuid($uuid: String!) {
  publicContracts(filters: {uuid: {eq: $uuid}}) {
    data {
      ...PublicContract
    }
  }
}
    ${PublicContractFragmentDoc}`;
export function usePublicContractByUuidQuery(baseOptions: Apollo.QueryHookOptions<PublicContractByUuidQuery, PublicContractByUuidQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublicContractByUuidQuery, PublicContractByUuidQueryVariables>(PublicContractByUuidDocument, options);
      }
export function usePublicContractByUuidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublicContractByUuidQuery, PublicContractByUuidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublicContractByUuidQuery, PublicContractByUuidQueryVariables>(PublicContractByUuidDocument, options);
        }
export type PublicContractByUuidQueryHookResult = ReturnType<typeof usePublicContractByUuidQuery>;
export type PublicContractByUuidLazyQueryHookResult = ReturnType<typeof usePublicContractByUuidLazyQuery>;
export type PublicContractByUuidQueryResult = Apollo.QueryResult<PublicContractByUuidQuery, PublicContractByUuidQueryVariables>;
export const PublicContractFormByUuidDocument = gql`
    query publicContractFormByUuid($uuid: String!) {
  publicForms(filters: {uuid: {eq: $uuid}}) {
    data {
      ...PublicForm
    }
  }
}
    ${PublicFormFragmentDoc}
${FileFragmentDoc}`;
export function usePublicContractFormByUuidQuery(baseOptions: Apollo.QueryHookOptions<PublicContractFormByUuidQuery, PublicContractFormByUuidQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublicContractFormByUuidQuery, PublicContractFormByUuidQueryVariables>(PublicContractFormByUuidDocument, options);
      }
export function usePublicContractFormByUuidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublicContractFormByUuidQuery, PublicContractFormByUuidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublicContractFormByUuidQuery, PublicContractFormByUuidQueryVariables>(PublicContractFormByUuidDocument, options);
        }
export type PublicContractFormByUuidQueryHookResult = ReturnType<typeof usePublicContractFormByUuidQuery>;
export type PublicContractFormByUuidLazyQueryHookResult = ReturnType<typeof usePublicContractFormByUuidLazyQuery>;
export type PublicContractFormByUuidQueryResult = Apollo.QueryResult<PublicContractFormByUuidQuery, PublicContractFormByUuidQueryVariables>;
export const AllAppraisalDocument = gql`
    query allAppraisal($filters: AppraisalFiltersInput!, $pagination: PaginationArg, $sort: [String]) {
  appraisals(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...Appraisal
    }
    meta {
      ...Meta
    }
  }
}
    ${AppraisalFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ContactMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${BusinessLocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}
${ProductFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${SerializeFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useAllAppraisalQuery(baseOptions: Apollo.QueryHookOptions<AllAppraisalQuery, AllAppraisalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllAppraisalQuery, AllAppraisalQueryVariables>(AllAppraisalDocument, options);
      }
export function useAllAppraisalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllAppraisalQuery, AllAppraisalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllAppraisalQuery, AllAppraisalQueryVariables>(AllAppraisalDocument, options);
        }
export type AllAppraisalQueryHookResult = ReturnType<typeof useAllAppraisalQuery>;
export type AllAppraisalLazyQueryHookResult = ReturnType<typeof useAllAppraisalLazyQuery>;
export type AllAppraisalQueryResult = Apollo.QueryResult<AllAppraisalQuery, AllAppraisalQueryVariables>;
export const AllContractFormsDocument = gql`
    query allContractForms($filters: FormFiltersInput!, $pagination: PaginationArg, $sort: [String]) {
  forms(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...Form
    }
    meta {
      ...Meta
    }
  }
}
    ${FormFragmentDoc}
${PublicFormFragmentDoc}
${FileFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useAllContractFormsQuery(baseOptions: Apollo.QueryHookOptions<AllContractFormsQuery, AllContractFormsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllContractFormsQuery, AllContractFormsQueryVariables>(AllContractFormsDocument, options);
      }
export function useAllContractFormsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllContractFormsQuery, AllContractFormsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllContractFormsQuery, AllContractFormsQueryVariables>(AllContractFormsDocument, options);
        }
export type AllContractFormsQueryHookResult = ReturnType<typeof useAllContractFormsQuery>;
export type AllContractFormsLazyQueryHookResult = ReturnType<typeof useAllContractFormsLazyQuery>;
export type AllContractFormsQueryResult = Apollo.QueryResult<AllContractFormsQuery, AllContractFormsQueryVariables>;
export const AllContractTemplatesDocument = gql`
    query allContractTemplates($filters: ContractTemplateFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  contractTemplates(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...ContractTemplate
    }
    meta {
      ...Meta
    }
  }
}
    ${ContractTemplateFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useAllContractTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<AllContractTemplatesQuery, AllContractTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllContractTemplatesQuery, AllContractTemplatesQueryVariables>(AllContractTemplatesDocument, options);
      }
export function useAllContractTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllContractTemplatesQuery, AllContractTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllContractTemplatesQuery, AllContractTemplatesQueryVariables>(AllContractTemplatesDocument, options);
        }
export type AllContractTemplatesQueryHookResult = ReturnType<typeof useAllContractTemplatesQuery>;
export type AllContractTemplatesLazyQueryHookResult = ReturnType<typeof useAllContractTemplatesLazyQuery>;
export type AllContractTemplatesQueryResult = Apollo.QueryResult<AllContractTemplatesQuery, AllContractTemplatesQueryVariables>;
export const AllContractsDocument = gql`
    query allContracts($filters: ContractFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  contracts(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...Contract
    }
    meta {
      ...Meta
    }
  }
}
    ${ContractFragmentDoc}
${ContactFragmentDoc}
${ContactMinFragmentDoc}
${FileFragmentDoc}
${ContactDynamicValuesFragmentDoc}
${LeadFragmentDoc}
${LeadMinFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${BusinessLocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}
${CompanyMinFragmentDoc}
${PublicContractFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useAllContractsQuery(baseOptions?: Apollo.QueryHookOptions<AllContractsQuery, AllContractsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllContractsQuery, AllContractsQueryVariables>(AllContractsDocument, options);
      }
export function useAllContractsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllContractsQuery, AllContractsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllContractsQuery, AllContractsQueryVariables>(AllContractsDocument, options);
        }
export type AllContractsQueryHookResult = ReturnType<typeof useAllContractsQuery>;
export type AllContractsLazyQueryHookResult = ReturnType<typeof useAllContractsLazyQuery>;
export type AllContractsQueryResult = Apollo.QueryResult<AllContractsQuery, AllContractsQueryVariables>;
export const AllFormTemplatesDocument = gql`
    query allFormTemplates($filters: FormTemplateFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  formTemplates(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...FormTemplate
    }
    meta {
      ...Meta
    }
  }
}
    ${FormTemplateFragmentDoc}
${FileFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useAllFormTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<AllFormTemplatesQuery, AllFormTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllFormTemplatesQuery, AllFormTemplatesQueryVariables>(AllFormTemplatesDocument, options);
      }
export function useAllFormTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllFormTemplatesQuery, AllFormTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllFormTemplatesQuery, AllFormTemplatesQueryVariables>(AllFormTemplatesDocument, options);
        }
export type AllFormTemplatesQueryHookResult = ReturnType<typeof useAllFormTemplatesQuery>;
export type AllFormTemplatesLazyQueryHookResult = ReturnType<typeof useAllFormTemplatesLazyQuery>;
export type AllFormTemplatesQueryResult = Apollo.QueryResult<AllFormTemplatesQuery, AllFormTemplatesQueryVariables>;
export const CreateConversationDocument = gql`
    mutation createConversation($input: ConversationInput!) {
  createConversation(data: $input) {
    data {
      ...Conversation
    }
  }
}
    ${ConversationFragmentDoc}
${ConversationMinFragmentDoc}
${ContactFragmentDoc}
${ContactMinFragmentDoc}
${FileFragmentDoc}
${ContactDynamicValuesFragmentDoc}
${LeadFragmentDoc}
${LeadMinFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${BusinessLocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}
${CompanyMinFragmentDoc}
${CompanyFragmentDoc}
${ChatNotificationMinFragmentDoc}`;
export type CreateConversationMutationFn = Apollo.MutationFunction<CreateConversationMutation, CreateConversationMutationVariables>;
export function useCreateConversationMutation(baseOptions?: Apollo.MutationHookOptions<CreateConversationMutation, CreateConversationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateConversationMutation, CreateConversationMutationVariables>(CreateConversationDocument, options);
      }
export type CreateConversationMutationHookResult = ReturnType<typeof useCreateConversationMutation>;
export type CreateConversationMutationResult = Apollo.MutationResult<CreateConversationMutation>;
export const CreateNewMessageDocument = gql`
    mutation createNewMessage($input: ConversationMessageInput!) {
  createNewMessage(input: $input)
}
    `;
export type CreateNewMessageMutationFn = Apollo.MutationFunction<CreateNewMessageMutation, CreateNewMessageMutationVariables>;
export function useCreateNewMessageMutation(baseOptions?: Apollo.MutationHookOptions<CreateNewMessageMutation, CreateNewMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNewMessageMutation, CreateNewMessageMutationVariables>(CreateNewMessageDocument, options);
      }
export type CreateNewMessageMutationHookResult = ReturnType<typeof useCreateNewMessageMutation>;
export type CreateNewMessageMutationResult = Apollo.MutationResult<CreateNewMessageMutation>;
export const DeleteConversationDocument = gql`
    mutation deleteConversation($id: ID!) {
  deleteConversation(id: $id) {
    data {
      ...Conversation
    }
  }
}
    ${ConversationFragmentDoc}
${ConversationMinFragmentDoc}
${ContactFragmentDoc}
${ContactMinFragmentDoc}
${FileFragmentDoc}
${ContactDynamicValuesFragmentDoc}
${LeadFragmentDoc}
${LeadMinFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${BusinessLocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}
${CompanyMinFragmentDoc}
${CompanyFragmentDoc}
${ChatNotificationMinFragmentDoc}`;
export type DeleteConversationMutationFn = Apollo.MutationFunction<DeleteConversationMutation, DeleteConversationMutationVariables>;
export function useDeleteConversationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteConversationMutation, DeleteConversationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteConversationMutation, DeleteConversationMutationVariables>(DeleteConversationDocument, options);
      }
export type DeleteConversationMutationHookResult = ReturnType<typeof useDeleteConversationMutation>;
export type DeleteConversationMutationResult = Apollo.MutationResult<DeleteConversationMutation>;
export const StopScheduledMessageDocument = gql`
    mutation stopScheduledMessage($conversationUuid: String!, $scheduledMessageId: String!) {
  stopScheduledMessage(
    conversationUuid: $conversationUuid
    scheduledMessageId: $scheduledMessageId
  )
}
    `;
export type StopScheduledMessageMutationFn = Apollo.MutationFunction<StopScheduledMessageMutation, StopScheduledMessageMutationVariables>;
export function useStopScheduledMessageMutation(baseOptions?: Apollo.MutationHookOptions<StopScheduledMessageMutation, StopScheduledMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StopScheduledMessageMutation, StopScheduledMessageMutationVariables>(StopScheduledMessageDocument, options);
      }
export type StopScheduledMessageMutationHookResult = ReturnType<typeof useStopScheduledMessageMutation>;
export type StopScheduledMessageMutationResult = Apollo.MutationResult<StopScheduledMessageMutation>;
export const UpdateConversationDocument = gql`
    mutation updateConversation($id: ID!, $input: ConversationInput!) {
  updateConversation(id: $id, data: $input) {
    data {
      ...Conversation
    }
  }
}
    ${ConversationFragmentDoc}
${ConversationMinFragmentDoc}
${ContactFragmentDoc}
${ContactMinFragmentDoc}
${FileFragmentDoc}
${ContactDynamicValuesFragmentDoc}
${LeadFragmentDoc}
${LeadMinFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${BusinessLocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}
${CompanyMinFragmentDoc}
${CompanyFragmentDoc}
${ChatNotificationMinFragmentDoc}`;
export type UpdateConversationMutationFn = Apollo.MutationFunction<UpdateConversationMutation, UpdateConversationMutationVariables>;
export function useUpdateConversationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateConversationMutation, UpdateConversationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateConversationMutation, UpdateConversationMutationVariables>(UpdateConversationDocument, options);
      }
export type UpdateConversationMutationHookResult = ReturnType<typeof useUpdateConversationMutation>;
export type UpdateConversationMutationResult = Apollo.MutationResult<UpdateConversationMutation>;
export const AccessTokenDocument = gql`
    query accessToken($uuid: String!) {
  getChatAccessToken(uuid: $uuid) {
    token
  }
}
    `;
export function useAccessTokenQuery(baseOptions: Apollo.QueryHookOptions<AccessTokenQuery, AccessTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccessTokenQuery, AccessTokenQueryVariables>(AccessTokenDocument, options);
      }
export function useAccessTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccessTokenQuery, AccessTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccessTokenQuery, AccessTokenQueryVariables>(AccessTokenDocument, options);
        }
export type AccessTokenQueryHookResult = ReturnType<typeof useAccessTokenQuery>;
export type AccessTokenLazyQueryHookResult = ReturnType<typeof useAccessTokenLazyQuery>;
export type AccessTokenQueryResult = Apollo.QueryResult<AccessTokenQuery, AccessTokenQueryVariables>;
export const ConversationDocument = gql`
    query conversation($id: ID!) {
  conversation(id: $id) {
    data {
      ...Conversation
    }
  }
}
    ${ConversationFragmentDoc}
${ConversationMinFragmentDoc}
${ContactFragmentDoc}
${ContactMinFragmentDoc}
${FileFragmentDoc}
${ContactDynamicValuesFragmentDoc}
${LeadFragmentDoc}
${LeadMinFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${BusinessLocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}
${CompanyMinFragmentDoc}
${CompanyFragmentDoc}
${ChatNotificationMinFragmentDoc}`;
export function useConversationQuery(baseOptions: Apollo.QueryHookOptions<ConversationQuery, ConversationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConversationQuery, ConversationQueryVariables>(ConversationDocument, options);
      }
export function useConversationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConversationQuery, ConversationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConversationQuery, ConversationQueryVariables>(ConversationDocument, options);
        }
export type ConversationQueryHookResult = ReturnType<typeof useConversationQuery>;
export type ConversationLazyQueryHookResult = ReturnType<typeof useConversationLazyQuery>;
export type ConversationQueryResult = Apollo.QueryResult<ConversationQuery, ConversationQueryVariables>;
export const ConversationMessagesDocument = gql`
    query conversationMessages($uuid: String!, $limit: Int!) {
  getConversationMessages(uuid: $uuid, limit: $limit) {
    messages
    scheduledMessages
    type
  }
}
    `;
export function useConversationMessagesQuery(baseOptions: Apollo.QueryHookOptions<ConversationMessagesQuery, ConversationMessagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConversationMessagesQuery, ConversationMessagesQueryVariables>(ConversationMessagesDocument, options);
      }
export function useConversationMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConversationMessagesQuery, ConversationMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConversationMessagesQuery, ConversationMessagesQueryVariables>(ConversationMessagesDocument, options);
        }
export type ConversationMessagesQueryHookResult = ReturnType<typeof useConversationMessagesQuery>;
export type ConversationMessagesLazyQueryHookResult = ReturnType<typeof useConversationMessagesLazyQuery>;
export type ConversationMessagesQueryResult = Apollo.QueryResult<ConversationMessagesQuery, ConversationMessagesQueryVariables>;
export const ConversationsDocument = gql`
    query conversations($filters: ConversationFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  conversations(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...Conversation
    }
    meta {
      ...Meta
    }
  }
}
    ${ConversationFragmentDoc}
${ConversationMinFragmentDoc}
${ContactFragmentDoc}
${ContactMinFragmentDoc}
${FileFragmentDoc}
${ContactDynamicValuesFragmentDoc}
${LeadFragmentDoc}
${LeadMinFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${BusinessLocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}
${CompanyMinFragmentDoc}
${CompanyFragmentDoc}
${ChatNotificationMinFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useConversationsQuery(baseOptions?: Apollo.QueryHookOptions<ConversationsQuery, ConversationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConversationsQuery, ConversationsQueryVariables>(ConversationsDocument, options);
      }
export function useConversationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConversationsQuery, ConversationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConversationsQuery, ConversationsQueryVariables>(ConversationsDocument, options);
        }
export type ConversationsQueryHookResult = ReturnType<typeof useConversationsQuery>;
export type ConversationsLazyQueryHookResult = ReturnType<typeof useConversationsLazyQuery>;
export type ConversationsQueryResult = Apollo.QueryResult<ConversationsQuery, ConversationsQueryVariables>;
export const CreateCrmCustomFieldNameDocument = gql`
    mutation createCrmCustomFieldName($input: CrmCustomFieldNameInput!) {
  createCrmCustomFieldName(data: $input) {
    data {
      ...CrmCustomFieldName
    }
  }
}
    ${CrmCustomFieldNameFragmentDoc}
${CrmCustomFieldNameMinFragmentDoc}`;
export type CreateCrmCustomFieldNameMutationFn = Apollo.MutationFunction<CreateCrmCustomFieldNameMutation, CreateCrmCustomFieldNameMutationVariables>;
export function useCreateCrmCustomFieldNameMutation(baseOptions?: Apollo.MutationHookOptions<CreateCrmCustomFieldNameMutation, CreateCrmCustomFieldNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCrmCustomFieldNameMutation, CreateCrmCustomFieldNameMutationVariables>(CreateCrmCustomFieldNameDocument, options);
      }
export type CreateCrmCustomFieldNameMutationHookResult = ReturnType<typeof useCreateCrmCustomFieldNameMutation>;
export type CreateCrmCustomFieldNameMutationResult = Apollo.MutationResult<CreateCrmCustomFieldNameMutation>;
export const DeleteCrmCustomFieldNameDocument = gql`
    mutation deleteCrmCustomFieldName($id: ID!) {
  deleteCrmCustomFieldName(id: $id) {
    data {
      ...CrmCustomFieldName
    }
  }
}
    ${CrmCustomFieldNameFragmentDoc}
${CrmCustomFieldNameMinFragmentDoc}`;
export type DeleteCrmCustomFieldNameMutationFn = Apollo.MutationFunction<DeleteCrmCustomFieldNameMutation, DeleteCrmCustomFieldNameMutationVariables>;
export function useDeleteCrmCustomFieldNameMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCrmCustomFieldNameMutation, DeleteCrmCustomFieldNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCrmCustomFieldNameMutation, DeleteCrmCustomFieldNameMutationVariables>(DeleteCrmCustomFieldNameDocument, options);
      }
export type DeleteCrmCustomFieldNameMutationHookResult = ReturnType<typeof useDeleteCrmCustomFieldNameMutation>;
export type DeleteCrmCustomFieldNameMutationResult = Apollo.MutationResult<DeleteCrmCustomFieldNameMutation>;
export const UpdateCrmCustomFieldNameDocument = gql`
    mutation updateCrmCustomFieldName($id: ID!, $input: CrmCustomFieldNameInput!) {
  updateCrmCustomFieldName(id: $id, data: $input) {
    data {
      ...CrmCustomFieldName
    }
  }
}
    ${CrmCustomFieldNameFragmentDoc}
${CrmCustomFieldNameMinFragmentDoc}`;
export type UpdateCrmCustomFieldNameMutationFn = Apollo.MutationFunction<UpdateCrmCustomFieldNameMutation, UpdateCrmCustomFieldNameMutationVariables>;
export function useUpdateCrmCustomFieldNameMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCrmCustomFieldNameMutation, UpdateCrmCustomFieldNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCrmCustomFieldNameMutation, UpdateCrmCustomFieldNameMutationVariables>(UpdateCrmCustomFieldNameDocument, options);
      }
export type UpdateCrmCustomFieldNameMutationHookResult = ReturnType<typeof useUpdateCrmCustomFieldNameMutation>;
export type UpdateCrmCustomFieldNameMutationResult = Apollo.MutationResult<UpdateCrmCustomFieldNameMutation>;
export const CrmCustomFieldNameDocument = gql`
    query crmCustomFieldName($id: ID!) {
  crmCustomFieldName(id: $id) {
    data {
      ...CrmCustomFieldName
    }
  }
}
    ${CrmCustomFieldNameFragmentDoc}
${CrmCustomFieldNameMinFragmentDoc}`;
export function useCrmCustomFieldNameQuery(baseOptions: Apollo.QueryHookOptions<CrmCustomFieldNameQuery, CrmCustomFieldNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CrmCustomFieldNameQuery, CrmCustomFieldNameQueryVariables>(CrmCustomFieldNameDocument, options);
      }
export function useCrmCustomFieldNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CrmCustomFieldNameQuery, CrmCustomFieldNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CrmCustomFieldNameQuery, CrmCustomFieldNameQueryVariables>(CrmCustomFieldNameDocument, options);
        }
export type CrmCustomFieldNameQueryHookResult = ReturnType<typeof useCrmCustomFieldNameQuery>;
export type CrmCustomFieldNameLazyQueryHookResult = ReturnType<typeof useCrmCustomFieldNameLazyQuery>;
export type CrmCustomFieldNameQueryResult = Apollo.QueryResult<CrmCustomFieldNameQuery, CrmCustomFieldNameQueryVariables>;
export const CrmCustomFieldNamesDocument = gql`
    query crmCustomFieldNames($filters: CrmCustomFieldNameFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  crmCustomFieldNames(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...CrmCustomFieldName
    }
    meta {
      ...Meta
    }
  }
}
    ${CrmCustomFieldNameFragmentDoc}
${CrmCustomFieldNameMinFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useCrmCustomFieldNamesQuery(baseOptions?: Apollo.QueryHookOptions<CrmCustomFieldNamesQuery, CrmCustomFieldNamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CrmCustomFieldNamesQuery, CrmCustomFieldNamesQueryVariables>(CrmCustomFieldNamesDocument, options);
      }
export function useCrmCustomFieldNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CrmCustomFieldNamesQuery, CrmCustomFieldNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CrmCustomFieldNamesQuery, CrmCustomFieldNamesQueryVariables>(CrmCustomFieldNamesDocument, options);
        }
export type CrmCustomFieldNamesQueryHookResult = ReturnType<typeof useCrmCustomFieldNamesQuery>;
export type CrmCustomFieldNamesLazyQueryHookResult = ReturnType<typeof useCrmCustomFieldNamesLazyQuery>;
export type CrmCustomFieldNamesQueryResult = Apollo.QueryResult<CrmCustomFieldNamesQuery, CrmCustomFieldNamesQueryVariables>;
export const CreateCrmCustomFieldValueDocument = gql`
    mutation createCrmCustomFieldValue($input: CrmCustomFieldValueInput!) {
  createCrmCustomFieldValue(data: $input) {
    data {
      ...CrmCustomFieldValue
    }
  }
}
    ${CrmCustomFieldValueFragmentDoc}
${CrmCustomFieldValueMinFragmentDoc}`;
export type CreateCrmCustomFieldValueMutationFn = Apollo.MutationFunction<CreateCrmCustomFieldValueMutation, CreateCrmCustomFieldValueMutationVariables>;
export function useCreateCrmCustomFieldValueMutation(baseOptions?: Apollo.MutationHookOptions<CreateCrmCustomFieldValueMutation, CreateCrmCustomFieldValueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCrmCustomFieldValueMutation, CreateCrmCustomFieldValueMutationVariables>(CreateCrmCustomFieldValueDocument, options);
      }
export type CreateCrmCustomFieldValueMutationHookResult = ReturnType<typeof useCreateCrmCustomFieldValueMutation>;
export type CreateCrmCustomFieldValueMutationResult = Apollo.MutationResult<CreateCrmCustomFieldValueMutation>;
export const DeleteCrmCustomFieldValueDocument = gql`
    mutation deleteCrmCustomFieldValue($id: ID!) {
  deleteCrmCustomFieldValue(id: $id) {
    data {
      ...CrmCustomFieldValue
    }
  }
}
    ${CrmCustomFieldValueFragmentDoc}
${CrmCustomFieldValueMinFragmentDoc}`;
export type DeleteCrmCustomFieldValueMutationFn = Apollo.MutationFunction<DeleteCrmCustomFieldValueMutation, DeleteCrmCustomFieldValueMutationVariables>;
export function useDeleteCrmCustomFieldValueMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCrmCustomFieldValueMutation, DeleteCrmCustomFieldValueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCrmCustomFieldValueMutation, DeleteCrmCustomFieldValueMutationVariables>(DeleteCrmCustomFieldValueDocument, options);
      }
export type DeleteCrmCustomFieldValueMutationHookResult = ReturnType<typeof useDeleteCrmCustomFieldValueMutation>;
export type DeleteCrmCustomFieldValueMutationResult = Apollo.MutationResult<DeleteCrmCustomFieldValueMutation>;
export const UpdateCrmCustomFieldValueDocument = gql`
    mutation updateCrmCustomFieldValue($id: ID!, $input: CrmCustomFieldValueInput!) {
  updateCrmCustomFieldValue(id: $id, data: $input) {
    data {
      ...CrmCustomFieldValue
    }
  }
}
    ${CrmCustomFieldValueFragmentDoc}
${CrmCustomFieldValueMinFragmentDoc}`;
export type UpdateCrmCustomFieldValueMutationFn = Apollo.MutationFunction<UpdateCrmCustomFieldValueMutation, UpdateCrmCustomFieldValueMutationVariables>;
export function useUpdateCrmCustomFieldValueMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCrmCustomFieldValueMutation, UpdateCrmCustomFieldValueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCrmCustomFieldValueMutation, UpdateCrmCustomFieldValueMutationVariables>(UpdateCrmCustomFieldValueDocument, options);
      }
export type UpdateCrmCustomFieldValueMutationHookResult = ReturnType<typeof useUpdateCrmCustomFieldValueMutation>;
export type UpdateCrmCustomFieldValueMutationResult = Apollo.MutationResult<UpdateCrmCustomFieldValueMutation>;
export const CrmCustomFieldValueDocument = gql`
    query crmCustomFieldValue($id: ID!) {
  crmCustomFieldValue(id: $id) {
    data {
      ...CrmCustomFieldValue
    }
  }
}
    ${CrmCustomFieldValueFragmentDoc}
${CrmCustomFieldValueMinFragmentDoc}`;
export function useCrmCustomFieldValueQuery(baseOptions: Apollo.QueryHookOptions<CrmCustomFieldValueQuery, CrmCustomFieldValueQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CrmCustomFieldValueQuery, CrmCustomFieldValueQueryVariables>(CrmCustomFieldValueDocument, options);
      }
export function useCrmCustomFieldValueLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CrmCustomFieldValueQuery, CrmCustomFieldValueQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CrmCustomFieldValueQuery, CrmCustomFieldValueQueryVariables>(CrmCustomFieldValueDocument, options);
        }
export type CrmCustomFieldValueQueryHookResult = ReturnType<typeof useCrmCustomFieldValueQuery>;
export type CrmCustomFieldValueLazyQueryHookResult = ReturnType<typeof useCrmCustomFieldValueLazyQuery>;
export type CrmCustomFieldValueQueryResult = Apollo.QueryResult<CrmCustomFieldValueQuery, CrmCustomFieldValueQueryVariables>;
export const CrmCustomFieldValuesDocument = gql`
    query crmCustomFieldValues($filters: CrmCustomFieldValueFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  crmCustomFieldValues(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...CrmCustomFieldValue
    }
    meta {
      ...Meta
    }
  }
}
    ${CrmCustomFieldValueFragmentDoc}
${CrmCustomFieldValueMinFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useCrmCustomFieldValuesQuery(baseOptions?: Apollo.QueryHookOptions<CrmCustomFieldValuesQuery, CrmCustomFieldValuesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CrmCustomFieldValuesQuery, CrmCustomFieldValuesQueryVariables>(CrmCustomFieldValuesDocument, options);
      }
export function useCrmCustomFieldValuesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CrmCustomFieldValuesQuery, CrmCustomFieldValuesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CrmCustomFieldValuesQuery, CrmCustomFieldValuesQueryVariables>(CrmCustomFieldValuesDocument, options);
        }
export type CrmCustomFieldValuesQueryHookResult = ReturnType<typeof useCrmCustomFieldValuesQuery>;
export type CrmCustomFieldValuesLazyQueryHookResult = ReturnType<typeof useCrmCustomFieldValuesLazyQuery>;
export type CrmCustomFieldValuesQueryResult = Apollo.QueryResult<CrmCustomFieldValuesQuery, CrmCustomFieldValuesQueryVariables>;
export const CreateCustomPermissionDocument = gql`
    mutation createCustomPermission($input: CustomPermissionInput!) {
  createCustomPermission(data: $input) {
    data {
      ...CustomPermission
    }
  }
}
    ${CustomPermissionFragmentDoc}
${CustomPermissionMinFragmentDoc}
${TenantFragmentDoc}
${FileFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}`;
export type CreateCustomPermissionMutationFn = Apollo.MutationFunction<CreateCustomPermissionMutation, CreateCustomPermissionMutationVariables>;
export function useCreateCustomPermissionMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomPermissionMutation, CreateCustomPermissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCustomPermissionMutation, CreateCustomPermissionMutationVariables>(CreateCustomPermissionDocument, options);
      }
export type CreateCustomPermissionMutationHookResult = ReturnType<typeof useCreateCustomPermissionMutation>;
export type CreateCustomPermissionMutationResult = Apollo.MutationResult<CreateCustomPermissionMutation>;
export const DeleteCustomPermissionDocument = gql`
    mutation deleteCustomPermission($id: ID!) {
  deleteCustomPermission(id: $id) {
    data {
      ...CustomPermission
    }
  }
}
    ${CustomPermissionFragmentDoc}
${CustomPermissionMinFragmentDoc}
${TenantFragmentDoc}
${FileFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}`;
export type DeleteCustomPermissionMutationFn = Apollo.MutationFunction<DeleteCustomPermissionMutation, DeleteCustomPermissionMutationVariables>;
export function useDeleteCustomPermissionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCustomPermissionMutation, DeleteCustomPermissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCustomPermissionMutation, DeleteCustomPermissionMutationVariables>(DeleteCustomPermissionDocument, options);
      }
export type DeleteCustomPermissionMutationHookResult = ReturnType<typeof useDeleteCustomPermissionMutation>;
export type DeleteCustomPermissionMutationResult = Apollo.MutationResult<DeleteCustomPermissionMutation>;
export const UpdateCustomPermissionDocument = gql`
    mutation updateCustomPermission($id: ID!, $input: CustomPermissionInput!) {
  updateCustomPermission(id: $id, data: $input) {
    data {
      ...CustomPermission
    }
  }
}
    ${CustomPermissionFragmentDoc}
${CustomPermissionMinFragmentDoc}
${TenantFragmentDoc}
${FileFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}`;
export type UpdateCustomPermissionMutationFn = Apollo.MutationFunction<UpdateCustomPermissionMutation, UpdateCustomPermissionMutationVariables>;
export function useUpdateCustomPermissionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomPermissionMutation, UpdateCustomPermissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomPermissionMutation, UpdateCustomPermissionMutationVariables>(UpdateCustomPermissionDocument, options);
      }
export type UpdateCustomPermissionMutationHookResult = ReturnType<typeof useUpdateCustomPermissionMutation>;
export type UpdateCustomPermissionMutationResult = Apollo.MutationResult<UpdateCustomPermissionMutation>;
export const CustomPermissionDocument = gql`
    query customPermission($id: ID!) {
  customPermission(id: $id) {
    data {
      ...CustomPermission
    }
  }
}
    ${CustomPermissionFragmentDoc}
${CustomPermissionMinFragmentDoc}
${TenantFragmentDoc}
${FileFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}`;
export function useCustomPermissionQuery(baseOptions: Apollo.QueryHookOptions<CustomPermissionQuery, CustomPermissionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomPermissionQuery, CustomPermissionQueryVariables>(CustomPermissionDocument, options);
      }
export function useCustomPermissionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomPermissionQuery, CustomPermissionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomPermissionQuery, CustomPermissionQueryVariables>(CustomPermissionDocument, options);
        }
export type CustomPermissionQueryHookResult = ReturnType<typeof useCustomPermissionQuery>;
export type CustomPermissionLazyQueryHookResult = ReturnType<typeof useCustomPermissionLazyQuery>;
export type CustomPermissionQueryResult = Apollo.QueryResult<CustomPermissionQuery, CustomPermissionQueryVariables>;
export const CustomPermissionsDocument = gql`
    query customPermissions($filters: CustomPermissionFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  customPermissions(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...CustomPermission
    }
    meta {
      ...Meta
    }
  }
}
    ${CustomPermissionFragmentDoc}
${CustomPermissionMinFragmentDoc}
${TenantFragmentDoc}
${FileFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useCustomPermissionsQuery(baseOptions?: Apollo.QueryHookOptions<CustomPermissionsQuery, CustomPermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomPermissionsQuery, CustomPermissionsQueryVariables>(CustomPermissionsDocument, options);
      }
export function useCustomPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomPermissionsQuery, CustomPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomPermissionsQuery, CustomPermissionsQueryVariables>(CustomPermissionsDocument, options);
        }
export type CustomPermissionsQueryHookResult = ReturnType<typeof useCustomPermissionsQuery>;
export type CustomPermissionsLazyQueryHookResult = ReturnType<typeof useCustomPermissionsLazyQuery>;
export type CustomPermissionsQueryResult = Apollo.QueryResult<CustomPermissionsQuery, CustomPermissionsQueryVariables>;
export const CreateDealDocument = gql`
    mutation createDeal($input: DealInput!) {
  createDeal(data: $input) {
    data {
      ...Deal
    }
  }
}
    ${DealFragmentDoc}
${DealMinFragmentDoc}
${ProductFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${SerializeFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${LeadFragmentDoc}
${LeadMinFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ContactMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}
${ContactFragmentDoc}
${ContactDynamicValuesFragmentDoc}
${CompanyFragmentDoc}`;
export type CreateDealMutationFn = Apollo.MutationFunction<CreateDealMutation, CreateDealMutationVariables>;
export function useCreateDealMutation(baseOptions?: Apollo.MutationHookOptions<CreateDealMutation, CreateDealMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDealMutation, CreateDealMutationVariables>(CreateDealDocument, options);
      }
export type CreateDealMutationHookResult = ReturnType<typeof useCreateDealMutation>;
export type CreateDealMutationResult = Apollo.MutationResult<CreateDealMutation>;
export const DeleteDealDocument = gql`
    mutation deleteDeal($id: ID!) {
  deleteDeal(id: $id) {
    data {
      ...Deal
    }
  }
}
    ${DealFragmentDoc}
${DealMinFragmentDoc}
${ProductFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${SerializeFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${LeadFragmentDoc}
${LeadMinFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ContactMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}
${ContactFragmentDoc}
${ContactDynamicValuesFragmentDoc}
${CompanyFragmentDoc}`;
export type DeleteDealMutationFn = Apollo.MutationFunction<DeleteDealMutation, DeleteDealMutationVariables>;
export function useDeleteDealMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDealMutation, DeleteDealMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDealMutation, DeleteDealMutationVariables>(DeleteDealDocument, options);
      }
export type DeleteDealMutationHookResult = ReturnType<typeof useDeleteDealMutation>;
export type DeleteDealMutationResult = Apollo.MutationResult<DeleteDealMutation>;
export const UpdateDealDocument = gql`
    mutation updateDeal($id: ID!, $input: DealInput!) {
  updateDeal(id: $id, data: $input) {
    data {
      ...Deal
    }
  }
}
    ${DealFragmentDoc}
${DealMinFragmentDoc}
${ProductFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${SerializeFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${LeadFragmentDoc}
${LeadMinFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ContactMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}
${ContactFragmentDoc}
${ContactDynamicValuesFragmentDoc}
${CompanyFragmentDoc}`;
export type UpdateDealMutationFn = Apollo.MutationFunction<UpdateDealMutation, UpdateDealMutationVariables>;
export function useUpdateDealMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDealMutation, UpdateDealMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDealMutation, UpdateDealMutationVariables>(UpdateDealDocument, options);
      }
export type UpdateDealMutationHookResult = ReturnType<typeof useUpdateDealMutation>;
export type UpdateDealMutationResult = Apollo.MutationResult<UpdateDealMutation>;
export const DealDocument = gql`
    query deal($id: ID!) {
  deal(id: $id) {
    data {
      ...Deal
    }
  }
}
    ${DealFragmentDoc}
${DealMinFragmentDoc}
${ProductFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${SerializeFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${LeadFragmentDoc}
${LeadMinFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ContactMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}
${ContactFragmentDoc}
${ContactDynamicValuesFragmentDoc}
${CompanyFragmentDoc}`;
export function useDealQuery(baseOptions: Apollo.QueryHookOptions<DealQuery, DealQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DealQuery, DealQueryVariables>(DealDocument, options);
      }
export function useDealLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DealQuery, DealQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DealQuery, DealQueryVariables>(DealDocument, options);
        }
export type DealQueryHookResult = ReturnType<typeof useDealQuery>;
export type DealLazyQueryHookResult = ReturnType<typeof useDealLazyQuery>;
export type DealQueryResult = Apollo.QueryResult<DealQuery, DealQueryVariables>;
export const DealsDocument = gql`
    query deals($filters: DealFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  deals(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...Deal
    }
    meta {
      ...Meta
    }
  }
}
    ${DealFragmentDoc}
${DealMinFragmentDoc}
${ProductFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${SerializeFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${LeadFragmentDoc}
${LeadMinFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ContactMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}
${ContactFragmentDoc}
${ContactDynamicValuesFragmentDoc}
${CompanyFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useDealsQuery(baseOptions?: Apollo.QueryHookOptions<DealsQuery, DealsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DealsQuery, DealsQueryVariables>(DealsDocument, options);
      }
export function useDealsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DealsQuery, DealsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DealsQuery, DealsQueryVariables>(DealsDocument, options);
        }
export type DealsQueryHookResult = ReturnType<typeof useDealsQuery>;
export type DealsLazyQueryHookResult = ReturnType<typeof useDealsLazyQuery>;
export type DealsQueryResult = Apollo.QueryResult<DealsQuery, DealsQueryVariables>;
export const CreateDealTransactionDocument = gql`
    mutation createDealTransaction($input: DealTransactionInput!) {
  createDealTransaction(data: $input) {
    data {
      ...DealTransaction
    }
  }
}
    ${DealTransactionFragmentDoc}
${DealTransactionMinFragmentDoc}
${OrderMinFragmentDoc}
${ContactFragmentDoc}
${ContactMinFragmentDoc}
${FileFragmentDoc}
${ContactDynamicValuesFragmentDoc}
${LeadFragmentDoc}
${LeadMinFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${BusinessLocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}
${CompanyMinFragmentDoc}
${ChartAccountFragmentDoc}
${ChartAccountMinFragmentDoc}
${ChartCategoryFragmentDoc}
${ChartCategoryMinFragmentDoc}
${ChartSubcategoryMinFragmentDoc}
${ChartSubcategoryFragmentDoc}
${CompanyFragmentDoc}`;
export type CreateDealTransactionMutationFn = Apollo.MutationFunction<CreateDealTransactionMutation, CreateDealTransactionMutationVariables>;
export function useCreateDealTransactionMutation(baseOptions?: Apollo.MutationHookOptions<CreateDealTransactionMutation, CreateDealTransactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDealTransactionMutation, CreateDealTransactionMutationVariables>(CreateDealTransactionDocument, options);
      }
export type CreateDealTransactionMutationHookResult = ReturnType<typeof useCreateDealTransactionMutation>;
export type CreateDealTransactionMutationResult = Apollo.MutationResult<CreateDealTransactionMutation>;
export const CreateDealTransactionInventoryShrinkageDocument = gql`
    mutation createDealTransactionInventoryShrinkage($input: CreateDealTransactionInventoryShrinkageInput!) {
  createDealTransactionInventoryShrinkage(input: $input) {
    status
  }
}
    `;
export type CreateDealTransactionInventoryShrinkageMutationFn = Apollo.MutationFunction<CreateDealTransactionInventoryShrinkageMutation, CreateDealTransactionInventoryShrinkageMutationVariables>;
export function useCreateDealTransactionInventoryShrinkageMutation(baseOptions?: Apollo.MutationHookOptions<CreateDealTransactionInventoryShrinkageMutation, CreateDealTransactionInventoryShrinkageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDealTransactionInventoryShrinkageMutation, CreateDealTransactionInventoryShrinkageMutationVariables>(CreateDealTransactionInventoryShrinkageDocument, options);
      }
export type CreateDealTransactionInventoryShrinkageMutationHookResult = ReturnType<typeof useCreateDealTransactionInventoryShrinkageMutation>;
export type CreateDealTransactionInventoryShrinkageMutationResult = Apollo.MutationResult<CreateDealTransactionInventoryShrinkageMutation>;
export const DeleteDealTransactionDocument = gql`
    mutation deleteDealTransaction($id: ID!) {
  deleteDealTransaction(id: $id) {
    data {
      ...DealTransaction
    }
  }
}
    ${DealTransactionFragmentDoc}
${DealTransactionMinFragmentDoc}
${OrderMinFragmentDoc}
${ContactFragmentDoc}
${ContactMinFragmentDoc}
${FileFragmentDoc}
${ContactDynamicValuesFragmentDoc}
${LeadFragmentDoc}
${LeadMinFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${BusinessLocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}
${CompanyMinFragmentDoc}
${ChartAccountFragmentDoc}
${ChartAccountMinFragmentDoc}
${ChartCategoryFragmentDoc}
${ChartCategoryMinFragmentDoc}
${ChartSubcategoryMinFragmentDoc}
${ChartSubcategoryFragmentDoc}
${CompanyFragmentDoc}`;
export type DeleteDealTransactionMutationFn = Apollo.MutationFunction<DeleteDealTransactionMutation, DeleteDealTransactionMutationVariables>;
export function useDeleteDealTransactionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDealTransactionMutation, DeleteDealTransactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDealTransactionMutation, DeleteDealTransactionMutationVariables>(DeleteDealTransactionDocument, options);
      }
export type DeleteDealTransactionMutationHookResult = ReturnType<typeof useDeleteDealTransactionMutation>;
export type DeleteDealTransactionMutationResult = Apollo.MutationResult<DeleteDealTransactionMutation>;
export const RefundTransactionDocument = gql`
    mutation refundTransaction($input: RefundTransactionArgInput!) {
  refundTransaction(input: $input) {
    status
  }
}
    `;
export type RefundTransactionMutationFn = Apollo.MutationFunction<RefundTransactionMutation, RefundTransactionMutationVariables>;
export function useRefundTransactionMutation(baseOptions?: Apollo.MutationHookOptions<RefundTransactionMutation, RefundTransactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefundTransactionMutation, RefundTransactionMutationVariables>(RefundTransactionDocument, options);
      }
export type RefundTransactionMutationHookResult = ReturnType<typeof useRefundTransactionMutation>;
export type RefundTransactionMutationResult = Apollo.MutationResult<RefundTransactionMutation>;
export const RemoveCardDocument = gql`
    mutation removeCard($input: RemoveCardArgInput!) {
  removeCard(input: $input) {
    status
  }
}
    `;
export type RemoveCardMutationFn = Apollo.MutationFunction<RemoveCardMutation, RemoveCardMutationVariables>;
export function useRemoveCardMutation(baseOptions?: Apollo.MutationHookOptions<RemoveCardMutation, RemoveCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveCardMutation, RemoveCardMutationVariables>(RemoveCardDocument, options);
      }
export type RemoveCardMutationHookResult = ReturnType<typeof useRemoveCardMutation>;
export type RemoveCardMutationResult = Apollo.MutationResult<RemoveCardMutation>;
export const SendPaymentLinkDocument = gql`
    mutation sendPaymentLink($input: PaymentLinkEmailArgsInput!) {
  sendPaymentLink(input: $input) {
    status
  }
}
    `;
export type SendPaymentLinkMutationFn = Apollo.MutationFunction<SendPaymentLinkMutation, SendPaymentLinkMutationVariables>;
export function useSendPaymentLinkMutation(baseOptions?: Apollo.MutationHookOptions<SendPaymentLinkMutation, SendPaymentLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendPaymentLinkMutation, SendPaymentLinkMutationVariables>(SendPaymentLinkDocument, options);
      }
export type SendPaymentLinkMutationHookResult = ReturnType<typeof useSendPaymentLinkMutation>;
export type SendPaymentLinkMutationResult = Apollo.MutationResult<SendPaymentLinkMutation>;
export const UpdateDealTransactionDocument = gql`
    mutation updateDealTransaction($id: ID!, $input: DealTransactionInput!) {
  updateDealTransaction(id: $id, data: $input) {
    data {
      ...DealTransaction
    }
  }
}
    ${DealTransactionFragmentDoc}
${DealTransactionMinFragmentDoc}
${OrderMinFragmentDoc}
${ContactFragmentDoc}
${ContactMinFragmentDoc}
${FileFragmentDoc}
${ContactDynamicValuesFragmentDoc}
${LeadFragmentDoc}
${LeadMinFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${BusinessLocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}
${CompanyMinFragmentDoc}
${ChartAccountFragmentDoc}
${ChartAccountMinFragmentDoc}
${ChartCategoryFragmentDoc}
${ChartCategoryMinFragmentDoc}
${ChartSubcategoryMinFragmentDoc}
${ChartSubcategoryFragmentDoc}
${CompanyFragmentDoc}`;
export type UpdateDealTransactionMutationFn = Apollo.MutationFunction<UpdateDealTransactionMutation, UpdateDealTransactionMutationVariables>;
export function useUpdateDealTransactionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDealTransactionMutation, UpdateDealTransactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDealTransactionMutation, UpdateDealTransactionMutationVariables>(UpdateDealTransactionDocument, options);
      }
export type UpdateDealTransactionMutationHookResult = ReturnType<typeof useUpdateDealTransactionMutation>;
export type UpdateDealTransactionMutationResult = Apollo.MutationResult<UpdateDealTransactionMutation>;
export const CardTotalsDocument = gql`
    query cardTotals($data: CardTotalsArg!) {
  cardTotals(data: $data) {
    id
    name
    total
    percentage
    cardImg
    description
    type
    onCardClick
  }
}
    `;
export function useCardTotalsQuery(baseOptions: Apollo.QueryHookOptions<CardTotalsQuery, CardTotalsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CardTotalsQuery, CardTotalsQueryVariables>(CardTotalsDocument, options);
      }
export function useCardTotalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CardTotalsQuery, CardTotalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CardTotalsQuery, CardTotalsQueryVariables>(CardTotalsDocument, options);
        }
export type CardTotalsQueryHookResult = ReturnType<typeof useCardTotalsQuery>;
export type CardTotalsLazyQueryHookResult = ReturnType<typeof useCardTotalsLazyQuery>;
export type CardTotalsQueryResult = Apollo.QueryResult<CardTotalsQuery, CardTotalsQueryVariables>;
export const ChartsTimeTotalsWithLengthDocument = gql`
    query ChartsTimeTotalsWithLength($input: TimeTotalsArgs!) {
  chartsTimeTotalsWithLength(input: $input) {
    length
    chartsTimeTotals {
      id
      chartName
      chartType
      isParent
      monthTotals {
        amount
        month
      }
      total
      yearTotals {
        amount
        year
      }
    }
  }
}
    `;
export function useChartsTimeTotalsWithLengthQuery(baseOptions: Apollo.QueryHookOptions<ChartsTimeTotalsWithLengthQuery, ChartsTimeTotalsWithLengthQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChartsTimeTotalsWithLengthQuery, ChartsTimeTotalsWithLengthQueryVariables>(ChartsTimeTotalsWithLengthDocument, options);
      }
export function useChartsTimeTotalsWithLengthLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChartsTimeTotalsWithLengthQuery, ChartsTimeTotalsWithLengthQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChartsTimeTotalsWithLengthQuery, ChartsTimeTotalsWithLengthQueryVariables>(ChartsTimeTotalsWithLengthDocument, options);
        }
export type ChartsTimeTotalsWithLengthQueryHookResult = ReturnType<typeof useChartsTimeTotalsWithLengthQuery>;
export type ChartsTimeTotalsWithLengthLazyQueryHookResult = ReturnType<typeof useChartsTimeTotalsWithLengthLazyQuery>;
export type ChartsTimeTotalsWithLengthQueryResult = Apollo.QueryResult<ChartsTimeTotalsWithLengthQuery, ChartsTimeTotalsWithLengthQueryVariables>;
export const DealTransactionDocument = gql`
    query dealTransaction($id: ID!) {
  dealTransaction(id: $id) {
    data {
      ...DealTransaction
    }
  }
}
    ${DealTransactionFragmentDoc}
${DealTransactionMinFragmentDoc}
${OrderMinFragmentDoc}
${ContactFragmentDoc}
${ContactMinFragmentDoc}
${FileFragmentDoc}
${ContactDynamicValuesFragmentDoc}
${LeadFragmentDoc}
${LeadMinFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${BusinessLocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}
${CompanyMinFragmentDoc}
${ChartAccountFragmentDoc}
${ChartAccountMinFragmentDoc}
${ChartCategoryFragmentDoc}
${ChartCategoryMinFragmentDoc}
${ChartSubcategoryMinFragmentDoc}
${ChartSubcategoryFragmentDoc}
${CompanyFragmentDoc}`;
export function useDealTransactionQuery(baseOptions: Apollo.QueryHookOptions<DealTransactionQuery, DealTransactionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DealTransactionQuery, DealTransactionQueryVariables>(DealTransactionDocument, options);
      }
export function useDealTransactionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DealTransactionQuery, DealTransactionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DealTransactionQuery, DealTransactionQueryVariables>(DealTransactionDocument, options);
        }
export type DealTransactionQueryHookResult = ReturnType<typeof useDealTransactionQuery>;
export type DealTransactionLazyQueryHookResult = ReturnType<typeof useDealTransactionLazyQuery>;
export type DealTransactionQueryResult = Apollo.QueryResult<DealTransactionQuery, DealTransactionQueryVariables>;
export const DealTransactionsDocument = gql`
    query dealTransactions($filters: DealTransactionFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  dealTransactions(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...DealTransaction
    }
    meta {
      ...Meta
    }
  }
}
    ${DealTransactionFragmentDoc}
${DealTransactionMinFragmentDoc}
${OrderMinFragmentDoc}
${ContactFragmentDoc}
${ContactMinFragmentDoc}
${FileFragmentDoc}
${ContactDynamicValuesFragmentDoc}
${LeadFragmentDoc}
${LeadMinFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${BusinessLocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}
${CompanyMinFragmentDoc}
${ChartAccountFragmentDoc}
${ChartAccountMinFragmentDoc}
${ChartCategoryFragmentDoc}
${ChartCategoryMinFragmentDoc}
${ChartSubcategoryMinFragmentDoc}
${ChartSubcategoryFragmentDoc}
${CompanyFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useDealTransactionsQuery(baseOptions?: Apollo.QueryHookOptions<DealTransactionsQuery, DealTransactionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DealTransactionsQuery, DealTransactionsQueryVariables>(DealTransactionsDocument, options);
      }
export function useDealTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DealTransactionsQuery, DealTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DealTransactionsQuery, DealTransactionsQueryVariables>(DealTransactionsDocument, options);
        }
export type DealTransactionsQueryHookResult = ReturnType<typeof useDealTransactionsQuery>;
export type DealTransactionsLazyQueryHookResult = ReturnType<typeof useDealTransactionsLazyQuery>;
export type DealTransactionsQueryResult = Apollo.QueryResult<DealTransactionsQuery, DealTransactionsQueryVariables>;
export const GetSavedCardsDocument = gql`
    query getSavedCards($input: CardListArgsInput!) {
  getSavedCards(input: $input) {
    data {
      id
      billing_details {
        email
        name
        phone
      }
      card {
        brand
        country
        display_brand
        exp_month
        exp_year
        fingerprint
        funding
        generated_from
        last4
        wallet
      }
    }
    customerId
  }
}
    `;
export function useGetSavedCardsQuery(baseOptions: Apollo.QueryHookOptions<GetSavedCardsQuery, GetSavedCardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSavedCardsQuery, GetSavedCardsQueryVariables>(GetSavedCardsDocument, options);
      }
export function useGetSavedCardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSavedCardsQuery, GetSavedCardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSavedCardsQuery, GetSavedCardsQueryVariables>(GetSavedCardsDocument, options);
        }
export type GetSavedCardsQueryHookResult = ReturnType<typeof useGetSavedCardsQuery>;
export type GetSavedCardsLazyQueryHookResult = ReturnType<typeof useGetSavedCardsLazyQuery>;
export type GetSavedCardsQueryResult = Apollo.QueryResult<GetSavedCardsQuery, GetSavedCardsQueryVariables>;
export const PaymentStatusDocument = gql`
    query paymentStatus($input: TransactionStatusArgsInput!) {
  paymentStatus(input: $input) {
    status
    failure_message
  }
}
    `;
export function usePaymentStatusQuery(baseOptions: Apollo.QueryHookOptions<PaymentStatusQuery, PaymentStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PaymentStatusQuery, PaymentStatusQueryVariables>(PaymentStatusDocument, options);
      }
export function usePaymentStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PaymentStatusQuery, PaymentStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PaymentStatusQuery, PaymentStatusQueryVariables>(PaymentStatusDocument, options);
        }
export type PaymentStatusQueryHookResult = ReturnType<typeof usePaymentStatusQuery>;
export type PaymentStatusLazyQueryHookResult = ReturnType<typeof usePaymentStatusLazyQuery>;
export type PaymentStatusQueryResult = Apollo.QueryResult<PaymentStatusQuery, PaymentStatusQueryVariables>;
export const CreateDealTransactionReminderDocument = gql`
    mutation createDealTransactionReminder($input: DealTransactionReminderInput!) {
  createDealTransactionReminder(data: $input) {
    data {
      ...DealTransactionReminder
    }
  }
}
    ${DealTransactionReminderFragmentDoc}
${DealTransactionReminderMinFragmentDoc}`;
export type CreateDealTransactionReminderMutationFn = Apollo.MutationFunction<CreateDealTransactionReminderMutation, CreateDealTransactionReminderMutationVariables>;
export function useCreateDealTransactionReminderMutation(baseOptions?: Apollo.MutationHookOptions<CreateDealTransactionReminderMutation, CreateDealTransactionReminderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDealTransactionReminderMutation, CreateDealTransactionReminderMutationVariables>(CreateDealTransactionReminderDocument, options);
      }
export type CreateDealTransactionReminderMutationHookResult = ReturnType<typeof useCreateDealTransactionReminderMutation>;
export type CreateDealTransactionReminderMutationResult = Apollo.MutationResult<CreateDealTransactionReminderMutation>;
export const DeleteDealTransactionReminderDocument = gql`
    mutation deleteDealTransactionReminder($id: ID!) {
  deleteDealTransactionReminder(id: $id) {
    data {
      ...DealTransactionReminder
    }
  }
}
    ${DealTransactionReminderFragmentDoc}
${DealTransactionReminderMinFragmentDoc}`;
export type DeleteDealTransactionReminderMutationFn = Apollo.MutationFunction<DeleteDealTransactionReminderMutation, DeleteDealTransactionReminderMutationVariables>;
export function useDeleteDealTransactionReminderMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDealTransactionReminderMutation, DeleteDealTransactionReminderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDealTransactionReminderMutation, DeleteDealTransactionReminderMutationVariables>(DeleteDealTransactionReminderDocument, options);
      }
export type DeleteDealTransactionReminderMutationHookResult = ReturnType<typeof useDeleteDealTransactionReminderMutation>;
export type DeleteDealTransactionReminderMutationResult = Apollo.MutationResult<DeleteDealTransactionReminderMutation>;
export const UpdateDealTransactionReminderDocument = gql`
    mutation updateDealTransactionReminder($id: ID!, $input: DealTransactionReminderInput!) {
  updateDealTransactionReminder(id: $id, data: $input) {
    data {
      ...DealTransactionReminder
    }
  }
}
    ${DealTransactionReminderFragmentDoc}
${DealTransactionReminderMinFragmentDoc}`;
export type UpdateDealTransactionReminderMutationFn = Apollo.MutationFunction<UpdateDealTransactionReminderMutation, UpdateDealTransactionReminderMutationVariables>;
export function useUpdateDealTransactionReminderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDealTransactionReminderMutation, UpdateDealTransactionReminderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDealTransactionReminderMutation, UpdateDealTransactionReminderMutationVariables>(UpdateDealTransactionReminderDocument, options);
      }
export type UpdateDealTransactionReminderMutationHookResult = ReturnType<typeof useUpdateDealTransactionReminderMutation>;
export type UpdateDealTransactionReminderMutationResult = Apollo.MutationResult<UpdateDealTransactionReminderMutation>;
export const DealTransactionReminderDocument = gql`
    query dealTransactionReminder($id: ID!) {
  dealTransactionReminder(id: $id) {
    data {
      ...DealTransactionReminder
    }
  }
}
    ${DealTransactionReminderFragmentDoc}
${DealTransactionReminderMinFragmentDoc}`;
export function useDealTransactionReminderQuery(baseOptions: Apollo.QueryHookOptions<DealTransactionReminderQuery, DealTransactionReminderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DealTransactionReminderQuery, DealTransactionReminderQueryVariables>(DealTransactionReminderDocument, options);
      }
export function useDealTransactionReminderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DealTransactionReminderQuery, DealTransactionReminderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DealTransactionReminderQuery, DealTransactionReminderQueryVariables>(DealTransactionReminderDocument, options);
        }
export type DealTransactionReminderQueryHookResult = ReturnType<typeof useDealTransactionReminderQuery>;
export type DealTransactionReminderLazyQueryHookResult = ReturnType<typeof useDealTransactionReminderLazyQuery>;
export type DealTransactionReminderQueryResult = Apollo.QueryResult<DealTransactionReminderQuery, DealTransactionReminderQueryVariables>;
export const DealTransactionRemindersDocument = gql`
    query dealTransactionReminders($filters: DealTransactionReminderFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  dealTransactionReminders(
    filters: $filters
    pagination: $pagination
    sort: $sort
  ) {
    data {
      ...DealTransactionReminder
    }
    meta {
      ...Meta
    }
  }
}
    ${DealTransactionReminderFragmentDoc}
${DealTransactionReminderMinFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useDealTransactionRemindersQuery(baseOptions?: Apollo.QueryHookOptions<DealTransactionRemindersQuery, DealTransactionRemindersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DealTransactionRemindersQuery, DealTransactionRemindersQueryVariables>(DealTransactionRemindersDocument, options);
      }
export function useDealTransactionRemindersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DealTransactionRemindersQuery, DealTransactionRemindersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DealTransactionRemindersQuery, DealTransactionRemindersQueryVariables>(DealTransactionRemindersDocument, options);
        }
export type DealTransactionRemindersQueryHookResult = ReturnType<typeof useDealTransactionRemindersQuery>;
export type DealTransactionRemindersLazyQueryHookResult = ReturnType<typeof useDealTransactionRemindersLazyQuery>;
export type DealTransactionRemindersQueryResult = Apollo.QueryResult<DealTransactionRemindersQuery, DealTransactionRemindersQueryVariables>;
export const CreateDimensionDocument = gql`
    mutation createDimension($input: DimensionInput!) {
  createDimension(data: $input) {
    data {
      ...Dimension
    }
  }
}
    ${DimensionFragmentDoc}`;
export type CreateDimensionMutationFn = Apollo.MutationFunction<CreateDimensionMutation, CreateDimensionMutationVariables>;
export function useCreateDimensionMutation(baseOptions?: Apollo.MutationHookOptions<CreateDimensionMutation, CreateDimensionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDimensionMutation, CreateDimensionMutationVariables>(CreateDimensionDocument, options);
      }
export type CreateDimensionMutationHookResult = ReturnType<typeof useCreateDimensionMutation>;
export type CreateDimensionMutationResult = Apollo.MutationResult<CreateDimensionMutation>;
export const DeleteDimensionDocument = gql`
    mutation deleteDimension($id: ID!) {
  deleteDimension(id: $id) {
    data {
      ...Dimension
    }
  }
}
    ${DimensionFragmentDoc}`;
export type DeleteDimensionMutationFn = Apollo.MutationFunction<DeleteDimensionMutation, DeleteDimensionMutationVariables>;
export function useDeleteDimensionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDimensionMutation, DeleteDimensionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDimensionMutation, DeleteDimensionMutationVariables>(DeleteDimensionDocument, options);
      }
export type DeleteDimensionMutationHookResult = ReturnType<typeof useDeleteDimensionMutation>;
export type DeleteDimensionMutationResult = Apollo.MutationResult<DeleteDimensionMutation>;
export const UpdateDimensionDocument = gql`
    mutation updateDimension($id: ID!, $input: DimensionInput!) {
  updateDimension(id: $id, data: $input) {
    data {
      ...Dimension
    }
  }
}
    ${DimensionFragmentDoc}`;
export type UpdateDimensionMutationFn = Apollo.MutationFunction<UpdateDimensionMutation, UpdateDimensionMutationVariables>;
export function useUpdateDimensionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDimensionMutation, UpdateDimensionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDimensionMutation, UpdateDimensionMutationVariables>(UpdateDimensionDocument, options);
      }
export type UpdateDimensionMutationHookResult = ReturnType<typeof useUpdateDimensionMutation>;
export type UpdateDimensionMutationResult = Apollo.MutationResult<UpdateDimensionMutation>;
export const CreateDiscountDocument = gql`
    mutation createDiscount($input: DiscountInput!) {
  createDiscount(data: $input) {
    data {
      ...Discount
    }
  }
}
    ${DiscountFragmentDoc}
${DiscountMinFragmentDoc}
${ProductFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${SerializeFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${ServiceFragmentDoc}
${ServiceMinFragmentDoc}
${ServiceLocationInfoFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}
${ServicePerformerFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ClassFragmentDoc}
${ClassMinFragmentDoc}
${ClassLocationInfoFragmentDoc}
${ClassLocationInfoMinFragmentDoc}
${ClassPerformerFragmentDoc}
${MembershipFragmentDoc}
${MembershipMinFragmentDoc}
${MembershipItemFragmentDoc}
${CompositeProductFragmentDoc}
${CompositeProductMinFragmentDoc}
${CompositeProductLocationInfoMinFragmentDoc}
${CompositeProductItemInfoFragmentDoc}
${CompositeProductItemInfoMinFragmentDoc}`;
export type CreateDiscountMutationFn = Apollo.MutationFunction<CreateDiscountMutation, CreateDiscountMutationVariables>;
export function useCreateDiscountMutation(baseOptions?: Apollo.MutationHookOptions<CreateDiscountMutation, CreateDiscountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDiscountMutation, CreateDiscountMutationVariables>(CreateDiscountDocument, options);
      }
export type CreateDiscountMutationHookResult = ReturnType<typeof useCreateDiscountMutation>;
export type CreateDiscountMutationResult = Apollo.MutationResult<CreateDiscountMutation>;
export const DeleteDiscountDocument = gql`
    mutation deleteDiscount($id: ID!) {
  deleteDiscount(id: $id) {
    data {
      ...Discount
    }
  }
}
    ${DiscountFragmentDoc}
${DiscountMinFragmentDoc}
${ProductFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${SerializeFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${ServiceFragmentDoc}
${ServiceMinFragmentDoc}
${ServiceLocationInfoFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}
${ServicePerformerFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ClassFragmentDoc}
${ClassMinFragmentDoc}
${ClassLocationInfoFragmentDoc}
${ClassLocationInfoMinFragmentDoc}
${ClassPerformerFragmentDoc}
${MembershipFragmentDoc}
${MembershipMinFragmentDoc}
${MembershipItemFragmentDoc}
${CompositeProductFragmentDoc}
${CompositeProductMinFragmentDoc}
${CompositeProductLocationInfoMinFragmentDoc}
${CompositeProductItemInfoFragmentDoc}
${CompositeProductItemInfoMinFragmentDoc}`;
export type DeleteDiscountMutationFn = Apollo.MutationFunction<DeleteDiscountMutation, DeleteDiscountMutationVariables>;
export function useDeleteDiscountMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDiscountMutation, DeleteDiscountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDiscountMutation, DeleteDiscountMutationVariables>(DeleteDiscountDocument, options);
      }
export type DeleteDiscountMutationHookResult = ReturnType<typeof useDeleteDiscountMutation>;
export type DeleteDiscountMutationResult = Apollo.MutationResult<DeleteDiscountMutation>;
export const UpdateDiscountDocument = gql`
    mutation updateDiscount($id: ID!, $input: DiscountInput!) {
  updateDiscount(id: $id, data: $input) {
    data {
      ...Discount
    }
  }
}
    ${DiscountFragmentDoc}
${DiscountMinFragmentDoc}
${ProductFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${SerializeFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${ServiceFragmentDoc}
${ServiceMinFragmentDoc}
${ServiceLocationInfoFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}
${ServicePerformerFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ClassFragmentDoc}
${ClassMinFragmentDoc}
${ClassLocationInfoFragmentDoc}
${ClassLocationInfoMinFragmentDoc}
${ClassPerformerFragmentDoc}
${MembershipFragmentDoc}
${MembershipMinFragmentDoc}
${MembershipItemFragmentDoc}
${CompositeProductFragmentDoc}
${CompositeProductMinFragmentDoc}
${CompositeProductLocationInfoMinFragmentDoc}
${CompositeProductItemInfoFragmentDoc}
${CompositeProductItemInfoMinFragmentDoc}`;
export type UpdateDiscountMutationFn = Apollo.MutationFunction<UpdateDiscountMutation, UpdateDiscountMutationVariables>;
export function useUpdateDiscountMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDiscountMutation, UpdateDiscountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDiscountMutation, UpdateDiscountMutationVariables>(UpdateDiscountDocument, options);
      }
export type UpdateDiscountMutationHookResult = ReturnType<typeof useUpdateDiscountMutation>;
export type UpdateDiscountMutationResult = Apollo.MutationResult<UpdateDiscountMutation>;
export const DiscountDocument = gql`
    query discount($id: ID!) {
  discount(id: $id) {
    data {
      ...Discount
    }
  }
}
    ${DiscountFragmentDoc}
${DiscountMinFragmentDoc}
${ProductFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${SerializeFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${ServiceFragmentDoc}
${ServiceMinFragmentDoc}
${ServiceLocationInfoFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}
${ServicePerformerFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ClassFragmentDoc}
${ClassMinFragmentDoc}
${ClassLocationInfoFragmentDoc}
${ClassLocationInfoMinFragmentDoc}
${ClassPerformerFragmentDoc}
${MembershipFragmentDoc}
${MembershipMinFragmentDoc}
${MembershipItemFragmentDoc}
${CompositeProductFragmentDoc}
${CompositeProductMinFragmentDoc}
${CompositeProductLocationInfoMinFragmentDoc}
${CompositeProductItemInfoFragmentDoc}
${CompositeProductItemInfoMinFragmentDoc}`;
export function useDiscountQuery(baseOptions: Apollo.QueryHookOptions<DiscountQuery, DiscountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DiscountQuery, DiscountQueryVariables>(DiscountDocument, options);
      }
export function useDiscountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DiscountQuery, DiscountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DiscountQuery, DiscountQueryVariables>(DiscountDocument, options);
        }
export type DiscountQueryHookResult = ReturnType<typeof useDiscountQuery>;
export type DiscountLazyQueryHookResult = ReturnType<typeof useDiscountLazyQuery>;
export type DiscountQueryResult = Apollo.QueryResult<DiscountQuery, DiscountQueryVariables>;
export const DiscountByUuidDocument = gql`
    query discountByUuid($uuid: String!) {
  discounts(filters: {uuid: {eq: $uuid}}) {
    data {
      ...Discount
    }
  }
}
    ${DiscountFragmentDoc}
${DiscountMinFragmentDoc}
${ProductFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${SerializeFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${ServiceFragmentDoc}
${ServiceMinFragmentDoc}
${ServiceLocationInfoFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}
${ServicePerformerFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ClassFragmentDoc}
${ClassMinFragmentDoc}
${ClassLocationInfoFragmentDoc}
${ClassLocationInfoMinFragmentDoc}
${ClassPerformerFragmentDoc}
${MembershipFragmentDoc}
${MembershipMinFragmentDoc}
${MembershipItemFragmentDoc}
${CompositeProductFragmentDoc}
${CompositeProductMinFragmentDoc}
${CompositeProductLocationInfoMinFragmentDoc}
${CompositeProductItemInfoFragmentDoc}
${CompositeProductItemInfoMinFragmentDoc}`;
export function useDiscountByUuidQuery(baseOptions: Apollo.QueryHookOptions<DiscountByUuidQuery, DiscountByUuidQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DiscountByUuidQuery, DiscountByUuidQueryVariables>(DiscountByUuidDocument, options);
      }
export function useDiscountByUuidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DiscountByUuidQuery, DiscountByUuidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DiscountByUuidQuery, DiscountByUuidQueryVariables>(DiscountByUuidDocument, options);
        }
export type DiscountByUuidQueryHookResult = ReturnType<typeof useDiscountByUuidQuery>;
export type DiscountByUuidLazyQueryHookResult = ReturnType<typeof useDiscountByUuidLazyQuery>;
export type DiscountByUuidQueryResult = Apollo.QueryResult<DiscountByUuidQuery, DiscountByUuidQueryVariables>;
export const DiscountsDocument = gql`
    query discounts($filters: DiscountFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  discounts(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...Discount
    }
    meta {
      ...Meta
    }
  }
}
    ${DiscountFragmentDoc}
${DiscountMinFragmentDoc}
${ProductFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${SerializeFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${ServiceFragmentDoc}
${ServiceMinFragmentDoc}
${ServiceLocationInfoFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}
${ServicePerformerFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ClassFragmentDoc}
${ClassMinFragmentDoc}
${ClassLocationInfoFragmentDoc}
${ClassLocationInfoMinFragmentDoc}
${ClassPerformerFragmentDoc}
${MembershipFragmentDoc}
${MembershipMinFragmentDoc}
${MembershipItemFragmentDoc}
${CompositeProductFragmentDoc}
${CompositeProductMinFragmentDoc}
${CompositeProductLocationInfoMinFragmentDoc}
${CompositeProductItemInfoFragmentDoc}
${CompositeProductItemInfoMinFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useDiscountsQuery(baseOptions?: Apollo.QueryHookOptions<DiscountsQuery, DiscountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DiscountsQuery, DiscountsQueryVariables>(DiscountsDocument, options);
      }
export function useDiscountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DiscountsQuery, DiscountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DiscountsQuery, DiscountsQueryVariables>(DiscountsDocument, options);
        }
export type DiscountsQueryHookResult = ReturnType<typeof useDiscountsQuery>;
export type DiscountsLazyQueryHookResult = ReturnType<typeof useDiscountsLazyQuery>;
export type DiscountsQueryResult = Apollo.QueryResult<DiscountsQuery, DiscountsQueryVariables>;
export const UpdateDocumentPermissionDocument = gql`
    mutation updateDocumentPermission($id: ID!, $input: DocumentPermissionInput!) {
  updateDocumentPermission(id: $id, data: $input) {
    data {
      ...DocumentPermission
    }
  }
}
    ${DocumentPermissionFragmentDoc}`;
export type UpdateDocumentPermissionMutationFn = Apollo.MutationFunction<UpdateDocumentPermissionMutation, UpdateDocumentPermissionMutationVariables>;
export function useUpdateDocumentPermissionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDocumentPermissionMutation, UpdateDocumentPermissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDocumentPermissionMutation, UpdateDocumentPermissionMutationVariables>(UpdateDocumentPermissionDocument, options);
      }
export type UpdateDocumentPermissionMutationHookResult = ReturnType<typeof useUpdateDocumentPermissionMutation>;
export type UpdateDocumentPermissionMutationResult = Apollo.MutationResult<UpdateDocumentPermissionMutation>;
export const DocumentPermissionsDocument = gql`
    query documentPermissions($filters: DocumentPermissionFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  documentPermissions(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...DocumentPermission
    }
    meta {
      ...Meta
    }
  }
}
    ${DocumentPermissionFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useDocumentPermissionsQuery(baseOptions?: Apollo.QueryHookOptions<DocumentPermissionsQuery, DocumentPermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocumentPermissionsQuery, DocumentPermissionsQueryVariables>(DocumentPermissionsDocument, options);
      }
export function useDocumentPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentPermissionsQuery, DocumentPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocumentPermissionsQuery, DocumentPermissionsQueryVariables>(DocumentPermissionsDocument, options);
        }
export type DocumentPermissionsQueryHookResult = ReturnType<typeof useDocumentPermissionsQuery>;
export type DocumentPermissionsLazyQueryHookResult = ReturnType<typeof useDocumentPermissionsLazyQuery>;
export type DocumentPermissionsQueryResult = Apollo.QueryResult<DocumentPermissionsQuery, DocumentPermissionsQueryVariables>;
export const CreateDownloadRecordDocument = gql`
    mutation createDownloadRecord($input: DownloadRecordInput!) {
  createDownloadRecord(data: $input) {
    data {
      ...DownloadRecord
    }
  }
}
    ${DownloadRecordFragmentDoc}
${DownloadRecordMinFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${FileItemMinFragmentDoc}`;
export type CreateDownloadRecordMutationFn = Apollo.MutationFunction<CreateDownloadRecordMutation, CreateDownloadRecordMutationVariables>;
export function useCreateDownloadRecordMutation(baseOptions?: Apollo.MutationHookOptions<CreateDownloadRecordMutation, CreateDownloadRecordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDownloadRecordMutation, CreateDownloadRecordMutationVariables>(CreateDownloadRecordDocument, options);
      }
export type CreateDownloadRecordMutationHookResult = ReturnType<typeof useCreateDownloadRecordMutation>;
export type CreateDownloadRecordMutationResult = Apollo.MutationResult<CreateDownloadRecordMutation>;
export const DeleteDownloadRecordDocument = gql`
    mutation deleteDownloadRecord($id: ID!) {
  deleteDownloadRecord(id: $id) {
    data {
      ...DownloadRecord
    }
  }
}
    ${DownloadRecordFragmentDoc}
${DownloadRecordMinFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${FileItemMinFragmentDoc}`;
export type DeleteDownloadRecordMutationFn = Apollo.MutationFunction<DeleteDownloadRecordMutation, DeleteDownloadRecordMutationVariables>;
export function useDeleteDownloadRecordMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDownloadRecordMutation, DeleteDownloadRecordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDownloadRecordMutation, DeleteDownloadRecordMutationVariables>(DeleteDownloadRecordDocument, options);
      }
export type DeleteDownloadRecordMutationHookResult = ReturnType<typeof useDeleteDownloadRecordMutation>;
export type DeleteDownloadRecordMutationResult = Apollo.MutationResult<DeleteDownloadRecordMutation>;
export const DeleteDownloadUserRecordsDocument = gql`
    mutation deleteDownloadUserRecords {
  deleteDownloadUserRecords {
    data {
      id
    }
  }
}
    `;
export type DeleteDownloadUserRecordsMutationFn = Apollo.MutationFunction<DeleteDownloadUserRecordsMutation, DeleteDownloadUserRecordsMutationVariables>;
export function useDeleteDownloadUserRecordsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDownloadUserRecordsMutation, DeleteDownloadUserRecordsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDownloadUserRecordsMutation, DeleteDownloadUserRecordsMutationVariables>(DeleteDownloadUserRecordsDocument, options);
      }
export type DeleteDownloadUserRecordsMutationHookResult = ReturnType<typeof useDeleteDownloadUserRecordsMutation>;
export type DeleteDownloadUserRecordsMutationResult = Apollo.MutationResult<DeleteDownloadUserRecordsMutation>;
export const UpdateDownloadRecordDocument = gql`
    mutation updateDownloadRecord($id: ID!, $input: DownloadRecordInput!) {
  updateDownloadRecord(id: $id, data: $input) {
    data {
      ...DownloadRecord
    }
  }
}
    ${DownloadRecordFragmentDoc}
${DownloadRecordMinFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${FileItemMinFragmentDoc}`;
export type UpdateDownloadRecordMutationFn = Apollo.MutationFunction<UpdateDownloadRecordMutation, UpdateDownloadRecordMutationVariables>;
export function useUpdateDownloadRecordMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDownloadRecordMutation, UpdateDownloadRecordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDownloadRecordMutation, UpdateDownloadRecordMutationVariables>(UpdateDownloadRecordDocument, options);
      }
export type UpdateDownloadRecordMutationHookResult = ReturnType<typeof useUpdateDownloadRecordMutation>;
export type UpdateDownloadRecordMutationResult = Apollo.MutationResult<UpdateDownloadRecordMutation>;
export const DownloadRecordDocument = gql`
    query downloadRecord($id: ID!) {
  downloadRecord(id: $id) {
    data {
      ...DownloadRecord
    }
  }
}
    ${DownloadRecordFragmentDoc}
${DownloadRecordMinFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${FileItemMinFragmentDoc}`;
export function useDownloadRecordQuery(baseOptions: Apollo.QueryHookOptions<DownloadRecordQuery, DownloadRecordQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DownloadRecordQuery, DownloadRecordQueryVariables>(DownloadRecordDocument, options);
      }
export function useDownloadRecordLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DownloadRecordQuery, DownloadRecordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DownloadRecordQuery, DownloadRecordQueryVariables>(DownloadRecordDocument, options);
        }
export type DownloadRecordQueryHookResult = ReturnType<typeof useDownloadRecordQuery>;
export type DownloadRecordLazyQueryHookResult = ReturnType<typeof useDownloadRecordLazyQuery>;
export type DownloadRecordQueryResult = Apollo.QueryResult<DownloadRecordQuery, DownloadRecordQueryVariables>;
export const DownloadRecordsDocument = gql`
    query downloadRecords($filters: DownloadRecordFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  downloadRecords(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...DownloadRecord
    }
    meta {
      ...Meta
    }
  }
}
    ${DownloadRecordFragmentDoc}
${DownloadRecordMinFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${FileItemMinFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useDownloadRecordsQuery(baseOptions?: Apollo.QueryHookOptions<DownloadRecordsQuery, DownloadRecordsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DownloadRecordsQuery, DownloadRecordsQueryVariables>(DownloadRecordsDocument, options);
      }
export function useDownloadRecordsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DownloadRecordsQuery, DownloadRecordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DownloadRecordsQuery, DownloadRecordsQueryVariables>(DownloadRecordsDocument, options);
        }
export type DownloadRecordsQueryHookResult = ReturnType<typeof useDownloadRecordsQuery>;
export type DownloadRecordsLazyQueryHookResult = ReturnType<typeof useDownloadRecordsLazyQuery>;
export type DownloadRecordsQueryResult = Apollo.QueryResult<DownloadRecordsQuery, DownloadRecordsQueryVariables>;
export const AddEmployeeDocument = gql`
    mutation addEmployee($input: EmployeeUserRegisterInput!) {
  addEmployee(input: $input) {
    jwt
    user {
      id
      username
      email
      blocked
      confirmed
      role {
        id
        name
      }
    }
  }
}
    `;
export type AddEmployeeMutationFn = Apollo.MutationFunction<AddEmployeeMutation, AddEmployeeMutationVariables>;
export function useAddEmployeeMutation(baseOptions?: Apollo.MutationHookOptions<AddEmployeeMutation, AddEmployeeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddEmployeeMutation, AddEmployeeMutationVariables>(AddEmployeeDocument, options);
      }
export type AddEmployeeMutationHookResult = ReturnType<typeof useAddEmployeeMutation>;
export type AddEmployeeMutationResult = Apollo.MutationResult<AddEmployeeMutation>;
export const DeleteEmployeeDocument = gql`
    mutation deleteEmployee($id: ID!) {
  deleteUsersPermissionsUser(id: $id) {
    data {
      ...User
    }
  }
}
    ${UserFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ContactMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${BusinessLocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}`;
export type DeleteEmployeeMutationFn = Apollo.MutationFunction<DeleteEmployeeMutation, DeleteEmployeeMutationVariables>;
export function useDeleteEmployeeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEmployeeMutation, DeleteEmployeeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEmployeeMutation, DeleteEmployeeMutationVariables>(DeleteEmployeeDocument, options);
      }
export type DeleteEmployeeMutationHookResult = ReturnType<typeof useDeleteEmployeeMutation>;
export type DeleteEmployeeMutationResult = Apollo.MutationResult<DeleteEmployeeMutation>;
export const DeleteUploadFileDocument = gql`
    mutation deleteUploadFile($id: ID!) {
  deleteUploadFile(id: $id) {
    data {
      ...File
    }
  }
}
    ${FileFragmentDoc}`;
export type DeleteUploadFileMutationFn = Apollo.MutationFunction<DeleteUploadFileMutation, DeleteUploadFileMutationVariables>;
export function useDeleteUploadFileMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUploadFileMutation, DeleteUploadFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUploadFileMutation, DeleteUploadFileMutationVariables>(DeleteUploadFileDocument, options);
      }
export type DeleteUploadFileMutationHookResult = ReturnType<typeof useDeleteUploadFileMutation>;
export type DeleteUploadFileMutationResult = Apollo.MutationResult<DeleteUploadFileMutation>;
export const MultipleUploadDocument = gql`
    mutation multipleUpload($files: [Upload]!, $ref: String, $refId: ID, $field: String) {
  multipleUpload(files: $files, ref: $ref, refId: $refId, field: $field) {
    data {
      ...File
    }
  }
}
    ${FileFragmentDoc}`;
export type MultipleUploadMutationFn = Apollo.MutationFunction<MultipleUploadMutation, MultipleUploadMutationVariables>;
export function useMultipleUploadMutation(baseOptions?: Apollo.MutationHookOptions<MultipleUploadMutation, MultipleUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MultipleUploadMutation, MultipleUploadMutationVariables>(MultipleUploadDocument, options);
      }
export type MultipleUploadMutationHookResult = ReturnType<typeof useMultipleUploadMutation>;
export type MultipleUploadMutationResult = Apollo.MutationResult<MultipleUploadMutation>;
export const ProcessingFileUploadingDocument = gql`
    mutation processingFileUploading($input: ProcessingFileUploadingArgInput!) {
  processingFileUploading(input: $input) {
    resultObj
  }
}
    `;
export type ProcessingFileUploadingMutationFn = Apollo.MutationFunction<ProcessingFileUploadingMutation, ProcessingFileUploadingMutationVariables>;
export function useProcessingFileUploadingMutation(baseOptions?: Apollo.MutationHookOptions<ProcessingFileUploadingMutation, ProcessingFileUploadingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProcessingFileUploadingMutation, ProcessingFileUploadingMutationVariables>(ProcessingFileUploadingDocument, options);
      }
export type ProcessingFileUploadingMutationHookResult = ReturnType<typeof useProcessingFileUploadingMutation>;
export type ProcessingFileUploadingMutationResult = Apollo.MutationResult<ProcessingFileUploadingMutation>;
export const UpdateUploadFileDocument = gql`
    mutation updateUploadFile($id: ID!, $data: UploadFileInput!) {
  updateUploadFile(id: $id, data: $data) {
    data {
      ...File
    }
  }
}
    ${FileFragmentDoc}`;
export type UpdateUploadFileMutationFn = Apollo.MutationFunction<UpdateUploadFileMutation, UpdateUploadFileMutationVariables>;
export function useUpdateUploadFileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUploadFileMutation, UpdateUploadFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUploadFileMutation, UpdateUploadFileMutationVariables>(UpdateUploadFileDocument, options);
      }
export type UpdateUploadFileMutationHookResult = ReturnType<typeof useUpdateUploadFileMutation>;
export type UpdateUploadFileMutationResult = Apollo.MutationResult<UpdateUploadFileMutation>;
export const UploadFileDocument = gql`
    mutation uploadFile($file: Upload!, $ref: String, $refId: ID, $field: String) {
  upload(file: $file, ref: $ref, refId: $refId, field: $field) {
    data {
      ...File
    }
  }
}
    ${FileFragmentDoc}`;
export type UploadFileMutationFn = Apollo.MutationFunction<UploadFileMutation, UploadFileMutationVariables>;
export function useUploadFileMutation(baseOptions?: Apollo.MutationHookOptions<UploadFileMutation, UploadFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadFileMutation, UploadFileMutationVariables>(UploadFileDocument, options);
      }
export type UploadFileMutationHookResult = ReturnType<typeof useUploadFileMutation>;
export type UploadFileMutationResult = Apollo.MutationResult<UploadFileMutation>;
export const CreateFileItemDocument = gql`
    mutation createFileItem($input: FileItemInput!) {
  createFileItem(data: $input) {
    data {
      ...FileItem
    }
  }
}
    ${FileItemFragmentDoc}
${FileItemMinFragmentDoc}
${FileFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}`;
export type CreateFileItemMutationFn = Apollo.MutationFunction<CreateFileItemMutation, CreateFileItemMutationVariables>;
export function useCreateFileItemMutation(baseOptions?: Apollo.MutationHookOptions<CreateFileItemMutation, CreateFileItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFileItemMutation, CreateFileItemMutationVariables>(CreateFileItemDocument, options);
      }
export type CreateFileItemMutationHookResult = ReturnType<typeof useCreateFileItemMutation>;
export type CreateFileItemMutationResult = Apollo.MutationResult<CreateFileItemMutation>;
export const DeleteFileItemDocument = gql`
    mutation deleteFileItem($id: ID!) {
  deleteFileItem(id: $id) {
    data {
      ...FileItem
    }
  }
}
    ${FileItemFragmentDoc}
${FileItemMinFragmentDoc}
${FileFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}`;
export type DeleteFileItemMutationFn = Apollo.MutationFunction<DeleteFileItemMutation, DeleteFileItemMutationVariables>;
export function useDeleteFileItemMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFileItemMutation, DeleteFileItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFileItemMutation, DeleteFileItemMutationVariables>(DeleteFileItemDocument, options);
      }
export type DeleteFileItemMutationHookResult = ReturnType<typeof useDeleteFileItemMutation>;
export type DeleteFileItemMutationResult = Apollo.MutationResult<DeleteFileItemMutation>;
export const UpdateFileItemDocument = gql`
    mutation updateFileItem($id: ID!, $input: FileItemInput!) {
  updateFileItem(id: $id, data: $input) {
    data {
      ...FileItem
    }
  }
}
    ${FileItemFragmentDoc}
${FileItemMinFragmentDoc}
${FileFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}`;
export type UpdateFileItemMutationFn = Apollo.MutationFunction<UpdateFileItemMutation, UpdateFileItemMutationVariables>;
export function useUpdateFileItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFileItemMutation, UpdateFileItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFileItemMutation, UpdateFileItemMutationVariables>(UpdateFileItemDocument, options);
      }
export type UpdateFileItemMutationHookResult = ReturnType<typeof useUpdateFileItemMutation>;
export type UpdateFileItemMutationResult = Apollo.MutationResult<UpdateFileItemMutation>;
export const FileItemDocument = gql`
    query fileItem($id: ID!) {
  fileItem(id: $id) {
    data {
      ...FileItem
    }
  }
}
    ${FileItemFragmentDoc}
${FileItemMinFragmentDoc}
${FileFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}`;
export function useFileItemQuery(baseOptions: Apollo.QueryHookOptions<FileItemQuery, FileItemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FileItemQuery, FileItemQueryVariables>(FileItemDocument, options);
      }
export function useFileItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FileItemQuery, FileItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FileItemQuery, FileItemQueryVariables>(FileItemDocument, options);
        }
export type FileItemQueryHookResult = ReturnType<typeof useFileItemQuery>;
export type FileItemLazyQueryHookResult = ReturnType<typeof useFileItemLazyQuery>;
export type FileItemQueryResult = Apollo.QueryResult<FileItemQuery, FileItemQueryVariables>;
export const FileItemsDocument = gql`
    query fileItems($filters: FileItemFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  fileItems(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...FileItem
    }
    meta {
      ...Meta
    }
  }
}
    ${FileItemFragmentDoc}
${FileItemMinFragmentDoc}
${FileFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useFileItemsQuery(baseOptions?: Apollo.QueryHookOptions<FileItemsQuery, FileItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FileItemsQuery, FileItemsQueryVariables>(FileItemsDocument, options);
      }
export function useFileItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FileItemsQuery, FileItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FileItemsQuery, FileItemsQueryVariables>(FileItemsDocument, options);
        }
export type FileItemsQueryHookResult = ReturnType<typeof useFileItemsQuery>;
export type FileItemsLazyQueryHookResult = ReturnType<typeof useFileItemsLazyQuery>;
export type FileItemsQueryResult = Apollo.QueryResult<FileItemsQuery, FileItemsQueryVariables>;
export const TotalFileItemsSizeDocument = gql`
    query totalFileItemsSize {
  totalFileItemsSize {
    totalSize
  }
}
    `;
export function useTotalFileItemsSizeQuery(baseOptions?: Apollo.QueryHookOptions<TotalFileItemsSizeQuery, TotalFileItemsSizeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TotalFileItemsSizeQuery, TotalFileItemsSizeQueryVariables>(TotalFileItemsSizeDocument, options);
      }
export function useTotalFileItemsSizeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TotalFileItemsSizeQuery, TotalFileItemsSizeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TotalFileItemsSizeQuery, TotalFileItemsSizeQueryVariables>(TotalFileItemsSizeDocument, options);
        }
export type TotalFileItemsSizeQueryHookResult = ReturnType<typeof useTotalFileItemsSizeQuery>;
export type TotalFileItemsSizeLazyQueryHookResult = ReturnType<typeof useTotalFileItemsSizeLazyQuery>;
export type TotalFileItemsSizeQueryResult = Apollo.QueryResult<TotalFileItemsSizeQuery, TotalFileItemsSizeQueryVariables>;
export const CreateInventoryAdjustmentDocument = gql`
    mutation createInventoryAdjustment($input: InventoryAdjustmentInput!) {
  createInventoryAdjustment(data: $input) {
    data {
      ...InventoryAdjustment
    }
  }
}
    ${InventoryAdjustmentFragmentDoc}
${InventoryAdjustmentMinFragmentDoc}
${InventoryAdjustmentItemFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${SerializeFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ContactMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}`;
export type CreateInventoryAdjustmentMutationFn = Apollo.MutationFunction<CreateInventoryAdjustmentMutation, CreateInventoryAdjustmentMutationVariables>;
export function useCreateInventoryAdjustmentMutation(baseOptions?: Apollo.MutationHookOptions<CreateInventoryAdjustmentMutation, CreateInventoryAdjustmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInventoryAdjustmentMutation, CreateInventoryAdjustmentMutationVariables>(CreateInventoryAdjustmentDocument, options);
      }
export type CreateInventoryAdjustmentMutationHookResult = ReturnType<typeof useCreateInventoryAdjustmentMutation>;
export type CreateInventoryAdjustmentMutationResult = Apollo.MutationResult<CreateInventoryAdjustmentMutation>;
export const CreateInventoryAdjustmentItemDocument = gql`
    mutation createInventoryAdjustmentItem($input: InventoryAdjustmentItemInput!) {
  createInventoryAdjustmentItem(data: $input) {
    data {
      ...InventoryAdjustmentItem
    }
  }
}
    ${InventoryAdjustmentItemFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${SerializeFragmentDoc}`;
export type CreateInventoryAdjustmentItemMutationFn = Apollo.MutationFunction<CreateInventoryAdjustmentItemMutation, CreateInventoryAdjustmentItemMutationVariables>;
export function useCreateInventoryAdjustmentItemMutation(baseOptions?: Apollo.MutationHookOptions<CreateInventoryAdjustmentItemMutation, CreateInventoryAdjustmentItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInventoryAdjustmentItemMutation, CreateInventoryAdjustmentItemMutationVariables>(CreateInventoryAdjustmentItemDocument, options);
      }
export type CreateInventoryAdjustmentItemMutationHookResult = ReturnType<typeof useCreateInventoryAdjustmentItemMutation>;
export type CreateInventoryAdjustmentItemMutationResult = Apollo.MutationResult<CreateInventoryAdjustmentItemMutation>;
export const CreateInventoryAdjustmentWithItemsByInventoryAuditDocument = gql`
    mutation createInventoryAdjustmentWithItemsByInventoryAudit($input: CreateInventoryAdjustmentWithItemsByInventoryAuditInput!) {
  createInventoryAdjustmentWithItemsByInventoryAudit(input: $input) {
    id
  }
}
    `;
export type CreateInventoryAdjustmentWithItemsByInventoryAuditMutationFn = Apollo.MutationFunction<CreateInventoryAdjustmentWithItemsByInventoryAuditMutation, CreateInventoryAdjustmentWithItemsByInventoryAuditMutationVariables>;
export function useCreateInventoryAdjustmentWithItemsByInventoryAuditMutation(baseOptions?: Apollo.MutationHookOptions<CreateInventoryAdjustmentWithItemsByInventoryAuditMutation, CreateInventoryAdjustmentWithItemsByInventoryAuditMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInventoryAdjustmentWithItemsByInventoryAuditMutation, CreateInventoryAdjustmentWithItemsByInventoryAuditMutationVariables>(CreateInventoryAdjustmentWithItemsByInventoryAuditDocument, options);
      }
export type CreateInventoryAdjustmentWithItemsByInventoryAuditMutationHookResult = ReturnType<typeof useCreateInventoryAdjustmentWithItemsByInventoryAuditMutation>;
export type CreateInventoryAdjustmentWithItemsByInventoryAuditMutationResult = Apollo.MutationResult<CreateInventoryAdjustmentWithItemsByInventoryAuditMutation>;
export const UpdateInventoryAdjustmentDocument = gql`
    mutation updateInventoryAdjustment($id: ID!, $input: InventoryAdjustmentInput!) {
  updateInventoryAdjustment(id: $id, data: $input) {
    data {
      ...InventoryAdjustment
    }
  }
}
    ${InventoryAdjustmentFragmentDoc}
${InventoryAdjustmentMinFragmentDoc}
${InventoryAdjustmentItemFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${SerializeFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ContactMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}`;
export type UpdateInventoryAdjustmentMutationFn = Apollo.MutationFunction<UpdateInventoryAdjustmentMutation, UpdateInventoryAdjustmentMutationVariables>;
export function useUpdateInventoryAdjustmentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInventoryAdjustmentMutation, UpdateInventoryAdjustmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInventoryAdjustmentMutation, UpdateInventoryAdjustmentMutationVariables>(UpdateInventoryAdjustmentDocument, options);
      }
export type UpdateInventoryAdjustmentMutationHookResult = ReturnType<typeof useUpdateInventoryAdjustmentMutation>;
export type UpdateInventoryAdjustmentMutationResult = Apollo.MutationResult<UpdateInventoryAdjustmentMutation>;
export const InventoryAdjustmentDocument = gql`
    query inventoryAdjustment($filters: InventoryAdjustmentFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  inventoryAdjustments(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...InventoryAdjustment
    }
    meta {
      ...Meta
    }
  }
}
    ${InventoryAdjustmentFragmentDoc}
${InventoryAdjustmentMinFragmentDoc}
${InventoryAdjustmentItemFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${SerializeFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ContactMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useInventoryAdjustmentQuery(baseOptions?: Apollo.QueryHookOptions<InventoryAdjustmentQuery, InventoryAdjustmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InventoryAdjustmentQuery, InventoryAdjustmentQueryVariables>(InventoryAdjustmentDocument, options);
      }
export function useInventoryAdjustmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InventoryAdjustmentQuery, InventoryAdjustmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InventoryAdjustmentQuery, InventoryAdjustmentQueryVariables>(InventoryAdjustmentDocument, options);
        }
export type InventoryAdjustmentQueryHookResult = ReturnType<typeof useInventoryAdjustmentQuery>;
export type InventoryAdjustmentLazyQueryHookResult = ReturnType<typeof useInventoryAdjustmentLazyQuery>;
export type InventoryAdjustmentQueryResult = Apollo.QueryResult<InventoryAdjustmentQuery, InventoryAdjustmentQueryVariables>;
export const InventoryAdjustmentByUuidDocument = gql`
    query inventoryAdjustmentByUuid($uuid: String!) {
  inventoryAdjustments(filters: {uuid: {eq: $uuid}}) {
    data {
      ...InventoryAdjustment
    }
  }
}
    ${InventoryAdjustmentFragmentDoc}
${InventoryAdjustmentMinFragmentDoc}
${InventoryAdjustmentItemFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${SerializeFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ContactMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}`;
export function useInventoryAdjustmentByUuidQuery(baseOptions: Apollo.QueryHookOptions<InventoryAdjustmentByUuidQuery, InventoryAdjustmentByUuidQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InventoryAdjustmentByUuidQuery, InventoryAdjustmentByUuidQueryVariables>(InventoryAdjustmentByUuidDocument, options);
      }
export function useInventoryAdjustmentByUuidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InventoryAdjustmentByUuidQuery, InventoryAdjustmentByUuidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InventoryAdjustmentByUuidQuery, InventoryAdjustmentByUuidQueryVariables>(InventoryAdjustmentByUuidDocument, options);
        }
export type InventoryAdjustmentByUuidQueryHookResult = ReturnType<typeof useInventoryAdjustmentByUuidQuery>;
export type InventoryAdjustmentByUuidLazyQueryHookResult = ReturnType<typeof useInventoryAdjustmentByUuidLazyQuery>;
export type InventoryAdjustmentByUuidQueryResult = Apollo.QueryResult<InventoryAdjustmentByUuidQuery, InventoryAdjustmentByUuidQueryVariables>;
export const CreateInventoryAuditDocument = gql`
    mutation createInventoryAudit($input: InventoryAuditInput!) {
  createInventoryAudit(data: $input) {
    data {
      ...InventoryAudit
    }
  }
}
    ${InventoryAuditFragmentDoc}
${InventoryAuditMinFragmentDoc}
${InventoryAuditItemFragmentDoc}
${InventoryAuditItemMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${FileFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${SerializeFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${SubLocationItemFragmentDoc}
${SubLocationFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ContactMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}`;
export type CreateInventoryAuditMutationFn = Apollo.MutationFunction<CreateInventoryAuditMutation, CreateInventoryAuditMutationVariables>;
export function useCreateInventoryAuditMutation(baseOptions?: Apollo.MutationHookOptions<CreateInventoryAuditMutation, CreateInventoryAuditMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInventoryAuditMutation, CreateInventoryAuditMutationVariables>(CreateInventoryAuditDocument, options);
      }
export type CreateInventoryAuditMutationHookResult = ReturnType<typeof useCreateInventoryAuditMutation>;
export type CreateInventoryAuditMutationResult = Apollo.MutationResult<CreateInventoryAuditMutation>;
export const CreateInventoryAuditItemDocument = gql`
    mutation createInventoryAuditItem($input: InventoryAuditItemInput!) {
  createInventoryAuditItem(data: $input) {
    data {
      ...InventoryAuditItem
    }
  }
}
    ${InventoryAuditItemFragmentDoc}
${InventoryAuditItemMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${FileFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${SerializeFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${SubLocationItemFragmentDoc}`;
export type CreateInventoryAuditItemMutationFn = Apollo.MutationFunction<CreateInventoryAuditItemMutation, CreateInventoryAuditItemMutationVariables>;
export function useCreateInventoryAuditItemMutation(baseOptions?: Apollo.MutationHookOptions<CreateInventoryAuditItemMutation, CreateInventoryAuditItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInventoryAuditItemMutation, CreateInventoryAuditItemMutationVariables>(CreateInventoryAuditItemDocument, options);
      }
export type CreateInventoryAuditItemMutationHookResult = ReturnType<typeof useCreateInventoryAuditItemMutation>;
export type CreateInventoryAuditItemMutationResult = Apollo.MutationResult<CreateInventoryAuditItemMutation>;
export const CreateInventoryAuditWithItemsDocument = gql`
    mutation createInventoryAuditWithItems($input: CreateInventoryAuditWithItemsInput!) {
  createInventoryAuditWithItems(input: $input) {
    data {
      ...InventoryAudit
    }
  }
}
    ${InventoryAuditFragmentDoc}
${InventoryAuditMinFragmentDoc}
${InventoryAuditItemFragmentDoc}
${InventoryAuditItemMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${FileFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${SerializeFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${SubLocationItemFragmentDoc}
${SubLocationFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ContactMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}`;
export type CreateInventoryAuditWithItemsMutationFn = Apollo.MutationFunction<CreateInventoryAuditWithItemsMutation, CreateInventoryAuditWithItemsMutationVariables>;
export function useCreateInventoryAuditWithItemsMutation(baseOptions?: Apollo.MutationHookOptions<CreateInventoryAuditWithItemsMutation, CreateInventoryAuditWithItemsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInventoryAuditWithItemsMutation, CreateInventoryAuditWithItemsMutationVariables>(CreateInventoryAuditWithItemsDocument, options);
      }
export type CreateInventoryAuditWithItemsMutationHookResult = ReturnType<typeof useCreateInventoryAuditWithItemsMutation>;
export type CreateInventoryAuditWithItemsMutationResult = Apollo.MutationResult<CreateInventoryAuditWithItemsMutation>;
export const UpdateInventoryAuditDocument = gql`
    mutation updateInventoryAudit($id: ID!, $input: InventoryAuditInput!) {
  updateInventoryAudit(id: $id, data: $input) {
    data {
      ...InventoryAuditMin
    }
  }
}
    ${InventoryAuditMinFragmentDoc}`;
export type UpdateInventoryAuditMutationFn = Apollo.MutationFunction<UpdateInventoryAuditMutation, UpdateInventoryAuditMutationVariables>;
export function useUpdateInventoryAuditMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInventoryAuditMutation, UpdateInventoryAuditMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInventoryAuditMutation, UpdateInventoryAuditMutationVariables>(UpdateInventoryAuditDocument, options);
      }
export type UpdateInventoryAuditMutationHookResult = ReturnType<typeof useUpdateInventoryAuditMutation>;
export type UpdateInventoryAuditMutationResult = Apollo.MutationResult<UpdateInventoryAuditMutation>;
export const UpdateInventoryAuditItemDocument = gql`
    mutation updateInventoryAuditItem($id: ID!, $input: InventoryAuditItemInput!) {
  updateInventoryAuditItem(id: $id, data: $input) {
    data {
      ...InventoryAuditItem
    }
  }
}
    ${InventoryAuditItemFragmentDoc}
${InventoryAuditItemMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${FileFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${SerializeFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${SubLocationItemFragmentDoc}`;
export type UpdateInventoryAuditItemMutationFn = Apollo.MutationFunction<UpdateInventoryAuditItemMutation, UpdateInventoryAuditItemMutationVariables>;
export function useUpdateInventoryAuditItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInventoryAuditItemMutation, UpdateInventoryAuditItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInventoryAuditItemMutation, UpdateInventoryAuditItemMutationVariables>(UpdateInventoryAuditItemDocument, options);
      }
export type UpdateInventoryAuditItemMutationHookResult = ReturnType<typeof useUpdateInventoryAuditItemMutation>;
export type UpdateInventoryAuditItemMutationResult = Apollo.MutationResult<UpdateInventoryAuditItemMutation>;
export const InventoryAuditDocument = gql`
    query inventoryAudit($filters: InventoryAuditFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  inventoryAudits(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...InventoryAudit
    }
    meta {
      ...Meta
    }
  }
}
    ${InventoryAuditFragmentDoc}
${InventoryAuditMinFragmentDoc}
${InventoryAuditItemFragmentDoc}
${InventoryAuditItemMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${FileFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${SerializeFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${SubLocationItemFragmentDoc}
${SubLocationFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ContactMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useInventoryAuditQuery(baseOptions?: Apollo.QueryHookOptions<InventoryAuditQuery, InventoryAuditQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InventoryAuditQuery, InventoryAuditQueryVariables>(InventoryAuditDocument, options);
      }
export function useInventoryAuditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InventoryAuditQuery, InventoryAuditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InventoryAuditQuery, InventoryAuditQueryVariables>(InventoryAuditDocument, options);
        }
export type InventoryAuditQueryHookResult = ReturnType<typeof useInventoryAuditQuery>;
export type InventoryAuditLazyQueryHookResult = ReturnType<typeof useInventoryAuditLazyQuery>;
export type InventoryAuditQueryResult = Apollo.QueryResult<InventoryAuditQuery, InventoryAuditQueryVariables>;
export const InventoryAuditItemDocument = gql`
    query inventoryAuditItem($id: ID!) {
  inventoryAuditItem(id: $id) {
    data {
      ...InventoryAuditItem
    }
  }
}
    ${InventoryAuditItemFragmentDoc}
${InventoryAuditItemMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${FileFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${SerializeFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${SubLocationItemFragmentDoc}`;
export function useInventoryAuditItemQuery(baseOptions: Apollo.QueryHookOptions<InventoryAuditItemQuery, InventoryAuditItemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InventoryAuditItemQuery, InventoryAuditItemQueryVariables>(InventoryAuditItemDocument, options);
      }
export function useInventoryAuditItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InventoryAuditItemQuery, InventoryAuditItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InventoryAuditItemQuery, InventoryAuditItemQueryVariables>(InventoryAuditItemDocument, options);
        }
export type InventoryAuditItemQueryHookResult = ReturnType<typeof useInventoryAuditItemQuery>;
export type InventoryAuditItemLazyQueryHookResult = ReturnType<typeof useInventoryAuditItemLazyQuery>;
export type InventoryAuditItemQueryResult = Apollo.QueryResult<InventoryAuditItemQuery, InventoryAuditItemQueryVariables>;
export const InventoryAuditItemRangeDataDocument = gql`
    query inventoryAuditItemRangeData($uuid: String!, $key: String!) {
  inventoryAuditItemRangeData(uuid: $uuid, key: $key) {
    min
    max
  }
}
    `;
export function useInventoryAuditItemRangeDataQuery(baseOptions: Apollo.QueryHookOptions<InventoryAuditItemRangeDataQuery, InventoryAuditItemRangeDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InventoryAuditItemRangeDataQuery, InventoryAuditItemRangeDataQueryVariables>(InventoryAuditItemRangeDataDocument, options);
      }
export function useInventoryAuditItemRangeDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InventoryAuditItemRangeDataQuery, InventoryAuditItemRangeDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InventoryAuditItemRangeDataQuery, InventoryAuditItemRangeDataQueryVariables>(InventoryAuditItemRangeDataDocument, options);
        }
export type InventoryAuditItemRangeDataQueryHookResult = ReturnType<typeof useInventoryAuditItemRangeDataQuery>;
export type InventoryAuditItemRangeDataLazyQueryHookResult = ReturnType<typeof useInventoryAuditItemRangeDataLazyQuery>;
export type InventoryAuditItemRangeDataQueryResult = Apollo.QueryResult<InventoryAuditItemRangeDataQuery, InventoryAuditItemRangeDataQueryVariables>;
export const InventoryAuditItemsDocument = gql`
    query inventoryAuditItems($filters: InventoryAuditItemFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  inventoryAuditItems(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...InventoryAuditItem
    }
    meta {
      ...Meta
    }
  }
}
    ${InventoryAuditItemFragmentDoc}
${InventoryAuditItemMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${FileFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${SerializeFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${SubLocationItemFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useInventoryAuditItemsQuery(baseOptions?: Apollo.QueryHookOptions<InventoryAuditItemsQuery, InventoryAuditItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InventoryAuditItemsQuery, InventoryAuditItemsQueryVariables>(InventoryAuditItemsDocument, options);
      }
export function useInventoryAuditItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InventoryAuditItemsQuery, InventoryAuditItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InventoryAuditItemsQuery, InventoryAuditItemsQueryVariables>(InventoryAuditItemsDocument, options);
        }
export type InventoryAuditItemsQueryHookResult = ReturnType<typeof useInventoryAuditItemsQuery>;
export type InventoryAuditItemsLazyQueryHookResult = ReturnType<typeof useInventoryAuditItemsLazyQuery>;
export type InventoryAuditItemsQueryResult = Apollo.QueryResult<InventoryAuditItemsQuery, InventoryAuditItemsQueryVariables>;
export const SendInvoiceByEmailDocument = gql`
    mutation sendInvoiceByEmail($id: ID!, $email: String, $contactId: ID, $fileType: String) {
  sendInvoiceByEmail(
    id: $id
    subjectEmail: $email
    contactId: $contactId
    fileType: $fileType
  )
}
    `;
export type SendInvoiceByEmailMutationFn = Apollo.MutationFunction<SendInvoiceByEmailMutation, SendInvoiceByEmailMutationVariables>;
export function useSendInvoiceByEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendInvoiceByEmailMutation, SendInvoiceByEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendInvoiceByEmailMutation, SendInvoiceByEmailMutationVariables>(SendInvoiceByEmailDocument, options);
      }
export type SendInvoiceByEmailMutationHookResult = ReturnType<typeof useSendInvoiceByEmailMutation>;
export type SendInvoiceByEmailMutationResult = Apollo.MutationResult<SendInvoiceByEmailMutation>;
export const SendWholesaleInvoiceDocument = gql`
    mutation sendWholesaleInvoice($id: ID!, $email: String, $subject: String, $body: String, $fileUrl: String) {
  sendWholesaleRequest(
    id: $id
    email: $email
    subject: $subject
    body: $body
    fileUrl: $fileUrl
  )
}
    `;
export type SendWholesaleInvoiceMutationFn = Apollo.MutationFunction<SendWholesaleInvoiceMutation, SendWholesaleInvoiceMutationVariables>;
export function useSendWholesaleInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<SendWholesaleInvoiceMutation, SendWholesaleInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendWholesaleInvoiceMutation, SendWholesaleInvoiceMutationVariables>(SendWholesaleInvoiceDocument, options);
      }
export type SendWholesaleInvoiceMutationHookResult = ReturnType<typeof useSendWholesaleInvoiceMutation>;
export type SendWholesaleInvoiceMutationResult = Apollo.MutationResult<SendWholesaleInvoiceMutation>;
export const UpdateInvoiceDocument = gql`
    mutation updateInvoice($id: ID!, $input: InvoiceInput!) {
  updateInvoice(id: $id, data: $input) {
    data {
      ...Invoice
    }
  }
}
    ${InvoiceFragmentDoc}
${InvoiceMinFragmentDoc}
${OrderFragmentDoc}
${OrderMinFragmentDoc}
${OrderWithItemsFragmentDoc}
${ProductOrderItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${SerializeFragmentDoc}
${DiscountMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${CompanyMinFragmentDoc}
${FileFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${CompositeProductOrderItemFragmentDoc}
${CompositeProductLocationInfoFragmentDoc}
${CompositeProductLocationInfoMinFragmentDoc}
${CompositeProductFragmentDoc}
${CompositeProductMinFragmentDoc}
${ProductFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${CompositeProductItemInfoFragmentDoc}
${CompositeProductItemInfoMinFragmentDoc}
${ServiceOrderItemFragmentDoc}
${ServicePerformerFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${ServiceMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}
${ClassOrderItemFragmentDoc}
${ClassPerformerFragmentDoc}
${ClassLocationInfoMinFragmentDoc}
${ClassMinFragmentDoc}
${MembershipOrderItemFragmentDoc}
${MembershipMinFragmentDoc}
${ContactFragmentDoc}
${ContactMinFragmentDoc}
${ContactDynamicValuesFragmentDoc}
${LeadFragmentDoc}
${LeadMinFragmentDoc}
${UserFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}
${CompanyFragmentDoc}
${DealTransactionMinFragmentDoc}
${InvoiceWithFileFragmentDoc}
${InvoiceShippingContactFragmentDoc}
${FileItemFragmentDoc}
${FileItemMinFragmentDoc}
${PurchaseRequestWithFileFragmentDoc}
${PurchaseRequestMinFragmentDoc}
${PurchaseRequestShippingInfoMinFragmentDoc}
${TaskMinFragmentDoc}
${SalesItemReportMinFragmentDoc}`;
export type UpdateInvoiceMutationFn = Apollo.MutationFunction<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>;
export function useUpdateInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>(UpdateInvoiceDocument, options);
      }
export type UpdateInvoiceMutationHookResult = ReturnType<typeof useUpdateInvoiceMutation>;
export type UpdateInvoiceMutationResult = Apollo.MutationResult<UpdateInvoiceMutation>;
export const UpdateInvoiceShippingContactDocument = gql`
    mutation updateInvoiceShippingContact($id: ID!, $input: InvoiceShippingContactInput!) {
  updateInvoiceShippingContact(id: $id, data: $input) {
    data {
      ...InvoiceShippingContact
    }
  }
}
    ${InvoiceShippingContactFragmentDoc}`;
export type UpdateInvoiceShippingContactMutationFn = Apollo.MutationFunction<UpdateInvoiceShippingContactMutation, UpdateInvoiceShippingContactMutationVariables>;
export function useUpdateInvoiceShippingContactMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInvoiceShippingContactMutation, UpdateInvoiceShippingContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInvoiceShippingContactMutation, UpdateInvoiceShippingContactMutationVariables>(UpdateInvoiceShippingContactDocument, options);
      }
export type UpdateInvoiceShippingContactMutationHookResult = ReturnType<typeof useUpdateInvoiceShippingContactMutation>;
export type UpdateInvoiceShippingContactMutationResult = Apollo.MutationResult<UpdateInvoiceShippingContactMutation>;
export const InvoiceByUuidDocument = gql`
    query invoiceByUuid($uuid: String!) {
  invoices(filters: {uuid: {eq: $uuid}}) {
    data {
      ...Invoice
    }
  }
}
    ${InvoiceFragmentDoc}
${InvoiceMinFragmentDoc}
${OrderFragmentDoc}
${OrderMinFragmentDoc}
${OrderWithItemsFragmentDoc}
${ProductOrderItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${SerializeFragmentDoc}
${DiscountMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${CompanyMinFragmentDoc}
${FileFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${CompositeProductOrderItemFragmentDoc}
${CompositeProductLocationInfoFragmentDoc}
${CompositeProductLocationInfoMinFragmentDoc}
${CompositeProductFragmentDoc}
${CompositeProductMinFragmentDoc}
${ProductFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${CompositeProductItemInfoFragmentDoc}
${CompositeProductItemInfoMinFragmentDoc}
${ServiceOrderItemFragmentDoc}
${ServicePerformerFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${ServiceMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}
${ClassOrderItemFragmentDoc}
${ClassPerformerFragmentDoc}
${ClassLocationInfoMinFragmentDoc}
${ClassMinFragmentDoc}
${MembershipOrderItemFragmentDoc}
${MembershipMinFragmentDoc}
${ContactFragmentDoc}
${ContactMinFragmentDoc}
${ContactDynamicValuesFragmentDoc}
${LeadFragmentDoc}
${LeadMinFragmentDoc}
${UserFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}
${CompanyFragmentDoc}
${DealTransactionMinFragmentDoc}
${InvoiceWithFileFragmentDoc}
${InvoiceShippingContactFragmentDoc}
${FileItemFragmentDoc}
${FileItemMinFragmentDoc}
${PurchaseRequestWithFileFragmentDoc}
${PurchaseRequestMinFragmentDoc}
${PurchaseRequestShippingInfoMinFragmentDoc}
${TaskMinFragmentDoc}
${SalesItemReportMinFragmentDoc}`;
export function useInvoiceByUuidQuery(baseOptions: Apollo.QueryHookOptions<InvoiceByUuidQuery, InvoiceByUuidQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvoiceByUuidQuery, InvoiceByUuidQueryVariables>(InvoiceByUuidDocument, options);
      }
export function useInvoiceByUuidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvoiceByUuidQuery, InvoiceByUuidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvoiceByUuidQuery, InvoiceByUuidQueryVariables>(InvoiceByUuidDocument, options);
        }
export type InvoiceByUuidQueryHookResult = ReturnType<typeof useInvoiceByUuidQuery>;
export type InvoiceByUuidLazyQueryHookResult = ReturnType<typeof useInvoiceByUuidLazyQuery>;
export type InvoiceByUuidQueryResult = Apollo.QueryResult<InvoiceByUuidQuery, InvoiceByUuidQueryVariables>;
export const InvoicesDocument = gql`
    query invoices($filters: InvoiceFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  invoices(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...Invoice
    }
    meta {
      ...Meta
    }
  }
}
    ${InvoiceFragmentDoc}
${InvoiceMinFragmentDoc}
${OrderFragmentDoc}
${OrderMinFragmentDoc}
${OrderWithItemsFragmentDoc}
${ProductOrderItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${SerializeFragmentDoc}
${DiscountMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${CompanyMinFragmentDoc}
${FileFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${CompositeProductOrderItemFragmentDoc}
${CompositeProductLocationInfoFragmentDoc}
${CompositeProductLocationInfoMinFragmentDoc}
${CompositeProductFragmentDoc}
${CompositeProductMinFragmentDoc}
${ProductFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${CompositeProductItemInfoFragmentDoc}
${CompositeProductItemInfoMinFragmentDoc}
${ServiceOrderItemFragmentDoc}
${ServicePerformerFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${ServiceMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}
${ClassOrderItemFragmentDoc}
${ClassPerformerFragmentDoc}
${ClassLocationInfoMinFragmentDoc}
${ClassMinFragmentDoc}
${MembershipOrderItemFragmentDoc}
${MembershipMinFragmentDoc}
${ContactFragmentDoc}
${ContactMinFragmentDoc}
${ContactDynamicValuesFragmentDoc}
${LeadFragmentDoc}
${LeadMinFragmentDoc}
${UserFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}
${CompanyFragmentDoc}
${DealTransactionMinFragmentDoc}
${InvoiceWithFileFragmentDoc}
${InvoiceShippingContactFragmentDoc}
${FileItemFragmentDoc}
${FileItemMinFragmentDoc}
${PurchaseRequestWithFileFragmentDoc}
${PurchaseRequestMinFragmentDoc}
${PurchaseRequestShippingInfoMinFragmentDoc}
${TaskMinFragmentDoc}
${SalesItemReportMinFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useInvoicesQuery(baseOptions?: Apollo.QueryHookOptions<InvoicesQuery, InvoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvoicesQuery, InvoicesQueryVariables>(InvoicesDocument, options);
      }
export function useInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvoicesQuery, InvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvoicesQuery, InvoicesQueryVariables>(InvoicesDocument, options);
        }
export type InvoicesQueryHookResult = ReturnType<typeof useInvoicesQuery>;
export type InvoicesLazyQueryHookResult = ReturnType<typeof useInvoicesLazyQuery>;
export type InvoicesQueryResult = Apollo.QueryResult<InvoicesQuery, InvoicesQueryVariables>;
export const CreateBoxPaperDocument = gql`
    mutation createBoxPaper($input: BoxPaperInput!) {
  createBoxPaper(data: $input) {
    data {
      ...BoxPaper
    }
  }
}
    ${BoxPaperFragmentDoc}`;
export type CreateBoxPaperMutationFn = Apollo.MutationFunction<CreateBoxPaperMutation, CreateBoxPaperMutationVariables>;
export function useCreateBoxPaperMutation(baseOptions?: Apollo.MutationHookOptions<CreateBoxPaperMutation, CreateBoxPaperMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBoxPaperMutation, CreateBoxPaperMutationVariables>(CreateBoxPaperDocument, options);
      }
export type CreateBoxPaperMutationHookResult = ReturnType<typeof useCreateBoxPaperMutation>;
export type CreateBoxPaperMutationResult = Apollo.MutationResult<CreateBoxPaperMutation>;
export const CreateBackingDocument = gql`
    mutation createBacking($input: BackingInput!) {
  createBacking(data: $input) {
    data {
      ...Backing
    }
  }
}
    ${BackingFragmentDoc}`;
export type CreateBackingMutationFn = Apollo.MutationFunction<CreateBackingMutation, CreateBackingMutationVariables>;
export function useCreateBackingMutation(baseOptions?: Apollo.MutationHookOptions<CreateBackingMutation, CreateBackingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBackingMutation, CreateBackingMutationVariables>(CreateBackingDocument, options);
      }
export type CreateBackingMutationHookResult = ReturnType<typeof useCreateBackingMutation>;
export type CreateBackingMutationResult = Apollo.MutationResult<CreateBackingMutation>;
export const CreateCountryDocument = gql`
    mutation createCountry($input: CountryInput!) {
  createCountry(data: $input) {
    data {
      ...Country
    }
  }
}
    ${CountryFragmentDoc}`;
export type CreateCountryMutationFn = Apollo.MutationFunction<CreateCountryMutation, CreateCountryMutationVariables>;
export function useCreateCountryMutation(baseOptions?: Apollo.MutationHookOptions<CreateCountryMutation, CreateCountryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCountryMutation, CreateCountryMutationVariables>(CreateCountryDocument, options);
      }
export type CreateCountryMutationHookResult = ReturnType<typeof useCreateCountryMutation>;
export type CreateCountryMutationResult = Apollo.MutationResult<CreateCountryMutation>;
export const CreateDesignStyleDocument = gql`
    mutation createDesignStyle($input: DesignStyleInput!) {
  createDesignStyle(data: $input) {
    data {
      ...DesignStyle
    }
  }
}
    ${DesignStyleFragmentDoc}`;
export type CreateDesignStyleMutationFn = Apollo.MutationFunction<CreateDesignStyleMutation, CreateDesignStyleMutationVariables>;
export function useCreateDesignStyleMutation(baseOptions?: Apollo.MutationHookOptions<CreateDesignStyleMutation, CreateDesignStyleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDesignStyleMutation, CreateDesignStyleMutationVariables>(CreateDesignStyleDocument, options);
      }
export type CreateDesignStyleMutationHookResult = ReturnType<typeof useCreateDesignStyleMutation>;
export type CreateDesignStyleMutationResult = Apollo.MutationResult<CreateDesignStyleMutation>;
export const CreateEngravingTypeDocument = gql`
    mutation createEngravingType($input: EngravingTypeInput!) {
  createEngravingType(data: $input) {
    data {
      ...EngravingType
    }
  }
}
    ${EngravingTypeFragmentDoc}`;
export type CreateEngravingTypeMutationFn = Apollo.MutationFunction<CreateEngravingTypeMutation, CreateEngravingTypeMutationVariables>;
export function useCreateEngravingTypeMutation(baseOptions?: Apollo.MutationHookOptions<CreateEngravingTypeMutation, CreateEngravingTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEngravingTypeMutation, CreateEngravingTypeMutationVariables>(CreateEngravingTypeDocument, options);
      }
export type CreateEngravingTypeMutationHookResult = ReturnType<typeof useCreateEngravingTypeMutation>;
export type CreateEngravingTypeMutationResult = Apollo.MutationResult<CreateEngravingTypeMutation>;
export const CreateJewelryConditionTypeDocument = gql`
    mutation createJewelryConditionType($input: ConditionTypeInput!) {
  createConditionType(data: $input) {
    data {
      ...JewelryConditionType
    }
  }
}
    ${JewelryConditionTypeFragmentDoc}`;
export type CreateJewelryConditionTypeMutationFn = Apollo.MutationFunction<CreateJewelryConditionTypeMutation, CreateJewelryConditionTypeMutationVariables>;
export function useCreateJewelryConditionTypeMutation(baseOptions?: Apollo.MutationHookOptions<CreateJewelryConditionTypeMutation, CreateJewelryConditionTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateJewelryConditionTypeMutation, CreateJewelryConditionTypeMutationVariables>(CreateJewelryConditionTypeDocument, options);
      }
export type CreateJewelryConditionTypeMutationHookResult = ReturnType<typeof useCreateJewelryConditionTypeMutation>;
export type CreateJewelryConditionTypeMutationResult = Apollo.MutationResult<CreateJewelryConditionTypeMutation>;
export const CreateJewelryGenderTypeDocument = gql`
    mutation createJewelryGenderType($input: GenderTypeInput!) {
  createGenderType(data: $input) {
    data {
      ...JewelryGenderType
    }
  }
}
    ${JewelryGenderTypeFragmentDoc}`;
export type CreateJewelryGenderTypeMutationFn = Apollo.MutationFunction<CreateJewelryGenderTypeMutation, CreateJewelryGenderTypeMutationVariables>;
export function useCreateJewelryGenderTypeMutation(baseOptions?: Apollo.MutationHookOptions<CreateJewelryGenderTypeMutation, CreateJewelryGenderTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateJewelryGenderTypeMutation, CreateJewelryGenderTypeMutationVariables>(CreateJewelryGenderTypeDocument, options);
      }
export type CreateJewelryGenderTypeMutationHookResult = ReturnType<typeof useCreateJewelryGenderTypeMutation>;
export type CreateJewelryGenderTypeMutationResult = Apollo.MutationResult<CreateJewelryGenderTypeMutation>;
export const CreateJewelryProductDocument = gql`
    mutation createJewelryProduct($input: JewelryProductInput!) {
  createJewelryProduct(data: $input) {
    data {
      ...JewelryProduct
    }
  }
}
    ${JewelryProductFragmentDoc}
${JewelryProductMinFragmentDoc}
${FileFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${ProductBrandFragmentDoc}
${RentableDataFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${ProductMinFragmentDoc}
${ProductTypeFragmentDoc}
${SerializeFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}`;
export type CreateJewelryProductMutationFn = Apollo.MutationFunction<CreateJewelryProductMutation, CreateJewelryProductMutationVariables>;
export function useCreateJewelryProductMutation(baseOptions?: Apollo.MutationHookOptions<CreateJewelryProductMutation, CreateJewelryProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateJewelryProductMutation, CreateJewelryProductMutationVariables>(CreateJewelryProductDocument, options);
      }
export type CreateJewelryProductMutationHookResult = ReturnType<typeof useCreateJewelryProductMutation>;
export type CreateJewelryProductMutationResult = Apollo.MutationResult<CreateJewelryProductMutation>;
export const CreateJewelryProductTypeDocument = gql`
    mutation createJewelryProductType($input: JewelryProductTypeInput!) {
  createJewelryProductType(data: $input) {
    data {
      ...JewelryProductType
    }
  }
}
    ${JewelryProductTypeFragmentDoc}`;
export type CreateJewelryProductTypeMutationFn = Apollo.MutationFunction<CreateJewelryProductTypeMutation, CreateJewelryProductTypeMutationVariables>;
export function useCreateJewelryProductTypeMutation(baseOptions?: Apollo.MutationHookOptions<CreateJewelryProductTypeMutation, CreateJewelryProductTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateJewelryProductTypeMutation, CreateJewelryProductTypeMutationVariables>(CreateJewelryProductTypeDocument, options);
      }
export type CreateJewelryProductTypeMutationHookResult = ReturnType<typeof useCreateJewelryProductTypeMutation>;
export type CreateJewelryProductTypeMutationResult = Apollo.MutationResult<CreateJewelryProductTypeMutation>;
export const CreateKnotStyleDocument = gql`
    mutation createKnotStyle($input: KnotStyleInput!) {
  createKnotStyle(data: $input) {
    data {
      ...KnotStyle
    }
  }
}
    ${KnotStyleFragmentDoc}`;
export type CreateKnotStyleMutationFn = Apollo.MutationFunction<CreateKnotStyleMutation, CreateKnotStyleMutationVariables>;
export function useCreateKnotStyleMutation(baseOptions?: Apollo.MutationHookOptions<CreateKnotStyleMutation, CreateKnotStyleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateKnotStyleMutation, CreateKnotStyleMutationVariables>(CreateKnotStyleDocument, options);
      }
export type CreateKnotStyleMutationHookResult = ReturnType<typeof useCreateKnotStyleMutation>;
export type CreateKnotStyleMutationResult = Apollo.MutationResult<CreateKnotStyleMutation>;
export const CreateLinkStyleDocument = gql`
    mutation createLinkStyle($input: LinkStyleInput!) {
  createLinkStyle(data: $input) {
    data {
      ...LinkStyle
    }
  }
}
    ${LinkStyleFragmentDoc}`;
export type CreateLinkStyleMutationFn = Apollo.MutationFunction<CreateLinkStyleMutation, CreateLinkStyleMutationVariables>;
export function useCreateLinkStyleMutation(baseOptions?: Apollo.MutationHookOptions<CreateLinkStyleMutation, CreateLinkStyleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLinkStyleMutation, CreateLinkStyleMutationVariables>(CreateLinkStyleDocument, options);
      }
export type CreateLinkStyleMutationHookResult = ReturnType<typeof useCreateLinkStyleMutation>;
export type CreateLinkStyleMutationResult = Apollo.MutationResult<CreateLinkStyleMutation>;
export const CreateLinkTypeDocument = gql`
    mutation createLinkType($input: LinkTypeInput!) {
  createLinkType(data: $input) {
    data {
      ...LinkType
    }
  }
}
    ${LinkTypeFragmentDoc}`;
export type CreateLinkTypeMutationFn = Apollo.MutationFunction<CreateLinkTypeMutation, CreateLinkTypeMutationVariables>;
export function useCreateLinkTypeMutation(baseOptions?: Apollo.MutationHookOptions<CreateLinkTypeMutation, CreateLinkTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLinkTypeMutation, CreateLinkTypeMutationVariables>(CreateLinkTypeDocument, options);
      }
export type CreateLinkTypeMutationHookResult = ReturnType<typeof useCreateLinkTypeMutation>;
export type CreateLinkTypeMutationResult = Apollo.MutationResult<CreateLinkTypeMutation>;
export const CreateMaterialGradeDocument = gql`
    mutation createMaterialGrade($input: MaterialGradeInput!) {
  createMaterialGrade(data: $input) {
    data {
      ...MaterialGrade
    }
  }
}
    ${MaterialGradeFragmentDoc}`;
export type CreateMaterialGradeMutationFn = Apollo.MutationFunction<CreateMaterialGradeMutation, CreateMaterialGradeMutationVariables>;
export function useCreateMaterialGradeMutation(baseOptions?: Apollo.MutationHookOptions<CreateMaterialGradeMutation, CreateMaterialGradeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMaterialGradeMutation, CreateMaterialGradeMutationVariables>(CreateMaterialGradeDocument, options);
      }
export type CreateMaterialGradeMutationHookResult = ReturnType<typeof useCreateMaterialGradeMutation>;
export type CreateMaterialGradeMutationResult = Apollo.MutationResult<CreateMaterialGradeMutation>;
export const CreateMetalFinishTypeDocument = gql`
    mutation createMetalFinishType($input: MetalFinishTypeInput!) {
  createMetalFinishType(data: $input) {
    data {
      ...MetalFinishType
    }
  }
}
    ${MetalFinishTypeFragmentDoc}`;
export type CreateMetalFinishTypeMutationFn = Apollo.MutationFunction<CreateMetalFinishTypeMutation, CreateMetalFinishTypeMutationVariables>;
export function useCreateMetalFinishTypeMutation(baseOptions?: Apollo.MutationHookOptions<CreateMetalFinishTypeMutation, CreateMetalFinishTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMetalFinishTypeMutation, CreateMetalFinishTypeMutationVariables>(CreateMetalFinishTypeDocument, options);
      }
export type CreateMetalFinishTypeMutationHookResult = ReturnType<typeof useCreateMetalFinishTypeMutation>;
export type CreateMetalFinishTypeMutationResult = Apollo.MutationResult<CreateMetalFinishTypeMutation>;
export const CreateMetalTypeDocument = gql`
    mutation createMetalType($input: MetalTypeInput!) {
  createMetalType(data: $input) {
    data {
      ...MetalType
    }
  }
}
    ${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}`;
export type CreateMetalTypeMutationFn = Apollo.MutationFunction<CreateMetalTypeMutation, CreateMetalTypeMutationVariables>;
export function useCreateMetalTypeMutation(baseOptions?: Apollo.MutationHookOptions<CreateMetalTypeMutation, CreateMetalTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMetalTypeMutation, CreateMetalTypeMutationVariables>(CreateMetalTypeDocument, options);
      }
export type CreateMetalTypeMutationHookResult = ReturnType<typeof useCreateMetalTypeMutation>;
export type CreateMetalTypeMutationResult = Apollo.MutationResult<CreateMetalTypeMutation>;
export const CreatePieceDocument = gql`
    mutation createPiece($input: PieceInput!) {
  createPiece(data: $input) {
    data {
      ...Piece
    }
  }
}
    ${PieceFragmentDoc}`;
export type CreatePieceMutationFn = Apollo.MutationFunction<CreatePieceMutation, CreatePieceMutationVariables>;
export function useCreatePieceMutation(baseOptions?: Apollo.MutationHookOptions<CreatePieceMutation, CreatePieceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePieceMutation, CreatePieceMutationVariables>(CreatePieceDocument, options);
      }
export type CreatePieceMutationHookResult = ReturnType<typeof useCreatePieceMutation>;
export type CreatePieceMutationResult = Apollo.MutationResult<CreatePieceMutation>;
export const CreatePlattingTypeDocument = gql`
    mutation createPlattingType($input: PlattingTypeInput!) {
  createPlattingType(data: $input) {
    data {
      ...PlattingType
    }
  }
}
    ${PlattingTypeFragmentDoc}`;
export type CreatePlattingTypeMutationFn = Apollo.MutationFunction<CreatePlattingTypeMutation, CreatePlattingTypeMutationVariables>;
export function useCreatePlattingTypeMutation(baseOptions?: Apollo.MutationHookOptions<CreatePlattingTypeMutation, CreatePlattingTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePlattingTypeMutation, CreatePlattingTypeMutationVariables>(CreatePlattingTypeDocument, options);
      }
export type CreatePlattingTypeMutationHookResult = ReturnType<typeof useCreatePlattingTypeMutation>;
export type CreatePlattingTypeMutationResult = Apollo.MutationResult<CreatePlattingTypeMutation>;
export const CreateShankStyleDocument = gql`
    mutation createShankStyle($input: ShankStyleInput!) {
  createShankStyle(data: $input) {
    data {
      ...ShankStyle
    }
  }
}
    ${ShankStyleFragmentDoc}`;
export type CreateShankStyleMutationFn = Apollo.MutationFunction<CreateShankStyleMutation, CreateShankStyleMutationVariables>;
export function useCreateShankStyleMutation(baseOptions?: Apollo.MutationHookOptions<CreateShankStyleMutation, CreateShankStyleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateShankStyleMutation, CreateShankStyleMutationVariables>(CreateShankStyleDocument, options);
      }
export type CreateShankStyleMutationHookResult = ReturnType<typeof useCreateShankStyleMutation>;
export type CreateShankStyleMutationResult = Apollo.MutationResult<CreateShankStyleMutation>;
export const CreateSizeDocument = gql`
    mutation createSize($input: SizeInput!) {
  createSize(data: $input) {
    data {
      ...Size
    }
  }
}
    ${SizeFragmentDoc}`;
export type CreateSizeMutationFn = Apollo.MutationFunction<CreateSizeMutation, CreateSizeMutationVariables>;
export function useCreateSizeMutation(baseOptions?: Apollo.MutationHookOptions<CreateSizeMutation, CreateSizeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSizeMutation, CreateSizeMutationVariables>(CreateSizeDocument, options);
      }
export type CreateSizeMutationHookResult = ReturnType<typeof useCreateSizeMutation>;
export type CreateSizeMutationResult = Apollo.MutationResult<CreateSizeMutation>;
export const CreateSpecificTypeDocument = gql`
    mutation createSpecificType($input: SpecificTypeInput!) {
  createSpecificType(data: $input) {
    data {
      ...SpecificType
    }
  }
}
    ${SpecificTypeFragmentDoc}`;
export type CreateSpecificTypeMutationFn = Apollo.MutationFunction<CreateSpecificTypeMutation, CreateSpecificTypeMutationVariables>;
export function useCreateSpecificTypeMutation(baseOptions?: Apollo.MutationHookOptions<CreateSpecificTypeMutation, CreateSpecificTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSpecificTypeMutation, CreateSpecificTypeMutationVariables>(CreateSpecificTypeDocument, options);
      }
export type CreateSpecificTypeMutationHookResult = ReturnType<typeof useCreateSpecificTypeMutation>;
export type CreateSpecificTypeMutationResult = Apollo.MutationResult<CreateSpecificTypeMutation>;
export const CreateStrandDocument = gql`
    mutation createStrand($input: StrandInput!) {
  createStrand(data: $input) {
    data {
      ...Strand
    }
  }
}
    ${StrandFragmentDoc}`;
export type CreateStrandMutationFn = Apollo.MutationFunction<CreateStrandMutation, CreateStrandMutationVariables>;
export function useCreateStrandMutation(baseOptions?: Apollo.MutationHookOptions<CreateStrandMutation, CreateStrandMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStrandMutation, CreateStrandMutationVariables>(CreateStrandDocument, options);
      }
export type CreateStrandMutationHookResult = ReturnType<typeof useCreateStrandMutation>;
export type CreateStrandMutationResult = Apollo.MutationResult<CreateStrandMutation>;
export const CreateStrandsLengthDocument = gql`
    mutation createStrandsLength($input: StrandsLengthInput!) {
  createStrandsLength(data: $input) {
    data {
      ...StrandsLength
    }
  }
}
    ${StrandsLengthFragmentDoc}`;
export type CreateStrandsLengthMutationFn = Apollo.MutationFunction<CreateStrandsLengthMutation, CreateStrandsLengthMutationVariables>;
export function useCreateStrandsLengthMutation(baseOptions?: Apollo.MutationHookOptions<CreateStrandsLengthMutation, CreateStrandsLengthMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStrandsLengthMutation, CreateStrandsLengthMutationVariables>(CreateStrandsLengthDocument, options);
      }
export type CreateStrandsLengthMutationHookResult = ReturnType<typeof useCreateStrandsLengthMutation>;
export type CreateStrandsLengthMutationResult = Apollo.MutationResult<CreateStrandsLengthMutation>;
export const CreateTimePeriodDocument = gql`
    mutation createTimePeriod($input: TimePeriodInput!) {
  createTimePeriod(data: $input) {
    data {
      ...TimePeriod
    }
  }
}
    ${TimePeriodFragmentDoc}`;
export type CreateTimePeriodMutationFn = Apollo.MutationFunction<CreateTimePeriodMutation, CreateTimePeriodMutationVariables>;
export function useCreateTimePeriodMutation(baseOptions?: Apollo.MutationHookOptions<CreateTimePeriodMutation, CreateTimePeriodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTimePeriodMutation, CreateTimePeriodMutationVariables>(CreateTimePeriodDocument, options);
      }
export type CreateTimePeriodMutationHookResult = ReturnType<typeof useCreateTimePeriodMutation>;
export type CreateTimePeriodMutationResult = Apollo.MutationResult<CreateTimePeriodMutation>;
export const CreateManufacturingProcessDocument = gql`
    mutation createManufacturingProcess($input: ManufacturingProcessInput!) {
  createManufacturingProcess(data: $input) {
    data {
      ...ManufacturingProcess
    }
  }
}
    ${ManufacturingProcessFragmentDoc}`;
export type CreateManufacturingProcessMutationFn = Apollo.MutationFunction<CreateManufacturingProcessMutation, CreateManufacturingProcessMutationVariables>;
export function useCreateManufacturingProcessMutation(baseOptions?: Apollo.MutationHookOptions<CreateManufacturingProcessMutation, CreateManufacturingProcessMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateManufacturingProcessMutation, CreateManufacturingProcessMutationVariables>(CreateManufacturingProcessDocument, options);
      }
export type CreateManufacturingProcessMutationHookResult = ReturnType<typeof useCreateManufacturingProcessMutation>;
export type CreateManufacturingProcessMutationResult = Apollo.MutationResult<CreateManufacturingProcessMutation>;
export const UpdateJewelryProductDocument = gql`
    mutation updateJewelryProduct($id: ID!, $input: JewelryProductInput!) {
  updateJewelryProduct(id: $id, data: $input) {
    data {
      ...JewelryProduct
    }
  }
}
    ${JewelryProductFragmentDoc}
${JewelryProductMinFragmentDoc}
${FileFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${ProductBrandFragmentDoc}
${RentableDataFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${ProductMinFragmentDoc}
${ProductTypeFragmentDoc}
${SerializeFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}`;
export type UpdateJewelryProductMutationFn = Apollo.MutationFunction<UpdateJewelryProductMutation, UpdateJewelryProductMutationVariables>;
export function useUpdateJewelryProductMutation(baseOptions?: Apollo.MutationHookOptions<UpdateJewelryProductMutation, UpdateJewelryProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateJewelryProductMutation, UpdateJewelryProductMutationVariables>(UpdateJewelryProductDocument, options);
      }
export type UpdateJewelryProductMutationHookResult = ReturnType<typeof useUpdateJewelryProductMutation>;
export type UpdateJewelryProductMutationResult = Apollo.MutationResult<UpdateJewelryProductMutation>;
export const UpdateMetalTypeDocument = gql`
    mutation updateMetalType($id: ID!, $input: MetalTypeInput!) {
  updateMetalType(id: $id, data: $input) {
    data {
      ...MetalType
    }
  }
}
    ${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}`;
export type UpdateMetalTypeMutationFn = Apollo.MutationFunction<UpdateMetalTypeMutation, UpdateMetalTypeMutationVariables>;
export function useUpdateMetalTypeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMetalTypeMutation, UpdateMetalTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMetalTypeMutation, UpdateMetalTypeMutationVariables>(UpdateMetalTypeDocument, options);
      }
export type UpdateMetalTypeMutationHookResult = ReturnType<typeof useUpdateMetalTypeMutation>;
export type UpdateMetalTypeMutationResult = Apollo.MutationResult<UpdateMetalTypeMutation>;
export const AllJewelryProductsDocument = gql`
    query allJewelryProducts($filters: JewelryProductFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  jewelryProducts(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...JewelryProduct
    }
    meta {
      ...Meta
    }
  }
}
    ${JewelryProductFragmentDoc}
${JewelryProductMinFragmentDoc}
${FileFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${ProductBrandFragmentDoc}
${RentableDataFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${ProductMinFragmentDoc}
${ProductTypeFragmentDoc}
${SerializeFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useAllJewelryProductsQuery(baseOptions?: Apollo.QueryHookOptions<AllJewelryProductsQuery, AllJewelryProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllJewelryProductsQuery, AllJewelryProductsQueryVariables>(AllJewelryProductsDocument, options);
      }
export function useAllJewelryProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllJewelryProductsQuery, AllJewelryProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllJewelryProductsQuery, AllJewelryProductsQueryVariables>(AllJewelryProductsDocument, options);
        }
export type AllJewelryProductsQueryHookResult = ReturnType<typeof useAllJewelryProductsQuery>;
export type AllJewelryProductsLazyQueryHookResult = ReturnType<typeof useAllJewelryProductsLazyQuery>;
export type AllJewelryProductsQueryResult = Apollo.QueryResult<AllJewelryProductsQuery, AllJewelryProductsQueryVariables>;
export const BackingsDocument = gql`
    query backings($filters: BackingFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  backings(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...Backing
    }
    meta {
      ...Meta
    }
  }
}
    ${BackingFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useBackingsQuery(baseOptions?: Apollo.QueryHookOptions<BackingsQuery, BackingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BackingsQuery, BackingsQueryVariables>(BackingsDocument, options);
      }
export function useBackingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BackingsQuery, BackingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BackingsQuery, BackingsQueryVariables>(BackingsDocument, options);
        }
export type BackingsQueryHookResult = ReturnType<typeof useBackingsQuery>;
export type BackingsLazyQueryHookResult = ReturnType<typeof useBackingsLazyQuery>;
export type BackingsQueryResult = Apollo.QueryResult<BackingsQuery, BackingsQueryVariables>;
export const BoxPapersDocument = gql`
    query boxPapers($filters: BoxPaperFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  boxPapers(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...BoxPaper
    }
    meta {
      ...Meta
    }
  }
}
    ${BoxPaperFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useBoxPapersQuery(baseOptions?: Apollo.QueryHookOptions<BoxPapersQuery, BoxPapersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BoxPapersQuery, BoxPapersQueryVariables>(BoxPapersDocument, options);
      }
export function useBoxPapersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BoxPapersQuery, BoxPapersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BoxPapersQuery, BoxPapersQueryVariables>(BoxPapersDocument, options);
        }
export type BoxPapersQueryHookResult = ReturnType<typeof useBoxPapersQuery>;
export type BoxPapersLazyQueryHookResult = ReturnType<typeof useBoxPapersLazyQuery>;
export type BoxPapersQueryResult = Apollo.QueryResult<BoxPapersQuery, BoxPapersQueryVariables>;
export const CountriesDocument = gql`
    query countries($filters: CountryFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  countries(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...Country
    }
    meta {
      ...Meta
    }
  }
}
    ${CountryFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useCountriesQuery(baseOptions?: Apollo.QueryHookOptions<CountriesQuery, CountriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CountriesQuery, CountriesQueryVariables>(CountriesDocument, options);
      }
export function useCountriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountriesQuery, CountriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CountriesQuery, CountriesQueryVariables>(CountriesDocument, options);
        }
export type CountriesQueryHookResult = ReturnType<typeof useCountriesQuery>;
export type CountriesLazyQueryHookResult = ReturnType<typeof useCountriesLazyQuery>;
export type CountriesQueryResult = Apollo.QueryResult<CountriesQuery, CountriesQueryVariables>;
export const DesignStylesDocument = gql`
    query designStyles($filters: DesignStyleFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  designStyles(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...DesignStyle
    }
    meta {
      ...Meta
    }
  }
}
    ${DesignStyleFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useDesignStylesQuery(baseOptions?: Apollo.QueryHookOptions<DesignStylesQuery, DesignStylesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DesignStylesQuery, DesignStylesQueryVariables>(DesignStylesDocument, options);
      }
export function useDesignStylesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DesignStylesQuery, DesignStylesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DesignStylesQuery, DesignStylesQueryVariables>(DesignStylesDocument, options);
        }
export type DesignStylesQueryHookResult = ReturnType<typeof useDesignStylesQuery>;
export type DesignStylesLazyQueryHookResult = ReturnType<typeof useDesignStylesLazyQuery>;
export type DesignStylesQueryResult = Apollo.QueryResult<DesignStylesQuery, DesignStylesQueryVariables>;
export const EngravingTypesDocument = gql`
    query engravingTypes($filters: EngravingTypeFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  engravingTypes(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...EngravingType
    }
    meta {
      ...Meta
    }
  }
}
    ${EngravingTypeFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useEngravingTypesQuery(baseOptions?: Apollo.QueryHookOptions<EngravingTypesQuery, EngravingTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EngravingTypesQuery, EngravingTypesQueryVariables>(EngravingTypesDocument, options);
      }
export function useEngravingTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EngravingTypesQuery, EngravingTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EngravingTypesQuery, EngravingTypesQueryVariables>(EngravingTypesDocument, options);
        }
export type EngravingTypesQueryHookResult = ReturnType<typeof useEngravingTypesQuery>;
export type EngravingTypesLazyQueryHookResult = ReturnType<typeof useEngravingTypesLazyQuery>;
export type EngravingTypesQueryResult = Apollo.QueryResult<EngravingTypesQuery, EngravingTypesQueryVariables>;
export const JewelryConditionTypesDocument = gql`
    query jewelryConditionTypes($filters: ConditionTypeFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  conditionTypes(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...JewelryConditionType
    }
    meta {
      ...Meta
    }
  }
}
    ${JewelryConditionTypeFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useJewelryConditionTypesQuery(baseOptions?: Apollo.QueryHookOptions<JewelryConditionTypesQuery, JewelryConditionTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JewelryConditionTypesQuery, JewelryConditionTypesQueryVariables>(JewelryConditionTypesDocument, options);
      }
export function useJewelryConditionTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JewelryConditionTypesQuery, JewelryConditionTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JewelryConditionTypesQuery, JewelryConditionTypesQueryVariables>(JewelryConditionTypesDocument, options);
        }
export type JewelryConditionTypesQueryHookResult = ReturnType<typeof useJewelryConditionTypesQuery>;
export type JewelryConditionTypesLazyQueryHookResult = ReturnType<typeof useJewelryConditionTypesLazyQuery>;
export type JewelryConditionTypesQueryResult = Apollo.QueryResult<JewelryConditionTypesQuery, JewelryConditionTypesQueryVariables>;
export const JewelryGenderTypesDocument = gql`
    query jewelryGenderTypes($filters: GenderTypeFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  genderTypes(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...JewelryGenderType
    }
    meta {
      ...Meta
    }
  }
}
    ${JewelryGenderTypeFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useJewelryGenderTypesQuery(baseOptions?: Apollo.QueryHookOptions<JewelryGenderTypesQuery, JewelryGenderTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JewelryGenderTypesQuery, JewelryGenderTypesQueryVariables>(JewelryGenderTypesDocument, options);
      }
export function useJewelryGenderTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JewelryGenderTypesQuery, JewelryGenderTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JewelryGenderTypesQuery, JewelryGenderTypesQueryVariables>(JewelryGenderTypesDocument, options);
        }
export type JewelryGenderTypesQueryHookResult = ReturnType<typeof useJewelryGenderTypesQuery>;
export type JewelryGenderTypesLazyQueryHookResult = ReturnType<typeof useJewelryGenderTypesLazyQuery>;
export type JewelryGenderTypesQueryResult = Apollo.QueryResult<JewelryGenderTypesQuery, JewelryGenderTypesQueryVariables>;
export const JewelryProductByUuidDocument = gql`
    query jewelryProductByUuid($uuid: String!) {
  jewelryProducts(filters: {uuid: {eq: $uuid}}) {
    data {
      ...JewelryProduct
    }
  }
}
    ${JewelryProductFragmentDoc}
${JewelryProductMinFragmentDoc}
${FileFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${ProductBrandFragmentDoc}
${RentableDataFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${ProductMinFragmentDoc}
${ProductTypeFragmentDoc}
${SerializeFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}`;
export function useJewelryProductByUuidQuery(baseOptions: Apollo.QueryHookOptions<JewelryProductByUuidQuery, JewelryProductByUuidQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JewelryProductByUuidQuery, JewelryProductByUuidQueryVariables>(JewelryProductByUuidDocument, options);
      }
export function useJewelryProductByUuidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JewelryProductByUuidQuery, JewelryProductByUuidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JewelryProductByUuidQuery, JewelryProductByUuidQueryVariables>(JewelryProductByUuidDocument, options);
        }
export type JewelryProductByUuidQueryHookResult = ReturnType<typeof useJewelryProductByUuidQuery>;
export type JewelryProductByUuidLazyQueryHookResult = ReturnType<typeof useJewelryProductByUuidLazyQuery>;
export type JewelryProductByUuidQueryResult = Apollo.QueryResult<JewelryProductByUuidQuery, JewelryProductByUuidQueryVariables>;
export const JewelryProductTypesDocument = gql`
    query jewelryProductTypes($filters: JewelryProductTypeFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  jewelryProductTypes(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...JewelryProductType
    }
    meta {
      ...Meta
    }
  }
}
    ${JewelryProductTypeFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useJewelryProductTypesQuery(baseOptions?: Apollo.QueryHookOptions<JewelryProductTypesQuery, JewelryProductTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JewelryProductTypesQuery, JewelryProductTypesQueryVariables>(JewelryProductTypesDocument, options);
      }
export function useJewelryProductTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JewelryProductTypesQuery, JewelryProductTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JewelryProductTypesQuery, JewelryProductTypesQueryVariables>(JewelryProductTypesDocument, options);
        }
export type JewelryProductTypesQueryHookResult = ReturnType<typeof useJewelryProductTypesQuery>;
export type JewelryProductTypesLazyQueryHookResult = ReturnType<typeof useJewelryProductTypesLazyQuery>;
export type JewelryProductTypesQueryResult = Apollo.QueryResult<JewelryProductTypesQuery, JewelryProductTypesQueryVariables>;
export const KnotStylesDocument = gql`
    query knotStyles($filters: KnotStyleFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  knotStyles(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...KnotStyle
    }
    meta {
      ...Meta
    }
  }
}
    ${KnotStyleFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useKnotStylesQuery(baseOptions?: Apollo.QueryHookOptions<KnotStylesQuery, KnotStylesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KnotStylesQuery, KnotStylesQueryVariables>(KnotStylesDocument, options);
      }
export function useKnotStylesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KnotStylesQuery, KnotStylesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KnotStylesQuery, KnotStylesQueryVariables>(KnotStylesDocument, options);
        }
export type KnotStylesQueryHookResult = ReturnType<typeof useKnotStylesQuery>;
export type KnotStylesLazyQueryHookResult = ReturnType<typeof useKnotStylesLazyQuery>;
export type KnotStylesQueryResult = Apollo.QueryResult<KnotStylesQuery, KnotStylesQueryVariables>;
export const LinkStylesDocument = gql`
    query linkStyles($filters: LinkStyleFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  linkStyles(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...LinkStyle
    }
    meta {
      ...Meta
    }
  }
}
    ${LinkStyleFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useLinkStylesQuery(baseOptions?: Apollo.QueryHookOptions<LinkStylesQuery, LinkStylesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LinkStylesQuery, LinkStylesQueryVariables>(LinkStylesDocument, options);
      }
export function useLinkStylesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LinkStylesQuery, LinkStylesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LinkStylesQuery, LinkStylesQueryVariables>(LinkStylesDocument, options);
        }
export type LinkStylesQueryHookResult = ReturnType<typeof useLinkStylesQuery>;
export type LinkStylesLazyQueryHookResult = ReturnType<typeof useLinkStylesLazyQuery>;
export type LinkStylesQueryResult = Apollo.QueryResult<LinkStylesQuery, LinkStylesQueryVariables>;
export const LinkTypesDocument = gql`
    query linkTypes($filters: LinkTypeFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  linkTypes(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...LinkType
    }
    meta {
      ...Meta
    }
  }
}
    ${LinkTypeFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useLinkTypesQuery(baseOptions?: Apollo.QueryHookOptions<LinkTypesQuery, LinkTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LinkTypesQuery, LinkTypesQueryVariables>(LinkTypesDocument, options);
      }
export function useLinkTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LinkTypesQuery, LinkTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LinkTypesQuery, LinkTypesQueryVariables>(LinkTypesDocument, options);
        }
export type LinkTypesQueryHookResult = ReturnType<typeof useLinkTypesQuery>;
export type LinkTypesLazyQueryHookResult = ReturnType<typeof useLinkTypesLazyQuery>;
export type LinkTypesQueryResult = Apollo.QueryResult<LinkTypesQuery, LinkTypesQueryVariables>;
export const ManufacturingProcessDocument = gql`
    query manufacturingProcess($filters: ManufacturingProcessFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  manufacturingProcesses(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...ManufacturingProcess
    }
    meta {
      ...Meta
    }
  }
}
    ${ManufacturingProcessFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useManufacturingProcessQuery(baseOptions?: Apollo.QueryHookOptions<ManufacturingProcessQuery, ManufacturingProcessQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ManufacturingProcessQuery, ManufacturingProcessQueryVariables>(ManufacturingProcessDocument, options);
      }
export function useManufacturingProcessLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ManufacturingProcessQuery, ManufacturingProcessQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ManufacturingProcessQuery, ManufacturingProcessQueryVariables>(ManufacturingProcessDocument, options);
        }
export type ManufacturingProcessQueryHookResult = ReturnType<typeof useManufacturingProcessQuery>;
export type ManufacturingProcessLazyQueryHookResult = ReturnType<typeof useManufacturingProcessLazyQuery>;
export type ManufacturingProcessQueryResult = Apollo.QueryResult<ManufacturingProcessQuery, ManufacturingProcessQueryVariables>;
export const MaterialGradesDocument = gql`
    query materialGrades($filters: MaterialGradeFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  materialGrades(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...MaterialGrade
    }
    meta {
      ...Meta
    }
  }
}
    ${MaterialGradeFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useMaterialGradesQuery(baseOptions?: Apollo.QueryHookOptions<MaterialGradesQuery, MaterialGradesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MaterialGradesQuery, MaterialGradesQueryVariables>(MaterialGradesDocument, options);
      }
export function useMaterialGradesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MaterialGradesQuery, MaterialGradesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MaterialGradesQuery, MaterialGradesQueryVariables>(MaterialGradesDocument, options);
        }
export type MaterialGradesQueryHookResult = ReturnType<typeof useMaterialGradesQuery>;
export type MaterialGradesLazyQueryHookResult = ReturnType<typeof useMaterialGradesLazyQuery>;
export type MaterialGradesQueryResult = Apollo.QueryResult<MaterialGradesQuery, MaterialGradesQueryVariables>;
export const MetalFinishTypesDocument = gql`
    query metalFinishTypes($filters: MetalFinishTypeFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  metalFinishTypes(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...MetalFinishType
    }
    meta {
      ...Meta
    }
  }
}
    ${MetalFinishTypeFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useMetalFinishTypesQuery(baseOptions?: Apollo.QueryHookOptions<MetalFinishTypesQuery, MetalFinishTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MetalFinishTypesQuery, MetalFinishTypesQueryVariables>(MetalFinishTypesDocument, options);
      }
export function useMetalFinishTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MetalFinishTypesQuery, MetalFinishTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MetalFinishTypesQuery, MetalFinishTypesQueryVariables>(MetalFinishTypesDocument, options);
        }
export type MetalFinishTypesQueryHookResult = ReturnType<typeof useMetalFinishTypesQuery>;
export type MetalFinishTypesLazyQueryHookResult = ReturnType<typeof useMetalFinishTypesLazyQuery>;
export type MetalFinishTypesQueryResult = Apollo.QueryResult<MetalFinishTypesQuery, MetalFinishTypesQueryVariables>;
export const MetalTypesDocument = gql`
    query metalTypes($filters: MetalTypeFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  metalTypes(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...MetalType
    }
    meta {
      ...Meta
    }
  }
}
    ${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useMetalTypesQuery(baseOptions?: Apollo.QueryHookOptions<MetalTypesQuery, MetalTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MetalTypesQuery, MetalTypesQueryVariables>(MetalTypesDocument, options);
      }
export function useMetalTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MetalTypesQuery, MetalTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MetalTypesQuery, MetalTypesQueryVariables>(MetalTypesDocument, options);
        }
export type MetalTypesQueryHookResult = ReturnType<typeof useMetalTypesQuery>;
export type MetalTypesLazyQueryHookResult = ReturnType<typeof useMetalTypesLazyQuery>;
export type MetalTypesQueryResult = Apollo.QueryResult<MetalTypesQuery, MetalTypesQueryVariables>;
export const PiecesDocument = gql`
    query pieces($filters: PieceFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  pieces(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...Piece
    }
    meta {
      ...Meta
    }
  }
}
    ${PieceFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function usePiecesQuery(baseOptions?: Apollo.QueryHookOptions<PiecesQuery, PiecesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PiecesQuery, PiecesQueryVariables>(PiecesDocument, options);
      }
export function usePiecesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PiecesQuery, PiecesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PiecesQuery, PiecesQueryVariables>(PiecesDocument, options);
        }
export type PiecesQueryHookResult = ReturnType<typeof usePiecesQuery>;
export type PiecesLazyQueryHookResult = ReturnType<typeof usePiecesLazyQuery>;
export type PiecesQueryResult = Apollo.QueryResult<PiecesQuery, PiecesQueryVariables>;
export const PlattingTypesDocument = gql`
    query plattingTypes($filters: PlattingTypeFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  plattingTypes(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...PlattingType
    }
    meta {
      ...Meta
    }
  }
}
    ${PlattingTypeFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function usePlattingTypesQuery(baseOptions?: Apollo.QueryHookOptions<PlattingTypesQuery, PlattingTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlattingTypesQuery, PlattingTypesQueryVariables>(PlattingTypesDocument, options);
      }
export function usePlattingTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlattingTypesQuery, PlattingTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlattingTypesQuery, PlattingTypesQueryVariables>(PlattingTypesDocument, options);
        }
export type PlattingTypesQueryHookResult = ReturnType<typeof usePlattingTypesQuery>;
export type PlattingTypesLazyQueryHookResult = ReturnType<typeof usePlattingTypesLazyQuery>;
export type PlattingTypesQueryResult = Apollo.QueryResult<PlattingTypesQuery, PlattingTypesQueryVariables>;
export const ShankStylesDocument = gql`
    query shankStyles($filters: ShankStyleFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  shankStyles(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...ShankStyle
    }
    meta {
      ...Meta
    }
  }
}
    ${ShankStyleFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useShankStylesQuery(baseOptions?: Apollo.QueryHookOptions<ShankStylesQuery, ShankStylesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShankStylesQuery, ShankStylesQueryVariables>(ShankStylesDocument, options);
      }
export function useShankStylesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShankStylesQuery, ShankStylesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShankStylesQuery, ShankStylesQueryVariables>(ShankStylesDocument, options);
        }
export type ShankStylesQueryHookResult = ReturnType<typeof useShankStylesQuery>;
export type ShankStylesLazyQueryHookResult = ReturnType<typeof useShankStylesLazyQuery>;
export type ShankStylesQueryResult = Apollo.QueryResult<ShankStylesQuery, ShankStylesQueryVariables>;
export const SizesDocument = gql`
    query sizes($filters: SizeFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  sizes(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...Size
    }
    meta {
      ...Meta
    }
  }
}
    ${SizeFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useSizesQuery(baseOptions?: Apollo.QueryHookOptions<SizesQuery, SizesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SizesQuery, SizesQueryVariables>(SizesDocument, options);
      }
export function useSizesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SizesQuery, SizesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SizesQuery, SizesQueryVariables>(SizesDocument, options);
        }
export type SizesQueryHookResult = ReturnType<typeof useSizesQuery>;
export type SizesLazyQueryHookResult = ReturnType<typeof useSizesLazyQuery>;
export type SizesQueryResult = Apollo.QueryResult<SizesQuery, SizesQueryVariables>;
export const SpecificTypesDocument = gql`
    query specificTypes($filters: SpecificTypeFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  specificTypes(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...SpecificType
    }
    meta {
      ...Meta
    }
  }
}
    ${SpecificTypeFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useSpecificTypesQuery(baseOptions?: Apollo.QueryHookOptions<SpecificTypesQuery, SpecificTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SpecificTypesQuery, SpecificTypesQueryVariables>(SpecificTypesDocument, options);
      }
export function useSpecificTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SpecificTypesQuery, SpecificTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SpecificTypesQuery, SpecificTypesQueryVariables>(SpecificTypesDocument, options);
        }
export type SpecificTypesQueryHookResult = ReturnType<typeof useSpecificTypesQuery>;
export type SpecificTypesLazyQueryHookResult = ReturnType<typeof useSpecificTypesLazyQuery>;
export type SpecificTypesQueryResult = Apollo.QueryResult<SpecificTypesQuery, SpecificTypesQueryVariables>;
export const StrandsDocument = gql`
    query strands($filters: StrandFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  strands(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...Strand
    }
    meta {
      ...Meta
    }
  }
}
    ${StrandFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useStrandsQuery(baseOptions?: Apollo.QueryHookOptions<StrandsQuery, StrandsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StrandsQuery, StrandsQueryVariables>(StrandsDocument, options);
      }
export function useStrandsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StrandsQuery, StrandsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StrandsQuery, StrandsQueryVariables>(StrandsDocument, options);
        }
export type StrandsQueryHookResult = ReturnType<typeof useStrandsQuery>;
export type StrandsLazyQueryHookResult = ReturnType<typeof useStrandsLazyQuery>;
export type StrandsQueryResult = Apollo.QueryResult<StrandsQuery, StrandsQueryVariables>;
export const StrandsLengthsDocument = gql`
    query strandsLengths($filters: StrandsLengthFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  strandsLengths(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...StrandsLength
    }
    meta {
      ...Meta
    }
  }
}
    ${StrandsLengthFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useStrandsLengthsQuery(baseOptions?: Apollo.QueryHookOptions<StrandsLengthsQuery, StrandsLengthsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StrandsLengthsQuery, StrandsLengthsQueryVariables>(StrandsLengthsDocument, options);
      }
export function useStrandsLengthsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StrandsLengthsQuery, StrandsLengthsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StrandsLengthsQuery, StrandsLengthsQueryVariables>(StrandsLengthsDocument, options);
        }
export type StrandsLengthsQueryHookResult = ReturnType<typeof useStrandsLengthsQuery>;
export type StrandsLengthsLazyQueryHookResult = ReturnType<typeof useStrandsLengthsLazyQuery>;
export type StrandsLengthsQueryResult = Apollo.QueryResult<StrandsLengthsQuery, StrandsLengthsQueryVariables>;
export const TimePeriodsDocument = gql`
    query timePeriods($filters: TimePeriodFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  timePeriods(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...TimePeriod
    }
    meta {
      ...Meta
    }
  }
}
    ${TimePeriodFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useTimePeriodsQuery(baseOptions?: Apollo.QueryHookOptions<TimePeriodsQuery, TimePeriodsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TimePeriodsQuery, TimePeriodsQueryVariables>(TimePeriodsDocument, options);
      }
export function useTimePeriodsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TimePeriodsQuery, TimePeriodsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TimePeriodsQuery, TimePeriodsQueryVariables>(TimePeriodsDocument, options);
        }
export type TimePeriodsQueryHookResult = ReturnType<typeof useTimePeriodsQuery>;
export type TimePeriodsLazyQueryHookResult = ReturnType<typeof useTimePeriodsLazyQuery>;
export type TimePeriodsQueryResult = Apollo.QueryResult<TimePeriodsQuery, TimePeriodsQueryVariables>;
export const CreateLeadDocument = gql`
    mutation createLead($input: LeadInput!) {
  createLead(data: $input) {
    data {
      ...Lead
    }
  }
}
    ${LeadFragmentDoc}
${LeadMinFragmentDoc}
${FileFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ContactMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${BusinessLocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}`;
export type CreateLeadMutationFn = Apollo.MutationFunction<CreateLeadMutation, CreateLeadMutationVariables>;
export function useCreateLeadMutation(baseOptions?: Apollo.MutationHookOptions<CreateLeadMutation, CreateLeadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLeadMutation, CreateLeadMutationVariables>(CreateLeadDocument, options);
      }
export type CreateLeadMutationHookResult = ReturnType<typeof useCreateLeadMutation>;
export type CreateLeadMutationResult = Apollo.MutationResult<CreateLeadMutation>;
export const DeleteLeadDocument = gql`
    mutation deleteLead($id: ID!) {
  deleteLead(id: $id) {
    data {
      ...Lead
    }
  }
}
    ${LeadFragmentDoc}
${LeadMinFragmentDoc}
${FileFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ContactMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${BusinessLocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}`;
export type DeleteLeadMutationFn = Apollo.MutationFunction<DeleteLeadMutation, DeleteLeadMutationVariables>;
export function useDeleteLeadMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLeadMutation, DeleteLeadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLeadMutation, DeleteLeadMutationVariables>(DeleteLeadDocument, options);
      }
export type DeleteLeadMutationHookResult = ReturnType<typeof useDeleteLeadMutation>;
export type DeleteLeadMutationResult = Apollo.MutationResult<DeleteLeadMutation>;
export const UpdateLeadDocument = gql`
    mutation updateLead($id: ID!, $input: LeadInput!) {
  updateLead(id: $id, data: $input) {
    data {
      ...Lead
    }
  }
}
    ${LeadFragmentDoc}
${LeadMinFragmentDoc}
${FileFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ContactMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${BusinessLocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}`;
export type UpdateLeadMutationFn = Apollo.MutationFunction<UpdateLeadMutation, UpdateLeadMutationVariables>;
export function useUpdateLeadMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLeadMutation, UpdateLeadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLeadMutation, UpdateLeadMutationVariables>(UpdateLeadDocument, options);
      }
export type UpdateLeadMutationHookResult = ReturnType<typeof useUpdateLeadMutation>;
export type UpdateLeadMutationResult = Apollo.MutationResult<UpdateLeadMutation>;
export const LeadByUuidDocument = gql`
    query leadByUuid($uuid: String!) {
  leads(filters: {uuid: {eq: $uuid}}) {
    data {
      ...Lead
    }
  }
}
    ${LeadFragmentDoc}
${LeadMinFragmentDoc}
${FileFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ContactMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${BusinessLocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}`;
export function useLeadByUuidQuery(baseOptions: Apollo.QueryHookOptions<LeadByUuidQuery, LeadByUuidQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LeadByUuidQuery, LeadByUuidQueryVariables>(LeadByUuidDocument, options);
      }
export function useLeadByUuidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LeadByUuidQuery, LeadByUuidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LeadByUuidQuery, LeadByUuidQueryVariables>(LeadByUuidDocument, options);
        }
export type LeadByUuidQueryHookResult = ReturnType<typeof useLeadByUuidQuery>;
export type LeadByUuidLazyQueryHookResult = ReturnType<typeof useLeadByUuidLazyQuery>;
export type LeadByUuidQueryResult = Apollo.QueryResult<LeadByUuidQuery, LeadByUuidQueryVariables>;
export const LeadsDocument = gql`
    query leads($filters: LeadFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  leads(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...Lead
    }
    meta {
      ...Meta
    }
  }
}
    ${LeadFragmentDoc}
${LeadMinFragmentDoc}
${FileFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ContactMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${BusinessLocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useLeadsQuery(baseOptions?: Apollo.QueryHookOptions<LeadsQuery, LeadsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LeadsQuery, LeadsQueryVariables>(LeadsDocument, options);
      }
export function useLeadsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LeadsQuery, LeadsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LeadsQuery, LeadsQueryVariables>(LeadsDocument, options);
        }
export type LeadsQueryHookResult = ReturnType<typeof useLeadsQuery>;
export type LeadsLazyQueryHookResult = ReturnType<typeof useLeadsLazyQuery>;
export type LeadsQueryResult = Apollo.QueryResult<LeadsQuery, LeadsQueryVariables>;
export const CreateLocationDocument = gql`
    mutation createLocation($input: LocationInput!) {
  createLocation(data: $input) {
    data {
      ...Location
    }
  }
}
    ${LocationFragmentDoc}`;
export type CreateLocationMutationFn = Apollo.MutationFunction<CreateLocationMutation, CreateLocationMutationVariables>;
export function useCreateLocationMutation(baseOptions?: Apollo.MutationHookOptions<CreateLocationMutation, CreateLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLocationMutation, CreateLocationMutationVariables>(CreateLocationDocument, options);
      }
export type CreateLocationMutationHookResult = ReturnType<typeof useCreateLocationMutation>;
export type CreateLocationMutationResult = Apollo.MutationResult<CreateLocationMutation>;
export const DeleteLocationDocument = gql`
    mutation deleteLocation($id: ID!) {
  deleteLocation(id: $id) {
    data {
      ...Location
    }
  }
}
    ${LocationFragmentDoc}`;
export type DeleteLocationMutationFn = Apollo.MutationFunction<DeleteLocationMutation, DeleteLocationMutationVariables>;
export function useDeleteLocationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLocationMutation, DeleteLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLocationMutation, DeleteLocationMutationVariables>(DeleteLocationDocument, options);
      }
export type DeleteLocationMutationHookResult = ReturnType<typeof useDeleteLocationMutation>;
export type DeleteLocationMutationResult = Apollo.MutationResult<DeleteLocationMutation>;
export const UpdateLocationDocument = gql`
    mutation updateLocation($id: ID!, $input: LocationInput!) {
  updateLocation(id: $id, data: $input) {
    data {
      ...Location
    }
  }
}
    ${LocationFragmentDoc}`;
export type UpdateLocationMutationFn = Apollo.MutationFunction<UpdateLocationMutation, UpdateLocationMutationVariables>;
export function useUpdateLocationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLocationMutation, UpdateLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLocationMutation, UpdateLocationMutationVariables>(UpdateLocationDocument, options);
      }
export type UpdateLocationMutationHookResult = ReturnType<typeof useUpdateLocationMutation>;
export type UpdateLocationMutationResult = Apollo.MutationResult<UpdateLocationMutation>;
export const CreateMailTemplateDocument = gql`
    mutation createMailTemplate($data: MailTemplateInput!) {
  createMailTemplate(data: $data) {
    data {
      ...MailTemplate
    }
  }
}
    ${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}`;
export type CreateMailTemplateMutationFn = Apollo.MutationFunction<CreateMailTemplateMutation, CreateMailTemplateMutationVariables>;
export function useCreateMailTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CreateMailTemplateMutation, CreateMailTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMailTemplateMutation, CreateMailTemplateMutationVariables>(CreateMailTemplateDocument, options);
      }
export type CreateMailTemplateMutationHookResult = ReturnType<typeof useCreateMailTemplateMutation>;
export type CreateMailTemplateMutationResult = Apollo.MutationResult<CreateMailTemplateMutation>;
export const DeleteMailTemplateDocument = gql`
    mutation deleteMailTemplate($id: ID!) {
  deleteMailTemplate(id: $id) {
    data {
      ...MailTemplate
    }
  }
}
    ${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}`;
export type DeleteMailTemplateMutationFn = Apollo.MutationFunction<DeleteMailTemplateMutation, DeleteMailTemplateMutationVariables>;
export function useDeleteMailTemplateMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMailTemplateMutation, DeleteMailTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMailTemplateMutation, DeleteMailTemplateMutationVariables>(DeleteMailTemplateDocument, options);
      }
export type DeleteMailTemplateMutationHookResult = ReturnType<typeof useDeleteMailTemplateMutation>;
export type DeleteMailTemplateMutationResult = Apollo.MutationResult<DeleteMailTemplateMutation>;
export const UpdateMailTemplateDocument = gql`
    mutation updateMailTemplate($id: ID!, $input: MailTemplateInput!) {
  updateMailTemplate(id: $id, data: $input) {
    data {
      ...MailTemplate
    }
  }
}
    ${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}`;
export type UpdateMailTemplateMutationFn = Apollo.MutationFunction<UpdateMailTemplateMutation, UpdateMailTemplateMutationVariables>;
export function useUpdateMailTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMailTemplateMutation, UpdateMailTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMailTemplateMutation, UpdateMailTemplateMutationVariables>(UpdateMailTemplateDocument, options);
      }
export type UpdateMailTemplateMutationHookResult = ReturnType<typeof useUpdateMailTemplateMutation>;
export type UpdateMailTemplateMutationResult = Apollo.MutationResult<UpdateMailTemplateMutation>;
export const MailTemplatesDocument = gql`
    query mailTemplates($filters: MailTemplateFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  mailTemplates(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...MailTemplate
    }
    meta {
      ...Meta
    }
  }
}
    ${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useMailTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<MailTemplatesQuery, MailTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MailTemplatesQuery, MailTemplatesQueryVariables>(MailTemplatesDocument, options);
      }
export function useMailTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MailTemplatesQuery, MailTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MailTemplatesQuery, MailTemplatesQueryVariables>(MailTemplatesDocument, options);
        }
export type MailTemplatesQueryHookResult = ReturnType<typeof useMailTemplatesQuery>;
export type MailTemplatesLazyQueryHookResult = ReturnType<typeof useMailTemplatesLazyQuery>;
export type MailTemplatesQueryResult = Apollo.QueryResult<MailTemplatesQuery, MailTemplatesQueryVariables>;
export const CreateMaintenanceDocument = gql`
    mutation createMaintenance($input: MaintenanceInput!) {
  createMaintenance(data: $input) {
    data {
      ...Maintenance
    }
  }
}
    ${MaintenanceFragmentDoc}
${FileFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${ResourceMinFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}`;
export type CreateMaintenanceMutationFn = Apollo.MutationFunction<CreateMaintenanceMutation, CreateMaintenanceMutationVariables>;
export function useCreateMaintenanceMutation(baseOptions?: Apollo.MutationHookOptions<CreateMaintenanceMutation, CreateMaintenanceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMaintenanceMutation, CreateMaintenanceMutationVariables>(CreateMaintenanceDocument, options);
      }
export type CreateMaintenanceMutationHookResult = ReturnType<typeof useCreateMaintenanceMutation>;
export type CreateMaintenanceMutationResult = Apollo.MutationResult<CreateMaintenanceMutation>;
export const CreateMaintenanceEventDocument = gql`
    mutation createMaintenanceEvent($input: MaintenanceEventInput!) {
  createMaintenanceEvent(data: $input) {
    data {
      ...MaintenanceEvent
    }
  }
}
    ${MaintenanceEventFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}`;
export type CreateMaintenanceEventMutationFn = Apollo.MutationFunction<CreateMaintenanceEventMutation, CreateMaintenanceEventMutationVariables>;
export function useCreateMaintenanceEventMutation(baseOptions?: Apollo.MutationHookOptions<CreateMaintenanceEventMutation, CreateMaintenanceEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMaintenanceEventMutation, CreateMaintenanceEventMutationVariables>(CreateMaintenanceEventDocument, options);
      }
export type CreateMaintenanceEventMutationHookResult = ReturnType<typeof useCreateMaintenanceEventMutation>;
export type CreateMaintenanceEventMutationResult = Apollo.MutationResult<CreateMaintenanceEventMutation>;
export const DeleteMaintenanceDocument = gql`
    mutation deleteMaintenance($id: ID!) {
  deleteMaintenance(id: $id) {
    data {
      ...Maintenance
    }
  }
}
    ${MaintenanceFragmentDoc}
${FileFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${ResourceMinFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}`;
export type DeleteMaintenanceMutationFn = Apollo.MutationFunction<DeleteMaintenanceMutation, DeleteMaintenanceMutationVariables>;
export function useDeleteMaintenanceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMaintenanceMutation, DeleteMaintenanceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMaintenanceMutation, DeleteMaintenanceMutationVariables>(DeleteMaintenanceDocument, options);
      }
export type DeleteMaintenanceMutationHookResult = ReturnType<typeof useDeleteMaintenanceMutation>;
export type DeleteMaintenanceMutationResult = Apollo.MutationResult<DeleteMaintenanceMutation>;
export const DeleteMaintenanceEventDocument = gql`
    mutation deleteMaintenanceEvent($id: ID!) {
  deleteMaintenanceEvent(id: $id) {
    data {
      ...MaintenanceEvent
    }
  }
}
    ${MaintenanceEventFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}`;
export type DeleteMaintenanceEventMutationFn = Apollo.MutationFunction<DeleteMaintenanceEventMutation, DeleteMaintenanceEventMutationVariables>;
export function useDeleteMaintenanceEventMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMaintenanceEventMutation, DeleteMaintenanceEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMaintenanceEventMutation, DeleteMaintenanceEventMutationVariables>(DeleteMaintenanceEventDocument, options);
      }
export type DeleteMaintenanceEventMutationHookResult = ReturnType<typeof useDeleteMaintenanceEventMutation>;
export type DeleteMaintenanceEventMutationResult = Apollo.MutationResult<DeleteMaintenanceEventMutation>;
export const UpdateMaintenanceDocument = gql`
    mutation updateMaintenance($id: ID!, $input: MaintenanceInput!) {
  updateMaintenance(id: $id, data: $input) {
    data {
      ...Maintenance
    }
  }
}
    ${MaintenanceFragmentDoc}
${FileFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${ResourceMinFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}`;
export type UpdateMaintenanceMutationFn = Apollo.MutationFunction<UpdateMaintenanceMutation, UpdateMaintenanceMutationVariables>;
export function useUpdateMaintenanceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMaintenanceMutation, UpdateMaintenanceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMaintenanceMutation, UpdateMaintenanceMutationVariables>(UpdateMaintenanceDocument, options);
      }
export type UpdateMaintenanceMutationHookResult = ReturnType<typeof useUpdateMaintenanceMutation>;
export type UpdateMaintenanceMutationResult = Apollo.MutationResult<UpdateMaintenanceMutation>;
export const UpdateMaintenanceEventDocument = gql`
    mutation updateMaintenanceEvent($id: ID!, $input: MaintenanceEventInput!) {
  updateMaintenanceEvent(id: $id, data: $input) {
    data {
      ...MaintenanceEvent
    }
  }
}
    ${MaintenanceEventFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}`;
export type UpdateMaintenanceEventMutationFn = Apollo.MutationFunction<UpdateMaintenanceEventMutation, UpdateMaintenanceEventMutationVariables>;
export function useUpdateMaintenanceEventMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMaintenanceEventMutation, UpdateMaintenanceEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMaintenanceEventMutation, UpdateMaintenanceEventMutationVariables>(UpdateMaintenanceEventDocument, options);
      }
export type UpdateMaintenanceEventMutationHookResult = ReturnType<typeof useUpdateMaintenanceEventMutation>;
export type UpdateMaintenanceEventMutationResult = Apollo.MutationResult<UpdateMaintenanceEventMutation>;
export const MaintenanceByUuidDocument = gql`
    query maintenanceByUuid($uuid: String!) {
  maintenances(filters: {uuid: {eq: $uuid}}) {
    data {
      ...Maintenance
    }
  }
}
    ${MaintenanceFragmentDoc}
${FileFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${ResourceMinFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}`;
export function useMaintenanceByUuidQuery(baseOptions: Apollo.QueryHookOptions<MaintenanceByUuidQuery, MaintenanceByUuidQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MaintenanceByUuidQuery, MaintenanceByUuidQueryVariables>(MaintenanceByUuidDocument, options);
      }
export function useMaintenanceByUuidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MaintenanceByUuidQuery, MaintenanceByUuidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MaintenanceByUuidQuery, MaintenanceByUuidQueryVariables>(MaintenanceByUuidDocument, options);
        }
export type MaintenanceByUuidQueryHookResult = ReturnType<typeof useMaintenanceByUuidQuery>;
export type MaintenanceByUuidLazyQueryHookResult = ReturnType<typeof useMaintenanceByUuidLazyQuery>;
export type MaintenanceByUuidQueryResult = Apollo.QueryResult<MaintenanceByUuidQuery, MaintenanceByUuidQueryVariables>;
export const MaintenanceEventsByUuidDocument = gql`
    query maintenanceEventsByUuid($uuid: String!, $pagination: PaginationArg) {
  maintenanceEvents(
    filters: {maintenance: {uuid: {eq: $uuid}}}
    pagination: $pagination
    sort: ["dateReported:DESC"]
  ) {
    data {
      ...MaintenanceEvent
    }
    meta {
      ...Meta
    }
  }
}
    ${MaintenanceEventFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useMaintenanceEventsByUuidQuery(baseOptions: Apollo.QueryHookOptions<MaintenanceEventsByUuidQuery, MaintenanceEventsByUuidQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MaintenanceEventsByUuidQuery, MaintenanceEventsByUuidQueryVariables>(MaintenanceEventsByUuidDocument, options);
      }
export function useMaintenanceEventsByUuidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MaintenanceEventsByUuidQuery, MaintenanceEventsByUuidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MaintenanceEventsByUuidQuery, MaintenanceEventsByUuidQueryVariables>(MaintenanceEventsByUuidDocument, options);
        }
export type MaintenanceEventsByUuidQueryHookResult = ReturnType<typeof useMaintenanceEventsByUuidQuery>;
export type MaintenanceEventsByUuidLazyQueryHookResult = ReturnType<typeof useMaintenanceEventsByUuidLazyQuery>;
export type MaintenanceEventsByUuidQueryResult = Apollo.QueryResult<MaintenanceEventsByUuidQuery, MaintenanceEventsByUuidQueryVariables>;
export const MaintenancesDocument = gql`
    query maintenances($filters: MaintenanceFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  maintenances(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...Maintenance
    }
    meta {
      ...Meta
    }
  }
}
    ${MaintenanceFragmentDoc}
${FileFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${ResourceMinFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useMaintenancesQuery(baseOptions?: Apollo.QueryHookOptions<MaintenancesQuery, MaintenancesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MaintenancesQuery, MaintenancesQueryVariables>(MaintenancesDocument, options);
      }
export function useMaintenancesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MaintenancesQuery, MaintenancesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MaintenancesQuery, MaintenancesQueryVariables>(MaintenancesDocument, options);
        }
export type MaintenancesQueryHookResult = ReturnType<typeof useMaintenancesQuery>;
export type MaintenancesLazyQueryHookResult = ReturnType<typeof useMaintenancesLazyQuery>;
export type MaintenancesQueryResult = Apollo.QueryResult<MaintenancesQuery, MaintenancesQueryVariables>;
export const CreateMarketingCustomersReportDocument = gql`
    mutation createMarketingCustomersReport($input: MarketingCustomersReportInput!) {
  createMarketingCustomersReport(data: $input) {
    data {
      ...MarketingCustomersReport
    }
  }
}
    ${MarketingCustomersReportFragmentDoc}
${MarketingCustomersReportMinFragmentDoc}`;
export type CreateMarketingCustomersReportMutationFn = Apollo.MutationFunction<CreateMarketingCustomersReportMutation, CreateMarketingCustomersReportMutationVariables>;
export function useCreateMarketingCustomersReportMutation(baseOptions?: Apollo.MutationHookOptions<CreateMarketingCustomersReportMutation, CreateMarketingCustomersReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMarketingCustomersReportMutation, CreateMarketingCustomersReportMutationVariables>(CreateMarketingCustomersReportDocument, options);
      }
export type CreateMarketingCustomersReportMutationHookResult = ReturnType<typeof useCreateMarketingCustomersReportMutation>;
export type CreateMarketingCustomersReportMutationResult = Apollo.MutationResult<CreateMarketingCustomersReportMutation>;
export const DeleteMarketingCustomersReportDocument = gql`
    mutation deleteMarketingCustomersReport($id: ID!) {
  deleteMarketingCustomersReport(id: $id) {
    data {
      id
    }
  }
}
    `;
export type DeleteMarketingCustomersReportMutationFn = Apollo.MutationFunction<DeleteMarketingCustomersReportMutation, DeleteMarketingCustomersReportMutationVariables>;
export function useDeleteMarketingCustomersReportMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMarketingCustomersReportMutation, DeleteMarketingCustomersReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMarketingCustomersReportMutation, DeleteMarketingCustomersReportMutationVariables>(DeleteMarketingCustomersReportDocument, options);
      }
export type DeleteMarketingCustomersReportMutationHookResult = ReturnType<typeof useDeleteMarketingCustomersReportMutation>;
export type DeleteMarketingCustomersReportMutationResult = Apollo.MutationResult<DeleteMarketingCustomersReportMutation>;
export const UpdateMarketingCustomersReportDocument = gql`
    mutation updateMarketingCustomersReport($id: ID!, $input: MarketingCustomersReportInput!) {
  updateMarketingCustomersReport(id: $id, data: $input) {
    data {
      ...MarketingCustomersReport
    }
  }
}
    ${MarketingCustomersReportFragmentDoc}
${MarketingCustomersReportMinFragmentDoc}`;
export type UpdateMarketingCustomersReportMutationFn = Apollo.MutationFunction<UpdateMarketingCustomersReportMutation, UpdateMarketingCustomersReportMutationVariables>;
export function useUpdateMarketingCustomersReportMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMarketingCustomersReportMutation, UpdateMarketingCustomersReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMarketingCustomersReportMutation, UpdateMarketingCustomersReportMutationVariables>(UpdateMarketingCustomersReportDocument, options);
      }
export type UpdateMarketingCustomersReportMutationHookResult = ReturnType<typeof useUpdateMarketingCustomersReportMutation>;
export type UpdateMarketingCustomersReportMutationResult = Apollo.MutationResult<UpdateMarketingCustomersReportMutation>;
export const MarketingCustomersReportDocument = gql`
    query marketingCustomersReport($id: ID!) {
  marketingCustomersReport(id: $id) {
    data {
      ...MarketingCustomersReport
    }
  }
}
    ${MarketingCustomersReportFragmentDoc}
${MarketingCustomersReportMinFragmentDoc}`;
export function useMarketingCustomersReportQuery(baseOptions: Apollo.QueryHookOptions<MarketingCustomersReportQuery, MarketingCustomersReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MarketingCustomersReportQuery, MarketingCustomersReportQueryVariables>(MarketingCustomersReportDocument, options);
      }
export function useMarketingCustomersReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MarketingCustomersReportQuery, MarketingCustomersReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MarketingCustomersReportQuery, MarketingCustomersReportQueryVariables>(MarketingCustomersReportDocument, options);
        }
export type MarketingCustomersReportQueryHookResult = ReturnType<typeof useMarketingCustomersReportQuery>;
export type MarketingCustomersReportLazyQueryHookResult = ReturnType<typeof useMarketingCustomersReportLazyQuery>;
export type MarketingCustomersReportQueryResult = Apollo.QueryResult<MarketingCustomersReportQuery, MarketingCustomersReportQueryVariables>;
export const MarketingCustomersReportsDocument = gql`
    query marketingCustomersReports($filters: MarketingCustomersReportFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  marketingCustomersReports(
    filters: $filters
    pagination: $pagination
    sort: $sort
  ) {
    data {
      ...MarketingCustomersReport
    }
    meta {
      ...Meta
    }
  }
}
    ${MarketingCustomersReportFragmentDoc}
${MarketingCustomersReportMinFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useMarketingCustomersReportsQuery(baseOptions?: Apollo.QueryHookOptions<MarketingCustomersReportsQuery, MarketingCustomersReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MarketingCustomersReportsQuery, MarketingCustomersReportsQueryVariables>(MarketingCustomersReportsDocument, options);
      }
export function useMarketingCustomersReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MarketingCustomersReportsQuery, MarketingCustomersReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MarketingCustomersReportsQuery, MarketingCustomersReportsQueryVariables>(MarketingCustomersReportsDocument, options);
        }
export type MarketingCustomersReportsQueryHookResult = ReturnType<typeof useMarketingCustomersReportsQuery>;
export type MarketingCustomersReportsLazyQueryHookResult = ReturnType<typeof useMarketingCustomersReportsLazyQuery>;
export type MarketingCustomersReportsQueryResult = Apollo.QueryResult<MarketingCustomersReportsQuery, MarketingCustomersReportsQueryVariables>;
export const CreateMarketingEmailTemplateDocument = gql`
    mutation createMarketingEmailTemplate($input: MarketingEmailTemplateInput!) {
  createMarketingEmailTemplate(data: $input) {
    data {
      ...MarketingEmailTemplate
    }
  }
}
    ${MarketingEmailTemplateFragmentDoc}
${MarketingEmailTemplateMinFragmentDoc}
${TenantFragmentDoc}
${FileFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}`;
export type CreateMarketingEmailTemplateMutationFn = Apollo.MutationFunction<CreateMarketingEmailTemplateMutation, CreateMarketingEmailTemplateMutationVariables>;
export function useCreateMarketingEmailTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CreateMarketingEmailTemplateMutation, CreateMarketingEmailTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMarketingEmailTemplateMutation, CreateMarketingEmailTemplateMutationVariables>(CreateMarketingEmailTemplateDocument, options);
      }
export type CreateMarketingEmailTemplateMutationHookResult = ReturnType<typeof useCreateMarketingEmailTemplateMutation>;
export type CreateMarketingEmailTemplateMutationResult = Apollo.MutationResult<CreateMarketingEmailTemplateMutation>;
export const DeleteMarketingEmailTemplateDocument = gql`
    mutation deleteMarketingEmailTemplate($id: ID!) {
  deleteMarketingEmailTemplate(id: $id) {
    data {
      ...MarketingEmailTemplate
    }
  }
}
    ${MarketingEmailTemplateFragmentDoc}
${MarketingEmailTemplateMinFragmentDoc}
${TenantFragmentDoc}
${FileFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}`;
export type DeleteMarketingEmailTemplateMutationFn = Apollo.MutationFunction<DeleteMarketingEmailTemplateMutation, DeleteMarketingEmailTemplateMutationVariables>;
export function useDeleteMarketingEmailTemplateMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMarketingEmailTemplateMutation, DeleteMarketingEmailTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMarketingEmailTemplateMutation, DeleteMarketingEmailTemplateMutationVariables>(DeleteMarketingEmailTemplateDocument, options);
      }
export type DeleteMarketingEmailTemplateMutationHookResult = ReturnType<typeof useDeleteMarketingEmailTemplateMutation>;
export type DeleteMarketingEmailTemplateMutationResult = Apollo.MutationResult<DeleteMarketingEmailTemplateMutation>;
export const UpdateMarketingEmailTemplateDocument = gql`
    mutation updateMarketingEmailTemplate($id: ID!, $input: MarketingEmailTemplateInput!) {
  updateMarketingEmailTemplate(id: $id, data: $input) {
    data {
      ...MarketingEmailTemplate
    }
  }
}
    ${MarketingEmailTemplateFragmentDoc}
${MarketingEmailTemplateMinFragmentDoc}
${TenantFragmentDoc}
${FileFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}`;
export type UpdateMarketingEmailTemplateMutationFn = Apollo.MutationFunction<UpdateMarketingEmailTemplateMutation, UpdateMarketingEmailTemplateMutationVariables>;
export function useUpdateMarketingEmailTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMarketingEmailTemplateMutation, UpdateMarketingEmailTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMarketingEmailTemplateMutation, UpdateMarketingEmailTemplateMutationVariables>(UpdateMarketingEmailTemplateDocument, options);
      }
export type UpdateMarketingEmailTemplateMutationHookResult = ReturnType<typeof useUpdateMarketingEmailTemplateMutation>;
export type UpdateMarketingEmailTemplateMutationResult = Apollo.MutationResult<UpdateMarketingEmailTemplateMutation>;
export const MarketingEmailTemplateDocument = gql`
    query marketingEmailTemplate($id: ID!) {
  marketingEmailTemplate(id: $id) {
    data {
      ...MarketingEmailTemplate
    }
  }
}
    ${MarketingEmailTemplateFragmentDoc}
${MarketingEmailTemplateMinFragmentDoc}
${TenantFragmentDoc}
${FileFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}`;
export function useMarketingEmailTemplateQuery(baseOptions: Apollo.QueryHookOptions<MarketingEmailTemplateQuery, MarketingEmailTemplateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MarketingEmailTemplateQuery, MarketingEmailTemplateQueryVariables>(MarketingEmailTemplateDocument, options);
      }
export function useMarketingEmailTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MarketingEmailTemplateQuery, MarketingEmailTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MarketingEmailTemplateQuery, MarketingEmailTemplateQueryVariables>(MarketingEmailTemplateDocument, options);
        }
export type MarketingEmailTemplateQueryHookResult = ReturnType<typeof useMarketingEmailTemplateQuery>;
export type MarketingEmailTemplateLazyQueryHookResult = ReturnType<typeof useMarketingEmailTemplateLazyQuery>;
export type MarketingEmailTemplateQueryResult = Apollo.QueryResult<MarketingEmailTemplateQuery, MarketingEmailTemplateQueryVariables>;
export const MarketingEmailTemplateByUuidDocument = gql`
    query marketingEmailTemplateByUuid($uuid: String!) {
  marketingEmailTemplates(filters: {uuid: {eq: $uuid}}) {
    data {
      ...MarketingEmailTemplate
    }
  }
}
    ${MarketingEmailTemplateFragmentDoc}
${MarketingEmailTemplateMinFragmentDoc}
${TenantFragmentDoc}
${FileFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}`;
export function useMarketingEmailTemplateByUuidQuery(baseOptions: Apollo.QueryHookOptions<MarketingEmailTemplateByUuidQuery, MarketingEmailTemplateByUuidQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MarketingEmailTemplateByUuidQuery, MarketingEmailTemplateByUuidQueryVariables>(MarketingEmailTemplateByUuidDocument, options);
      }
export function useMarketingEmailTemplateByUuidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MarketingEmailTemplateByUuidQuery, MarketingEmailTemplateByUuidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MarketingEmailTemplateByUuidQuery, MarketingEmailTemplateByUuidQueryVariables>(MarketingEmailTemplateByUuidDocument, options);
        }
export type MarketingEmailTemplateByUuidQueryHookResult = ReturnType<typeof useMarketingEmailTemplateByUuidQuery>;
export type MarketingEmailTemplateByUuidLazyQueryHookResult = ReturnType<typeof useMarketingEmailTemplateByUuidLazyQuery>;
export type MarketingEmailTemplateByUuidQueryResult = Apollo.QueryResult<MarketingEmailTemplateByUuidQuery, MarketingEmailTemplateByUuidQueryVariables>;
export const MarketingEmailTemplatesDocument = gql`
    query marketingEmailTemplates($filters: MarketingEmailTemplateFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  marketingEmailTemplates(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...MarketingEmailTemplate
    }
    meta {
      ...Meta
    }
  }
}
    ${MarketingEmailTemplateFragmentDoc}
${MarketingEmailTemplateMinFragmentDoc}
${TenantFragmentDoc}
${FileFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useMarketingEmailTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<MarketingEmailTemplatesQuery, MarketingEmailTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MarketingEmailTemplatesQuery, MarketingEmailTemplatesQueryVariables>(MarketingEmailTemplatesDocument, options);
      }
export function useMarketingEmailTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MarketingEmailTemplatesQuery, MarketingEmailTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MarketingEmailTemplatesQuery, MarketingEmailTemplatesQueryVariables>(MarketingEmailTemplatesDocument, options);
        }
export type MarketingEmailTemplatesQueryHookResult = ReturnType<typeof useMarketingEmailTemplatesQuery>;
export type MarketingEmailTemplatesLazyQueryHookResult = ReturnType<typeof useMarketingEmailTemplatesLazyQuery>;
export type MarketingEmailTemplatesQueryResult = Apollo.QueryResult<MarketingEmailTemplatesQuery, MarketingEmailTemplatesQueryVariables>;
export const CreateMembershipDocument = gql`
    mutation createMembership($input: MembershipInput!) {
  createMembership(data: $input) {
    data {
      ...Membership
    }
  }
}
    ${MembershipFragmentDoc}
${MembershipMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${MembershipItemFragmentDoc}
${CompositeProductFragmentDoc}
${CompositeProductMinFragmentDoc}
${ProductFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${SerializeFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${CompositeProductLocationInfoMinFragmentDoc}
${CompositeProductItemInfoFragmentDoc}
${CompositeProductItemInfoMinFragmentDoc}
${ServiceFragmentDoc}
${ServiceMinFragmentDoc}
${ServiceLocationInfoFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}
${ServicePerformerFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ClassFragmentDoc}
${ClassMinFragmentDoc}
${ClassLocationInfoFragmentDoc}
${ClassLocationInfoMinFragmentDoc}
${ClassPerformerFragmentDoc}`;
export type CreateMembershipMutationFn = Apollo.MutationFunction<CreateMembershipMutation, CreateMembershipMutationVariables>;
export function useCreateMembershipMutation(baseOptions?: Apollo.MutationHookOptions<CreateMembershipMutation, CreateMembershipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMembershipMutation, CreateMembershipMutationVariables>(CreateMembershipDocument, options);
      }
export type CreateMembershipMutationHookResult = ReturnType<typeof useCreateMembershipMutation>;
export type CreateMembershipMutationResult = Apollo.MutationResult<CreateMembershipMutation>;
export const CreateMembershipItemDocument = gql`
    mutation createMembershipItem($input: MembershipItemInput!) {
  createMembershipItem(data: $input) {
    data {
      ...MembershipItem
    }
  }
}
    ${MembershipItemFragmentDoc}
${MembershipMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${CompositeProductFragmentDoc}
${CompositeProductMinFragmentDoc}
${ProductFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${SerializeFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${CompositeProductLocationInfoMinFragmentDoc}
${CompositeProductItemInfoFragmentDoc}
${CompositeProductItemInfoMinFragmentDoc}
${ServiceFragmentDoc}
${ServiceMinFragmentDoc}
${ServiceLocationInfoFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}
${ServicePerformerFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ClassFragmentDoc}
${ClassMinFragmentDoc}
${ClassLocationInfoFragmentDoc}
${ClassLocationInfoMinFragmentDoc}
${ClassPerformerFragmentDoc}`;
export type CreateMembershipItemMutationFn = Apollo.MutationFunction<CreateMembershipItemMutation, CreateMembershipItemMutationVariables>;
export function useCreateMembershipItemMutation(baseOptions?: Apollo.MutationHookOptions<CreateMembershipItemMutation, CreateMembershipItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMembershipItemMutation, CreateMembershipItemMutationVariables>(CreateMembershipItemDocument, options);
      }
export type CreateMembershipItemMutationHookResult = ReturnType<typeof useCreateMembershipItemMutation>;
export type CreateMembershipItemMutationResult = Apollo.MutationResult<CreateMembershipItemMutation>;
export const DeleteMembershipItemDocument = gql`
    mutation deleteMembershipItem($id: ID!) {
  deleteMembershipItem(id: $id) {
    data {
      ...MembershipItem
    }
  }
}
    ${MembershipItemFragmentDoc}
${MembershipMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${CompositeProductFragmentDoc}
${CompositeProductMinFragmentDoc}
${ProductFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${SerializeFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${CompositeProductLocationInfoMinFragmentDoc}
${CompositeProductItemInfoFragmentDoc}
${CompositeProductItemInfoMinFragmentDoc}
${ServiceFragmentDoc}
${ServiceMinFragmentDoc}
${ServiceLocationInfoFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}
${ServicePerformerFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ClassFragmentDoc}
${ClassMinFragmentDoc}
${ClassLocationInfoFragmentDoc}
${ClassLocationInfoMinFragmentDoc}
${ClassPerformerFragmentDoc}`;
export type DeleteMembershipItemMutationFn = Apollo.MutationFunction<DeleteMembershipItemMutation, DeleteMembershipItemMutationVariables>;
export function useDeleteMembershipItemMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMembershipItemMutation, DeleteMembershipItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMembershipItemMutation, DeleteMembershipItemMutationVariables>(DeleteMembershipItemDocument, options);
      }
export type DeleteMembershipItemMutationHookResult = ReturnType<typeof useDeleteMembershipItemMutation>;
export type DeleteMembershipItemMutationResult = Apollo.MutationResult<DeleteMembershipItemMutation>;
export const UpdateMembershipDocument = gql`
    mutation updateMembership($id: ID!, $input: MembershipInput!) {
  updateMembership(id: $id, data: $input) {
    data {
      ...Membership
    }
  }
}
    ${MembershipFragmentDoc}
${MembershipMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${MembershipItemFragmentDoc}
${CompositeProductFragmentDoc}
${CompositeProductMinFragmentDoc}
${ProductFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${SerializeFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${CompositeProductLocationInfoMinFragmentDoc}
${CompositeProductItemInfoFragmentDoc}
${CompositeProductItemInfoMinFragmentDoc}
${ServiceFragmentDoc}
${ServiceMinFragmentDoc}
${ServiceLocationInfoFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}
${ServicePerformerFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ClassFragmentDoc}
${ClassMinFragmentDoc}
${ClassLocationInfoFragmentDoc}
${ClassLocationInfoMinFragmentDoc}
${ClassPerformerFragmentDoc}`;
export type UpdateMembershipMutationFn = Apollo.MutationFunction<UpdateMembershipMutation, UpdateMembershipMutationVariables>;
export function useUpdateMembershipMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMembershipMutation, UpdateMembershipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMembershipMutation, UpdateMembershipMutationVariables>(UpdateMembershipDocument, options);
      }
export type UpdateMembershipMutationHookResult = ReturnType<typeof useUpdateMembershipMutation>;
export type UpdateMembershipMutationResult = Apollo.MutationResult<UpdateMembershipMutation>;
export const UpdateMembershipItemDocument = gql`
    mutation updateMembershipItem($id: ID!, $input: MembershipItemInput!) {
  updateMembershipItem(id: $id, data: $input) {
    data {
      ...MembershipItem
    }
  }
}
    ${MembershipItemFragmentDoc}
${MembershipMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${CompositeProductFragmentDoc}
${CompositeProductMinFragmentDoc}
${ProductFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${SerializeFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${CompositeProductLocationInfoMinFragmentDoc}
${CompositeProductItemInfoFragmentDoc}
${CompositeProductItemInfoMinFragmentDoc}
${ServiceFragmentDoc}
${ServiceMinFragmentDoc}
${ServiceLocationInfoFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}
${ServicePerformerFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ClassFragmentDoc}
${ClassMinFragmentDoc}
${ClassLocationInfoFragmentDoc}
${ClassLocationInfoMinFragmentDoc}
${ClassPerformerFragmentDoc}`;
export type UpdateMembershipItemMutationFn = Apollo.MutationFunction<UpdateMembershipItemMutation, UpdateMembershipItemMutationVariables>;
export function useUpdateMembershipItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMembershipItemMutation, UpdateMembershipItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMembershipItemMutation, UpdateMembershipItemMutationVariables>(UpdateMembershipItemDocument, options);
      }
export type UpdateMembershipItemMutationHookResult = ReturnType<typeof useUpdateMembershipItemMutation>;
export type UpdateMembershipItemMutationResult = Apollo.MutationResult<UpdateMembershipItemMutation>;
export const MembershipByUuidDocument = gql`
    query membershipByUuid($uuid: String!) {
  memberships(filters: {uuid: {eq: $uuid}}) {
    data {
      ...Membership
    }
  }
}
    ${MembershipFragmentDoc}
${MembershipMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${MembershipItemFragmentDoc}
${CompositeProductFragmentDoc}
${CompositeProductMinFragmentDoc}
${ProductFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${SerializeFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${CompositeProductLocationInfoMinFragmentDoc}
${CompositeProductItemInfoFragmentDoc}
${CompositeProductItemInfoMinFragmentDoc}
${ServiceFragmentDoc}
${ServiceMinFragmentDoc}
${ServiceLocationInfoFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}
${ServicePerformerFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ClassFragmentDoc}
${ClassMinFragmentDoc}
${ClassLocationInfoFragmentDoc}
${ClassLocationInfoMinFragmentDoc}
${ClassPerformerFragmentDoc}`;
export function useMembershipByUuidQuery(baseOptions: Apollo.QueryHookOptions<MembershipByUuidQuery, MembershipByUuidQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MembershipByUuidQuery, MembershipByUuidQueryVariables>(MembershipByUuidDocument, options);
      }
export function useMembershipByUuidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MembershipByUuidQuery, MembershipByUuidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MembershipByUuidQuery, MembershipByUuidQueryVariables>(MembershipByUuidDocument, options);
        }
export type MembershipByUuidQueryHookResult = ReturnType<typeof useMembershipByUuidQuery>;
export type MembershipByUuidLazyQueryHookResult = ReturnType<typeof useMembershipByUuidLazyQuery>;
export type MembershipByUuidQueryResult = Apollo.QueryResult<MembershipByUuidQuery, MembershipByUuidQueryVariables>;
export const MembershipsDocument = gql`
    query memberships($filters: MembershipFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  memberships(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...Membership
    }
    meta {
      ...Meta
    }
  }
}
    ${MembershipFragmentDoc}
${MembershipMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${MembershipItemFragmentDoc}
${CompositeProductFragmentDoc}
${CompositeProductMinFragmentDoc}
${ProductFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${SerializeFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${CompositeProductLocationInfoMinFragmentDoc}
${CompositeProductItemInfoFragmentDoc}
${CompositeProductItemInfoMinFragmentDoc}
${ServiceFragmentDoc}
${ServiceMinFragmentDoc}
${ServiceLocationInfoFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}
${ServicePerformerFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ClassFragmentDoc}
${ClassMinFragmentDoc}
${ClassLocationInfoFragmentDoc}
${ClassLocationInfoMinFragmentDoc}
${ClassPerformerFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useMembershipsQuery(baseOptions?: Apollo.QueryHookOptions<MembershipsQuery, MembershipsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MembershipsQuery, MembershipsQueryVariables>(MembershipsDocument, options);
      }
export function useMembershipsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MembershipsQuery, MembershipsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MembershipsQuery, MembershipsQueryVariables>(MembershipsDocument, options);
        }
export type MembershipsQueryHookResult = ReturnType<typeof useMembershipsQuery>;
export type MembershipsLazyQueryHookResult = ReturnType<typeof useMembershipsLazyQuery>;
export type MembershipsQueryResult = Apollo.QueryResult<MembershipsQuery, MembershipsQueryVariables>;
export const MembershipsWithSoldRevenueDocument = gql`
    query membershipsWithSoldRevenue($filters: MembershipFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = [], $startDate: DateTime!, $endDate: DateTime!, $businessLocationId: Int) {
  memberships(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...MembershipWithSoldRevenue
    }
    meta {
      ...Meta
    }
  }
}
    ${MembershipWithSoldRevenueFragmentDoc}
${MembershipFragmentDoc}
${MembershipMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${MembershipItemFragmentDoc}
${CompositeProductFragmentDoc}
${CompositeProductMinFragmentDoc}
${ProductFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${SerializeFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${CompositeProductLocationInfoMinFragmentDoc}
${CompositeProductItemInfoFragmentDoc}
${CompositeProductItemInfoMinFragmentDoc}
${ServiceFragmentDoc}
${ServiceMinFragmentDoc}
${ServiceLocationInfoFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}
${ServicePerformerFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ClassFragmentDoc}
${ClassMinFragmentDoc}
${ClassLocationInfoFragmentDoc}
${ClassLocationInfoMinFragmentDoc}
${ClassPerformerFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useMembershipsWithSoldRevenueQuery(baseOptions: Apollo.QueryHookOptions<MembershipsWithSoldRevenueQuery, MembershipsWithSoldRevenueQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MembershipsWithSoldRevenueQuery, MembershipsWithSoldRevenueQueryVariables>(MembershipsWithSoldRevenueDocument, options);
      }
export function useMembershipsWithSoldRevenueLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MembershipsWithSoldRevenueQuery, MembershipsWithSoldRevenueQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MembershipsWithSoldRevenueQuery, MembershipsWithSoldRevenueQueryVariables>(MembershipsWithSoldRevenueDocument, options);
        }
export type MembershipsWithSoldRevenueQueryHookResult = ReturnType<typeof useMembershipsWithSoldRevenueQuery>;
export type MembershipsWithSoldRevenueLazyQueryHookResult = ReturnType<typeof useMembershipsWithSoldRevenueLazyQuery>;
export type MembershipsWithSoldRevenueQueryResult = Apollo.QueryResult<MembershipsWithSoldRevenueQuery, MembershipsWithSoldRevenueQueryVariables>;
export const CreateNoteDocument = gql`
    mutation createNote($input: NoteInput!) {
  createNote(data: $input) {
    data {
      ...Note
    }
  }
}
    ${NoteFragmentDoc}`;
export type CreateNoteMutationFn = Apollo.MutationFunction<CreateNoteMutation, CreateNoteMutationVariables>;
export function useCreateNoteMutation(baseOptions?: Apollo.MutationHookOptions<CreateNoteMutation, CreateNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNoteMutation, CreateNoteMutationVariables>(CreateNoteDocument, options);
      }
export type CreateNoteMutationHookResult = ReturnType<typeof useCreateNoteMutation>;
export type CreateNoteMutationResult = Apollo.MutationResult<CreateNoteMutation>;
export const DeleteNoteDocument = gql`
    mutation deleteNote($id: ID!) {
  deleteNote(id: $id) {
    data {
      ...Note
    }
  }
}
    ${NoteFragmentDoc}`;
export type DeleteNoteMutationFn = Apollo.MutationFunction<DeleteNoteMutation, DeleteNoteMutationVariables>;
export function useDeleteNoteMutation(baseOptions?: Apollo.MutationHookOptions<DeleteNoteMutation, DeleteNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteNoteMutation, DeleteNoteMutationVariables>(DeleteNoteDocument, options);
      }
export type DeleteNoteMutationHookResult = ReturnType<typeof useDeleteNoteMutation>;
export type DeleteNoteMutationResult = Apollo.MutationResult<DeleteNoteMutation>;
export const UpdateNoteDocument = gql`
    mutation updateNote($id: ID!, $input: NoteInput!) {
  updateNote(id: $id, data: $input) {
    data {
      ...Note
    }
  }
}
    ${NoteFragmentDoc}`;
export type UpdateNoteMutationFn = Apollo.MutationFunction<UpdateNoteMutation, UpdateNoteMutationVariables>;
export function useUpdateNoteMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNoteMutation, UpdateNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateNoteMutation, UpdateNoteMutationVariables>(UpdateNoteDocument, options);
      }
export type UpdateNoteMutationHookResult = ReturnType<typeof useUpdateNoteMutation>;
export type UpdateNoteMutationResult = Apollo.MutationResult<UpdateNoteMutation>;
export const NotesDocument = gql`
    query notes($filters: NoteFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  notes(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...Note
    }
    meta {
      ...Meta
    }
  }
}
    ${NoteFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useNotesQuery(baseOptions?: Apollo.QueryHookOptions<NotesQuery, NotesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotesQuery, NotesQueryVariables>(NotesDocument, options);
      }
export function useNotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotesQuery, NotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotesQuery, NotesQueryVariables>(NotesDocument, options);
        }
export type NotesQueryHookResult = ReturnType<typeof useNotesQuery>;
export type NotesLazyQueryHookResult = ReturnType<typeof useNotesLazyQuery>;
export type NotesQueryResult = Apollo.QueryResult<NotesQuery, NotesQueryVariables>;
export const ReadNotificationDocument = gql`
    mutation readNotification($id: ID!) {
  updateUserNotification(id: $id, data: {hasBeenSeen: true}) {
    data {
      ...UserNotification
    }
  }
}
    ${UserNotificationFragmentDoc}
${InventoryQuantityNotificationFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${FileFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${SerializeFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${MaintenanceQuantityNotificationFragmentDoc}
${MaintenanceFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${NylasGrantExpireNotificationFragmentDoc}`;
export type ReadNotificationMutationFn = Apollo.MutationFunction<ReadNotificationMutation, ReadNotificationMutationVariables>;
export function useReadNotificationMutation(baseOptions?: Apollo.MutationHookOptions<ReadNotificationMutation, ReadNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReadNotificationMutation, ReadNotificationMutationVariables>(ReadNotificationDocument, options);
      }
export type ReadNotificationMutationHookResult = ReturnType<typeof useReadNotificationMutation>;
export type ReadNotificationMutationResult = Apollo.MutationResult<ReadNotificationMutation>;
export const UnreadNotificationCountDocument = gql`
    query unreadNotificationCount {
  userNotifications(filters: {hasBeenSeen: {eq: false}}, pagination: {limit: 100}) {
    data {
      id
    }
    meta {
      pagination {
        total
      }
    }
  }
}
    `;
export function useUnreadNotificationCountQuery(baseOptions?: Apollo.QueryHookOptions<UnreadNotificationCountQuery, UnreadNotificationCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UnreadNotificationCountQuery, UnreadNotificationCountQueryVariables>(UnreadNotificationCountDocument, options);
      }
export function useUnreadNotificationCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnreadNotificationCountQuery, UnreadNotificationCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UnreadNotificationCountQuery, UnreadNotificationCountQueryVariables>(UnreadNotificationCountDocument, options);
        }
export type UnreadNotificationCountQueryHookResult = ReturnType<typeof useUnreadNotificationCountQuery>;
export type UnreadNotificationCountLazyQueryHookResult = ReturnType<typeof useUnreadNotificationCountLazyQuery>;
export type UnreadNotificationCountQueryResult = Apollo.QueryResult<UnreadNotificationCountQuery, UnreadNotificationCountQueryVariables>;
export const UserNotificationsDocument = gql`
    query userNotifications($filters: UserNotificationFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  userNotifications(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...UserNotification
    }
    meta {
      ...Meta
    }
  }
}
    ${UserNotificationFragmentDoc}
${InventoryQuantityNotificationFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${FileFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${SerializeFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${MaintenanceQuantityNotificationFragmentDoc}
${MaintenanceFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${NylasGrantExpireNotificationFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useUserNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<UserNotificationsQuery, UserNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserNotificationsQuery, UserNotificationsQueryVariables>(UserNotificationsDocument, options);
      }
export function useUserNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserNotificationsQuery, UserNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserNotificationsQuery, UserNotificationsQueryVariables>(UserNotificationsDocument, options);
        }
export type UserNotificationsQueryHookResult = ReturnType<typeof useUserNotificationsQuery>;
export type UserNotificationsLazyQueryHookResult = ReturnType<typeof useUserNotificationsLazyQuery>;
export type UserNotificationsQueryResult = Apollo.QueryResult<UserNotificationsQuery, UserNotificationsQueryVariables>;
export const CreateConnectionDocument = gql`
    mutation createConnection($input: ConnectionInput!) {
  createConnection(input: $input)
}
    `;
export type CreateConnectionMutationFn = Apollo.MutationFunction<CreateConnectionMutation, CreateConnectionMutationVariables>;
export function useCreateConnectionMutation(baseOptions?: Apollo.MutationHookOptions<CreateConnectionMutation, CreateConnectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateConnectionMutation, CreateConnectionMutationVariables>(CreateConnectionDocument, options);
      }
export type CreateConnectionMutationHookResult = ReturnType<typeof useCreateConnectionMutation>;
export type CreateConnectionMutationResult = Apollo.MutationResult<CreateConnectionMutation>;
export const DisconnectNylasDocument = gql`
    mutation disconnectNylas($input: DisconnectInput!) {
  disconnectNylas(input: $input)
}
    `;
export type DisconnectNylasMutationFn = Apollo.MutationFunction<DisconnectNylasMutation, DisconnectNylasMutationVariables>;
export function useDisconnectNylasMutation(baseOptions?: Apollo.MutationHookOptions<DisconnectNylasMutation, DisconnectNylasMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisconnectNylasMutation, DisconnectNylasMutationVariables>(DisconnectNylasDocument, options);
      }
export type DisconnectNylasMutationHookResult = ReturnType<typeof useDisconnectNylasMutation>;
export type DisconnectNylasMutationResult = Apollo.MutationResult<DisconnectNylasMutation>;
export const GetNylasAuthUrlDocument = gql`
    query getNylasAuthUrl($email: String!, $emailProvider: String!, $userId: ID!, $tenantSlug: String!) {
  getNylasAuthUrl(
    email: $email
    emailProvider: $emailProvider
    userId: $userId
    tenantSlug: $tenantSlug
  ) {
    url
  }
}
    `;
export function useGetNylasAuthUrlQuery(baseOptions: Apollo.QueryHookOptions<GetNylasAuthUrlQuery, GetNylasAuthUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNylasAuthUrlQuery, GetNylasAuthUrlQueryVariables>(GetNylasAuthUrlDocument, options);
      }
export function useGetNylasAuthUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNylasAuthUrlQuery, GetNylasAuthUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNylasAuthUrlQuery, GetNylasAuthUrlQueryVariables>(GetNylasAuthUrlDocument, options);
        }
export type GetNylasAuthUrlQueryHookResult = ReturnType<typeof useGetNylasAuthUrlQuery>;
export type GetNylasAuthUrlLazyQueryHookResult = ReturnType<typeof useGetNylasAuthUrlLazyQuery>;
export type GetNylasAuthUrlQueryResult = Apollo.QueryResult<GetNylasAuthUrlQuery, GetNylasAuthUrlQueryVariables>;
export const UpdateOnboardingUserDocument = gql`
    mutation updateOnboardingUser($id: ID!, $input: OnboardingUserInput!) {
  updateOnboardingUser(id: $id, data: $input) {
    data {
      ...OnboardingUser
    }
  }
}
    ${OnboardingUserFragmentDoc}`;
export type UpdateOnboardingUserMutationFn = Apollo.MutationFunction<UpdateOnboardingUserMutation, UpdateOnboardingUserMutationVariables>;
export function useUpdateOnboardingUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOnboardingUserMutation, UpdateOnboardingUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOnboardingUserMutation, UpdateOnboardingUserMutationVariables>(UpdateOnboardingUserDocument, options);
      }
export type UpdateOnboardingUserMutationHookResult = ReturnType<typeof useUpdateOnboardingUserMutation>;
export type UpdateOnboardingUserMutationResult = Apollo.MutationResult<UpdateOnboardingUserMutation>;
export const UpdateOnboardingDocument = gql`
    mutation updateOnboarding($id: ID!, $input: OnboardingInput!) {
  updateOnboarding(id: $id, data: $input) {
    data {
      ...Onboarding
    }
  }
}
    ${OnboardingFragmentDoc}`;
export type UpdateOnboardingMutationFn = Apollo.MutationFunction<UpdateOnboardingMutation, UpdateOnboardingMutationVariables>;
export function useUpdateOnboardingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOnboardingMutation, UpdateOnboardingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOnboardingMutation, UpdateOnboardingMutationVariables>(UpdateOnboardingDocument, options);
      }
export type UpdateOnboardingMutationHookResult = ReturnType<typeof useUpdateOnboardingMutation>;
export type UpdateOnboardingMutationResult = Apollo.MutationResult<UpdateOnboardingMutation>;
export const OnboardingUsersDocument = gql`
    query onboardingUsers($filters: OnboardingUserFiltersInput!, $pagination: PaginationArg! = {}, $sort: [String] = []) {
  onboardingUsers(sort: $sort, filters: $filters, pagination: $pagination) {
    data {
      ...OnboardingUser
    }
    meta {
      ...Meta
    }
  }
}
    ${OnboardingUserFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useOnboardingUsersQuery(baseOptions: Apollo.QueryHookOptions<OnboardingUsersQuery, OnboardingUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OnboardingUsersQuery, OnboardingUsersQueryVariables>(OnboardingUsersDocument, options);
      }
export function useOnboardingUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OnboardingUsersQuery, OnboardingUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OnboardingUsersQuery, OnboardingUsersQueryVariables>(OnboardingUsersDocument, options);
        }
export type OnboardingUsersQueryHookResult = ReturnType<typeof useOnboardingUsersQuery>;
export type OnboardingUsersLazyQueryHookResult = ReturnType<typeof useOnboardingUsersLazyQuery>;
export type OnboardingUsersQueryResult = Apollo.QueryResult<OnboardingUsersQuery, OnboardingUsersQueryVariables>;
export const OnboardingsDocument = gql`
    query onboardings($filters: OnboardingFiltersInput!, $pagination: PaginationArg! = {}, $sort: [String] = []) {
  onboardings(sort: $sort, filters: $filters, pagination: $pagination) {
    data {
      ...Onboarding
    }
    meta {
      ...Meta
    }
  }
}
    ${OnboardingFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useOnboardingsQuery(baseOptions: Apollo.QueryHookOptions<OnboardingsQuery, OnboardingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OnboardingsQuery, OnboardingsQueryVariables>(OnboardingsDocument, options);
      }
export function useOnboardingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OnboardingsQuery, OnboardingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OnboardingsQuery, OnboardingsQueryVariables>(OnboardingsDocument, options);
        }
export type OnboardingsQueryHookResult = ReturnType<typeof useOnboardingsQuery>;
export type OnboardingsLazyQueryHookResult = ReturnType<typeof useOnboardingsLazyQuery>;
export type OnboardingsQueryResult = Apollo.QueryResult<OnboardingsQuery, OnboardingsQueryVariables>;
export const AddDiscountDocument = gql`
    mutation addDiscount($input: OrderDiscountInput!) {
  addDiscount(input: $input) {
    data {
      ...Order
    }
  }
}
    ${OrderFragmentDoc}
${OrderMinFragmentDoc}
${OrderWithItemsFragmentDoc}
${ProductOrderItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${SerializeFragmentDoc}
${DiscountMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${CompanyMinFragmentDoc}
${FileFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${CompositeProductOrderItemFragmentDoc}
${CompositeProductLocationInfoFragmentDoc}
${CompositeProductLocationInfoMinFragmentDoc}
${CompositeProductFragmentDoc}
${CompositeProductMinFragmentDoc}
${ProductFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${CompositeProductItemInfoFragmentDoc}
${CompositeProductItemInfoMinFragmentDoc}
${ServiceOrderItemFragmentDoc}
${ServicePerformerFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${ServiceMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}
${ClassOrderItemFragmentDoc}
${ClassPerformerFragmentDoc}
${ClassLocationInfoMinFragmentDoc}
${ClassMinFragmentDoc}
${MembershipOrderItemFragmentDoc}
${MembershipMinFragmentDoc}
${ContactFragmentDoc}
${ContactMinFragmentDoc}
${ContactDynamicValuesFragmentDoc}
${LeadFragmentDoc}
${LeadMinFragmentDoc}
${UserFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}
${CompanyFragmentDoc}
${DealTransactionMinFragmentDoc}
${InvoiceWithFileFragmentDoc}
${InvoiceMinFragmentDoc}
${InvoiceShippingContactFragmentDoc}
${FileItemFragmentDoc}
${FileItemMinFragmentDoc}
${PurchaseRequestWithFileFragmentDoc}
${PurchaseRequestMinFragmentDoc}
${PurchaseRequestShippingInfoMinFragmentDoc}
${TaskMinFragmentDoc}
${SalesItemReportMinFragmentDoc}`;
export type AddDiscountMutationFn = Apollo.MutationFunction<AddDiscountMutation, AddDiscountMutationVariables>;
export function useAddDiscountMutation(baseOptions?: Apollo.MutationHookOptions<AddDiscountMutation, AddDiscountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddDiscountMutation, AddDiscountMutationVariables>(AddDiscountDocument, options);
      }
export type AddDiscountMutationHookResult = ReturnType<typeof useAddDiscountMutation>;
export type AddDiscountMutationResult = Apollo.MutationResult<AddDiscountMutation>;
export const AddFollowingTransactionsDocument = gql`
    mutation addFollowingTransactions($input: OrderFollowingTransactionsInput!) {
  addFollowingTransactions(input: $input) {
    data {
      ...Order
    }
  }
}
    ${OrderFragmentDoc}
${OrderMinFragmentDoc}
${OrderWithItemsFragmentDoc}
${ProductOrderItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${SerializeFragmentDoc}
${DiscountMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${CompanyMinFragmentDoc}
${FileFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${CompositeProductOrderItemFragmentDoc}
${CompositeProductLocationInfoFragmentDoc}
${CompositeProductLocationInfoMinFragmentDoc}
${CompositeProductFragmentDoc}
${CompositeProductMinFragmentDoc}
${ProductFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${CompositeProductItemInfoFragmentDoc}
${CompositeProductItemInfoMinFragmentDoc}
${ServiceOrderItemFragmentDoc}
${ServicePerformerFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${ServiceMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}
${ClassOrderItemFragmentDoc}
${ClassPerformerFragmentDoc}
${ClassLocationInfoMinFragmentDoc}
${ClassMinFragmentDoc}
${MembershipOrderItemFragmentDoc}
${MembershipMinFragmentDoc}
${ContactFragmentDoc}
${ContactMinFragmentDoc}
${ContactDynamicValuesFragmentDoc}
${LeadFragmentDoc}
${LeadMinFragmentDoc}
${UserFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}
${CompanyFragmentDoc}
${DealTransactionMinFragmentDoc}
${InvoiceWithFileFragmentDoc}
${InvoiceMinFragmentDoc}
${InvoiceShippingContactFragmentDoc}
${FileItemFragmentDoc}
${FileItemMinFragmentDoc}
${PurchaseRequestWithFileFragmentDoc}
${PurchaseRequestMinFragmentDoc}
${PurchaseRequestShippingInfoMinFragmentDoc}
${TaskMinFragmentDoc}
${SalesItemReportMinFragmentDoc}`;
export type AddFollowingTransactionsMutationFn = Apollo.MutationFunction<AddFollowingTransactionsMutation, AddFollowingTransactionsMutationVariables>;
export function useAddFollowingTransactionsMutation(baseOptions?: Apollo.MutationHookOptions<AddFollowingTransactionsMutation, AddFollowingTransactionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddFollowingTransactionsMutation, AddFollowingTransactionsMutationVariables>(AddFollowingTransactionsDocument, options);
      }
export type AddFollowingTransactionsMutationHookResult = ReturnType<typeof useAddFollowingTransactionsMutation>;
export type AddFollowingTransactionsMutationResult = Apollo.MutationResult<AddFollowingTransactionsMutation>;
export const AddPointsDocument = gql`
    mutation addPoints($input: OrderPointsInput!) {
  addPoints(input: $input) {
    data {
      ...OrderMin
    }
  }
}
    ${OrderMinFragmentDoc}`;
export type AddPointsMutationFn = Apollo.MutationFunction<AddPointsMutation, AddPointsMutationVariables>;
export function useAddPointsMutation(baseOptions?: Apollo.MutationHookOptions<AddPointsMutation, AddPointsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPointsMutation, AddPointsMutationVariables>(AddPointsDocument, options);
      }
export type AddPointsMutationHookResult = ReturnType<typeof useAddPointsMutation>;
export type AddPointsMutationResult = Apollo.MutationResult<AddPointsMutation>;
export const AddTipDocument = gql`
    mutation addTip($input: OrderTipInput!) {
  addTip(input: $input) {
    data {
      ...OrderMin
    }
  }
}
    ${OrderMinFragmentDoc}`;
export type AddTipMutationFn = Apollo.MutationFunction<AddTipMutation, AddTipMutationVariables>;
export function useAddTipMutation(baseOptions?: Apollo.MutationHookOptions<AddTipMutation, AddTipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddTipMutation, AddTipMutationVariables>(AddTipDocument, options);
      }
export type AddTipMutationHookResult = ReturnType<typeof useAddTipMutation>;
export type AddTipMutationResult = Apollo.MutationResult<AddTipMutation>;
export const CreateClassOrderItemDocument = gql`
    mutation createClassOrderItem($input: ClassOrderItemInput!) {
  createClassOrderItem(data: $input) {
    data {
      ...ClassOrderItem
    }
  }
}
    ${ClassOrderItemFragmentDoc}
${DiscountMinFragmentDoc}
${OrderMinFragmentDoc}
${ClassPerformerFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ClassLocationInfoMinFragmentDoc}
${ClassMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}`;
export type CreateClassOrderItemMutationFn = Apollo.MutationFunction<CreateClassOrderItemMutation, CreateClassOrderItemMutationVariables>;
export function useCreateClassOrderItemMutation(baseOptions?: Apollo.MutationHookOptions<CreateClassOrderItemMutation, CreateClassOrderItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateClassOrderItemMutation, CreateClassOrderItemMutationVariables>(CreateClassOrderItemDocument, options);
      }
export type CreateClassOrderItemMutationHookResult = ReturnType<typeof useCreateClassOrderItemMutation>;
export type CreateClassOrderItemMutationResult = Apollo.MutationResult<CreateClassOrderItemMutation>;
export const CreateCompositeProductOrderItemDocument = gql`
    mutation createCompositeProductOrderItem($input: CompositeProductOrderItemInput!) {
  createCompositeProductOrderItem(data: $input) {
    data {
      ...CompositeProductOrderItem
    }
  }
}
    ${CompositeProductOrderItemFragmentDoc}
${DiscountMinFragmentDoc}
${CompositeProductLocationInfoFragmentDoc}
${CompositeProductLocationInfoMinFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${CompositeProductFragmentDoc}
${CompositeProductMinFragmentDoc}
${ProductFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${SerializeFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${CompositeProductItemInfoFragmentDoc}
${CompositeProductItemInfoMinFragmentDoc}
${OrderMinFragmentDoc}`;
export type CreateCompositeProductOrderItemMutationFn = Apollo.MutationFunction<CreateCompositeProductOrderItemMutation, CreateCompositeProductOrderItemMutationVariables>;
export function useCreateCompositeProductOrderItemMutation(baseOptions?: Apollo.MutationHookOptions<CreateCompositeProductOrderItemMutation, CreateCompositeProductOrderItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCompositeProductOrderItemMutation, CreateCompositeProductOrderItemMutationVariables>(CreateCompositeProductOrderItemDocument, options);
      }
export type CreateCompositeProductOrderItemMutationHookResult = ReturnType<typeof useCreateCompositeProductOrderItemMutation>;
export type CreateCompositeProductOrderItemMutationResult = Apollo.MutationResult<CreateCompositeProductOrderItemMutation>;
export const CreateMembershipOrderItemDocument = gql`
    mutation createMembershipOrderItem($input: MembershipOrderItemInput!) {
  createMembershipOrderItem(data: $input) {
    data {
      ...MembershipOrderItem
    }
  }
}
    ${MembershipOrderItemFragmentDoc}
${DiscountMinFragmentDoc}
${MembershipMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${TaxMinFragmentDoc}`;
export type CreateMembershipOrderItemMutationFn = Apollo.MutationFunction<CreateMembershipOrderItemMutation, CreateMembershipOrderItemMutationVariables>;
export function useCreateMembershipOrderItemMutation(baseOptions?: Apollo.MutationHookOptions<CreateMembershipOrderItemMutation, CreateMembershipOrderItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMembershipOrderItemMutation, CreateMembershipOrderItemMutationVariables>(CreateMembershipOrderItemDocument, options);
      }
export type CreateMembershipOrderItemMutationHookResult = ReturnType<typeof useCreateMembershipOrderItemMutation>;
export type CreateMembershipOrderItemMutationResult = Apollo.MutationResult<CreateMembershipOrderItemMutation>;
export const CreateOrderDocument = gql`
    mutation createOrder($input: OrderInput!) {
  createOrder(data: $input) {
    data {
      ...Order
    }
  }
}
    ${OrderFragmentDoc}
${OrderMinFragmentDoc}
${OrderWithItemsFragmentDoc}
${ProductOrderItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${SerializeFragmentDoc}
${DiscountMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${CompanyMinFragmentDoc}
${FileFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${CompositeProductOrderItemFragmentDoc}
${CompositeProductLocationInfoFragmentDoc}
${CompositeProductLocationInfoMinFragmentDoc}
${CompositeProductFragmentDoc}
${CompositeProductMinFragmentDoc}
${ProductFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${CompositeProductItemInfoFragmentDoc}
${CompositeProductItemInfoMinFragmentDoc}
${ServiceOrderItemFragmentDoc}
${ServicePerformerFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${ServiceMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}
${ClassOrderItemFragmentDoc}
${ClassPerformerFragmentDoc}
${ClassLocationInfoMinFragmentDoc}
${ClassMinFragmentDoc}
${MembershipOrderItemFragmentDoc}
${MembershipMinFragmentDoc}
${ContactFragmentDoc}
${ContactMinFragmentDoc}
${ContactDynamicValuesFragmentDoc}
${LeadFragmentDoc}
${LeadMinFragmentDoc}
${UserFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}
${CompanyFragmentDoc}
${DealTransactionMinFragmentDoc}
${InvoiceWithFileFragmentDoc}
${InvoiceMinFragmentDoc}
${InvoiceShippingContactFragmentDoc}
${FileItemFragmentDoc}
${FileItemMinFragmentDoc}
${PurchaseRequestWithFileFragmentDoc}
${PurchaseRequestMinFragmentDoc}
${PurchaseRequestShippingInfoMinFragmentDoc}
${TaskMinFragmentDoc}
${SalesItemReportMinFragmentDoc}`;
export type CreateOrderMutationFn = Apollo.MutationFunction<CreateOrderMutation, CreateOrderMutationVariables>;
export function useCreateOrderMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrderMutation, CreateOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrderMutation, CreateOrderMutationVariables>(CreateOrderDocument, options);
      }
export type CreateOrderMutationHookResult = ReturnType<typeof useCreateOrderMutation>;
export type CreateOrderMutationResult = Apollo.MutationResult<CreateOrderMutation>;
export const CreatePaymentIntentForPosDocument = gql`
    mutation createPaymentIntentForPos($input: CreatePaymentIntentForPosInput!) {
  createPaymentIntentForPos(input: $input) {
    paymentIntent
    clientSecret
  }
}
    `;
export type CreatePaymentIntentForPosMutationFn = Apollo.MutationFunction<CreatePaymentIntentForPosMutation, CreatePaymentIntentForPosMutationVariables>;
export function useCreatePaymentIntentForPosMutation(baseOptions?: Apollo.MutationHookOptions<CreatePaymentIntentForPosMutation, CreatePaymentIntentForPosMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePaymentIntentForPosMutation, CreatePaymentIntentForPosMutationVariables>(CreatePaymentIntentForPosDocument, options);
      }
export type CreatePaymentIntentForPosMutationHookResult = ReturnType<typeof useCreatePaymentIntentForPosMutation>;
export type CreatePaymentIntentForPosMutationResult = Apollo.MutationResult<CreatePaymentIntentForPosMutation>;
export const CreateProductOrderItemDocument = gql`
    mutation createProductOrderItem($input: ProductOrderItemInput!) {
  createProductOrderItem(data: $input) {
    data {
      ...ProductOrderItem
    }
  }
}
    ${ProductOrderItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${SerializeFragmentDoc}
${DiscountMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${CompanyMinFragmentDoc}
${FileFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${OrderMinFragmentDoc}`;
export type CreateProductOrderItemMutationFn = Apollo.MutationFunction<CreateProductOrderItemMutation, CreateProductOrderItemMutationVariables>;
export function useCreateProductOrderItemMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductOrderItemMutation, CreateProductOrderItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProductOrderItemMutation, CreateProductOrderItemMutationVariables>(CreateProductOrderItemDocument, options);
      }
export type CreateProductOrderItemMutationHookResult = ReturnType<typeof useCreateProductOrderItemMutation>;
export type CreateProductOrderItemMutationResult = Apollo.MutationResult<CreateProductOrderItemMutation>;
export const CreateServiceOrderItemDocument = gql`
    mutation createServiceOrderItem($input: ServiceOrderItemInput!) {
  createServiceOrderItem(data: $input) {
    data {
      ...ServiceOrderItem
    }
  }
}
    ${ServiceOrderItemFragmentDoc}
${DiscountMinFragmentDoc}
${ServicePerformerFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${ServiceMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}
${OrderMinFragmentDoc}`;
export type CreateServiceOrderItemMutationFn = Apollo.MutationFunction<CreateServiceOrderItemMutation, CreateServiceOrderItemMutationVariables>;
export function useCreateServiceOrderItemMutation(baseOptions?: Apollo.MutationHookOptions<CreateServiceOrderItemMutation, CreateServiceOrderItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateServiceOrderItemMutation, CreateServiceOrderItemMutationVariables>(CreateServiceOrderItemDocument, options);
      }
export type CreateServiceOrderItemMutationHookResult = ReturnType<typeof useCreateServiceOrderItemMutation>;
export type CreateServiceOrderItemMutationResult = Apollo.MutationResult<CreateServiceOrderItemMutation>;
export const DeleteClassOrderItemDocument = gql`
    mutation deleteClassOrderItem($id: ID!) {
  deleteClassOrderItem(id: $id) {
    data {
      id
      attributes {
        itemId
        purchaseType
      }
    }
  }
}
    `;
export type DeleteClassOrderItemMutationFn = Apollo.MutationFunction<DeleteClassOrderItemMutation, DeleteClassOrderItemMutationVariables>;
export function useDeleteClassOrderItemMutation(baseOptions?: Apollo.MutationHookOptions<DeleteClassOrderItemMutation, DeleteClassOrderItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteClassOrderItemMutation, DeleteClassOrderItemMutationVariables>(DeleteClassOrderItemDocument, options);
      }
export type DeleteClassOrderItemMutationHookResult = ReturnType<typeof useDeleteClassOrderItemMutation>;
export type DeleteClassOrderItemMutationResult = Apollo.MutationResult<DeleteClassOrderItemMutation>;
export const DeleteCompositeProductOrderItemDocument = gql`
    mutation deleteCompositeProductOrderItem($id: ID!) {
  deleteCompositeProductOrderItem(id: $id) {
    data {
      id
      attributes {
        itemId
        purchaseType
      }
    }
  }
}
    `;
export type DeleteCompositeProductOrderItemMutationFn = Apollo.MutationFunction<DeleteCompositeProductOrderItemMutation, DeleteCompositeProductOrderItemMutationVariables>;
export function useDeleteCompositeProductOrderItemMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCompositeProductOrderItemMutation, DeleteCompositeProductOrderItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCompositeProductOrderItemMutation, DeleteCompositeProductOrderItemMutationVariables>(DeleteCompositeProductOrderItemDocument, options);
      }
export type DeleteCompositeProductOrderItemMutationHookResult = ReturnType<typeof useDeleteCompositeProductOrderItemMutation>;
export type DeleteCompositeProductOrderItemMutationResult = Apollo.MutationResult<DeleteCompositeProductOrderItemMutation>;
export const DeleteMembershipOrderItemDocument = gql`
    mutation deleteMembershipOrderItem($id: ID!) {
  deleteMembershipOrderItem(id: $id) {
    data {
      id
      attributes {
        itemId
        purchaseType
      }
    }
  }
}
    `;
export type DeleteMembershipOrderItemMutationFn = Apollo.MutationFunction<DeleteMembershipOrderItemMutation, DeleteMembershipOrderItemMutationVariables>;
export function useDeleteMembershipOrderItemMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMembershipOrderItemMutation, DeleteMembershipOrderItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMembershipOrderItemMutation, DeleteMembershipOrderItemMutationVariables>(DeleteMembershipOrderItemDocument, options);
      }
export type DeleteMembershipOrderItemMutationHookResult = ReturnType<typeof useDeleteMembershipOrderItemMutation>;
export type DeleteMembershipOrderItemMutationResult = Apollo.MutationResult<DeleteMembershipOrderItemMutation>;
export const DeleteOrderDocument = gql`
    mutation deleteOrder($id: ID!) {
  deleteOrder(id: $id) {
    data {
      id
      attributes {
        orderId
      }
    }
  }
}
    `;
export type DeleteOrderMutationFn = Apollo.MutationFunction<DeleteOrderMutation, DeleteOrderMutationVariables>;
export function useDeleteOrderMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOrderMutation, DeleteOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOrderMutation, DeleteOrderMutationVariables>(DeleteOrderDocument, options);
      }
export type DeleteOrderMutationHookResult = ReturnType<typeof useDeleteOrderMutation>;
export type DeleteOrderMutationResult = Apollo.MutationResult<DeleteOrderMutation>;
export const DeleteProductOrderItemDocument = gql`
    mutation deleteProductOrderItem($id: ID!) {
  deleteProductOrderItem(id: $id) {
    data {
      id
      attributes {
        itemId
        purchaseType
      }
    }
  }
}
    `;
export type DeleteProductOrderItemMutationFn = Apollo.MutationFunction<DeleteProductOrderItemMutation, DeleteProductOrderItemMutationVariables>;
export function useDeleteProductOrderItemMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProductOrderItemMutation, DeleteProductOrderItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProductOrderItemMutation, DeleteProductOrderItemMutationVariables>(DeleteProductOrderItemDocument, options);
      }
export type DeleteProductOrderItemMutationHookResult = ReturnType<typeof useDeleteProductOrderItemMutation>;
export type DeleteProductOrderItemMutationResult = Apollo.MutationResult<DeleteProductOrderItemMutation>;
export const DeleteServiceOrderItemDocument = gql`
    mutation deleteServiceOrderItem($id: ID!) {
  deleteServiceOrderItem(id: $id) {
    data {
      id
      attributes {
        itemId
        purchaseType
      }
    }
  }
}
    `;
export type DeleteServiceOrderItemMutationFn = Apollo.MutationFunction<DeleteServiceOrderItemMutation, DeleteServiceOrderItemMutationVariables>;
export function useDeleteServiceOrderItemMutation(baseOptions?: Apollo.MutationHookOptions<DeleteServiceOrderItemMutation, DeleteServiceOrderItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteServiceOrderItemMutation, DeleteServiceOrderItemMutationVariables>(DeleteServiceOrderItemDocument, options);
      }
export type DeleteServiceOrderItemMutationHookResult = ReturnType<typeof useDeleteServiceOrderItemMutation>;
export type DeleteServiceOrderItemMutationResult = Apollo.MutationResult<DeleteServiceOrderItemMutation>;
export const GenerateInvoiceDocument = gql`
    mutation generateInvoice($input: OrderInvoiceInput!) {
  generateInvoice(input: $input) {
    uuid
  }
}
    `;
export type GenerateInvoiceMutationFn = Apollo.MutationFunction<GenerateInvoiceMutation, GenerateInvoiceMutationVariables>;
export function useGenerateInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<GenerateInvoiceMutation, GenerateInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateInvoiceMutation, GenerateInvoiceMutationVariables>(GenerateInvoiceDocument, options);
      }
export type GenerateInvoiceMutationHookResult = ReturnType<typeof useGenerateInvoiceMutation>;
export type GenerateInvoiceMutationResult = Apollo.MutationResult<GenerateInvoiceMutation>;
export const InventoryPurchaseFormMutationDocument = gql`
    mutation inventoryPurchaseFormMutation($input: InventoryPurchaseFormInput!) {
  inventoryPurchaseFormMutation(input: $input) {
    success
  }
}
    `;
export type InventoryPurchaseFormMutationMutationFn = Apollo.MutationFunction<InventoryPurchaseFormMutationMutation, InventoryPurchaseFormMutationMutationVariables>;
export function useInventoryPurchaseFormMutationMutation(baseOptions?: Apollo.MutationHookOptions<InventoryPurchaseFormMutationMutation, InventoryPurchaseFormMutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InventoryPurchaseFormMutationMutation, InventoryPurchaseFormMutationMutationVariables>(InventoryPurchaseFormMutationDocument, options);
      }
export type InventoryPurchaseFormMutationMutationHookResult = ReturnType<typeof useInventoryPurchaseFormMutationMutation>;
export type InventoryPurchaseFormMutationMutationResult = Apollo.MutationResult<InventoryPurchaseFormMutationMutation>;
export const MoveWholesaleToSellDocument = gql`
    mutation moveWholesaleToSell($input: WholesaleToSellInput!) {
  moveWholesaleToSell(input: $input) {
    data {
      ...OrderMin
    }
  }
}
    ${OrderMinFragmentDoc}`;
export type MoveWholesaleToSellMutationFn = Apollo.MutationFunction<MoveWholesaleToSellMutation, MoveWholesaleToSellMutationVariables>;
export function useMoveWholesaleToSellMutation(baseOptions?: Apollo.MutationHookOptions<MoveWholesaleToSellMutation, MoveWholesaleToSellMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MoveWholesaleToSellMutation, MoveWholesaleToSellMutationVariables>(MoveWholesaleToSellDocument, options);
      }
export type MoveWholesaleToSellMutationHookResult = ReturnType<typeof useMoveWholesaleToSellMutation>;
export type MoveWholesaleToSellMutationResult = Apollo.MutationResult<MoveWholesaleToSellMutation>;
export const OrderPaymentDocument = gql`
    mutation orderPayment($input: OrderPaymentArgInput!) {
  orderPayment(input: $input) {
    paymentIntentId
    clientSecret
    dealTransactionsId
    message
    status
  }
}
    `;
export type OrderPaymentMutationFn = Apollo.MutationFunction<OrderPaymentMutation, OrderPaymentMutationVariables>;
export function useOrderPaymentMutation(baseOptions?: Apollo.MutationHookOptions<OrderPaymentMutation, OrderPaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrderPaymentMutation, OrderPaymentMutationVariables>(OrderPaymentDocument, options);
      }
export type OrderPaymentMutationHookResult = ReturnType<typeof useOrderPaymentMutation>;
export type OrderPaymentMutationResult = Apollo.MutationResult<OrderPaymentMutation>;
export const RemoveDiscountDocument = gql`
    mutation removeDiscount($input: OrderDiscountRemoveInput!) {
  removeDiscount(input: $input) {
    data {
      ...Order
    }
  }
}
    ${OrderFragmentDoc}
${OrderMinFragmentDoc}
${OrderWithItemsFragmentDoc}
${ProductOrderItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${SerializeFragmentDoc}
${DiscountMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${CompanyMinFragmentDoc}
${FileFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${CompositeProductOrderItemFragmentDoc}
${CompositeProductLocationInfoFragmentDoc}
${CompositeProductLocationInfoMinFragmentDoc}
${CompositeProductFragmentDoc}
${CompositeProductMinFragmentDoc}
${ProductFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${CompositeProductItemInfoFragmentDoc}
${CompositeProductItemInfoMinFragmentDoc}
${ServiceOrderItemFragmentDoc}
${ServicePerformerFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${ServiceMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}
${ClassOrderItemFragmentDoc}
${ClassPerformerFragmentDoc}
${ClassLocationInfoMinFragmentDoc}
${ClassMinFragmentDoc}
${MembershipOrderItemFragmentDoc}
${MembershipMinFragmentDoc}
${ContactFragmentDoc}
${ContactMinFragmentDoc}
${ContactDynamicValuesFragmentDoc}
${LeadFragmentDoc}
${LeadMinFragmentDoc}
${UserFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}
${CompanyFragmentDoc}
${DealTransactionMinFragmentDoc}
${InvoiceWithFileFragmentDoc}
${InvoiceMinFragmentDoc}
${InvoiceShippingContactFragmentDoc}
${FileItemFragmentDoc}
${FileItemMinFragmentDoc}
${PurchaseRequestWithFileFragmentDoc}
${PurchaseRequestMinFragmentDoc}
${PurchaseRequestShippingInfoMinFragmentDoc}
${TaskMinFragmentDoc}
${SalesItemReportMinFragmentDoc}`;
export type RemoveDiscountMutationFn = Apollo.MutationFunction<RemoveDiscountMutation, RemoveDiscountMutationVariables>;
export function useRemoveDiscountMutation(baseOptions?: Apollo.MutationHookOptions<RemoveDiscountMutation, RemoveDiscountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveDiscountMutation, RemoveDiscountMutationVariables>(RemoveDiscountDocument, options);
      }
export type RemoveDiscountMutationHookResult = ReturnType<typeof useRemoveDiscountMutation>;
export type RemoveDiscountMutationResult = Apollo.MutationResult<RemoveDiscountMutation>;
export const UpdateClassOrderItemDocument = gql`
    mutation updateClassOrderItem($id: ID!, $input: ClassOrderItemInput!) {
  updateClassOrderItem(id: $id, data: $input) {
    data {
      ...ClassOrderItem
    }
  }
}
    ${ClassOrderItemFragmentDoc}
${DiscountMinFragmentDoc}
${OrderMinFragmentDoc}
${ClassPerformerFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ClassLocationInfoMinFragmentDoc}
${ClassMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}`;
export type UpdateClassOrderItemMutationFn = Apollo.MutationFunction<UpdateClassOrderItemMutation, UpdateClassOrderItemMutationVariables>;
export function useUpdateClassOrderItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClassOrderItemMutation, UpdateClassOrderItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClassOrderItemMutation, UpdateClassOrderItemMutationVariables>(UpdateClassOrderItemDocument, options);
      }
export type UpdateClassOrderItemMutationHookResult = ReturnType<typeof useUpdateClassOrderItemMutation>;
export type UpdateClassOrderItemMutationResult = Apollo.MutationResult<UpdateClassOrderItemMutation>;
export const UpdateCompositeProductOrderItemDocument = gql`
    mutation updateCompositeProductOrderItem($id: ID!, $input: CompositeProductOrderItemInput!) {
  updateCompositeProductOrderItem(id: $id, data: $input) {
    data {
      ...CompositeProductOrderItem
    }
  }
}
    ${CompositeProductOrderItemFragmentDoc}
${DiscountMinFragmentDoc}
${CompositeProductLocationInfoFragmentDoc}
${CompositeProductLocationInfoMinFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${CompositeProductFragmentDoc}
${CompositeProductMinFragmentDoc}
${ProductFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${SerializeFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${CompositeProductItemInfoFragmentDoc}
${CompositeProductItemInfoMinFragmentDoc}
${OrderMinFragmentDoc}`;
export type UpdateCompositeProductOrderItemMutationFn = Apollo.MutationFunction<UpdateCompositeProductOrderItemMutation, UpdateCompositeProductOrderItemMutationVariables>;
export function useUpdateCompositeProductOrderItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompositeProductOrderItemMutation, UpdateCompositeProductOrderItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompositeProductOrderItemMutation, UpdateCompositeProductOrderItemMutationVariables>(UpdateCompositeProductOrderItemDocument, options);
      }
export type UpdateCompositeProductOrderItemMutationHookResult = ReturnType<typeof useUpdateCompositeProductOrderItemMutation>;
export type UpdateCompositeProductOrderItemMutationResult = Apollo.MutationResult<UpdateCompositeProductOrderItemMutation>;
export const UpdateCustomerOrderDocument = gql`
    mutation updateCustomerOrder($input: UpdateCustomerOrderInput!) {
  updateCustomerOrder(input: $input) {
    data {
      ...OrderMin
    }
  }
}
    ${OrderMinFragmentDoc}`;
export type UpdateCustomerOrderMutationFn = Apollo.MutationFunction<UpdateCustomerOrderMutation, UpdateCustomerOrderMutationVariables>;
export function useUpdateCustomerOrderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomerOrderMutation, UpdateCustomerOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomerOrderMutation, UpdateCustomerOrderMutationVariables>(UpdateCustomerOrderDocument, options);
      }
export type UpdateCustomerOrderMutationHookResult = ReturnType<typeof useUpdateCustomerOrderMutation>;
export type UpdateCustomerOrderMutationResult = Apollo.MutationResult<UpdateCustomerOrderMutation>;
export const UpdateInventoryAfterPurchaseDocument = gql`
    mutation updateInventoryAfterPurchase($input: UpdateInventoryAfterPurchaseInput!) {
  updateInventoryAfterPurchase(input: $input)
}
    `;
export type UpdateInventoryAfterPurchaseMutationFn = Apollo.MutationFunction<UpdateInventoryAfterPurchaseMutation, UpdateInventoryAfterPurchaseMutationVariables>;
export function useUpdateInventoryAfterPurchaseMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInventoryAfterPurchaseMutation, UpdateInventoryAfterPurchaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInventoryAfterPurchaseMutation, UpdateInventoryAfterPurchaseMutationVariables>(UpdateInventoryAfterPurchaseDocument, options);
      }
export type UpdateInventoryAfterPurchaseMutationHookResult = ReturnType<typeof useUpdateInventoryAfterPurchaseMutation>;
export type UpdateInventoryAfterPurchaseMutationResult = Apollo.MutationResult<UpdateInventoryAfterPurchaseMutation>;
export const UpdateMembershipOrderItemDocument = gql`
    mutation updateMembershipOrderItem($id: ID!, $input: MembershipOrderItemInput!) {
  updateMembershipOrderItem(id: $id, data: $input) {
    data {
      ...MembershipOrderItem
    }
  }
}
    ${MembershipOrderItemFragmentDoc}
${DiscountMinFragmentDoc}
${MembershipMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${TaxMinFragmentDoc}`;
export type UpdateMembershipOrderItemMutationFn = Apollo.MutationFunction<UpdateMembershipOrderItemMutation, UpdateMembershipOrderItemMutationVariables>;
export function useUpdateMembershipOrderItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMembershipOrderItemMutation, UpdateMembershipOrderItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMembershipOrderItemMutation, UpdateMembershipOrderItemMutationVariables>(UpdateMembershipOrderItemDocument, options);
      }
export type UpdateMembershipOrderItemMutationHookResult = ReturnType<typeof useUpdateMembershipOrderItemMutation>;
export type UpdateMembershipOrderItemMutationResult = Apollo.MutationResult<UpdateMembershipOrderItemMutation>;
export const UpdateOrderDocument = gql`
    mutation updateOrder($id: ID!, $input: OrderInput!) {
  updateOrder(id: $id, data: $input) {
    data {
      ...Order
    }
  }
}
    ${OrderFragmentDoc}
${OrderMinFragmentDoc}
${OrderWithItemsFragmentDoc}
${ProductOrderItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${SerializeFragmentDoc}
${DiscountMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${CompanyMinFragmentDoc}
${FileFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${CompositeProductOrderItemFragmentDoc}
${CompositeProductLocationInfoFragmentDoc}
${CompositeProductLocationInfoMinFragmentDoc}
${CompositeProductFragmentDoc}
${CompositeProductMinFragmentDoc}
${ProductFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${CompositeProductItemInfoFragmentDoc}
${CompositeProductItemInfoMinFragmentDoc}
${ServiceOrderItemFragmentDoc}
${ServicePerformerFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${ServiceMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}
${ClassOrderItemFragmentDoc}
${ClassPerformerFragmentDoc}
${ClassLocationInfoMinFragmentDoc}
${ClassMinFragmentDoc}
${MembershipOrderItemFragmentDoc}
${MembershipMinFragmentDoc}
${ContactFragmentDoc}
${ContactMinFragmentDoc}
${ContactDynamicValuesFragmentDoc}
${LeadFragmentDoc}
${LeadMinFragmentDoc}
${UserFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}
${CompanyFragmentDoc}
${DealTransactionMinFragmentDoc}
${InvoiceWithFileFragmentDoc}
${InvoiceMinFragmentDoc}
${InvoiceShippingContactFragmentDoc}
${FileItemFragmentDoc}
${FileItemMinFragmentDoc}
${PurchaseRequestWithFileFragmentDoc}
${PurchaseRequestMinFragmentDoc}
${PurchaseRequestShippingInfoMinFragmentDoc}
${TaskMinFragmentDoc}
${SalesItemReportMinFragmentDoc}`;
export type UpdateOrderMutationFn = Apollo.MutationFunction<UpdateOrderMutation, UpdateOrderMutationVariables>;
export function useUpdateOrderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrderMutation, UpdateOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrderMutation, UpdateOrderMutationVariables>(UpdateOrderDocument, options);
      }
export type UpdateOrderMutationHookResult = ReturnType<typeof useUpdateOrderMutation>;
export type UpdateOrderMutationResult = Apollo.MutationResult<UpdateOrderMutation>;
export const UpdateProductOrderItemDocument = gql`
    mutation updateProductOrderItem($input: ProductOrderItemInput!, $id: ID!) {
  updateProductOrderItem(id: $id, data: $input) {
    data {
      ...ProductOrderItem
    }
  }
}
    ${ProductOrderItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${SerializeFragmentDoc}
${DiscountMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${CompanyMinFragmentDoc}
${FileFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${OrderMinFragmentDoc}`;
export type UpdateProductOrderItemMutationFn = Apollo.MutationFunction<UpdateProductOrderItemMutation, UpdateProductOrderItemMutationVariables>;
export function useUpdateProductOrderItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductOrderItemMutation, UpdateProductOrderItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductOrderItemMutation, UpdateProductOrderItemMutationVariables>(UpdateProductOrderItemDocument, options);
      }
export type UpdateProductOrderItemMutationHookResult = ReturnType<typeof useUpdateProductOrderItemMutation>;
export type UpdateProductOrderItemMutationResult = Apollo.MutationResult<UpdateProductOrderItemMutation>;
export const UpdateServiceOrderItemDocument = gql`
    mutation updateServiceOrderItem($id: ID!, $input: ServiceOrderItemInput!) {
  updateServiceOrderItem(id: $id, data: $input) {
    data {
      ...ServiceOrderItem
    }
  }
}
    ${ServiceOrderItemFragmentDoc}
${DiscountMinFragmentDoc}
${ServicePerformerFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${ServiceMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}
${OrderMinFragmentDoc}`;
export type UpdateServiceOrderItemMutationFn = Apollo.MutationFunction<UpdateServiceOrderItemMutation, UpdateServiceOrderItemMutationVariables>;
export function useUpdateServiceOrderItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateServiceOrderItemMutation, UpdateServiceOrderItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateServiceOrderItemMutation, UpdateServiceOrderItemMutationVariables>(UpdateServiceOrderItemDocument, options);
      }
export type UpdateServiceOrderItemMutationHookResult = ReturnType<typeof useUpdateServiceOrderItemMutation>;
export type UpdateServiceOrderItemMutationResult = Apollo.MutationResult<UpdateServiceOrderItemMutation>;
export const CreateOrdersFromCsvDocument = gql`
    mutation createOrdersFromCSV($input: CreateOrdersFromCSVInput!) {
  createOrdersFromCSV(input: $input)
}
    `;
export type CreateOrdersFromCsvMutationFn = Apollo.MutationFunction<CreateOrdersFromCsvMutation, CreateOrdersFromCsvMutationVariables>;
export function useCreateOrdersFromCsvMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrdersFromCsvMutation, CreateOrdersFromCsvMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrdersFromCsvMutation, CreateOrdersFromCsvMutationVariables>(CreateOrdersFromCsvDocument, options);
      }
export type CreateOrdersFromCsvMutationHookResult = ReturnType<typeof useCreateOrdersFromCsvMutation>;
export type CreateOrdersFromCsvMutationResult = Apollo.MutationResult<CreateOrdersFromCsvMutation>;
export const GetPosTerminalListDocument = gql`
    query getPosTerminalList($input: getPosTerminalListInput!) {
  getPosTerminalList(input: $input) {
    data {
      id
      object
      device_sw_version
      device_type
      ip_address
      label
      last_seen_at
      livemode
      location
      serial_number
      status
    }
  }
}
    `;
export function useGetPosTerminalListQuery(baseOptions: Apollo.QueryHookOptions<GetPosTerminalListQuery, GetPosTerminalListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPosTerminalListQuery, GetPosTerminalListQueryVariables>(GetPosTerminalListDocument, options);
      }
export function useGetPosTerminalListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPosTerminalListQuery, GetPosTerminalListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPosTerminalListQuery, GetPosTerminalListQueryVariables>(GetPosTerminalListDocument, options);
        }
export type GetPosTerminalListQueryHookResult = ReturnType<typeof useGetPosTerminalListQuery>;
export type GetPosTerminalListLazyQueryHookResult = ReturnType<typeof useGetPosTerminalListLazyQuery>;
export type GetPosTerminalListQueryResult = Apollo.QueryResult<GetPosTerminalListQuery, GetPosTerminalListQueryVariables>;
export const OrderDocument = gql`
    query order($id: ID!) {
  order(id: $id) {
    data {
      ...Order
    }
  }
}
    ${OrderFragmentDoc}
${OrderMinFragmentDoc}
${OrderWithItemsFragmentDoc}
${ProductOrderItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${SerializeFragmentDoc}
${DiscountMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${CompanyMinFragmentDoc}
${FileFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${CompositeProductOrderItemFragmentDoc}
${CompositeProductLocationInfoFragmentDoc}
${CompositeProductLocationInfoMinFragmentDoc}
${CompositeProductFragmentDoc}
${CompositeProductMinFragmentDoc}
${ProductFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${CompositeProductItemInfoFragmentDoc}
${CompositeProductItemInfoMinFragmentDoc}
${ServiceOrderItemFragmentDoc}
${ServicePerformerFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${ServiceMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}
${ClassOrderItemFragmentDoc}
${ClassPerformerFragmentDoc}
${ClassLocationInfoMinFragmentDoc}
${ClassMinFragmentDoc}
${MembershipOrderItemFragmentDoc}
${MembershipMinFragmentDoc}
${ContactFragmentDoc}
${ContactMinFragmentDoc}
${ContactDynamicValuesFragmentDoc}
${LeadFragmentDoc}
${LeadMinFragmentDoc}
${UserFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}
${CompanyFragmentDoc}
${DealTransactionMinFragmentDoc}
${InvoiceWithFileFragmentDoc}
${InvoiceMinFragmentDoc}
${InvoiceShippingContactFragmentDoc}
${FileItemFragmentDoc}
${FileItemMinFragmentDoc}
${PurchaseRequestWithFileFragmentDoc}
${PurchaseRequestMinFragmentDoc}
${PurchaseRequestShippingInfoMinFragmentDoc}
${TaskMinFragmentDoc}
${SalesItemReportMinFragmentDoc}`;
export function useOrderQuery(baseOptions: Apollo.QueryHookOptions<OrderQuery, OrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderQuery, OrderQueryVariables>(OrderDocument, options);
      }
export function useOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderQuery, OrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderQuery, OrderQueryVariables>(OrderDocument, options);
        }
export type OrderQueryHookResult = ReturnType<typeof useOrderQuery>;
export type OrderLazyQueryHookResult = ReturnType<typeof useOrderLazyQuery>;
export type OrderQueryResult = Apollo.QueryResult<OrderQuery, OrderQueryVariables>;
export const OrderByIdDocument = gql`
    query orderById($orderId: String!) {
  orders(filters: {orderId: {eq: $orderId}}) {
    data {
      ...Order
    }
  }
}
    ${OrderFragmentDoc}
${OrderMinFragmentDoc}
${OrderWithItemsFragmentDoc}
${ProductOrderItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${SerializeFragmentDoc}
${DiscountMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${CompanyMinFragmentDoc}
${FileFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${CompositeProductOrderItemFragmentDoc}
${CompositeProductLocationInfoFragmentDoc}
${CompositeProductLocationInfoMinFragmentDoc}
${CompositeProductFragmentDoc}
${CompositeProductMinFragmentDoc}
${ProductFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${CompositeProductItemInfoFragmentDoc}
${CompositeProductItemInfoMinFragmentDoc}
${ServiceOrderItemFragmentDoc}
${ServicePerformerFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${ServiceMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}
${ClassOrderItemFragmentDoc}
${ClassPerformerFragmentDoc}
${ClassLocationInfoMinFragmentDoc}
${ClassMinFragmentDoc}
${MembershipOrderItemFragmentDoc}
${MembershipMinFragmentDoc}
${ContactFragmentDoc}
${ContactMinFragmentDoc}
${ContactDynamicValuesFragmentDoc}
${LeadFragmentDoc}
${LeadMinFragmentDoc}
${UserFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}
${CompanyFragmentDoc}
${DealTransactionMinFragmentDoc}
${InvoiceWithFileFragmentDoc}
${InvoiceMinFragmentDoc}
${InvoiceShippingContactFragmentDoc}
${FileItemFragmentDoc}
${FileItemMinFragmentDoc}
${PurchaseRequestWithFileFragmentDoc}
${PurchaseRequestMinFragmentDoc}
${PurchaseRequestShippingInfoMinFragmentDoc}
${TaskMinFragmentDoc}
${SalesItemReportMinFragmentDoc}`;
export function useOrderByIdQuery(baseOptions: Apollo.QueryHookOptions<OrderByIdQuery, OrderByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderByIdQuery, OrderByIdQueryVariables>(OrderByIdDocument, options);
      }
export function useOrderByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderByIdQuery, OrderByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderByIdQuery, OrderByIdQueryVariables>(OrderByIdDocument, options);
        }
export type OrderByIdQueryHookResult = ReturnType<typeof useOrderByIdQuery>;
export type OrderByIdLazyQueryHookResult = ReturnType<typeof useOrderByIdLazyQuery>;
export type OrderByIdQueryResult = Apollo.QueryResult<OrderByIdQuery, OrderByIdQueryVariables>;
export const OrderItemsDocument = gql`
    query orderItems($orderId: String!) {
  orders(filters: {orderId: {eq: $orderId}}) {
    data {
      ...OrderWithItems
    }
  }
}
    ${OrderWithItemsFragmentDoc}
${ProductOrderItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${SerializeFragmentDoc}
${DiscountMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${CompanyMinFragmentDoc}
${FileFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${OrderMinFragmentDoc}
${CompositeProductOrderItemFragmentDoc}
${CompositeProductLocationInfoFragmentDoc}
${CompositeProductLocationInfoMinFragmentDoc}
${CompositeProductFragmentDoc}
${CompositeProductMinFragmentDoc}
${ProductFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${CompositeProductItemInfoFragmentDoc}
${CompositeProductItemInfoMinFragmentDoc}
${ServiceOrderItemFragmentDoc}
${ServicePerformerFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${ServiceMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}
${ClassOrderItemFragmentDoc}
${ClassPerformerFragmentDoc}
${ClassLocationInfoMinFragmentDoc}
${ClassMinFragmentDoc}
${MembershipOrderItemFragmentDoc}
${MembershipMinFragmentDoc}`;
export function useOrderItemsQuery(baseOptions: Apollo.QueryHookOptions<OrderItemsQuery, OrderItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderItemsQuery, OrderItemsQueryVariables>(OrderItemsDocument, options);
      }
export function useOrderItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderItemsQuery, OrderItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderItemsQuery, OrderItemsQueryVariables>(OrderItemsDocument, options);
        }
export type OrderItemsQueryHookResult = ReturnType<typeof useOrderItemsQuery>;
export type OrderItemsLazyQueryHookResult = ReturnType<typeof useOrderItemsLazyQuery>;
export type OrderItemsQueryResult = Apollo.QueryResult<OrderItemsQuery, OrderItemsQueryVariables>;
export const OrderTaxReportsDocument = gql`
    query orderTaxReports($filters: OrderFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  orders(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...OrderTaxReport
    }
    meta {
      ...Meta
    }
  }
}
    ${OrderTaxReportFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useOrderTaxReportsQuery(baseOptions?: Apollo.QueryHookOptions<OrderTaxReportsQuery, OrderTaxReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrderTaxReportsQuery, OrderTaxReportsQueryVariables>(OrderTaxReportsDocument, options);
      }
export function useOrderTaxReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderTaxReportsQuery, OrderTaxReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrderTaxReportsQuery, OrderTaxReportsQueryVariables>(OrderTaxReportsDocument, options);
        }
export type OrderTaxReportsQueryHookResult = ReturnType<typeof useOrderTaxReportsQuery>;
export type OrderTaxReportsLazyQueryHookResult = ReturnType<typeof useOrderTaxReportsLazyQuery>;
export type OrderTaxReportsQueryResult = Apollo.QueryResult<OrderTaxReportsQuery, OrderTaxReportsQueryVariables>;
export const OrdersDocument = gql`
    query orders($filters: OrderFiltersInput!, $pagination: PaginationArg! = {}, $sort: [String] = []) {
  orders(sort: $sort, filters: $filters, pagination: $pagination) {
    data {
      ...Order
    }
    meta {
      ...Meta
    }
  }
}
    ${OrderFragmentDoc}
${OrderMinFragmentDoc}
${OrderWithItemsFragmentDoc}
${ProductOrderItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${SerializeFragmentDoc}
${DiscountMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${CompanyMinFragmentDoc}
${FileFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${CompositeProductOrderItemFragmentDoc}
${CompositeProductLocationInfoFragmentDoc}
${CompositeProductLocationInfoMinFragmentDoc}
${CompositeProductFragmentDoc}
${CompositeProductMinFragmentDoc}
${ProductFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${CompositeProductItemInfoFragmentDoc}
${CompositeProductItemInfoMinFragmentDoc}
${ServiceOrderItemFragmentDoc}
${ServicePerformerFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${ServiceMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}
${ClassOrderItemFragmentDoc}
${ClassPerformerFragmentDoc}
${ClassLocationInfoMinFragmentDoc}
${ClassMinFragmentDoc}
${MembershipOrderItemFragmentDoc}
${MembershipMinFragmentDoc}
${ContactFragmentDoc}
${ContactMinFragmentDoc}
${ContactDynamicValuesFragmentDoc}
${LeadFragmentDoc}
${LeadMinFragmentDoc}
${UserFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}
${CompanyFragmentDoc}
${DealTransactionMinFragmentDoc}
${InvoiceWithFileFragmentDoc}
${InvoiceMinFragmentDoc}
${InvoiceShippingContactFragmentDoc}
${FileItemFragmentDoc}
${FileItemMinFragmentDoc}
${PurchaseRequestWithFileFragmentDoc}
${PurchaseRequestMinFragmentDoc}
${PurchaseRequestShippingInfoMinFragmentDoc}
${TaskMinFragmentDoc}
${SalesItemReportMinFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useOrdersQuery(baseOptions: Apollo.QueryHookOptions<OrdersQuery, OrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, options);
      }
export function useOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrdersQuery, OrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, options);
        }
export type OrdersQueryHookResult = ReturnType<typeof useOrdersQuery>;
export type OrdersLazyQueryHookResult = ReturnType<typeof useOrdersLazyQuery>;
export type OrdersQueryResult = Apollo.QueryResult<OrdersQuery, OrdersQueryVariables>;
export const OrdersCsvReportDocument = gql`
    query ordersCsvReport($filters: OrderFiltersInput, $sort: [String] = []) {
  orders(sort: $sort, filters: $filters, pagination: {limit: -1}) {
    data {
      id
      attributes {
        orderId
        itemsAmount
        dueDate
        total
        company {
          data {
            id
            attributes {
              name
            }
          }
        }
        contact {
          data {
            id
            attributes {
              fullName
            }
          }
        }
        sales {
          data {
            id
            attributes {
              fullName
            }
          }
        }
        salesItemReports {
          data {
            ...SalesItemReportMin
          }
        }
      }
    }
    meta {
      ...Meta
    }
  }
}
    ${SalesItemReportMinFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useOrdersCsvReportQuery(baseOptions?: Apollo.QueryHookOptions<OrdersCsvReportQuery, OrdersCsvReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrdersCsvReportQuery, OrdersCsvReportQueryVariables>(OrdersCsvReportDocument, options);
      }
export function useOrdersCsvReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrdersCsvReportQuery, OrdersCsvReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrdersCsvReportQuery, OrdersCsvReportQueryVariables>(OrdersCsvReportDocument, options);
        }
export type OrdersCsvReportQueryHookResult = ReturnType<typeof useOrdersCsvReportQuery>;
export type OrdersCsvReportLazyQueryHookResult = ReturnType<typeof useOrdersCsvReportLazyQuery>;
export type OrdersCsvReportQueryResult = Apollo.QueryResult<OrdersCsvReportQuery, OrdersCsvReportQueryVariables>;
export const ProductOrderItemsDocument = gql`
    query productOrderItems($filters: ProductOrderItemFiltersInput!, $pagination: PaginationArg! = {}, $sort: [String] = []) {
  productOrderItems(sort: $sort, filters: $filters, pagination: $pagination) {
    data {
      ...ProductOrderItem
    }
    meta {
      ...Meta
    }
  }
}
    ${ProductOrderItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${SerializeFragmentDoc}
${DiscountMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${CompanyMinFragmentDoc}
${FileFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${OrderMinFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useProductOrderItemsQuery(baseOptions: Apollo.QueryHookOptions<ProductOrderItemsQuery, ProductOrderItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductOrderItemsQuery, ProductOrderItemsQueryVariables>(ProductOrderItemsDocument, options);
      }
export function useProductOrderItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductOrderItemsQuery, ProductOrderItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductOrderItemsQuery, ProductOrderItemsQueryVariables>(ProductOrderItemsDocument, options);
        }
export type ProductOrderItemsQueryHookResult = ReturnType<typeof useProductOrderItemsQuery>;
export type ProductOrderItemsLazyQueryHookResult = ReturnType<typeof useProductOrderItemsLazyQuery>;
export type ProductOrderItemsQueryResult = Apollo.QueryResult<ProductOrderItemsQuery, ProductOrderItemsQueryVariables>;
export const ProductOrderItemsWithCustomerDocument = gql`
    query productOrderItemsWithCustomer($filters: ProductOrderItemFiltersInput!, $pagination: PaginationArg! = {}, $sort: [String] = []) {
  productOrderItems(sort: $sort, filters: $filters, pagination: $pagination) {
    data {
      ...ProductOrderItemWithCustomer
    }
    meta {
      ...Meta
    }
  }
}
    ${ProductOrderItemWithCustomerFragmentDoc}
${DiscountMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${FileFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${SerializeFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${OrderMinWithCustomerFragmentDoc}
${ContactMinFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useProductOrderItemsWithCustomerQuery(baseOptions: Apollo.QueryHookOptions<ProductOrderItemsWithCustomerQuery, ProductOrderItemsWithCustomerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductOrderItemsWithCustomerQuery, ProductOrderItemsWithCustomerQueryVariables>(ProductOrderItemsWithCustomerDocument, options);
      }
export function useProductOrderItemsWithCustomerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductOrderItemsWithCustomerQuery, ProductOrderItemsWithCustomerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductOrderItemsWithCustomerQuery, ProductOrderItemsWithCustomerQueryVariables>(ProductOrderItemsWithCustomerDocument, options);
        }
export type ProductOrderItemsWithCustomerQueryHookResult = ReturnType<typeof useProductOrderItemsWithCustomerQuery>;
export type ProductOrderItemsWithCustomerLazyQueryHookResult = ReturnType<typeof useProductOrderItemsWithCustomerLazyQuery>;
export type ProductOrderItemsWithCustomerQueryResult = Apollo.QueryResult<ProductOrderItemsWithCustomerQuery, ProductOrderItemsWithCustomerQueryVariables>;
export const UpdatePayRateDocument = gql`
    mutation updatePayRate($id: ID!, $input: PayRateInput!) {
  updatePayRate(id: $id, data: $input) {
    data {
      ...PayRate
    }
  }
}
    ${PayRateFragmentDoc}`;
export type UpdatePayRateMutationFn = Apollo.MutationFunction<UpdatePayRateMutation, UpdatePayRateMutationVariables>;
export function useUpdatePayRateMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePayRateMutation, UpdatePayRateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePayRateMutation, UpdatePayRateMutationVariables>(UpdatePayRateDocument, options);
      }
export type UpdatePayRateMutationHookResult = ReturnType<typeof useUpdatePayRateMutation>;
export type UpdatePayRateMutationResult = Apollo.MutationResult<UpdatePayRateMutation>;
export const CreatePayRateDocument = gql`
    mutation createPayRate($input: PayRateInput!) {
  createPayRate(data: $input) {
    data {
      ...PayRate
    }
  }
}
    ${PayRateFragmentDoc}`;
export type CreatePayRateMutationFn = Apollo.MutationFunction<CreatePayRateMutation, CreatePayRateMutationVariables>;
export function useCreatePayRateMutation(baseOptions?: Apollo.MutationHookOptions<CreatePayRateMutation, CreatePayRateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePayRateMutation, CreatePayRateMutationVariables>(CreatePayRateDocument, options);
      }
export type CreatePayRateMutationHookResult = ReturnType<typeof useCreatePayRateMutation>;
export type CreatePayRateMutationResult = Apollo.MutationResult<CreatePayRateMutation>;
export const PlatformDocument = gql`
    query platform {
  platform {
    data {
      attributes {
        name
        logo {
          data {
            ...File
          }
        }
        minifiedLogo {
          data {
            ...File
          }
        }
        address
      }
    }
  }
}
    ${FileFragmentDoc}`;
export function usePlatformQuery(baseOptions?: Apollo.QueryHookOptions<PlatformQuery, PlatformQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlatformQuery, PlatformQueryVariables>(PlatformDocument, options);
      }
export function usePlatformLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlatformQuery, PlatformQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlatformQuery, PlatformQueryVariables>(PlatformDocument, options);
        }
export type PlatformQueryHookResult = ReturnType<typeof usePlatformQuery>;
export type PlatformLazyQueryHookResult = ReturnType<typeof usePlatformLazyQuery>;
export type PlatformQueryResult = Apollo.QueryResult<PlatformQuery, PlatformQueryVariables>;
export const PosProductItemsDocument = gql`
    query posProductItems($pagination: PaginationArg, $productsFilter: ProductInventoryItemFiltersInput! = {}, $servicesFilter: ServicePerformerFiltersInput! = {}, $classesFilter: ClassPerformerFiltersInput! = {}, $compositeProductFilter: CompositeProductFiltersInput! = {}, $membershipFilter: MembershipFiltersInput! = {}) {
  productInventoryItems(pagination: $pagination, filters: $productsFilter) {
    data {
      ...ProductInventoryItem
    }
  }
  servicePerformers(pagination: $pagination, filters: $servicesFilter) {
    data {
      ...ServicePerformer
    }
  }
  classPerformers(pagination: $pagination, filters: $classesFilter) {
    data {
      ...ClassPerformer
    }
  }
  compositeProducts(pagination: $pagination, filters: $compositeProductFilter) {
    data {
      ...CompositeProduct
    }
  }
  memberships(pagination: $pagination, filters: $membershipFilter) {
    data {
      ...Membership
    }
  }
}
    ${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${FileFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${SerializeFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${ServicePerformerFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${ServiceMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}
${ClassPerformerFragmentDoc}
${ClassLocationInfoMinFragmentDoc}
${ClassMinFragmentDoc}
${CompositeProductFragmentDoc}
${CompositeProductMinFragmentDoc}
${ProductFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${CompositeProductLocationInfoMinFragmentDoc}
${CompositeProductItemInfoFragmentDoc}
${CompositeProductItemInfoMinFragmentDoc}
${MembershipFragmentDoc}
${MembershipMinFragmentDoc}
${MembershipItemFragmentDoc}
${ServiceFragmentDoc}
${ServiceLocationInfoFragmentDoc}
${ClassFragmentDoc}
${ClassLocationInfoFragmentDoc}`;
export function usePosProductItemsQuery(baseOptions?: Apollo.QueryHookOptions<PosProductItemsQuery, PosProductItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PosProductItemsQuery, PosProductItemsQueryVariables>(PosProductItemsDocument, options);
      }
export function usePosProductItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PosProductItemsQuery, PosProductItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PosProductItemsQuery, PosProductItemsQueryVariables>(PosProductItemsDocument, options);
        }
export type PosProductItemsQueryHookResult = ReturnType<typeof usePosProductItemsQuery>;
export type PosProductItemsLazyQueryHookResult = ReturnType<typeof usePosProductItemsLazyQuery>;
export type PosProductItemsQueryResult = Apollo.QueryResult<PosProductItemsQuery, PosProductItemsQueryVariables>;
export const CreateProductDocument = gql`
    mutation createProduct($input: ProductInput!) {
  createProduct(data: $input) {
    data {
      ...Product
    }
  }
}
    ${ProductFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${SerializeFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}`;
export type CreateProductMutationFn = Apollo.MutationFunction<CreateProductMutation, CreateProductMutationVariables>;
export function useCreateProductMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductMutation, CreateProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProductMutation, CreateProductMutationVariables>(CreateProductDocument, options);
      }
export type CreateProductMutationHookResult = ReturnType<typeof useCreateProductMutation>;
export type CreateProductMutationResult = Apollo.MutationResult<CreateProductMutation>;
export const CreateProductBrandDocument = gql`
    mutation createProductBrand($input: ProductBrandInput!) {
  createProductBrand(data: $input) {
    data {
      ...ProductBrand
    }
  }
}
    ${ProductBrandFragmentDoc}`;
export type CreateProductBrandMutationFn = Apollo.MutationFunction<CreateProductBrandMutation, CreateProductBrandMutationVariables>;
export function useCreateProductBrandMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductBrandMutation, CreateProductBrandMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProductBrandMutation, CreateProductBrandMutationVariables>(CreateProductBrandDocument, options);
      }
export type CreateProductBrandMutationHookResult = ReturnType<typeof useCreateProductBrandMutation>;
export type CreateProductBrandMutationResult = Apollo.MutationResult<CreateProductBrandMutation>;
export const CreateProductInventoryItemDocument = gql`
    mutation createProductInventoryItem($input: ProductInventoryItemInput!) {
  createProductInventoryItem(data: $input) {
    data {
      ...ProductInventoryItem
    }
  }
}
    ${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${FileFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${SerializeFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}`;
export type CreateProductInventoryItemMutationFn = Apollo.MutationFunction<CreateProductInventoryItemMutation, CreateProductInventoryItemMutationVariables>;
export function useCreateProductInventoryItemMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductInventoryItemMutation, CreateProductInventoryItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProductInventoryItemMutation, CreateProductInventoryItemMutationVariables>(CreateProductInventoryItemDocument, options);
      }
export type CreateProductInventoryItemMutationHookResult = ReturnType<typeof useCreateProductInventoryItemMutation>;
export type CreateProductInventoryItemMutationResult = Apollo.MutationResult<CreateProductInventoryItemMutation>;
export const CreateProductInventoryItemEventDocument = gql`
    mutation createProductInventoryItemEvent($input: ProductInventoryItemEventInput!) {
  createProductInventoryItemEvent(data: $input) {
    data {
      ...ProductInventoryItemEvent
    }
  }
}
    ${ProductInventoryItemEventFragmentDoc}
${CompanyFragmentDoc}
${CompanyMinFragmentDoc}
${FileFragmentDoc}
${ContactMinFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${BusinessLocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}
${OrderMinFragmentDoc}
${ContactFragmentDoc}
${ContactDynamicValuesFragmentDoc}
${LeadFragmentDoc}
${LeadMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${SerializeFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}`;
export type CreateProductInventoryItemEventMutationFn = Apollo.MutationFunction<CreateProductInventoryItemEventMutation, CreateProductInventoryItemEventMutationVariables>;
export function useCreateProductInventoryItemEventMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductInventoryItemEventMutation, CreateProductInventoryItemEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProductInventoryItemEventMutation, CreateProductInventoryItemEventMutationVariables>(CreateProductInventoryItemEventDocument, options);
      }
export type CreateProductInventoryItemEventMutationHookResult = ReturnType<typeof useCreateProductInventoryItemEventMutation>;
export type CreateProductInventoryItemEventMutationResult = Apollo.MutationResult<CreateProductInventoryItemEventMutation>;
export const CreateProductTypeDocument = gql`
    mutation createProductType($input: ProductTypeInput!) {
  createProductType(data: $input) {
    data {
      ...ProductType
    }
  }
}
    ${ProductTypeFragmentDoc}`;
export type CreateProductTypeMutationFn = Apollo.MutationFunction<CreateProductTypeMutation, CreateProductTypeMutationVariables>;
export function useCreateProductTypeMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductTypeMutation, CreateProductTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProductTypeMutation, CreateProductTypeMutationVariables>(CreateProductTypeDocument, options);
      }
export type CreateProductTypeMutationHookResult = ReturnType<typeof useCreateProductTypeMutation>;
export type CreateProductTypeMutationResult = Apollo.MutationResult<CreateProductTypeMutation>;
export const CreateProductsFromCsvDocument = gql`
    mutation createProductsFromCsv($input: CreateProductsFromCSVInput!) {
  createProductsFromCSV(input: $input)
}
    `;
export type CreateProductsFromCsvMutationFn = Apollo.MutationFunction<CreateProductsFromCsvMutation, CreateProductsFromCsvMutationVariables>;
export function useCreateProductsFromCsvMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductsFromCsvMutation, CreateProductsFromCsvMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProductsFromCsvMutation, CreateProductsFromCsvMutationVariables>(CreateProductsFromCsvDocument, options);
      }
export type CreateProductsFromCsvMutationHookResult = ReturnType<typeof useCreateProductsFromCsvMutation>;
export type CreateProductsFromCsvMutationResult = Apollo.MutationResult<CreateProductsFromCsvMutation>;
export const CreateRentableDataDocument = gql`
    mutation createRentableData($input: RentableDataInput!) {
  createRentableData(data: $input) {
    data {
      ...RentableData
    }
  }
}
    ${RentableDataFragmentDoc}`;
export type CreateRentableDataMutationFn = Apollo.MutationFunction<CreateRentableDataMutation, CreateRentableDataMutationVariables>;
export function useCreateRentableDataMutation(baseOptions?: Apollo.MutationHookOptions<CreateRentableDataMutation, CreateRentableDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRentableDataMutation, CreateRentableDataMutationVariables>(CreateRentableDataDocument, options);
      }
export type CreateRentableDataMutationHookResult = ReturnType<typeof useCreateRentableDataMutation>;
export type CreateRentableDataMutationResult = Apollo.MutationResult<CreateRentableDataMutation>;
export const CreateSerializeDocument = gql`
    mutation createSerialize($input: InventorySerializeInput!) {
  createInventorySerialize(data: $input) {
    data {
      ...Serialize
    }
  }
}
    ${SerializeFragmentDoc}`;
export type CreateSerializeMutationFn = Apollo.MutationFunction<CreateSerializeMutation, CreateSerializeMutationVariables>;
export function useCreateSerializeMutation(baseOptions?: Apollo.MutationHookOptions<CreateSerializeMutation, CreateSerializeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSerializeMutation, CreateSerializeMutationVariables>(CreateSerializeDocument, options);
      }
export type CreateSerializeMutationHookResult = ReturnType<typeof useCreateSerializeMutation>;
export type CreateSerializeMutationResult = Apollo.MutationResult<CreateSerializeMutation>;
export const CreateWeightDocument = gql`
    mutation createWeight($input: WeightInput!) {
  createWeight(data: $input) {
    data {
      ...Weight
    }
  }
}
    ${WeightFragmentDoc}`;
export type CreateWeightMutationFn = Apollo.MutationFunction<CreateWeightMutation, CreateWeightMutationVariables>;
export function useCreateWeightMutation(baseOptions?: Apollo.MutationHookOptions<CreateWeightMutation, CreateWeightMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWeightMutation, CreateWeightMutationVariables>(CreateWeightDocument, options);
      }
export type CreateWeightMutationHookResult = ReturnType<typeof useCreateWeightMutation>;
export type CreateWeightMutationResult = Apollo.MutationResult<CreateWeightMutation>;
export const DeleteProductBrandDocument = gql`
    mutation deleteProductBrand($id: ID!) {
  deleteProductBrand(id: $id) {
    data {
      ...ProductBrand
    }
  }
}
    ${ProductBrandFragmentDoc}`;
export type DeleteProductBrandMutationFn = Apollo.MutationFunction<DeleteProductBrandMutation, DeleteProductBrandMutationVariables>;
export function useDeleteProductBrandMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProductBrandMutation, DeleteProductBrandMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProductBrandMutation, DeleteProductBrandMutationVariables>(DeleteProductBrandDocument, options);
      }
export type DeleteProductBrandMutationHookResult = ReturnType<typeof useDeleteProductBrandMutation>;
export type DeleteProductBrandMutationResult = Apollo.MutationResult<DeleteProductBrandMutation>;
export const DeleteProductInventoryItemEventDocument = gql`
    mutation deleteProductInventoryItemEvent($id: ID!) {
  deleteProductInventoryItemEvent(id: $id) {
    data {
      ...ProductInventoryItemEvent
    }
  }
}
    ${ProductInventoryItemEventFragmentDoc}
${CompanyFragmentDoc}
${CompanyMinFragmentDoc}
${FileFragmentDoc}
${ContactMinFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${BusinessLocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}
${OrderMinFragmentDoc}
${ContactFragmentDoc}
${ContactDynamicValuesFragmentDoc}
${LeadFragmentDoc}
${LeadMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${SerializeFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}`;
export type DeleteProductInventoryItemEventMutationFn = Apollo.MutationFunction<DeleteProductInventoryItemEventMutation, DeleteProductInventoryItemEventMutationVariables>;
export function useDeleteProductInventoryItemEventMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProductInventoryItemEventMutation, DeleteProductInventoryItemEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProductInventoryItemEventMutation, DeleteProductInventoryItemEventMutationVariables>(DeleteProductInventoryItemEventDocument, options);
      }
export type DeleteProductInventoryItemEventMutationHookResult = ReturnType<typeof useDeleteProductInventoryItemEventMutation>;
export type DeleteProductInventoryItemEventMutationResult = Apollo.MutationResult<DeleteProductInventoryItemEventMutation>;
export const DeleteProductTypeDocument = gql`
    mutation deleteProductType($id: ID!) {
  deleteProductType(id: $id) {
    data {
      ...ProductType
    }
  }
}
    ${ProductTypeFragmentDoc}`;
export type DeleteProductTypeMutationFn = Apollo.MutationFunction<DeleteProductTypeMutation, DeleteProductTypeMutationVariables>;
export function useDeleteProductTypeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProductTypeMutation, DeleteProductTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProductTypeMutation, DeleteProductTypeMutationVariables>(DeleteProductTypeDocument, options);
      }
export type DeleteProductTypeMutationHookResult = ReturnType<typeof useDeleteProductTypeMutation>;
export type DeleteProductTypeMutationResult = Apollo.MutationResult<DeleteProductTypeMutation>;
export const DeleteRentableDataDocument = gql`
    mutation deleteRentableData($id: ID!) {
  deleteRentableData(id: $id) {
    data {
      ...RentableData
    }
  }
}
    ${RentableDataFragmentDoc}`;
export type DeleteRentableDataMutationFn = Apollo.MutationFunction<DeleteRentableDataMutation, DeleteRentableDataMutationVariables>;
export function useDeleteRentableDataMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRentableDataMutation, DeleteRentableDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRentableDataMutation, DeleteRentableDataMutationVariables>(DeleteRentableDataDocument, options);
      }
export type DeleteRentableDataMutationHookResult = ReturnType<typeof useDeleteRentableDataMutation>;
export type DeleteRentableDataMutationResult = Apollo.MutationResult<DeleteRentableDataMutation>;
export const DeleteSerializeDocument = gql`
    mutation deleteSerialize($id: ID!) {
  deleteInventorySerialize(id: $id) {
    data {
      ...Serialize
    }
  }
}
    ${SerializeFragmentDoc}`;
export type DeleteSerializeMutationFn = Apollo.MutationFunction<DeleteSerializeMutation, DeleteSerializeMutationVariables>;
export function useDeleteSerializeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSerializeMutation, DeleteSerializeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSerializeMutation, DeleteSerializeMutationVariables>(DeleteSerializeDocument, options);
      }
export type DeleteSerializeMutationHookResult = ReturnType<typeof useDeleteSerializeMutation>;
export type DeleteSerializeMutationResult = Apollo.MutationResult<DeleteSerializeMutation>;
export const DeleteWeightDocument = gql`
    mutation deleteWeight($id: ID!) {
  deleteWeight(id: $id) {
    data {
      ...Weight
    }
  }
}
    ${WeightFragmentDoc}`;
export type DeleteWeightMutationFn = Apollo.MutationFunction<DeleteWeightMutation, DeleteWeightMutationVariables>;
export function useDeleteWeightMutation(baseOptions?: Apollo.MutationHookOptions<DeleteWeightMutation, DeleteWeightMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteWeightMutation, DeleteWeightMutationVariables>(DeleteWeightDocument, options);
      }
export type DeleteWeightMutationHookResult = ReturnType<typeof useDeleteWeightMutation>;
export type DeleteWeightMutationResult = Apollo.MutationResult<DeleteWeightMutation>;
export const FastUpdateAllProductsFromCsvDocument = gql`
    mutation fastUpdateAllProductsFromCSV($input: FastUpdateAllProductsFromCSVInput!) {
  fastUpdateAllProductsFromCSV(input: $input)
}
    `;
export type FastUpdateAllProductsFromCsvMutationFn = Apollo.MutationFunction<FastUpdateAllProductsFromCsvMutation, FastUpdateAllProductsFromCsvMutationVariables>;
export function useFastUpdateAllProductsFromCsvMutation(baseOptions?: Apollo.MutationHookOptions<FastUpdateAllProductsFromCsvMutation, FastUpdateAllProductsFromCsvMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FastUpdateAllProductsFromCsvMutation, FastUpdateAllProductsFromCsvMutationVariables>(FastUpdateAllProductsFromCsvDocument, options);
      }
export type FastUpdateAllProductsFromCsvMutationHookResult = ReturnType<typeof useFastUpdateAllProductsFromCsvMutation>;
export type FastUpdateAllProductsFromCsvMutationResult = Apollo.MutationResult<FastUpdateAllProductsFromCsvMutation>;
export const FastUpdateSingleProductDocument = gql`
    mutation fastUpdateSingleProduct($input: FastUpdateSingleProductInput!) {
  fastUpdateSingleProduct(input: $input)
}
    `;
export type FastUpdateSingleProductMutationFn = Apollo.MutationFunction<FastUpdateSingleProductMutation, FastUpdateSingleProductMutationVariables>;
export function useFastUpdateSingleProductMutation(baseOptions?: Apollo.MutationHookOptions<FastUpdateSingleProductMutation, FastUpdateSingleProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FastUpdateSingleProductMutation, FastUpdateSingleProductMutationVariables>(FastUpdateSingleProductDocument, options);
      }
export type FastUpdateSingleProductMutationHookResult = ReturnType<typeof useFastUpdateSingleProductMutation>;
export type FastUpdateSingleProductMutationResult = Apollo.MutationResult<FastUpdateSingleProductMutation>;
export const UpdateProductDocument = gql`
    mutation updateProduct($id: ID!, $input: ProductInput!) {
  updateProduct(id: $id, data: $input) {
    data {
      ...Product
    }
  }
}
    ${ProductFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${SerializeFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}`;
export type UpdateProductMutationFn = Apollo.MutationFunction<UpdateProductMutation, UpdateProductMutationVariables>;
export function useUpdateProductMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductMutation, UpdateProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductMutation, UpdateProductMutationVariables>(UpdateProductDocument, options);
      }
export type UpdateProductMutationHookResult = ReturnType<typeof useUpdateProductMutation>;
export type UpdateProductMutationResult = Apollo.MutationResult<UpdateProductMutation>;
export const UpdateProductInventoryItemDocument = gql`
    mutation updateProductInventoryItem($id: ID!, $input: ProductInventoryItemInput!) {
  updateProductInventoryItem(id: $id, data: $input) {
    data {
      ...ProductInventoryItem
    }
  }
}
    ${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${FileFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${SerializeFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}`;
export type UpdateProductInventoryItemMutationFn = Apollo.MutationFunction<UpdateProductInventoryItemMutation, UpdateProductInventoryItemMutationVariables>;
export function useUpdateProductInventoryItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductInventoryItemMutation, UpdateProductInventoryItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductInventoryItemMutation, UpdateProductInventoryItemMutationVariables>(UpdateProductInventoryItemDocument, options);
      }
export type UpdateProductInventoryItemMutationHookResult = ReturnType<typeof useUpdateProductInventoryItemMutation>;
export type UpdateProductInventoryItemMutationResult = Apollo.MutationResult<UpdateProductInventoryItemMutation>;
export const UpdateProductInventoryItemEventDocument = gql`
    mutation updateProductInventoryItemEvent($id: ID!, $input: ProductInventoryItemEventInput!) {
  updateProductInventoryItemEvent(id: $id, data: $input) {
    data {
      ...ProductInventoryItemEvent
    }
  }
}
    ${ProductInventoryItemEventFragmentDoc}
${CompanyFragmentDoc}
${CompanyMinFragmentDoc}
${FileFragmentDoc}
${ContactMinFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${BusinessLocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}
${OrderMinFragmentDoc}
${ContactFragmentDoc}
${ContactDynamicValuesFragmentDoc}
${LeadFragmentDoc}
${LeadMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${SerializeFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}`;
export type UpdateProductInventoryItemEventMutationFn = Apollo.MutationFunction<UpdateProductInventoryItemEventMutation, UpdateProductInventoryItemEventMutationVariables>;
export function useUpdateProductInventoryItemEventMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductInventoryItemEventMutation, UpdateProductInventoryItemEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductInventoryItemEventMutation, UpdateProductInventoryItemEventMutationVariables>(UpdateProductInventoryItemEventDocument, options);
      }
export type UpdateProductInventoryItemEventMutationHookResult = ReturnType<typeof useUpdateProductInventoryItemEventMutation>;
export type UpdateProductInventoryItemEventMutationResult = Apollo.MutationResult<UpdateProductInventoryItemEventMutation>;
export const UpdateRentableDataDocument = gql`
    mutation updateRentableData($id: ID!, $input: RentableDataInput!) {
  updateRentableData(id: $id, data: $input) {
    data {
      ...RentableData
    }
  }
}
    ${RentableDataFragmentDoc}`;
export type UpdateRentableDataMutationFn = Apollo.MutationFunction<UpdateRentableDataMutation, UpdateRentableDataMutationVariables>;
export function useUpdateRentableDataMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRentableDataMutation, UpdateRentableDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRentableDataMutation, UpdateRentableDataMutationVariables>(UpdateRentableDataDocument, options);
      }
export type UpdateRentableDataMutationHookResult = ReturnType<typeof useUpdateRentableDataMutation>;
export type UpdateRentableDataMutationResult = Apollo.MutationResult<UpdateRentableDataMutation>;
export const UpdateSerializeDocument = gql`
    mutation updateSerialize($id: ID!, $input: InventorySerializeInput!) {
  updateInventorySerialize(id: $id, data: $input) {
    data {
      ...Serialize
    }
  }
}
    ${SerializeFragmentDoc}`;
export type UpdateSerializeMutationFn = Apollo.MutationFunction<UpdateSerializeMutation, UpdateSerializeMutationVariables>;
export function useUpdateSerializeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSerializeMutation, UpdateSerializeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSerializeMutation, UpdateSerializeMutationVariables>(UpdateSerializeDocument, options);
      }
export type UpdateSerializeMutationHookResult = ReturnType<typeof useUpdateSerializeMutation>;
export type UpdateSerializeMutationResult = Apollo.MutationResult<UpdateSerializeMutation>;
export const UpdateWeightDocument = gql`
    mutation updateWeight($id: ID!, $input: WeightInput!) {
  updateWeight(id: $id, data: $input) {
    data {
      ...Weight
    }
  }
}
    ${WeightFragmentDoc}`;
export type UpdateWeightMutationFn = Apollo.MutationFunction<UpdateWeightMutation, UpdateWeightMutationVariables>;
export function useUpdateWeightMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWeightMutation, UpdateWeightMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWeightMutation, UpdateWeightMutationVariables>(UpdateWeightDocument, options);
      }
export type UpdateWeightMutationHookResult = ReturnType<typeof useUpdateWeightMutation>;
export type UpdateWeightMutationResult = Apollo.MutationResult<UpdateWeightMutation>;
export const ProductBrandsDocument = gql`
    query productBrands($filters: ProductBrandFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  productBrands(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...ProductBrand
    }
    meta {
      ...Meta
    }
  }
}
    ${ProductBrandFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useProductBrandsQuery(baseOptions?: Apollo.QueryHookOptions<ProductBrandsQuery, ProductBrandsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductBrandsQuery, ProductBrandsQueryVariables>(ProductBrandsDocument, options);
      }
export function useProductBrandsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductBrandsQuery, ProductBrandsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductBrandsQuery, ProductBrandsQueryVariables>(ProductBrandsDocument, options);
        }
export type ProductBrandsQueryHookResult = ReturnType<typeof useProductBrandsQuery>;
export type ProductBrandsLazyQueryHookResult = ReturnType<typeof useProductBrandsLazyQuery>;
export type ProductBrandsQueryResult = Apollo.QueryResult<ProductBrandsQuery, ProductBrandsQueryVariables>;
export const ProductByUuidDocument = gql`
    query productByUuid($uuid: String!) {
  products(filters: {uuid: {eq: $uuid}}) {
    data {
      ...Product
    }
  }
}
    ${ProductFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${SerializeFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}`;
export function useProductByUuidQuery(baseOptions: Apollo.QueryHookOptions<ProductByUuidQuery, ProductByUuidQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductByUuidQuery, ProductByUuidQueryVariables>(ProductByUuidDocument, options);
      }
export function useProductByUuidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductByUuidQuery, ProductByUuidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductByUuidQuery, ProductByUuidQueryVariables>(ProductByUuidDocument, options);
        }
export type ProductByUuidQueryHookResult = ReturnType<typeof useProductByUuidQuery>;
export type ProductByUuidLazyQueryHookResult = ReturnType<typeof useProductByUuidLazyQuery>;
export type ProductByUuidQueryResult = Apollo.QueryResult<ProductByUuidQuery, ProductByUuidQueryVariables>;
export const ProductInventoryByUuidDocument = gql`
    query productInventoryByUuid($uuid: String!, $businessLocationID: ID) {
  productInventoryItems(
    filters: {uuid: {eq: $uuid}, businessLocation: {id: {eq: $businessLocationID}}}
  ) {
    data {
      ...ProductInventoryItem
    }
  }
}
    ${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${FileFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${SerializeFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}`;
export function useProductInventoryByUuidQuery(baseOptions: Apollo.QueryHookOptions<ProductInventoryByUuidQuery, ProductInventoryByUuidQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductInventoryByUuidQuery, ProductInventoryByUuidQueryVariables>(ProductInventoryByUuidDocument, options);
      }
export function useProductInventoryByUuidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductInventoryByUuidQuery, ProductInventoryByUuidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductInventoryByUuidQuery, ProductInventoryByUuidQueryVariables>(ProductInventoryByUuidDocument, options);
        }
export type ProductInventoryByUuidQueryHookResult = ReturnType<typeof useProductInventoryByUuidQuery>;
export type ProductInventoryByUuidLazyQueryHookResult = ReturnType<typeof useProductInventoryByUuidLazyQuery>;
export type ProductInventoryByUuidQueryResult = Apollo.QueryResult<ProductInventoryByUuidQuery, ProductInventoryByUuidQueryVariables>;
export const ProductInventoryItemEventsByIdDocument = gql`
    query productInventoryItemEventsById($id: ID!, $pagination: PaginationArg, $sort: [String] = [], $eventType: String) {
  productInventoryItemEvents(
    filters: {productInventoryItem: {id: {eq: $id}}, eventType: {eq: $eventType}}
    pagination: $pagination
    sort: $sort
  ) {
    data {
      ...ProductInventoryItemEvent
    }
    meta {
      ...Meta
    }
  }
}
    ${ProductInventoryItemEventFragmentDoc}
${CompanyFragmentDoc}
${CompanyMinFragmentDoc}
${FileFragmentDoc}
${ContactMinFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${BusinessLocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}
${OrderMinFragmentDoc}
${ContactFragmentDoc}
${ContactDynamicValuesFragmentDoc}
${LeadFragmentDoc}
${LeadMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${SerializeFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useProductInventoryItemEventsByIdQuery(baseOptions: Apollo.QueryHookOptions<ProductInventoryItemEventsByIdQuery, ProductInventoryItemEventsByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductInventoryItemEventsByIdQuery, ProductInventoryItemEventsByIdQueryVariables>(ProductInventoryItemEventsByIdDocument, options);
      }
export function useProductInventoryItemEventsByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductInventoryItemEventsByIdQuery, ProductInventoryItemEventsByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductInventoryItemEventsByIdQuery, ProductInventoryItemEventsByIdQueryVariables>(ProductInventoryItemEventsByIdDocument, options);
        }
export type ProductInventoryItemEventsByIdQueryHookResult = ReturnType<typeof useProductInventoryItemEventsByIdQuery>;
export type ProductInventoryItemEventsByIdLazyQueryHookResult = ReturnType<typeof useProductInventoryItemEventsByIdLazyQuery>;
export type ProductInventoryItemEventsByIdQueryResult = Apollo.QueryResult<ProductInventoryItemEventsByIdQuery, ProductInventoryItemEventsByIdQueryVariables>;
export const ProductInventoryItemMemoEventsDocument = gql`
    query productInventoryItemMemoEvents($filters: ProductInventoryItemEventFiltersInput, $pagination: PaginationArg, $sort: [String] = []) {
  productInventoryItemEvents(
    filters: {eventType: {eq: "receive"}, memo: {eq: true}, and: [$filters]}
    pagination: $pagination
    sort: $sort
  ) {
    data {
      ...ProductInventoryItemEvent
    }
    meta {
      ...Meta
    }
  }
}
    ${ProductInventoryItemEventFragmentDoc}
${CompanyFragmentDoc}
${CompanyMinFragmentDoc}
${FileFragmentDoc}
${ContactMinFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${BusinessLocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}
${OrderMinFragmentDoc}
${ContactFragmentDoc}
${ContactDynamicValuesFragmentDoc}
${LeadFragmentDoc}
${LeadMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${SerializeFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useProductInventoryItemMemoEventsQuery(baseOptions?: Apollo.QueryHookOptions<ProductInventoryItemMemoEventsQuery, ProductInventoryItemMemoEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductInventoryItemMemoEventsQuery, ProductInventoryItemMemoEventsQueryVariables>(ProductInventoryItemMemoEventsDocument, options);
      }
export function useProductInventoryItemMemoEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductInventoryItemMemoEventsQuery, ProductInventoryItemMemoEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductInventoryItemMemoEventsQuery, ProductInventoryItemMemoEventsQueryVariables>(ProductInventoryItemMemoEventsDocument, options);
        }
export type ProductInventoryItemMemoEventsQueryHookResult = ReturnType<typeof useProductInventoryItemMemoEventsQuery>;
export type ProductInventoryItemMemoEventsLazyQueryHookResult = ReturnType<typeof useProductInventoryItemMemoEventsLazyQuery>;
export type ProductInventoryItemMemoEventsQueryResult = Apollo.QueryResult<ProductInventoryItemMemoEventsQuery, ProductInventoryItemMemoEventsQueryVariables>;
export const ProductInventoryItemsDocument = gql`
    query productInventoryItems($filters: ProductInventoryItemFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  productInventoryItems(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...ProductInventoryItem
    }
    meta {
      ...Meta
    }
  }
}
    ${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${FileFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${SerializeFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useProductInventoryItemsQuery(baseOptions?: Apollo.QueryHookOptions<ProductInventoryItemsQuery, ProductInventoryItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductInventoryItemsQuery, ProductInventoryItemsQueryVariables>(ProductInventoryItemsDocument, options);
      }
export function useProductInventoryItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductInventoryItemsQuery, ProductInventoryItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductInventoryItemsQuery, ProductInventoryItemsQueryVariables>(ProductInventoryItemsDocument, options);
        }
export type ProductInventoryItemsQueryHookResult = ReturnType<typeof useProductInventoryItemsQuery>;
export type ProductInventoryItemsLazyQueryHookResult = ReturnType<typeof useProductInventoryItemsLazyQuery>;
export type ProductInventoryItemsQueryResult = Apollo.QueryResult<ProductInventoryItemsQuery, ProductInventoryItemsQueryVariables>;
export const ProductTypesDocument = gql`
    query productTypes($filters: ProductTypeFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  productTypes(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...ProductType
    }
    meta {
      ...Meta
    }
  }
}
    ${ProductTypeFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useProductTypesQuery(baseOptions?: Apollo.QueryHookOptions<ProductTypesQuery, ProductTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductTypesQuery, ProductTypesQueryVariables>(ProductTypesDocument, options);
      }
export function useProductTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductTypesQuery, ProductTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductTypesQuery, ProductTypesQueryVariables>(ProductTypesDocument, options);
        }
export type ProductTypesQueryHookResult = ReturnType<typeof useProductTypesQuery>;
export type ProductTypesLazyQueryHookResult = ReturnType<typeof useProductTypesLazyQuery>;
export type ProductTypesQueryResult = Apollo.QueryResult<ProductTypesQuery, ProductTypesQueryVariables>;
export const ProductsDocument = gql`
    query products($filters: ProductFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  products(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...Product
    }
    meta {
      ...Meta
    }
  }
}
    ${ProductFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${SerializeFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useProductsQuery(baseOptions?: Apollo.QueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, options);
      }
export function useProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, options);
        }
export type ProductsQueryHookResult = ReturnType<typeof useProductsQuery>;
export type ProductsLazyQueryHookResult = ReturnType<typeof useProductsLazyQuery>;
export type ProductsQueryResult = Apollo.QueryResult<ProductsQuery, ProductsQueryVariables>;
export const ProductsInventoryItemHistoryNumbersDocument = gql`
    query productsInventoryItemHistoryNumbers($id: ID!) {
  productsInventoryItemHistoryNumbers(id: $id) {
    owned
    memo
    laidAway
    purchase
  }
}
    `;
export function useProductsInventoryItemHistoryNumbersQuery(baseOptions: Apollo.QueryHookOptions<ProductsInventoryItemHistoryNumbersQuery, ProductsInventoryItemHistoryNumbersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductsInventoryItemHistoryNumbersQuery, ProductsInventoryItemHistoryNumbersQueryVariables>(ProductsInventoryItemHistoryNumbersDocument, options);
      }
export function useProductsInventoryItemHistoryNumbersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductsInventoryItemHistoryNumbersQuery, ProductsInventoryItemHistoryNumbersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductsInventoryItemHistoryNumbersQuery, ProductsInventoryItemHistoryNumbersQueryVariables>(ProductsInventoryItemHistoryNumbersDocument, options);
        }
export type ProductsInventoryItemHistoryNumbersQueryHookResult = ReturnType<typeof useProductsInventoryItemHistoryNumbersQuery>;
export type ProductsInventoryItemHistoryNumbersLazyQueryHookResult = ReturnType<typeof useProductsInventoryItemHistoryNumbersLazyQuery>;
export type ProductsInventoryItemHistoryNumbersQueryResult = Apollo.QueryResult<ProductsInventoryItemHistoryNumbersQuery, ProductsInventoryItemHistoryNumbersQueryVariables>;
export const ProductsReportDocument = gql`
    query productsReport($filters: ProductFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  products(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...ProductReport
    }
    meta {
      ...Meta
    }
  }
}
    ${ProductReportFragmentDoc}
${ProductFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${SerializeFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useProductsReportQuery(baseOptions?: Apollo.QueryHookOptions<ProductsReportQuery, ProductsReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductsReportQuery, ProductsReportQueryVariables>(ProductsReportDocument, options);
      }
export function useProductsReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductsReportQuery, ProductsReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductsReportQuery, ProductsReportQueryVariables>(ProductsReportDocument, options);
        }
export type ProductsReportQueryHookResult = ReturnType<typeof useProductsReportQuery>;
export type ProductsReportLazyQueryHookResult = ReturnType<typeof useProductsReportLazyQuery>;
export type ProductsReportQueryResult = Apollo.QueryResult<ProductsReportQuery, ProductsReportQueryVariables>;
export const ProductsWithLocationDataDocument = gql`
    query productsWithLocationData($filters: ProductFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = [], $businessLocationId: Int) {
  products(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...ProductWithLocationData
    }
    meta {
      ...Meta
    }
  }
}
    ${ProductWithLocationDataFragmentDoc}
${ProductFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${SerializeFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useProductsWithLocationDataQuery(baseOptions?: Apollo.QueryHookOptions<ProductsWithLocationDataQuery, ProductsWithLocationDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductsWithLocationDataQuery, ProductsWithLocationDataQueryVariables>(ProductsWithLocationDataDocument, options);
      }
export function useProductsWithLocationDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductsWithLocationDataQuery, ProductsWithLocationDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductsWithLocationDataQuery, ProductsWithLocationDataQueryVariables>(ProductsWithLocationDataDocument, options);
        }
export type ProductsWithLocationDataQueryHookResult = ReturnType<typeof useProductsWithLocationDataQuery>;
export type ProductsWithLocationDataLazyQueryHookResult = ReturnType<typeof useProductsWithLocationDataLazyQuery>;
export type ProductsWithLocationDataQueryResult = Apollo.QueryResult<ProductsWithLocationDataQuery, ProductsWithLocationDataQueryVariables>;
export const ProductsWithSoldRevenueDocument = gql`
    query productsWithSoldRevenue($filters: ProductFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = [], $startDate: DateTime!, $endDate: DateTime!, $businessLocationId: Int) {
  products(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...ProductWithSoldRevenue
    }
    meta {
      ...Meta
    }
  }
}
    ${ProductWithSoldRevenueFragmentDoc}
${ProductFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${SerializeFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useProductsWithSoldRevenueQuery(baseOptions: Apollo.QueryHookOptions<ProductsWithSoldRevenueQuery, ProductsWithSoldRevenueQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductsWithSoldRevenueQuery, ProductsWithSoldRevenueQueryVariables>(ProductsWithSoldRevenueDocument, options);
      }
export function useProductsWithSoldRevenueLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductsWithSoldRevenueQuery, ProductsWithSoldRevenueQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductsWithSoldRevenueQuery, ProductsWithSoldRevenueQueryVariables>(ProductsWithSoldRevenueDocument, options);
        }
export type ProductsWithSoldRevenueQueryHookResult = ReturnType<typeof useProductsWithSoldRevenueQuery>;
export type ProductsWithSoldRevenueLazyQueryHookResult = ReturnType<typeof useProductsWithSoldRevenueLazyQuery>;
export type ProductsWithSoldRevenueQueryResult = Apollo.QueryResult<ProductsWithSoldRevenueQuery, ProductsWithSoldRevenueQueryVariables>;
export const SerializesDocument = gql`
    query serializes($filters: InventorySerializeFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  inventorySerializes(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...Serialize
    }
    meta {
      ...Meta
    }
  }
}
    ${SerializeFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useSerializesQuery(baseOptions?: Apollo.QueryHookOptions<SerializesQuery, SerializesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SerializesQuery, SerializesQueryVariables>(SerializesDocument, options);
      }
export function useSerializesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SerializesQuery, SerializesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SerializesQuery, SerializesQueryVariables>(SerializesDocument, options);
        }
export type SerializesQueryHookResult = ReturnType<typeof useSerializesQuery>;
export type SerializesLazyQueryHookResult = ReturnType<typeof useSerializesLazyQuery>;
export type SerializesQueryResult = Apollo.QueryResult<SerializesQuery, SerializesQueryVariables>;
export const SerializesWithoutProductDocument = gql`
    query serializesWithoutProduct($filters: InventorySerializeFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  inventorySerializes(
    filters: {productInventoryItem: {id: {null: true}}, sellingProductOrderItem: {id: {null: true}}, and: [$filters]}
    pagination: $pagination
    sort: $sort
  ) {
    data {
      ...Serialize
    }
    meta {
      ...Meta
    }
  }
}
    ${SerializeFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useSerializesWithoutProductQuery(baseOptions?: Apollo.QueryHookOptions<SerializesWithoutProductQuery, SerializesWithoutProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SerializesWithoutProductQuery, SerializesWithoutProductQueryVariables>(SerializesWithoutProductDocument, options);
      }
export function useSerializesWithoutProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SerializesWithoutProductQuery, SerializesWithoutProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SerializesWithoutProductQuery, SerializesWithoutProductQueryVariables>(SerializesWithoutProductDocument, options);
        }
export type SerializesWithoutProductQueryHookResult = ReturnType<typeof useSerializesWithoutProductQuery>;
export type SerializesWithoutProductLazyQueryHookResult = ReturnType<typeof useSerializesWithoutProductLazyQuery>;
export type SerializesWithoutProductQueryResult = Apollo.QueryResult<SerializesWithoutProductQuery, SerializesWithoutProductQueryVariables>;
export const CreateProductAttributeDocument = gql`
    mutation createProductAttribute($input: ProductAttributeInput!) {
  createProductAttribute(data: $input) {
    data {
      ...ProductAttribute
    }
  }
}
    ${ProductAttributeFragmentDoc}
${ProductAttributeMinFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${FileFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${ProductBrandFragmentDoc}
${RentableDataFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductMinFragmentDoc}
${ProductTypeFragmentDoc}`;
export type CreateProductAttributeMutationFn = Apollo.MutationFunction<CreateProductAttributeMutation, CreateProductAttributeMutationVariables>;
export function useCreateProductAttributeMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductAttributeMutation, CreateProductAttributeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProductAttributeMutation, CreateProductAttributeMutationVariables>(CreateProductAttributeDocument, options);
      }
export type CreateProductAttributeMutationHookResult = ReturnType<typeof useCreateProductAttributeMutation>;
export type CreateProductAttributeMutationResult = Apollo.MutationResult<CreateProductAttributeMutation>;
export const CreateProductAttributeOptionDocument = gql`
    mutation createProductAttributeOption($input: ProductAttributeOptionInput!) {
  createProductAttributeOption(data: $input) {
    data {
      ...ProductAttributeOption
    }
  }
}
    ${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${FileFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${ProductBrandFragmentDoc}
${RentableDataFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductMinFragmentDoc}
${ProductTypeFragmentDoc}`;
export type CreateProductAttributeOptionMutationFn = Apollo.MutationFunction<CreateProductAttributeOptionMutation, CreateProductAttributeOptionMutationVariables>;
export function useCreateProductAttributeOptionMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductAttributeOptionMutation, CreateProductAttributeOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProductAttributeOptionMutation, CreateProductAttributeOptionMutationVariables>(CreateProductAttributeOptionDocument, options);
      }
export type CreateProductAttributeOptionMutationHookResult = ReturnType<typeof useCreateProductAttributeOptionMutation>;
export type CreateProductAttributeOptionMutationResult = Apollo.MutationResult<CreateProductAttributeOptionMutation>;
export const UpdateProductAttributeDocument = gql`
    mutation updateProductAttribute($id: ID!, $input: ProductAttributeInput!) {
  updateProductAttribute(id: $id, data: $input) {
    data {
      ...ProductAttribute
    }
  }
}
    ${ProductAttributeFragmentDoc}
${ProductAttributeMinFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${FileFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${ProductBrandFragmentDoc}
${RentableDataFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductMinFragmentDoc}
${ProductTypeFragmentDoc}`;
export type UpdateProductAttributeMutationFn = Apollo.MutationFunction<UpdateProductAttributeMutation, UpdateProductAttributeMutationVariables>;
export function useUpdateProductAttributeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductAttributeMutation, UpdateProductAttributeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductAttributeMutation, UpdateProductAttributeMutationVariables>(UpdateProductAttributeDocument, options);
      }
export type UpdateProductAttributeMutationHookResult = ReturnType<typeof useUpdateProductAttributeMutation>;
export type UpdateProductAttributeMutationResult = Apollo.MutationResult<UpdateProductAttributeMutation>;
export const UpdateProductAttributeOptionDocument = gql`
    mutation updateProductAttributeOption($id: ID!, $input: ProductAttributeOptionInput!) {
  updateProductAttributeOption(id: $id, data: $input) {
    data {
      ...ProductAttributeOption
    }
  }
}
    ${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${FileFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${ProductBrandFragmentDoc}
${RentableDataFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductMinFragmentDoc}
${ProductTypeFragmentDoc}`;
export type UpdateProductAttributeOptionMutationFn = Apollo.MutationFunction<UpdateProductAttributeOptionMutation, UpdateProductAttributeOptionMutationVariables>;
export function useUpdateProductAttributeOptionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductAttributeOptionMutation, UpdateProductAttributeOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductAttributeOptionMutation, UpdateProductAttributeOptionMutationVariables>(UpdateProductAttributeOptionDocument, options);
      }
export type UpdateProductAttributeOptionMutationHookResult = ReturnType<typeof useUpdateProductAttributeOptionMutation>;
export type UpdateProductAttributeOptionMutationResult = Apollo.MutationResult<UpdateProductAttributeOptionMutation>;
export const ProductAttributeDocument = gql`
    query productAttribute($id: ID!) {
  productAttribute(id: $id) {
    data {
      ...ProductAttribute
    }
  }
}
    ${ProductAttributeFragmentDoc}
${ProductAttributeMinFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${FileFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${ProductBrandFragmentDoc}
${RentableDataFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductMinFragmentDoc}
${ProductTypeFragmentDoc}`;
export function useProductAttributeQuery(baseOptions: Apollo.QueryHookOptions<ProductAttributeQuery, ProductAttributeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductAttributeQuery, ProductAttributeQueryVariables>(ProductAttributeDocument, options);
      }
export function useProductAttributeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductAttributeQuery, ProductAttributeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductAttributeQuery, ProductAttributeQueryVariables>(ProductAttributeDocument, options);
        }
export type ProductAttributeQueryHookResult = ReturnType<typeof useProductAttributeQuery>;
export type ProductAttributeLazyQueryHookResult = ReturnType<typeof useProductAttributeLazyQuery>;
export type ProductAttributeQueryResult = Apollo.QueryResult<ProductAttributeQuery, ProductAttributeQueryVariables>;
export const ProductAttributeOptionsDocument = gql`
    query productAttributeOptions($filters: ProductAttributeOptionFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  productAttributeOptions(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...ProductAttributeOption
    }
    meta {
      ...Meta
    }
  }
}
    ${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${FileFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${ProductBrandFragmentDoc}
${RentableDataFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductMinFragmentDoc}
${ProductTypeFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useProductAttributeOptionsQuery(baseOptions?: Apollo.QueryHookOptions<ProductAttributeOptionsQuery, ProductAttributeOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductAttributeOptionsQuery, ProductAttributeOptionsQueryVariables>(ProductAttributeOptionsDocument, options);
      }
export function useProductAttributeOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductAttributeOptionsQuery, ProductAttributeOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductAttributeOptionsQuery, ProductAttributeOptionsQueryVariables>(ProductAttributeOptionsDocument, options);
        }
export type ProductAttributeOptionsQueryHookResult = ReturnType<typeof useProductAttributeOptionsQuery>;
export type ProductAttributeOptionsLazyQueryHookResult = ReturnType<typeof useProductAttributeOptionsLazyQuery>;
export type ProductAttributeOptionsQueryResult = Apollo.QueryResult<ProductAttributeOptionsQuery, ProductAttributeOptionsQueryVariables>;
export const ProductAttributesDocument = gql`
    query productAttributes($filters: ProductAttributeFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  productAttributes(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...ProductAttribute
    }
    meta {
      ...Meta
    }
  }
}
    ${ProductAttributeFragmentDoc}
${ProductAttributeMinFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${FileFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${ProductBrandFragmentDoc}
${RentableDataFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductMinFragmentDoc}
${ProductTypeFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useProductAttributesQuery(baseOptions?: Apollo.QueryHookOptions<ProductAttributesQuery, ProductAttributesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductAttributesQuery, ProductAttributesQueryVariables>(ProductAttributesDocument, options);
      }
export function useProductAttributesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductAttributesQuery, ProductAttributesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductAttributesQuery, ProductAttributesQueryVariables>(ProductAttributesDocument, options);
        }
export type ProductAttributesQueryHookResult = ReturnType<typeof useProductAttributesQuery>;
export type ProductAttributesLazyQueryHookResult = ReturnType<typeof useProductAttributesLazyQuery>;
export type ProductAttributesQueryResult = Apollo.QueryResult<ProductAttributesQuery, ProductAttributesQueryVariables>;
export const CreateProductGroupDocument = gql`
    mutation createProductGroup($input: ProductGroupInput!) {
  createProductGroup(data: $input) {
    data {
      ...ProductGroup
    }
  }
}
    ${ProductGroupFragmentDoc}
${ProductGroupMinFragmentDoc}
${ProductGroupAttributeFragmentDoc}
${ProductGroupAttributeMinFragmentDoc}
${ProductGroupAttributeOptionMinFragmentDoc}
${ProductGroupItemFragmentDoc}
${ProductFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${SerializeFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${ProductGroupAttributeOptionFragmentDoc}`;
export type CreateProductGroupMutationFn = Apollo.MutationFunction<CreateProductGroupMutation, CreateProductGroupMutationVariables>;
export function useCreateProductGroupMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductGroupMutation, CreateProductGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProductGroupMutation, CreateProductGroupMutationVariables>(CreateProductGroupDocument, options);
      }
export type CreateProductGroupMutationHookResult = ReturnType<typeof useCreateProductGroupMutation>;
export type CreateProductGroupMutationResult = Apollo.MutationResult<CreateProductGroupMutation>;
export const CreateProductGroupItemDocument = gql`
    mutation createProductGroupItem($input: ProductGroupItemInput!) {
  createProductGroupItem(data: $input) {
    data {
      ...ProductGroupItem
    }
  }
}
    ${ProductGroupItemFragmentDoc}
${ProductFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${SerializeFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${ProductGroupAttributeOptionFragmentDoc}
${ProductGroupAttributeOptionMinFragmentDoc}
${ProductGroupAttributeMinFragmentDoc}
${ProductGroupMinFragmentDoc}`;
export type CreateProductGroupItemMutationFn = Apollo.MutationFunction<CreateProductGroupItemMutation, CreateProductGroupItemMutationVariables>;
export function useCreateProductGroupItemMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductGroupItemMutation, CreateProductGroupItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProductGroupItemMutation, CreateProductGroupItemMutationVariables>(CreateProductGroupItemDocument, options);
      }
export type CreateProductGroupItemMutationHookResult = ReturnType<typeof useCreateProductGroupItemMutation>;
export type CreateProductGroupItemMutationResult = Apollo.MutationResult<CreateProductGroupItemMutation>;
export const DeleteProductGroupItemDocument = gql`
    mutation deleteProductGroupItem($id: ID!) {
  deleteProductGroupItem(id: $id) {
    data {
      ...ProductGroupItem
    }
  }
}
    ${ProductGroupItemFragmentDoc}
${ProductFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${SerializeFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${ProductGroupAttributeOptionFragmentDoc}
${ProductGroupAttributeOptionMinFragmentDoc}
${ProductGroupAttributeMinFragmentDoc}
${ProductGroupMinFragmentDoc}`;
export type DeleteProductGroupItemMutationFn = Apollo.MutationFunction<DeleteProductGroupItemMutation, DeleteProductGroupItemMutationVariables>;
export function useDeleteProductGroupItemMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProductGroupItemMutation, DeleteProductGroupItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProductGroupItemMutation, DeleteProductGroupItemMutationVariables>(DeleteProductGroupItemDocument, options);
      }
export type DeleteProductGroupItemMutationHookResult = ReturnType<typeof useDeleteProductGroupItemMutation>;
export type DeleteProductGroupItemMutationResult = Apollo.MutationResult<DeleteProductGroupItemMutation>;
export const UpdateProductGroupDocument = gql`
    mutation updateProductGroup($id: ID!, $input: ProductGroupInput!) {
  updateProductGroup(id: $id, data: $input) {
    data {
      ...ProductGroup
    }
  }
}
    ${ProductGroupFragmentDoc}
${ProductGroupMinFragmentDoc}
${ProductGroupAttributeFragmentDoc}
${ProductGroupAttributeMinFragmentDoc}
${ProductGroupAttributeOptionMinFragmentDoc}
${ProductGroupItemFragmentDoc}
${ProductFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${SerializeFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${ProductGroupAttributeOptionFragmentDoc}`;
export type UpdateProductGroupMutationFn = Apollo.MutationFunction<UpdateProductGroupMutation, UpdateProductGroupMutationVariables>;
export function useUpdateProductGroupMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductGroupMutation, UpdateProductGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductGroupMutation, UpdateProductGroupMutationVariables>(UpdateProductGroupDocument, options);
      }
export type UpdateProductGroupMutationHookResult = ReturnType<typeof useUpdateProductGroupMutation>;
export type UpdateProductGroupMutationResult = Apollo.MutationResult<UpdateProductGroupMutation>;
export const UpdateProductGroupItemDocument = gql`
    mutation updateProductGroupItem($id: ID!, $input: ProductGroupItemInput!) {
  updateProductGroupItem(id: $id, data: $input) {
    data {
      ...ProductGroupItem
    }
  }
}
    ${ProductGroupItemFragmentDoc}
${ProductFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${SerializeFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${ProductGroupAttributeOptionFragmentDoc}
${ProductGroupAttributeOptionMinFragmentDoc}
${ProductGroupAttributeMinFragmentDoc}
${ProductGroupMinFragmentDoc}`;
export type UpdateProductGroupItemMutationFn = Apollo.MutationFunction<UpdateProductGroupItemMutation, UpdateProductGroupItemMutationVariables>;
export function useUpdateProductGroupItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductGroupItemMutation, UpdateProductGroupItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductGroupItemMutation, UpdateProductGroupItemMutationVariables>(UpdateProductGroupItemDocument, options);
      }
export type UpdateProductGroupItemMutationHookResult = ReturnType<typeof useUpdateProductGroupItemMutation>;
export type UpdateProductGroupItemMutationResult = Apollo.MutationResult<UpdateProductGroupItemMutation>;
export const ProductGroupByUuidDocument = gql`
    query productGroupByUuid($uuid: String!) {
  productGroups(filters: {uuid: {eq: $uuid}}) {
    data {
      ...ProductGroup
    }
  }
}
    ${ProductGroupFragmentDoc}
${ProductGroupMinFragmentDoc}
${ProductGroupAttributeFragmentDoc}
${ProductGroupAttributeMinFragmentDoc}
${ProductGroupAttributeOptionMinFragmentDoc}
${ProductGroupItemFragmentDoc}
${ProductFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${SerializeFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${ProductGroupAttributeOptionFragmentDoc}`;
export function useProductGroupByUuidQuery(baseOptions: Apollo.QueryHookOptions<ProductGroupByUuidQuery, ProductGroupByUuidQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductGroupByUuidQuery, ProductGroupByUuidQueryVariables>(ProductGroupByUuidDocument, options);
      }
export function useProductGroupByUuidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductGroupByUuidQuery, ProductGroupByUuidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductGroupByUuidQuery, ProductGroupByUuidQueryVariables>(ProductGroupByUuidDocument, options);
        }
export type ProductGroupByUuidQueryHookResult = ReturnType<typeof useProductGroupByUuidQuery>;
export type ProductGroupByUuidLazyQueryHookResult = ReturnType<typeof useProductGroupByUuidLazyQuery>;
export type ProductGroupByUuidQueryResult = Apollo.QueryResult<ProductGroupByUuidQuery, ProductGroupByUuidQueryVariables>;
export const ProductGroupsDocument = gql`
    query productGroups($filters: ProductGroupFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  productGroups(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...ProductGroup
    }
    meta {
      ...Meta
    }
  }
}
    ${ProductGroupFragmentDoc}
${ProductGroupMinFragmentDoc}
${ProductGroupAttributeFragmentDoc}
${ProductGroupAttributeMinFragmentDoc}
${ProductGroupAttributeOptionMinFragmentDoc}
${ProductGroupItemFragmentDoc}
${ProductFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${SerializeFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${ProductGroupAttributeOptionFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useProductGroupsQuery(baseOptions?: Apollo.QueryHookOptions<ProductGroupsQuery, ProductGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductGroupsQuery, ProductGroupsQueryVariables>(ProductGroupsDocument, options);
      }
export function useProductGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductGroupsQuery, ProductGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductGroupsQuery, ProductGroupsQueryVariables>(ProductGroupsDocument, options);
        }
export type ProductGroupsQueryHookResult = ReturnType<typeof useProductGroupsQuery>;
export type ProductGroupsLazyQueryHookResult = ReturnType<typeof useProductGroupsLazyQuery>;
export type ProductGroupsQueryResult = Apollo.QueryResult<ProductGroupsQuery, ProductGroupsQueryVariables>;
export const CreateProductGroupAttributeDocument = gql`
    mutation createProductGroupAttribute($input: ProductGroupAttributeInput!) {
  createProductGroupAttribute(data: $input) {
    data {
      ...ProductGroupAttribute
    }
  }
}
    ${ProductGroupAttributeFragmentDoc}
${ProductGroupAttributeMinFragmentDoc}
${ProductGroupAttributeOptionMinFragmentDoc}`;
export type CreateProductGroupAttributeMutationFn = Apollo.MutationFunction<CreateProductGroupAttributeMutation, CreateProductGroupAttributeMutationVariables>;
export function useCreateProductGroupAttributeMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductGroupAttributeMutation, CreateProductGroupAttributeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProductGroupAttributeMutation, CreateProductGroupAttributeMutationVariables>(CreateProductGroupAttributeDocument, options);
      }
export type CreateProductGroupAttributeMutationHookResult = ReturnType<typeof useCreateProductGroupAttributeMutation>;
export type CreateProductGroupAttributeMutationResult = Apollo.MutationResult<CreateProductGroupAttributeMutation>;
export const CreateProductGroupAttributeOptionDocument = gql`
    mutation createProductGroupAttributeOption($input: ProductGroupAttributeOptionInput!) {
  createProductGroupAttributeOption(data: $input) {
    data {
      ...ProductGroupAttributeOption
    }
  }
}
    ${ProductGroupAttributeOptionFragmentDoc}
${ProductGroupAttributeOptionMinFragmentDoc}
${ProductGroupAttributeMinFragmentDoc}`;
export type CreateProductGroupAttributeOptionMutationFn = Apollo.MutationFunction<CreateProductGroupAttributeOptionMutation, CreateProductGroupAttributeOptionMutationVariables>;
export function useCreateProductGroupAttributeOptionMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductGroupAttributeOptionMutation, CreateProductGroupAttributeOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProductGroupAttributeOptionMutation, CreateProductGroupAttributeOptionMutationVariables>(CreateProductGroupAttributeOptionDocument, options);
      }
export type CreateProductGroupAttributeOptionMutationHookResult = ReturnType<typeof useCreateProductGroupAttributeOptionMutation>;
export type CreateProductGroupAttributeOptionMutationResult = Apollo.MutationResult<CreateProductGroupAttributeOptionMutation>;
export const DeleteProductGroupAttributeDocument = gql`
    mutation deleteProductGroupAttribute($id: ID!) {
  deleteProductGroupAttribute(id: $id) {
    data {
      ...ProductGroupAttribute
    }
  }
}
    ${ProductGroupAttributeFragmentDoc}
${ProductGroupAttributeMinFragmentDoc}
${ProductGroupAttributeOptionMinFragmentDoc}`;
export type DeleteProductGroupAttributeMutationFn = Apollo.MutationFunction<DeleteProductGroupAttributeMutation, DeleteProductGroupAttributeMutationVariables>;
export function useDeleteProductGroupAttributeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProductGroupAttributeMutation, DeleteProductGroupAttributeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProductGroupAttributeMutation, DeleteProductGroupAttributeMutationVariables>(DeleteProductGroupAttributeDocument, options);
      }
export type DeleteProductGroupAttributeMutationHookResult = ReturnType<typeof useDeleteProductGroupAttributeMutation>;
export type DeleteProductGroupAttributeMutationResult = Apollo.MutationResult<DeleteProductGroupAttributeMutation>;
export const UpdateProductGroupAttributeDocument = gql`
    mutation updateProductGroupAttribute($id: ID!, $input: ProductGroupAttributeInput!) {
  updateProductGroupAttribute(id: $id, data: $input) {
    data {
      ...ProductGroupAttribute
    }
  }
}
    ${ProductGroupAttributeFragmentDoc}
${ProductGroupAttributeMinFragmentDoc}
${ProductGroupAttributeOptionMinFragmentDoc}`;
export type UpdateProductGroupAttributeMutationFn = Apollo.MutationFunction<UpdateProductGroupAttributeMutation, UpdateProductGroupAttributeMutationVariables>;
export function useUpdateProductGroupAttributeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductGroupAttributeMutation, UpdateProductGroupAttributeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductGroupAttributeMutation, UpdateProductGroupAttributeMutationVariables>(UpdateProductGroupAttributeDocument, options);
      }
export type UpdateProductGroupAttributeMutationHookResult = ReturnType<typeof useUpdateProductGroupAttributeMutation>;
export type UpdateProductGroupAttributeMutationResult = Apollo.MutationResult<UpdateProductGroupAttributeMutation>;
export const UpdateProductGroupAttributeOptionDocument = gql`
    mutation updateProductGroupAttributeOption($id: ID!, $input: ProductGroupAttributeOptionInput!) {
  updateProductGroupAttributeOption(id: $id, data: $input) {
    data {
      ...ProductGroupAttributeOption
    }
  }
}
    ${ProductGroupAttributeOptionFragmentDoc}
${ProductGroupAttributeOptionMinFragmentDoc}
${ProductGroupAttributeMinFragmentDoc}`;
export type UpdateProductGroupAttributeOptionMutationFn = Apollo.MutationFunction<UpdateProductGroupAttributeOptionMutation, UpdateProductGroupAttributeOptionMutationVariables>;
export function useUpdateProductGroupAttributeOptionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductGroupAttributeOptionMutation, UpdateProductGroupAttributeOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductGroupAttributeOptionMutation, UpdateProductGroupAttributeOptionMutationVariables>(UpdateProductGroupAttributeOptionDocument, options);
      }
export type UpdateProductGroupAttributeOptionMutationHookResult = ReturnType<typeof useUpdateProductGroupAttributeOptionMutation>;
export type UpdateProductGroupAttributeOptionMutationResult = Apollo.MutationResult<UpdateProductGroupAttributeOptionMutation>;
export const ProductGroupAttributeDocument = gql`
    query productGroupAttribute($id: ID!) {
  productGroupAttribute(id: $id) {
    data {
      ...ProductGroupAttribute
    }
  }
}
    ${ProductGroupAttributeFragmentDoc}
${ProductGroupAttributeMinFragmentDoc}
${ProductGroupAttributeOptionMinFragmentDoc}`;
export function useProductGroupAttributeQuery(baseOptions: Apollo.QueryHookOptions<ProductGroupAttributeQuery, ProductGroupAttributeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductGroupAttributeQuery, ProductGroupAttributeQueryVariables>(ProductGroupAttributeDocument, options);
      }
export function useProductGroupAttributeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductGroupAttributeQuery, ProductGroupAttributeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductGroupAttributeQuery, ProductGroupAttributeQueryVariables>(ProductGroupAttributeDocument, options);
        }
export type ProductGroupAttributeQueryHookResult = ReturnType<typeof useProductGroupAttributeQuery>;
export type ProductGroupAttributeLazyQueryHookResult = ReturnType<typeof useProductGroupAttributeLazyQuery>;
export type ProductGroupAttributeQueryResult = Apollo.QueryResult<ProductGroupAttributeQuery, ProductGroupAttributeQueryVariables>;
export const ProductGroupsAttributeOptionsDocument = gql`
    query productGroupsAttributeOptions($filters: ProductGroupAttributeOptionFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  productGroupAttributeOptions(
    filters: $filters
    pagination: $pagination
    sort: $sort
  ) {
    data {
      ...ProductGroupAttributeOption
    }
    meta {
      ...Meta
    }
  }
}
    ${ProductGroupAttributeOptionFragmentDoc}
${ProductGroupAttributeOptionMinFragmentDoc}
${ProductGroupAttributeMinFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useProductGroupsAttributeOptionsQuery(baseOptions?: Apollo.QueryHookOptions<ProductGroupsAttributeOptionsQuery, ProductGroupsAttributeOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductGroupsAttributeOptionsQuery, ProductGroupsAttributeOptionsQueryVariables>(ProductGroupsAttributeOptionsDocument, options);
      }
export function useProductGroupsAttributeOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductGroupsAttributeOptionsQuery, ProductGroupsAttributeOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductGroupsAttributeOptionsQuery, ProductGroupsAttributeOptionsQueryVariables>(ProductGroupsAttributeOptionsDocument, options);
        }
export type ProductGroupsAttributeOptionsQueryHookResult = ReturnType<typeof useProductGroupsAttributeOptionsQuery>;
export type ProductGroupsAttributeOptionsLazyQueryHookResult = ReturnType<typeof useProductGroupsAttributeOptionsLazyQuery>;
export type ProductGroupsAttributeOptionsQueryResult = Apollo.QueryResult<ProductGroupsAttributeOptionsQuery, ProductGroupsAttributeOptionsQueryVariables>;
export const ProductGroupsAttributesDocument = gql`
    query productGroupsAttributes($filters: ProductGroupAttributeFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  productGroupAttributes(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...ProductGroupAttribute
    }
    meta {
      ...Meta
    }
  }
}
    ${ProductGroupAttributeFragmentDoc}
${ProductGroupAttributeMinFragmentDoc}
${ProductGroupAttributeOptionMinFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useProductGroupsAttributesQuery(baseOptions?: Apollo.QueryHookOptions<ProductGroupsAttributesQuery, ProductGroupsAttributesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductGroupsAttributesQuery, ProductGroupsAttributesQueryVariables>(ProductGroupsAttributesDocument, options);
      }
export function useProductGroupsAttributesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductGroupsAttributesQuery, ProductGroupsAttributesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductGroupsAttributesQuery, ProductGroupsAttributesQueryVariables>(ProductGroupsAttributesDocument, options);
        }
export type ProductGroupsAttributesQueryHookResult = ReturnType<typeof useProductGroupsAttributesQuery>;
export type ProductGroupsAttributesLazyQueryHookResult = ReturnType<typeof useProductGroupsAttributesLazyQuery>;
export type ProductGroupsAttributesQueryResult = Apollo.QueryResult<ProductGroupsAttributesQuery, ProductGroupsAttributesQueryVariables>;
export const CreatePurchaseRequestDocument = gql`
    mutation createPurchaseRequest($input: PurchaseRequestInput!) {
  createPurchaseRequest(data: $input) {
    data {
      ...PurchaseRequest
    }
  }
}
    ${PurchaseRequestFragmentDoc}
${PurchaseRequestMinFragmentDoc}
${OrderFragmentDoc}
${OrderMinFragmentDoc}
${OrderWithItemsFragmentDoc}
${ProductOrderItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${SerializeFragmentDoc}
${DiscountMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${CompanyMinFragmentDoc}
${FileFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${CompositeProductOrderItemFragmentDoc}
${CompositeProductLocationInfoFragmentDoc}
${CompositeProductLocationInfoMinFragmentDoc}
${CompositeProductFragmentDoc}
${CompositeProductMinFragmentDoc}
${ProductFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${CompositeProductItemInfoFragmentDoc}
${CompositeProductItemInfoMinFragmentDoc}
${ServiceOrderItemFragmentDoc}
${ServicePerformerFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${ServiceMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}
${ClassOrderItemFragmentDoc}
${ClassPerformerFragmentDoc}
${ClassLocationInfoMinFragmentDoc}
${ClassMinFragmentDoc}
${MembershipOrderItemFragmentDoc}
${MembershipMinFragmentDoc}
${ContactFragmentDoc}
${ContactMinFragmentDoc}
${ContactDynamicValuesFragmentDoc}
${LeadFragmentDoc}
${LeadMinFragmentDoc}
${UserFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}
${CompanyFragmentDoc}
${DealTransactionMinFragmentDoc}
${InvoiceWithFileFragmentDoc}
${InvoiceMinFragmentDoc}
${InvoiceShippingContactFragmentDoc}
${FileItemFragmentDoc}
${FileItemMinFragmentDoc}
${PurchaseRequestWithFileFragmentDoc}
${PurchaseRequestShippingInfoMinFragmentDoc}
${TaskMinFragmentDoc}
${SalesItemReportMinFragmentDoc}`;
export type CreatePurchaseRequestMutationFn = Apollo.MutationFunction<CreatePurchaseRequestMutation, CreatePurchaseRequestMutationVariables>;
export function useCreatePurchaseRequestMutation(baseOptions?: Apollo.MutationHookOptions<CreatePurchaseRequestMutation, CreatePurchaseRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePurchaseRequestMutation, CreatePurchaseRequestMutationVariables>(CreatePurchaseRequestDocument, options);
      }
export type CreatePurchaseRequestMutationHookResult = ReturnType<typeof useCreatePurchaseRequestMutation>;
export type CreatePurchaseRequestMutationResult = Apollo.MutationResult<CreatePurchaseRequestMutation>;
export const CreateShippingInfoDocument = gql`
    mutation createShippingInfo($input: PurchaseRequestShippingInfoInput!) {
  createPurchaseRequestShippingInfo(data: $input) {
    data {
      ...PurchaseRequestShippingInfoMin
    }
  }
}
    ${PurchaseRequestShippingInfoMinFragmentDoc}`;
export type CreateShippingInfoMutationFn = Apollo.MutationFunction<CreateShippingInfoMutation, CreateShippingInfoMutationVariables>;
export function useCreateShippingInfoMutation(baseOptions?: Apollo.MutationHookOptions<CreateShippingInfoMutation, CreateShippingInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateShippingInfoMutation, CreateShippingInfoMutationVariables>(CreateShippingInfoDocument, options);
      }
export type CreateShippingInfoMutationHookResult = ReturnType<typeof useCreateShippingInfoMutation>;
export type CreateShippingInfoMutationResult = Apollo.MutationResult<CreateShippingInfoMutation>;
export const DeletePurchaseRequestDocument = gql`
    mutation deletePurchaseRequest($id: ID!) {
  deletePurchaseRequest(id: $id) {
    data {
      ...PurchaseRequest
    }
  }
}
    ${PurchaseRequestFragmentDoc}
${PurchaseRequestMinFragmentDoc}
${OrderFragmentDoc}
${OrderMinFragmentDoc}
${OrderWithItemsFragmentDoc}
${ProductOrderItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${SerializeFragmentDoc}
${DiscountMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${CompanyMinFragmentDoc}
${FileFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${CompositeProductOrderItemFragmentDoc}
${CompositeProductLocationInfoFragmentDoc}
${CompositeProductLocationInfoMinFragmentDoc}
${CompositeProductFragmentDoc}
${CompositeProductMinFragmentDoc}
${ProductFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${CompositeProductItemInfoFragmentDoc}
${CompositeProductItemInfoMinFragmentDoc}
${ServiceOrderItemFragmentDoc}
${ServicePerformerFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${ServiceMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}
${ClassOrderItemFragmentDoc}
${ClassPerformerFragmentDoc}
${ClassLocationInfoMinFragmentDoc}
${ClassMinFragmentDoc}
${MembershipOrderItemFragmentDoc}
${MembershipMinFragmentDoc}
${ContactFragmentDoc}
${ContactMinFragmentDoc}
${ContactDynamicValuesFragmentDoc}
${LeadFragmentDoc}
${LeadMinFragmentDoc}
${UserFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}
${CompanyFragmentDoc}
${DealTransactionMinFragmentDoc}
${InvoiceWithFileFragmentDoc}
${InvoiceMinFragmentDoc}
${InvoiceShippingContactFragmentDoc}
${FileItemFragmentDoc}
${FileItemMinFragmentDoc}
${PurchaseRequestWithFileFragmentDoc}
${PurchaseRequestShippingInfoMinFragmentDoc}
${TaskMinFragmentDoc}
${SalesItemReportMinFragmentDoc}`;
export type DeletePurchaseRequestMutationFn = Apollo.MutationFunction<DeletePurchaseRequestMutation, DeletePurchaseRequestMutationVariables>;
export function useDeletePurchaseRequestMutation(baseOptions?: Apollo.MutationHookOptions<DeletePurchaseRequestMutation, DeletePurchaseRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePurchaseRequestMutation, DeletePurchaseRequestMutationVariables>(DeletePurchaseRequestDocument, options);
      }
export type DeletePurchaseRequestMutationHookResult = ReturnType<typeof useDeletePurchaseRequestMutation>;
export type DeletePurchaseRequestMutationResult = Apollo.MutationResult<DeletePurchaseRequestMutation>;
export const SendPurchaseRequestDocument = gql`
    mutation sendPurchaseRequest($id: ID!, $email: String, $subject: String, $body: String, $fileUrl: String, $contactId: ID) {
  sendPurchaseRequest(
    id: $id
    email: $email
    subject: $subject
    body: $body
    fileUrl: $fileUrl
    contactId: $contactId
  )
}
    `;
export type SendPurchaseRequestMutationFn = Apollo.MutationFunction<SendPurchaseRequestMutation, SendPurchaseRequestMutationVariables>;
export function useSendPurchaseRequestMutation(baseOptions?: Apollo.MutationHookOptions<SendPurchaseRequestMutation, SendPurchaseRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendPurchaseRequestMutation, SendPurchaseRequestMutationVariables>(SendPurchaseRequestDocument, options);
      }
export type SendPurchaseRequestMutationHookResult = ReturnType<typeof useSendPurchaseRequestMutation>;
export type SendPurchaseRequestMutationResult = Apollo.MutationResult<SendPurchaseRequestMutation>;
export const UpdatePurchaseRequestDocument = gql`
    mutation updatePurchaseRequest($id: ID!, $input: PurchaseRequestInput!) {
  updatePurchaseRequest(id: $id, data: $input) {
    data {
      ...PurchaseRequest
    }
  }
}
    ${PurchaseRequestFragmentDoc}
${PurchaseRequestMinFragmentDoc}
${OrderFragmentDoc}
${OrderMinFragmentDoc}
${OrderWithItemsFragmentDoc}
${ProductOrderItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${SerializeFragmentDoc}
${DiscountMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${CompanyMinFragmentDoc}
${FileFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${CompositeProductOrderItemFragmentDoc}
${CompositeProductLocationInfoFragmentDoc}
${CompositeProductLocationInfoMinFragmentDoc}
${CompositeProductFragmentDoc}
${CompositeProductMinFragmentDoc}
${ProductFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${CompositeProductItemInfoFragmentDoc}
${CompositeProductItemInfoMinFragmentDoc}
${ServiceOrderItemFragmentDoc}
${ServicePerformerFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${ServiceMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}
${ClassOrderItemFragmentDoc}
${ClassPerformerFragmentDoc}
${ClassLocationInfoMinFragmentDoc}
${ClassMinFragmentDoc}
${MembershipOrderItemFragmentDoc}
${MembershipMinFragmentDoc}
${ContactFragmentDoc}
${ContactMinFragmentDoc}
${ContactDynamicValuesFragmentDoc}
${LeadFragmentDoc}
${LeadMinFragmentDoc}
${UserFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}
${CompanyFragmentDoc}
${DealTransactionMinFragmentDoc}
${InvoiceWithFileFragmentDoc}
${InvoiceMinFragmentDoc}
${InvoiceShippingContactFragmentDoc}
${FileItemFragmentDoc}
${FileItemMinFragmentDoc}
${PurchaseRequestWithFileFragmentDoc}
${PurchaseRequestShippingInfoMinFragmentDoc}
${TaskMinFragmentDoc}
${SalesItemReportMinFragmentDoc}`;
export type UpdatePurchaseRequestMutationFn = Apollo.MutationFunction<UpdatePurchaseRequestMutation, UpdatePurchaseRequestMutationVariables>;
export function useUpdatePurchaseRequestMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePurchaseRequestMutation, UpdatePurchaseRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePurchaseRequestMutation, UpdatePurchaseRequestMutationVariables>(UpdatePurchaseRequestDocument, options);
      }
export type UpdatePurchaseRequestMutationHookResult = ReturnType<typeof useUpdatePurchaseRequestMutation>;
export type UpdatePurchaseRequestMutationResult = Apollo.MutationResult<UpdatePurchaseRequestMutation>;
export const PurchaseRequestDocument = gql`
    query purchaseRequest($id: ID!) {
  purchaseRequest(id: $id) {
    data {
      ...PurchaseRequest
    }
  }
}
    ${PurchaseRequestFragmentDoc}
${PurchaseRequestMinFragmentDoc}
${OrderFragmentDoc}
${OrderMinFragmentDoc}
${OrderWithItemsFragmentDoc}
${ProductOrderItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${SerializeFragmentDoc}
${DiscountMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${CompanyMinFragmentDoc}
${FileFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${CompositeProductOrderItemFragmentDoc}
${CompositeProductLocationInfoFragmentDoc}
${CompositeProductLocationInfoMinFragmentDoc}
${CompositeProductFragmentDoc}
${CompositeProductMinFragmentDoc}
${ProductFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${CompositeProductItemInfoFragmentDoc}
${CompositeProductItemInfoMinFragmentDoc}
${ServiceOrderItemFragmentDoc}
${ServicePerformerFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${ServiceMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}
${ClassOrderItemFragmentDoc}
${ClassPerformerFragmentDoc}
${ClassLocationInfoMinFragmentDoc}
${ClassMinFragmentDoc}
${MembershipOrderItemFragmentDoc}
${MembershipMinFragmentDoc}
${ContactFragmentDoc}
${ContactMinFragmentDoc}
${ContactDynamicValuesFragmentDoc}
${LeadFragmentDoc}
${LeadMinFragmentDoc}
${UserFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}
${CompanyFragmentDoc}
${DealTransactionMinFragmentDoc}
${InvoiceWithFileFragmentDoc}
${InvoiceMinFragmentDoc}
${InvoiceShippingContactFragmentDoc}
${FileItemFragmentDoc}
${FileItemMinFragmentDoc}
${PurchaseRequestWithFileFragmentDoc}
${PurchaseRequestShippingInfoMinFragmentDoc}
${TaskMinFragmentDoc}
${SalesItemReportMinFragmentDoc}`;
export function usePurchaseRequestQuery(baseOptions: Apollo.QueryHookOptions<PurchaseRequestQuery, PurchaseRequestQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PurchaseRequestQuery, PurchaseRequestQueryVariables>(PurchaseRequestDocument, options);
      }
export function usePurchaseRequestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PurchaseRequestQuery, PurchaseRequestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PurchaseRequestQuery, PurchaseRequestQueryVariables>(PurchaseRequestDocument, options);
        }
export type PurchaseRequestQueryHookResult = ReturnType<typeof usePurchaseRequestQuery>;
export type PurchaseRequestLazyQueryHookResult = ReturnType<typeof usePurchaseRequestLazyQuery>;
export type PurchaseRequestQueryResult = Apollo.QueryResult<PurchaseRequestQuery, PurchaseRequestQueryVariables>;
export const PurchaseRequestsDocument = gql`
    query purchaseRequests($filters: PurchaseRequestFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  purchaseRequests(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...PurchaseRequest
    }
    meta {
      ...Meta
    }
  }
}
    ${PurchaseRequestFragmentDoc}
${PurchaseRequestMinFragmentDoc}
${OrderFragmentDoc}
${OrderMinFragmentDoc}
${OrderWithItemsFragmentDoc}
${ProductOrderItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${SerializeFragmentDoc}
${DiscountMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${CompanyMinFragmentDoc}
${FileFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${CompositeProductOrderItemFragmentDoc}
${CompositeProductLocationInfoFragmentDoc}
${CompositeProductLocationInfoMinFragmentDoc}
${CompositeProductFragmentDoc}
${CompositeProductMinFragmentDoc}
${ProductFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${CompositeProductItemInfoFragmentDoc}
${CompositeProductItemInfoMinFragmentDoc}
${ServiceOrderItemFragmentDoc}
${ServicePerformerFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${ServiceMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}
${ClassOrderItemFragmentDoc}
${ClassPerformerFragmentDoc}
${ClassLocationInfoMinFragmentDoc}
${ClassMinFragmentDoc}
${MembershipOrderItemFragmentDoc}
${MembershipMinFragmentDoc}
${ContactFragmentDoc}
${ContactMinFragmentDoc}
${ContactDynamicValuesFragmentDoc}
${LeadFragmentDoc}
${LeadMinFragmentDoc}
${UserFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}
${CompanyFragmentDoc}
${DealTransactionMinFragmentDoc}
${InvoiceWithFileFragmentDoc}
${InvoiceMinFragmentDoc}
${InvoiceShippingContactFragmentDoc}
${FileItemFragmentDoc}
${FileItemMinFragmentDoc}
${PurchaseRequestWithFileFragmentDoc}
${PurchaseRequestShippingInfoMinFragmentDoc}
${TaskMinFragmentDoc}
${SalesItemReportMinFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function usePurchaseRequestsQuery(baseOptions?: Apollo.QueryHookOptions<PurchaseRequestsQuery, PurchaseRequestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PurchaseRequestsQuery, PurchaseRequestsQueryVariables>(PurchaseRequestsDocument, options);
      }
export function usePurchaseRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PurchaseRequestsQuery, PurchaseRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PurchaseRequestsQuery, PurchaseRequestsQueryVariables>(PurchaseRequestsDocument, options);
        }
export type PurchaseRequestsQueryHookResult = ReturnType<typeof usePurchaseRequestsQuery>;
export type PurchaseRequestsLazyQueryHookResult = ReturnType<typeof usePurchaseRequestsLazyQuery>;
export type PurchaseRequestsQueryResult = Apollo.QueryResult<PurchaseRequestsQuery, PurchaseRequestsQueryVariables>;
export const CreateQuestionDocument = gql`
    mutation createQuestion($input: QuestionInput!) {
  createQuestion(data: $input) {
    data {
      ...Question
    }
  }
}
    ${QuestionFragmentDoc}
${QuestionMinFragmentDoc}`;
export type CreateQuestionMutationFn = Apollo.MutationFunction<CreateQuestionMutation, CreateQuestionMutationVariables>;
export function useCreateQuestionMutation(baseOptions?: Apollo.MutationHookOptions<CreateQuestionMutation, CreateQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateQuestionMutation, CreateQuestionMutationVariables>(CreateQuestionDocument, options);
      }
export type CreateQuestionMutationHookResult = ReturnType<typeof useCreateQuestionMutation>;
export type CreateQuestionMutationResult = Apollo.MutationResult<CreateQuestionMutation>;
export const DeleteQuestionDocument = gql`
    mutation deleteQuestion($id: ID!) {
  deleteQuestion(id: $id) {
    data {
      ...Question
    }
  }
}
    ${QuestionFragmentDoc}
${QuestionMinFragmentDoc}`;
export type DeleteQuestionMutationFn = Apollo.MutationFunction<DeleteQuestionMutation, DeleteQuestionMutationVariables>;
export function useDeleteQuestionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteQuestionMutation, DeleteQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteQuestionMutation, DeleteQuestionMutationVariables>(DeleteQuestionDocument, options);
      }
export type DeleteQuestionMutationHookResult = ReturnType<typeof useDeleteQuestionMutation>;
export type DeleteQuestionMutationResult = Apollo.MutationResult<DeleteQuestionMutation>;
export const UpdateQuestionDocument = gql`
    mutation updateQuestion($id: ID!, $input: QuestionInput!) {
  updateQuestion(id: $id, data: $input) {
    data {
      ...Question
    }
  }
}
    ${QuestionFragmentDoc}
${QuestionMinFragmentDoc}`;
export type UpdateQuestionMutationFn = Apollo.MutationFunction<UpdateQuestionMutation, UpdateQuestionMutationVariables>;
export function useUpdateQuestionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateQuestionMutation, UpdateQuestionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateQuestionMutation, UpdateQuestionMutationVariables>(UpdateQuestionDocument, options);
      }
export type UpdateQuestionMutationHookResult = ReturnType<typeof useUpdateQuestionMutation>;
export type UpdateQuestionMutationResult = Apollo.MutationResult<UpdateQuestionMutation>;
export const QuestionDocument = gql`
    query question($id: ID!) {
  question(id: $id) {
    data {
      ...Question
    }
  }
}
    ${QuestionFragmentDoc}
${QuestionMinFragmentDoc}`;
export function useQuestionQuery(baseOptions: Apollo.QueryHookOptions<QuestionQuery, QuestionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuestionQuery, QuestionQueryVariables>(QuestionDocument, options);
      }
export function useQuestionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuestionQuery, QuestionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuestionQuery, QuestionQueryVariables>(QuestionDocument, options);
        }
export type QuestionQueryHookResult = ReturnType<typeof useQuestionQuery>;
export type QuestionLazyQueryHookResult = ReturnType<typeof useQuestionLazyQuery>;
export type QuestionQueryResult = Apollo.QueryResult<QuestionQuery, QuestionQueryVariables>;
export const QuestionsDocument = gql`
    query questions($filters: QuestionFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  questions(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...Question
    }
    meta {
      ...Meta
    }
  }
}
    ${QuestionFragmentDoc}
${QuestionMinFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useQuestionsQuery(baseOptions?: Apollo.QueryHookOptions<QuestionsQuery, QuestionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuestionsQuery, QuestionsQueryVariables>(QuestionsDocument, options);
      }
export function useQuestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuestionsQuery, QuestionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuestionsQuery, QuestionsQueryVariables>(QuestionsDocument, options);
        }
export type QuestionsQueryHookResult = ReturnType<typeof useQuestionsQuery>;
export type QuestionsLazyQueryHookResult = ReturnType<typeof useQuestionsLazyQuery>;
export type QuestionsQueryResult = Apollo.QueryResult<QuestionsQuery, QuestionsQueryVariables>;
export const CreateRateDocument = gql`
    mutation createRate($input: RateInput!) {
  createRate(data: $input) {
    data {
      ...Rate
    }
  }
}
    ${RateFragmentDoc}
${RateMinFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}`;
export type CreateRateMutationFn = Apollo.MutationFunction<CreateRateMutation, CreateRateMutationVariables>;
export function useCreateRateMutation(baseOptions?: Apollo.MutationHookOptions<CreateRateMutation, CreateRateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRateMutation, CreateRateMutationVariables>(CreateRateDocument, options);
      }
export type CreateRateMutationHookResult = ReturnType<typeof useCreateRateMutation>;
export type CreateRateMutationResult = Apollo.MutationResult<CreateRateMutation>;
export const DeleteRateDocument = gql`
    mutation deleteRate($id: ID!) {
  deleteRate(id: $id) {
    data {
      ...Rate
    }
  }
}
    ${RateFragmentDoc}
${RateMinFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}`;
export type DeleteRateMutationFn = Apollo.MutationFunction<DeleteRateMutation, DeleteRateMutationVariables>;
export function useDeleteRateMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRateMutation, DeleteRateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRateMutation, DeleteRateMutationVariables>(DeleteRateDocument, options);
      }
export type DeleteRateMutationHookResult = ReturnType<typeof useDeleteRateMutation>;
export type DeleteRateMutationResult = Apollo.MutationResult<DeleteRateMutation>;
export const UpdateRateDocument = gql`
    mutation updateRate($id: ID!, $input: RateInput!) {
  updateRate(id: $id, data: $input) {
    data {
      ...Rate
    }
  }
}
    ${RateFragmentDoc}
${RateMinFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}`;
export type UpdateRateMutationFn = Apollo.MutationFunction<UpdateRateMutation, UpdateRateMutationVariables>;
export function useUpdateRateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRateMutation, UpdateRateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRateMutation, UpdateRateMutationVariables>(UpdateRateDocument, options);
      }
export type UpdateRateMutationHookResult = ReturnType<typeof useUpdateRateMutation>;
export type UpdateRateMutationResult = Apollo.MutationResult<UpdateRateMutation>;
export const RateDocument = gql`
    query rate($id: ID!) {
  rate(id: $id) {
    data {
      ...Rate
    }
  }
}
    ${RateFragmentDoc}
${RateMinFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}`;
export function useRateQuery(baseOptions: Apollo.QueryHookOptions<RateQuery, RateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RateQuery, RateQueryVariables>(RateDocument, options);
      }
export function useRateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RateQuery, RateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RateQuery, RateQueryVariables>(RateDocument, options);
        }
export type RateQueryHookResult = ReturnType<typeof useRateQuery>;
export type RateLazyQueryHookResult = ReturnType<typeof useRateLazyQuery>;
export type RateQueryResult = Apollo.QueryResult<RateQuery, RateQueryVariables>;
export const RatesDocument = gql`
    query rates($filters: RateFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  rates(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...Rate
    }
    meta {
      ...Meta
    }
  }
}
    ${RateFragmentDoc}
${RateMinFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useRatesQuery(baseOptions?: Apollo.QueryHookOptions<RatesQuery, RatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RatesQuery, RatesQueryVariables>(RatesDocument, options);
      }
export function useRatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RatesQuery, RatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RatesQuery, RatesQueryVariables>(RatesDocument, options);
        }
export type RatesQueryHookResult = ReturnType<typeof useRatesQuery>;
export type RatesLazyQueryHookResult = ReturnType<typeof useRatesLazyQuery>;
export type RatesQueryResult = Apollo.QueryResult<RatesQuery, RatesQueryVariables>;
export const ReportsScheduleDocument = gql`
    query reportsSchedule($id: ID!) {
  reportsSchedule(id: $id) {
    data {
      ...ReportsSchedule
    }
  }
}
    ${ReportsScheduleFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}`;
export function useReportsScheduleQuery(baseOptions: Apollo.QueryHookOptions<ReportsScheduleQuery, ReportsScheduleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportsScheduleQuery, ReportsScheduleQueryVariables>(ReportsScheduleDocument, options);
      }
export function useReportsScheduleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportsScheduleQuery, ReportsScheduleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportsScheduleQuery, ReportsScheduleQueryVariables>(ReportsScheduleDocument, options);
        }
export type ReportsScheduleQueryHookResult = ReturnType<typeof useReportsScheduleQuery>;
export type ReportsScheduleLazyQueryHookResult = ReturnType<typeof useReportsScheduleLazyQuery>;
export type ReportsScheduleQueryResult = Apollo.QueryResult<ReportsScheduleQuery, ReportsScheduleQueryVariables>;
export const ReportsSchedulesDocument = gql`
    query reportsSchedules($filters: ReportsScheduleFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  reportsSchedules(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...ReportsSchedule
    }
    meta {
      ...Meta
    }
  }
}
    ${ReportsScheduleFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useReportsSchedulesQuery(baseOptions?: Apollo.QueryHookOptions<ReportsSchedulesQuery, ReportsSchedulesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportsSchedulesQuery, ReportsSchedulesQueryVariables>(ReportsSchedulesDocument, options);
      }
export function useReportsSchedulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportsSchedulesQuery, ReportsSchedulesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportsSchedulesQuery, ReportsSchedulesQueryVariables>(ReportsSchedulesDocument, options);
        }
export type ReportsSchedulesQueryHookResult = ReturnType<typeof useReportsSchedulesQuery>;
export type ReportsSchedulesLazyQueryHookResult = ReturnType<typeof useReportsSchedulesLazyQuery>;
export type ReportsSchedulesQueryResult = Apollo.QueryResult<ReportsSchedulesQuery, ReportsSchedulesQueryVariables>;
export const UpdateReportsScheduleDocument = gql`
    mutation updateReportsSchedule($id: ID!, $input: ReportsScheduleInput!) {
  updateReportsSchedule(id: $id, data: $input) {
    data {
      ...ReportsSchedule
    }
  }
}
    ${ReportsScheduleFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}`;
export type UpdateReportsScheduleMutationFn = Apollo.MutationFunction<UpdateReportsScheduleMutation, UpdateReportsScheduleMutationVariables>;
export function useUpdateReportsScheduleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReportsScheduleMutation, UpdateReportsScheduleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateReportsScheduleMutation, UpdateReportsScheduleMutationVariables>(UpdateReportsScheduleDocument, options);
      }
export type UpdateReportsScheduleMutationHookResult = ReturnType<typeof useUpdateReportsScheduleMutation>;
export type UpdateReportsScheduleMutationResult = Apollo.MutationResult<UpdateReportsScheduleMutation>;
export const CreateResourceDocument = gql`
    mutation createResource($input: ResourceInput!) {
  createResource(data: $input) {
    data {
      ...Resource
    }
  }
}
    ${ResourceFragmentDoc}
${ResourceMinFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}`;
export type CreateResourceMutationFn = Apollo.MutationFunction<CreateResourceMutation, CreateResourceMutationVariables>;
export function useCreateResourceMutation(baseOptions?: Apollo.MutationHookOptions<CreateResourceMutation, CreateResourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateResourceMutation, CreateResourceMutationVariables>(CreateResourceDocument, options);
      }
export type CreateResourceMutationHookResult = ReturnType<typeof useCreateResourceMutation>;
export type CreateResourceMutationResult = Apollo.MutationResult<CreateResourceMutation>;
export const CreateResourceCountDocument = gql`
    mutation createResourceCount($input: ResourceCountInput!) {
  createResourceCount(data: $input) {
    data {
      ...ResourceCount
    }
  }
}
    ${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${ResourceMinFragmentDoc}`;
export type CreateResourceCountMutationFn = Apollo.MutationFunction<CreateResourceCountMutation, CreateResourceCountMutationVariables>;
export function useCreateResourceCountMutation(baseOptions?: Apollo.MutationHookOptions<CreateResourceCountMutation, CreateResourceCountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateResourceCountMutation, CreateResourceCountMutationVariables>(CreateResourceCountDocument, options);
      }
export type CreateResourceCountMutationHookResult = ReturnType<typeof useCreateResourceCountMutation>;
export type CreateResourceCountMutationResult = Apollo.MutationResult<CreateResourceCountMutation>;
export const CreateResourceInventoryItemDocument = gql`
    mutation createResourceInventoryItem($input: ResourceInventoryItemInput!) {
  createResourceInventoryItem(data: $input) {
    data {
      ...ResourceInventoryItem
    }
  }
}
    ${ResourceInventoryItemFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${ResourceMinFragmentDoc}`;
export type CreateResourceInventoryItemMutationFn = Apollo.MutationFunction<CreateResourceInventoryItemMutation, CreateResourceInventoryItemMutationVariables>;
export function useCreateResourceInventoryItemMutation(baseOptions?: Apollo.MutationHookOptions<CreateResourceInventoryItemMutation, CreateResourceInventoryItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateResourceInventoryItemMutation, CreateResourceInventoryItemMutationVariables>(CreateResourceInventoryItemDocument, options);
      }
export type CreateResourceInventoryItemMutationHookResult = ReturnType<typeof useCreateResourceInventoryItemMutation>;
export type CreateResourceInventoryItemMutationResult = Apollo.MutationResult<CreateResourceInventoryItemMutation>;
export const DeleteResourceCountDocument = gql`
    mutation deleteResourceCount($id: ID!) {
  deleteResourceCount(id: $id) {
    data {
      ...ResourceCount
    }
  }
}
    ${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${ResourceMinFragmentDoc}`;
export type DeleteResourceCountMutationFn = Apollo.MutationFunction<DeleteResourceCountMutation, DeleteResourceCountMutationVariables>;
export function useDeleteResourceCountMutation(baseOptions?: Apollo.MutationHookOptions<DeleteResourceCountMutation, DeleteResourceCountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteResourceCountMutation, DeleteResourceCountMutationVariables>(DeleteResourceCountDocument, options);
      }
export type DeleteResourceCountMutationHookResult = ReturnType<typeof useDeleteResourceCountMutation>;
export type DeleteResourceCountMutationResult = Apollo.MutationResult<DeleteResourceCountMutation>;
export const DeleteResourceInventoryItemDocument = gql`
    mutation deleteResourceInventoryItem($id: ID!) {
  deleteResourceInventoryItem(id: $id) {
    data {
      ...ResourceInventoryItem
    }
  }
}
    ${ResourceInventoryItemFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${ResourceMinFragmentDoc}`;
export type DeleteResourceInventoryItemMutationFn = Apollo.MutationFunction<DeleteResourceInventoryItemMutation, DeleteResourceInventoryItemMutationVariables>;
export function useDeleteResourceInventoryItemMutation(baseOptions?: Apollo.MutationHookOptions<DeleteResourceInventoryItemMutation, DeleteResourceInventoryItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteResourceInventoryItemMutation, DeleteResourceInventoryItemMutationVariables>(DeleteResourceInventoryItemDocument, options);
      }
export type DeleteResourceInventoryItemMutationHookResult = ReturnType<typeof useDeleteResourceInventoryItemMutation>;
export type DeleteResourceInventoryItemMutationResult = Apollo.MutationResult<DeleteResourceInventoryItemMutation>;
export const UpdateResourceDocument = gql`
    mutation updateResource($id: ID!, $input: ResourceInput!) {
  updateResource(id: $id, data: $input) {
    data {
      ...Resource
    }
  }
}
    ${ResourceFragmentDoc}
${ResourceMinFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}`;
export type UpdateResourceMutationFn = Apollo.MutationFunction<UpdateResourceMutation, UpdateResourceMutationVariables>;
export function useUpdateResourceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateResourceMutation, UpdateResourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateResourceMutation, UpdateResourceMutationVariables>(UpdateResourceDocument, options);
      }
export type UpdateResourceMutationHookResult = ReturnType<typeof useUpdateResourceMutation>;
export type UpdateResourceMutationResult = Apollo.MutationResult<UpdateResourceMutation>;
export const UpdateResourceCountDocument = gql`
    mutation updateResourceCount($id: ID!, $input: ResourceCountInput!) {
  updateResourceCount(id: $id, data: $input) {
    data {
      ...ResourceCount
    }
  }
}
    ${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${ResourceMinFragmentDoc}`;
export type UpdateResourceCountMutationFn = Apollo.MutationFunction<UpdateResourceCountMutation, UpdateResourceCountMutationVariables>;
export function useUpdateResourceCountMutation(baseOptions?: Apollo.MutationHookOptions<UpdateResourceCountMutation, UpdateResourceCountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateResourceCountMutation, UpdateResourceCountMutationVariables>(UpdateResourceCountDocument, options);
      }
export type UpdateResourceCountMutationHookResult = ReturnType<typeof useUpdateResourceCountMutation>;
export type UpdateResourceCountMutationResult = Apollo.MutationResult<UpdateResourceCountMutation>;
export const UpdateResourceInventoryItemDocument = gql`
    mutation updateResourceInventoryItem($id: ID!, $input: ResourceInventoryItemInput!) {
  updateResourceInventoryItem(id: $id, data: $input) {
    data {
      ...ResourceInventoryItem
    }
  }
}
    ${ResourceInventoryItemFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${ResourceMinFragmentDoc}`;
export type UpdateResourceInventoryItemMutationFn = Apollo.MutationFunction<UpdateResourceInventoryItemMutation, UpdateResourceInventoryItemMutationVariables>;
export function useUpdateResourceInventoryItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateResourceInventoryItemMutation, UpdateResourceInventoryItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateResourceInventoryItemMutation, UpdateResourceInventoryItemMutationVariables>(UpdateResourceInventoryItemDocument, options);
      }
export type UpdateResourceInventoryItemMutationHookResult = ReturnType<typeof useUpdateResourceInventoryItemMutation>;
export type UpdateResourceInventoryItemMutationResult = Apollo.MutationResult<UpdateResourceInventoryItemMutation>;
export const ResourceByUuidDocument = gql`
    query resourceByUuid($uuid: String!) {
  resources(filters: {uuid: {eq: $uuid}}) {
    data {
      ...Resource
    }
  }
}
    ${ResourceFragmentDoc}
${ResourceMinFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}`;
export function useResourceByUuidQuery(baseOptions: Apollo.QueryHookOptions<ResourceByUuidQuery, ResourceByUuidQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ResourceByUuidQuery, ResourceByUuidQueryVariables>(ResourceByUuidDocument, options);
      }
export function useResourceByUuidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResourceByUuidQuery, ResourceByUuidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ResourceByUuidQuery, ResourceByUuidQueryVariables>(ResourceByUuidDocument, options);
        }
export type ResourceByUuidQueryHookResult = ReturnType<typeof useResourceByUuidQuery>;
export type ResourceByUuidLazyQueryHookResult = ReturnType<typeof useResourceByUuidLazyQuery>;
export type ResourceByUuidQueryResult = Apollo.QueryResult<ResourceByUuidQuery, ResourceByUuidQueryVariables>;
export const ResourceInventoryItemsDocument = gql`
    query resourceInventoryItems($filters: ResourceInventoryItemFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  resourceInventoryItems(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...ResourceInventoryItem
    }
    meta {
      ...Meta
    }
  }
}
    ${ResourceInventoryItemFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${ResourceMinFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useResourceInventoryItemsQuery(baseOptions?: Apollo.QueryHookOptions<ResourceInventoryItemsQuery, ResourceInventoryItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ResourceInventoryItemsQuery, ResourceInventoryItemsQueryVariables>(ResourceInventoryItemsDocument, options);
      }
export function useResourceInventoryItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResourceInventoryItemsQuery, ResourceInventoryItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ResourceInventoryItemsQuery, ResourceInventoryItemsQueryVariables>(ResourceInventoryItemsDocument, options);
        }
export type ResourceInventoryItemsQueryHookResult = ReturnType<typeof useResourceInventoryItemsQuery>;
export type ResourceInventoryItemsLazyQueryHookResult = ReturnType<typeof useResourceInventoryItemsLazyQuery>;
export type ResourceInventoryItemsQueryResult = Apollo.QueryResult<ResourceInventoryItemsQuery, ResourceInventoryItemsQueryVariables>;
export const ResourcesDocument = gql`
    query resources($filters: ResourceFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  resources(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...Resource
    }
    meta {
      ...Meta
    }
  }
}
    ${ResourceFragmentDoc}
${ResourceMinFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useResourcesQuery(baseOptions?: Apollo.QueryHookOptions<ResourcesQuery, ResourcesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ResourcesQuery, ResourcesQueryVariables>(ResourcesDocument, options);
      }
export function useResourcesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResourcesQuery, ResourcesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ResourcesQuery, ResourcesQueryVariables>(ResourcesDocument, options);
        }
export type ResourcesQueryHookResult = ReturnType<typeof useResourcesQuery>;
export type ResourcesLazyQueryHookResult = ReturnType<typeof useResourcesLazyQuery>;
export type ResourcesQueryResult = Apollo.QueryResult<ResourcesQuery, ResourcesQueryVariables>;
export const CreateReturnDocument = gql`
    mutation createReturn($input: ReturnInput!) {
  createReturn(data: $input) {
    data {
      ...Return
    }
  }
}
    ${ReturnFragmentDoc}
${ReturnMinFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationFragmentDoc}
${SubLocationItemFragmentDoc}
${SubLocationItemMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${FileFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${SerializeFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${OrderMinFragmentDoc}
${ContactMinFragmentDoc}
${ReturnMethodFragmentDoc}
${ReturnItemFragmentDoc}
${ProductOrderItemFragmentDoc}
${DiscountMinFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}`;
export type CreateReturnMutationFn = Apollo.MutationFunction<CreateReturnMutation, CreateReturnMutationVariables>;
export function useCreateReturnMutation(baseOptions?: Apollo.MutationHookOptions<CreateReturnMutation, CreateReturnMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReturnMutation, CreateReturnMutationVariables>(CreateReturnDocument, options);
      }
export type CreateReturnMutationHookResult = ReturnType<typeof useCreateReturnMutation>;
export type CreateReturnMutationResult = Apollo.MutationResult<CreateReturnMutation>;
export const CreateReturnItemDocument = gql`
    mutation createReturnItem($input: ReturnItemInput!) {
  createReturnItem(data: $input) {
    data {
      ...ReturnItem
    }
  }
}
    ${ReturnItemFragmentDoc}
${ProductOrderItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${SerializeFragmentDoc}
${DiscountMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${CompanyMinFragmentDoc}
${FileFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${OrderMinFragmentDoc}`;
export type CreateReturnItemMutationFn = Apollo.MutationFunction<CreateReturnItemMutation, CreateReturnItemMutationVariables>;
export function useCreateReturnItemMutation(baseOptions?: Apollo.MutationHookOptions<CreateReturnItemMutation, CreateReturnItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReturnItemMutation, CreateReturnItemMutationVariables>(CreateReturnItemDocument, options);
      }
export type CreateReturnItemMutationHookResult = ReturnType<typeof useCreateReturnItemMutation>;
export type CreateReturnItemMutationResult = Apollo.MutationResult<CreateReturnItemMutation>;
export const CreateReturnMethodDocument = gql`
    mutation createReturnMethod($input: ReturnMethodInput!) {
  createReturnMethod(data: $input) {
    data {
      ...ReturnMethod
    }
  }
}
    ${ReturnMethodFragmentDoc}`;
export type CreateReturnMethodMutationFn = Apollo.MutationFunction<CreateReturnMethodMutation, CreateReturnMethodMutationVariables>;
export function useCreateReturnMethodMutation(baseOptions?: Apollo.MutationHookOptions<CreateReturnMethodMutation, CreateReturnMethodMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReturnMethodMutation, CreateReturnMethodMutationVariables>(CreateReturnMethodDocument, options);
      }
export type CreateReturnMethodMutationHookResult = ReturnType<typeof useCreateReturnMethodMutation>;
export type CreateReturnMethodMutationResult = Apollo.MutationResult<CreateReturnMethodMutation>;
export const DeleteReturnItemDocument = gql`
    mutation deleteReturnItem($id: ID!) {
  deleteReturnItem(id: $id) {
    data {
      ...ReturnItem
    }
  }
}
    ${ReturnItemFragmentDoc}
${ProductOrderItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${SerializeFragmentDoc}
${DiscountMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${CompanyMinFragmentDoc}
${FileFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${OrderMinFragmentDoc}`;
export type DeleteReturnItemMutationFn = Apollo.MutationFunction<DeleteReturnItemMutation, DeleteReturnItemMutationVariables>;
export function useDeleteReturnItemMutation(baseOptions?: Apollo.MutationHookOptions<DeleteReturnItemMutation, DeleteReturnItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteReturnItemMutation, DeleteReturnItemMutationVariables>(DeleteReturnItemDocument, options);
      }
export type DeleteReturnItemMutationHookResult = ReturnType<typeof useDeleteReturnItemMutation>;
export type DeleteReturnItemMutationResult = Apollo.MutationResult<DeleteReturnItemMutation>;
export const UpdateReturnDocument = gql`
    mutation updateReturn($id: ID!, $input: ReturnInput!) {
  updateReturn(id: $id, data: $input) {
    data {
      ...Return
    }
  }
}
    ${ReturnFragmentDoc}
${ReturnMinFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationFragmentDoc}
${SubLocationItemFragmentDoc}
${SubLocationItemMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${FileFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${SerializeFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${OrderMinFragmentDoc}
${ContactMinFragmentDoc}
${ReturnMethodFragmentDoc}
${ReturnItemFragmentDoc}
${ProductOrderItemFragmentDoc}
${DiscountMinFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}`;
export type UpdateReturnMutationFn = Apollo.MutationFunction<UpdateReturnMutation, UpdateReturnMutationVariables>;
export function useUpdateReturnMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReturnMutation, UpdateReturnMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateReturnMutation, UpdateReturnMutationVariables>(UpdateReturnDocument, options);
      }
export type UpdateReturnMutationHookResult = ReturnType<typeof useUpdateReturnMutation>;
export type UpdateReturnMutationResult = Apollo.MutationResult<UpdateReturnMutation>;
export const UpdateReturnItemDocument = gql`
    mutation updateReturnItem($id: ID!, $input: ReturnItemInput!) {
  updateReturnItem(id: $id, data: $input) {
    data {
      ...ReturnItem
    }
  }
}
    ${ReturnItemFragmentDoc}
${ProductOrderItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${SerializeFragmentDoc}
${DiscountMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${CompanyMinFragmentDoc}
${FileFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${OrderMinFragmentDoc}`;
export type UpdateReturnItemMutationFn = Apollo.MutationFunction<UpdateReturnItemMutation, UpdateReturnItemMutationVariables>;
export function useUpdateReturnItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReturnItemMutation, UpdateReturnItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateReturnItemMutation, UpdateReturnItemMutationVariables>(UpdateReturnItemDocument, options);
      }
export type UpdateReturnItemMutationHookResult = ReturnType<typeof useUpdateReturnItemMutation>;
export type UpdateReturnItemMutationResult = Apollo.MutationResult<UpdateReturnItemMutation>;
export const ReturnByUuidDocument = gql`
    query returnByUuid($uuid: String!) {
  returns(filters: {uuid: {eq: $uuid}}) {
    data {
      ...Return
    }
  }
}
    ${ReturnFragmentDoc}
${ReturnMinFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationFragmentDoc}
${SubLocationItemFragmentDoc}
${SubLocationItemMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${FileFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${SerializeFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${OrderMinFragmentDoc}
${ContactMinFragmentDoc}
${ReturnMethodFragmentDoc}
${ReturnItemFragmentDoc}
${ProductOrderItemFragmentDoc}
${DiscountMinFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}`;
export function useReturnByUuidQuery(baseOptions: Apollo.QueryHookOptions<ReturnByUuidQuery, ReturnByUuidQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReturnByUuidQuery, ReturnByUuidQueryVariables>(ReturnByUuidDocument, options);
      }
export function useReturnByUuidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReturnByUuidQuery, ReturnByUuidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReturnByUuidQuery, ReturnByUuidQueryVariables>(ReturnByUuidDocument, options);
        }
export type ReturnByUuidQueryHookResult = ReturnType<typeof useReturnByUuidQuery>;
export type ReturnByUuidLazyQueryHookResult = ReturnType<typeof useReturnByUuidLazyQuery>;
export type ReturnByUuidQueryResult = Apollo.QueryResult<ReturnByUuidQuery, ReturnByUuidQueryVariables>;
export const ReturnMethodsDocument = gql`
    query returnMethods($filters: ReturnMethodFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  returnMethods(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...ReturnMethod
    }
    meta {
      ...Meta
    }
  }
}
    ${ReturnMethodFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useReturnMethodsQuery(baseOptions?: Apollo.QueryHookOptions<ReturnMethodsQuery, ReturnMethodsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReturnMethodsQuery, ReturnMethodsQueryVariables>(ReturnMethodsDocument, options);
      }
export function useReturnMethodsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReturnMethodsQuery, ReturnMethodsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReturnMethodsQuery, ReturnMethodsQueryVariables>(ReturnMethodsDocument, options);
        }
export type ReturnMethodsQueryHookResult = ReturnType<typeof useReturnMethodsQuery>;
export type ReturnMethodsLazyQueryHookResult = ReturnType<typeof useReturnMethodsLazyQuery>;
export type ReturnMethodsQueryResult = Apollo.QueryResult<ReturnMethodsQuery, ReturnMethodsQueryVariables>;
export const ReturnsDocument = gql`
    query returns($filters: ReturnFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  returns(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...Return
    }
    meta {
      ...Meta
    }
  }
}
    ${ReturnFragmentDoc}
${ReturnMinFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationFragmentDoc}
${SubLocationItemFragmentDoc}
${SubLocationItemMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${FileFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${SerializeFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${OrderMinFragmentDoc}
${ContactMinFragmentDoc}
${ReturnMethodFragmentDoc}
${ReturnItemFragmentDoc}
${ProductOrderItemFragmentDoc}
${DiscountMinFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useReturnsQuery(baseOptions?: Apollo.QueryHookOptions<ReturnsQuery, ReturnsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReturnsQuery, ReturnsQueryVariables>(ReturnsDocument, options);
      }
export function useReturnsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReturnsQuery, ReturnsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReturnsQuery, ReturnsQueryVariables>(ReturnsDocument, options);
        }
export type ReturnsQueryHookResult = ReturnType<typeof useReturnsQuery>;
export type ReturnsLazyQueryHookResult = ReturnType<typeof useReturnsLazyQuery>;
export type ReturnsQueryResult = Apollo.QueryResult<ReturnsQuery, ReturnsQueryVariables>;
export const RolesDocument = gql`
    query roles($filters: UsersPermissionsRoleFiltersInput!) {
  usersPermissionsRoles(filters: $filters) {
    data {
      id
      attributes {
        name
        type
      }
    }
  }
}
    `;
export function useRolesQuery(baseOptions: Apollo.QueryHookOptions<RolesQuery, RolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RolesQuery, RolesQueryVariables>(RolesDocument, options);
      }
export function useRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RolesQuery, RolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RolesQuery, RolesQueryVariables>(RolesDocument, options);
        }
export type RolesQueryHookResult = ReturnType<typeof useRolesQuery>;
export type RolesLazyQueryHookResult = ReturnType<typeof useRolesLazyQuery>;
export type RolesQueryResult = Apollo.QueryResult<RolesQuery, RolesQueryVariables>;
export const CreateSalesItemReportDocument = gql`
    mutation createSalesItemReport($input: SalesItemReportInput!) {
  createSalesItemReport(data: $input) {
    data {
      ...SalesItemReport
    }
  }
}
    ${SalesItemReportFragmentDoc}
${SalesItemReportMinFragmentDoc}
${OrderMinFragmentDoc}
${ProductOrderItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${SerializeFragmentDoc}
${DiscountMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${CompanyMinFragmentDoc}
${FileFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${CompositeProductOrderItemFragmentDoc}
${CompositeProductLocationInfoFragmentDoc}
${CompositeProductLocationInfoMinFragmentDoc}
${CompositeProductFragmentDoc}
${CompositeProductMinFragmentDoc}
${ProductFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${CompositeProductItemInfoFragmentDoc}
${CompositeProductItemInfoMinFragmentDoc}
${ServiceOrderItemFragmentDoc}
${ServicePerformerFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${ServiceMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}
${MembershipOrderItemFragmentDoc}
${MembershipMinFragmentDoc}
${ClassOrderItemFragmentDoc}
${ClassPerformerFragmentDoc}
${ClassLocationInfoMinFragmentDoc}
${ClassMinFragmentDoc}
${ContactFragmentDoc}
${ContactMinFragmentDoc}
${ContactDynamicValuesFragmentDoc}
${LeadFragmentDoc}
${LeadMinFragmentDoc}
${UserFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}
${CompanyFragmentDoc}`;
export type CreateSalesItemReportMutationFn = Apollo.MutationFunction<CreateSalesItemReportMutation, CreateSalesItemReportMutationVariables>;
export function useCreateSalesItemReportMutation(baseOptions?: Apollo.MutationHookOptions<CreateSalesItemReportMutation, CreateSalesItemReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSalesItemReportMutation, CreateSalesItemReportMutationVariables>(CreateSalesItemReportDocument, options);
      }
export type CreateSalesItemReportMutationHookResult = ReturnType<typeof useCreateSalesItemReportMutation>;
export type CreateSalesItemReportMutationResult = Apollo.MutationResult<CreateSalesItemReportMutation>;
export const DeleteSalesItemReportDocument = gql`
    mutation deleteSalesItemReport($id: ID!) {
  deleteSalesItemReport(id: $id) {
    data {
      id
    }
  }
}
    `;
export type DeleteSalesItemReportMutationFn = Apollo.MutationFunction<DeleteSalesItemReportMutation, DeleteSalesItemReportMutationVariables>;
export function useDeleteSalesItemReportMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSalesItemReportMutation, DeleteSalesItemReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSalesItemReportMutation, DeleteSalesItemReportMutationVariables>(DeleteSalesItemReportDocument, options);
      }
export type DeleteSalesItemReportMutationHookResult = ReturnType<typeof useDeleteSalesItemReportMutation>;
export type DeleteSalesItemReportMutationResult = Apollo.MutationResult<DeleteSalesItemReportMutation>;
export const UpdateSalesItemReportDocument = gql`
    mutation updateSalesItemReport($id: ID!, $input: SalesItemReportInput!) {
  updateSalesItemReport(id: $id, data: $input) {
    data {
      ...SalesItemReport
    }
  }
}
    ${SalesItemReportFragmentDoc}
${SalesItemReportMinFragmentDoc}
${OrderMinFragmentDoc}
${ProductOrderItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${SerializeFragmentDoc}
${DiscountMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${CompanyMinFragmentDoc}
${FileFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${CompositeProductOrderItemFragmentDoc}
${CompositeProductLocationInfoFragmentDoc}
${CompositeProductLocationInfoMinFragmentDoc}
${CompositeProductFragmentDoc}
${CompositeProductMinFragmentDoc}
${ProductFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${CompositeProductItemInfoFragmentDoc}
${CompositeProductItemInfoMinFragmentDoc}
${ServiceOrderItemFragmentDoc}
${ServicePerformerFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${ServiceMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}
${MembershipOrderItemFragmentDoc}
${MembershipMinFragmentDoc}
${ClassOrderItemFragmentDoc}
${ClassPerformerFragmentDoc}
${ClassLocationInfoMinFragmentDoc}
${ClassMinFragmentDoc}
${ContactFragmentDoc}
${ContactMinFragmentDoc}
${ContactDynamicValuesFragmentDoc}
${LeadFragmentDoc}
${LeadMinFragmentDoc}
${UserFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}
${CompanyFragmentDoc}`;
export type UpdateSalesItemReportMutationFn = Apollo.MutationFunction<UpdateSalesItemReportMutation, UpdateSalesItemReportMutationVariables>;
export function useUpdateSalesItemReportMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSalesItemReportMutation, UpdateSalesItemReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSalesItemReportMutation, UpdateSalesItemReportMutationVariables>(UpdateSalesItemReportDocument, options);
      }
export type UpdateSalesItemReportMutationHookResult = ReturnType<typeof useUpdateSalesItemReportMutation>;
export type UpdateSalesItemReportMutationResult = Apollo.MutationResult<UpdateSalesItemReportMutation>;
export const SalesItemReportDocument = gql`
    query salesItemReport($id: ID!) {
  salesItemReport(id: $id) {
    data {
      ...SalesItemReport
    }
  }
}
    ${SalesItemReportFragmentDoc}
${SalesItemReportMinFragmentDoc}
${OrderMinFragmentDoc}
${ProductOrderItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${SerializeFragmentDoc}
${DiscountMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${CompanyMinFragmentDoc}
${FileFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${CompositeProductOrderItemFragmentDoc}
${CompositeProductLocationInfoFragmentDoc}
${CompositeProductLocationInfoMinFragmentDoc}
${CompositeProductFragmentDoc}
${CompositeProductMinFragmentDoc}
${ProductFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${CompositeProductItemInfoFragmentDoc}
${CompositeProductItemInfoMinFragmentDoc}
${ServiceOrderItemFragmentDoc}
${ServicePerformerFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${ServiceMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}
${MembershipOrderItemFragmentDoc}
${MembershipMinFragmentDoc}
${ClassOrderItemFragmentDoc}
${ClassPerformerFragmentDoc}
${ClassLocationInfoMinFragmentDoc}
${ClassMinFragmentDoc}
${ContactFragmentDoc}
${ContactMinFragmentDoc}
${ContactDynamicValuesFragmentDoc}
${LeadFragmentDoc}
${LeadMinFragmentDoc}
${UserFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}
${CompanyFragmentDoc}`;
export function useSalesItemReportQuery(baseOptions: Apollo.QueryHookOptions<SalesItemReportQuery, SalesItemReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SalesItemReportQuery, SalesItemReportQueryVariables>(SalesItemReportDocument, options);
      }
export function useSalesItemReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SalesItemReportQuery, SalesItemReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SalesItemReportQuery, SalesItemReportQueryVariables>(SalesItemReportDocument, options);
        }
export type SalesItemReportQueryHookResult = ReturnType<typeof useSalesItemReportQuery>;
export type SalesItemReportLazyQueryHookResult = ReturnType<typeof useSalesItemReportLazyQuery>;
export type SalesItemReportQueryResult = Apollo.QueryResult<SalesItemReportQuery, SalesItemReportQueryVariables>;
export const SalesItemReportsDocument = gql`
    query salesItemReports($filters: SalesItemReportFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  salesItemReports(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...SalesItemReport
    }
    meta {
      ...Meta
    }
  }
}
    ${SalesItemReportFragmentDoc}
${SalesItemReportMinFragmentDoc}
${OrderMinFragmentDoc}
${ProductOrderItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${SerializeFragmentDoc}
${DiscountMinFragmentDoc}
${ProductInventoryItemFragmentDoc}
${CompanyMinFragmentDoc}
${FileFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${ProductMinFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${CompositeProductOrderItemFragmentDoc}
${CompositeProductLocationInfoFragmentDoc}
${CompositeProductLocationInfoMinFragmentDoc}
${CompositeProductFragmentDoc}
${CompositeProductMinFragmentDoc}
${ProductFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${CompositeProductItemInfoFragmentDoc}
${CompositeProductItemInfoMinFragmentDoc}
${ServiceOrderItemFragmentDoc}
${ServicePerformerFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${ServiceMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}
${MembershipOrderItemFragmentDoc}
${MembershipMinFragmentDoc}
${ClassOrderItemFragmentDoc}
${ClassPerformerFragmentDoc}
${ClassLocationInfoMinFragmentDoc}
${ClassMinFragmentDoc}
${ContactFragmentDoc}
${ContactMinFragmentDoc}
${ContactDynamicValuesFragmentDoc}
${LeadFragmentDoc}
${LeadMinFragmentDoc}
${UserFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}
${CompanyFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useSalesItemReportsQuery(baseOptions?: Apollo.QueryHookOptions<SalesItemReportsQuery, SalesItemReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SalesItemReportsQuery, SalesItemReportsQueryVariables>(SalesItemReportsDocument, options);
      }
export function useSalesItemReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SalesItemReportsQuery, SalesItemReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SalesItemReportsQuery, SalesItemReportsQueryVariables>(SalesItemReportsDocument, options);
        }
export type SalesItemReportsQueryHookResult = ReturnType<typeof useSalesItemReportsQuery>;
export type SalesItemReportsLazyQueryHookResult = ReturnType<typeof useSalesItemReportsLazyQuery>;
export type SalesItemReportsQueryResult = Apollo.QueryResult<SalesItemReportsQuery, SalesItemReportsQueryVariables>;
export const CreateSchedulingAppointmentDocument = gql`
    mutation createSchedulingAppointment($input: SchedulingAppointmentInput!) {
  createSchedulingAppointment(data: $input) {
    data {
      ...SchedulingAppointment
    }
  }
}
    ${SchedulingAppointmentFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${CompanyMinFragmentDoc}
${LeadMinFragmentDoc}
${ContactMinFragmentDoc}
${ServiceFragmentDoc}
${ServiceMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${ServiceLocationInfoFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}
${ServicePerformerFragmentDoc}`;
export type CreateSchedulingAppointmentMutationFn = Apollo.MutationFunction<CreateSchedulingAppointmentMutation, CreateSchedulingAppointmentMutationVariables>;
export function useCreateSchedulingAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<CreateSchedulingAppointmentMutation, CreateSchedulingAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSchedulingAppointmentMutation, CreateSchedulingAppointmentMutationVariables>(CreateSchedulingAppointmentDocument, options);
      }
export type CreateSchedulingAppointmentMutationHookResult = ReturnType<typeof useCreateSchedulingAppointmentMutation>;
export type CreateSchedulingAppointmentMutationResult = Apollo.MutationResult<CreateSchedulingAppointmentMutation>;
export const DeleteSchedulingAppointmentDocument = gql`
    mutation deleteSchedulingAppointment($id: ID!) {
  deleteSchedulingAppointment(id: $id) {
    data {
      ...SchedulingAppointment
    }
  }
}
    ${SchedulingAppointmentFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${CompanyMinFragmentDoc}
${LeadMinFragmentDoc}
${ContactMinFragmentDoc}
${ServiceFragmentDoc}
${ServiceMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${ServiceLocationInfoFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}
${ServicePerformerFragmentDoc}`;
export type DeleteSchedulingAppointmentMutationFn = Apollo.MutationFunction<DeleteSchedulingAppointmentMutation, DeleteSchedulingAppointmentMutationVariables>;
export function useDeleteSchedulingAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSchedulingAppointmentMutation, DeleteSchedulingAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSchedulingAppointmentMutation, DeleteSchedulingAppointmentMutationVariables>(DeleteSchedulingAppointmentDocument, options);
      }
export type DeleteSchedulingAppointmentMutationHookResult = ReturnType<typeof useDeleteSchedulingAppointmentMutation>;
export type DeleteSchedulingAppointmentMutationResult = Apollo.MutationResult<DeleteSchedulingAppointmentMutation>;
export const UpdateSchedulingAppointmentDocument = gql`
    mutation updateSchedulingAppointment($id: ID!, $input: SchedulingAppointmentInput!) {
  updateSchedulingAppointment(id: $id, data: $input) {
    data {
      ...SchedulingAppointment
    }
  }
}
    ${SchedulingAppointmentFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${CompanyMinFragmentDoc}
${LeadMinFragmentDoc}
${ContactMinFragmentDoc}
${ServiceFragmentDoc}
${ServiceMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${ServiceLocationInfoFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}
${ServicePerformerFragmentDoc}`;
export type UpdateSchedulingAppointmentMutationFn = Apollo.MutationFunction<UpdateSchedulingAppointmentMutation, UpdateSchedulingAppointmentMutationVariables>;
export function useUpdateSchedulingAppointmentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSchedulingAppointmentMutation, UpdateSchedulingAppointmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSchedulingAppointmentMutation, UpdateSchedulingAppointmentMutationVariables>(UpdateSchedulingAppointmentDocument, options);
      }
export type UpdateSchedulingAppointmentMutationHookResult = ReturnType<typeof useUpdateSchedulingAppointmentMutation>;
export type UpdateSchedulingAppointmentMutationResult = Apollo.MutationResult<UpdateSchedulingAppointmentMutation>;
export const SchedulingAppointmentDocument = gql`
    query schedulingAppointment($id: ID!) {
  schedulingAppointment(id: $id) {
    data {
      ...SchedulingAppointment
    }
  }
}
    ${SchedulingAppointmentFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${CompanyMinFragmentDoc}
${LeadMinFragmentDoc}
${ContactMinFragmentDoc}
${ServiceFragmentDoc}
${ServiceMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${ServiceLocationInfoFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}
${ServicePerformerFragmentDoc}`;
export function useSchedulingAppointmentQuery(baseOptions: Apollo.QueryHookOptions<SchedulingAppointmentQuery, SchedulingAppointmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SchedulingAppointmentQuery, SchedulingAppointmentQueryVariables>(SchedulingAppointmentDocument, options);
      }
export function useSchedulingAppointmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SchedulingAppointmentQuery, SchedulingAppointmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SchedulingAppointmentQuery, SchedulingAppointmentQueryVariables>(SchedulingAppointmentDocument, options);
        }
export type SchedulingAppointmentQueryHookResult = ReturnType<typeof useSchedulingAppointmentQuery>;
export type SchedulingAppointmentLazyQueryHookResult = ReturnType<typeof useSchedulingAppointmentLazyQuery>;
export type SchedulingAppointmentQueryResult = Apollo.QueryResult<SchedulingAppointmentQuery, SchedulingAppointmentQueryVariables>;
export const SchedulingAppointmentsDocument = gql`
    query schedulingAppointments($filters: SchedulingAppointmentFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  schedulingAppointments(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...SchedulingAppointment
    }
    meta {
      ...Meta
    }
  }
}
    ${SchedulingAppointmentFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${CompanyMinFragmentDoc}
${LeadMinFragmentDoc}
${ContactMinFragmentDoc}
${ServiceFragmentDoc}
${ServiceMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${ServiceLocationInfoFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}
${ServicePerformerFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useSchedulingAppointmentsQuery(baseOptions?: Apollo.QueryHookOptions<SchedulingAppointmentsQuery, SchedulingAppointmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SchedulingAppointmentsQuery, SchedulingAppointmentsQueryVariables>(SchedulingAppointmentsDocument, options);
      }
export function useSchedulingAppointmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SchedulingAppointmentsQuery, SchedulingAppointmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SchedulingAppointmentsQuery, SchedulingAppointmentsQueryVariables>(SchedulingAppointmentsDocument, options);
        }
export type SchedulingAppointmentsQueryHookResult = ReturnType<typeof useSchedulingAppointmentsQuery>;
export type SchedulingAppointmentsLazyQueryHookResult = ReturnType<typeof useSchedulingAppointmentsLazyQuery>;
export type SchedulingAppointmentsQueryResult = Apollo.QueryResult<SchedulingAppointmentsQuery, SchedulingAppointmentsQueryVariables>;
export const CreateSchedulingRecurrenceDocument = gql`
    mutation createSchedulingRecurrence($input: SchedulingRecurrenceInput!) {
  createSchedulingRecurrence(data: $input) {
    data {
      ...SchedulingRecurrence
    }
  }
}
    ${SchedulingRecurrenceFragmentDoc}
${SchedulingRecurrenceMinFragmentDoc}`;
export type CreateSchedulingRecurrenceMutationFn = Apollo.MutationFunction<CreateSchedulingRecurrenceMutation, CreateSchedulingRecurrenceMutationVariables>;
export function useCreateSchedulingRecurrenceMutation(baseOptions?: Apollo.MutationHookOptions<CreateSchedulingRecurrenceMutation, CreateSchedulingRecurrenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSchedulingRecurrenceMutation, CreateSchedulingRecurrenceMutationVariables>(CreateSchedulingRecurrenceDocument, options);
      }
export type CreateSchedulingRecurrenceMutationHookResult = ReturnType<typeof useCreateSchedulingRecurrenceMutation>;
export type CreateSchedulingRecurrenceMutationResult = Apollo.MutationResult<CreateSchedulingRecurrenceMutation>;
export const DeleteSchedulingRecurrenceDocument = gql`
    mutation deleteSchedulingRecurrence($id: ID!) {
  deleteSchedulingRecurrence(id: $id) {
    data {
      ...SchedulingRecurrence
    }
  }
}
    ${SchedulingRecurrenceFragmentDoc}
${SchedulingRecurrenceMinFragmentDoc}`;
export type DeleteSchedulingRecurrenceMutationFn = Apollo.MutationFunction<DeleteSchedulingRecurrenceMutation, DeleteSchedulingRecurrenceMutationVariables>;
export function useDeleteSchedulingRecurrenceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSchedulingRecurrenceMutation, DeleteSchedulingRecurrenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSchedulingRecurrenceMutation, DeleteSchedulingRecurrenceMutationVariables>(DeleteSchedulingRecurrenceDocument, options);
      }
export type DeleteSchedulingRecurrenceMutationHookResult = ReturnType<typeof useDeleteSchedulingRecurrenceMutation>;
export type DeleteSchedulingRecurrenceMutationResult = Apollo.MutationResult<DeleteSchedulingRecurrenceMutation>;
export const UpdateSchedulingRecurrenceDocument = gql`
    mutation updateSchedulingRecurrence($id: ID!, $input: SchedulingRecurrenceInput!) {
  updateSchedulingRecurrence(id: $id, data: $input) {
    data {
      ...SchedulingRecurrence
    }
  }
}
    ${SchedulingRecurrenceFragmentDoc}
${SchedulingRecurrenceMinFragmentDoc}`;
export type UpdateSchedulingRecurrenceMutationFn = Apollo.MutationFunction<UpdateSchedulingRecurrenceMutation, UpdateSchedulingRecurrenceMutationVariables>;
export function useUpdateSchedulingRecurrenceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSchedulingRecurrenceMutation, UpdateSchedulingRecurrenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSchedulingRecurrenceMutation, UpdateSchedulingRecurrenceMutationVariables>(UpdateSchedulingRecurrenceDocument, options);
      }
export type UpdateSchedulingRecurrenceMutationHookResult = ReturnType<typeof useUpdateSchedulingRecurrenceMutation>;
export type UpdateSchedulingRecurrenceMutationResult = Apollo.MutationResult<UpdateSchedulingRecurrenceMutation>;
export const SchedulingRecurrenceDocument = gql`
    query schedulingRecurrence($id: ID!) {
  schedulingRecurrence(id: $id) {
    data {
      ...SchedulingRecurrence
    }
  }
}
    ${SchedulingRecurrenceFragmentDoc}
${SchedulingRecurrenceMinFragmentDoc}`;
export function useSchedulingRecurrenceQuery(baseOptions: Apollo.QueryHookOptions<SchedulingRecurrenceQuery, SchedulingRecurrenceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SchedulingRecurrenceQuery, SchedulingRecurrenceQueryVariables>(SchedulingRecurrenceDocument, options);
      }
export function useSchedulingRecurrenceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SchedulingRecurrenceQuery, SchedulingRecurrenceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SchedulingRecurrenceQuery, SchedulingRecurrenceQueryVariables>(SchedulingRecurrenceDocument, options);
        }
export type SchedulingRecurrenceQueryHookResult = ReturnType<typeof useSchedulingRecurrenceQuery>;
export type SchedulingRecurrenceLazyQueryHookResult = ReturnType<typeof useSchedulingRecurrenceLazyQuery>;
export type SchedulingRecurrenceQueryResult = Apollo.QueryResult<SchedulingRecurrenceQuery, SchedulingRecurrenceQueryVariables>;
export const SchedulingRecurrencesDocument = gql`
    query schedulingRecurrences($filters: SchedulingRecurrenceFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  schedulingRecurrences(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...SchedulingRecurrence
    }
    meta {
      ...Meta
    }
  }
}
    ${SchedulingRecurrenceFragmentDoc}
${SchedulingRecurrenceMinFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useSchedulingRecurrencesQuery(baseOptions?: Apollo.QueryHookOptions<SchedulingRecurrencesQuery, SchedulingRecurrencesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SchedulingRecurrencesQuery, SchedulingRecurrencesQueryVariables>(SchedulingRecurrencesDocument, options);
      }
export function useSchedulingRecurrencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SchedulingRecurrencesQuery, SchedulingRecurrencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SchedulingRecurrencesQuery, SchedulingRecurrencesQueryVariables>(SchedulingRecurrencesDocument, options);
        }
export type SchedulingRecurrencesQueryHookResult = ReturnType<typeof useSchedulingRecurrencesQuery>;
export type SchedulingRecurrencesLazyQueryHookResult = ReturnType<typeof useSchedulingRecurrencesLazyQuery>;
export type SchedulingRecurrencesQueryResult = Apollo.QueryResult<SchedulingRecurrencesQuery, SchedulingRecurrencesQueryVariables>;
export const CreateServiceDocument = gql`
    mutation createService($input: ServiceInput!) {
  createService(data: $input) {
    data {
      ...Service
    }
  }
}
    ${ServiceFragmentDoc}
${ServiceMinFragmentDoc}
${FileFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${ServiceLocationInfoFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}
${ServicePerformerFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}`;
export type CreateServiceMutationFn = Apollo.MutationFunction<CreateServiceMutation, CreateServiceMutationVariables>;
export function useCreateServiceMutation(baseOptions?: Apollo.MutationHookOptions<CreateServiceMutation, CreateServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateServiceMutation, CreateServiceMutationVariables>(CreateServiceDocument, options);
      }
export type CreateServiceMutationHookResult = ReturnType<typeof useCreateServiceMutation>;
export type CreateServiceMutationResult = Apollo.MutationResult<CreateServiceMutation>;
export const CreateServiceLocationInfoDocument = gql`
    mutation createServiceLocationInfo($input: ServiceLocationInfoInput!) {
  createServiceLocationInfo(data: $input) {
    data {
      ...ServiceLocationInfo
    }
  }
}
    ${ServiceLocationInfoFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${ServiceMinFragmentDoc}
${FileFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}
${ServicePerformerFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}`;
export type CreateServiceLocationInfoMutationFn = Apollo.MutationFunction<CreateServiceLocationInfoMutation, CreateServiceLocationInfoMutationVariables>;
export function useCreateServiceLocationInfoMutation(baseOptions?: Apollo.MutationHookOptions<CreateServiceLocationInfoMutation, CreateServiceLocationInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateServiceLocationInfoMutation, CreateServiceLocationInfoMutationVariables>(CreateServiceLocationInfoDocument, options);
      }
export type CreateServiceLocationInfoMutationHookResult = ReturnType<typeof useCreateServiceLocationInfoMutation>;
export type CreateServiceLocationInfoMutationResult = Apollo.MutationResult<CreateServiceLocationInfoMutation>;
export const CreateServicePerformerDocument = gql`
    mutation createServicePerformer($input: ServicePerformerInput!) {
  createServicePerformer(data: $input) {
    data {
      ...ServicePerformer
    }
  }
}
    ${ServicePerformerFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${ServiceMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}`;
export type CreateServicePerformerMutationFn = Apollo.MutationFunction<CreateServicePerformerMutation, CreateServicePerformerMutationVariables>;
export function useCreateServicePerformerMutation(baseOptions?: Apollo.MutationHookOptions<CreateServicePerformerMutation, CreateServicePerformerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateServicePerformerMutation, CreateServicePerformerMutationVariables>(CreateServicePerformerDocument, options);
      }
export type CreateServicePerformerMutationHookResult = ReturnType<typeof useCreateServicePerformerMutation>;
export type CreateServicePerformerMutationResult = Apollo.MutationResult<CreateServicePerformerMutation>;
export const DeleteServicePerformerDocument = gql`
    mutation deleteServicePerformer($id: ID!) {
  deleteServicePerformer(id: $id) {
    data {
      ...ServicePerformer
    }
  }
}
    ${ServicePerformerFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${ServiceMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}`;
export type DeleteServicePerformerMutationFn = Apollo.MutationFunction<DeleteServicePerformerMutation, DeleteServicePerformerMutationVariables>;
export function useDeleteServicePerformerMutation(baseOptions?: Apollo.MutationHookOptions<DeleteServicePerformerMutation, DeleteServicePerformerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteServicePerformerMutation, DeleteServicePerformerMutationVariables>(DeleteServicePerformerDocument, options);
      }
export type DeleteServicePerformerMutationHookResult = ReturnType<typeof useDeleteServicePerformerMutation>;
export type DeleteServicePerformerMutationResult = Apollo.MutationResult<DeleteServicePerformerMutation>;
export const UpdateServiceDocument = gql`
    mutation updateService($id: ID!, $input: ServiceInput!) {
  updateService(id: $id, data: $input) {
    data {
      ...Service
    }
  }
}
    ${ServiceFragmentDoc}
${ServiceMinFragmentDoc}
${FileFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${ServiceLocationInfoFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}
${ServicePerformerFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}`;
export type UpdateServiceMutationFn = Apollo.MutationFunction<UpdateServiceMutation, UpdateServiceMutationVariables>;
export function useUpdateServiceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateServiceMutation, UpdateServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateServiceMutation, UpdateServiceMutationVariables>(UpdateServiceDocument, options);
      }
export type UpdateServiceMutationHookResult = ReturnType<typeof useUpdateServiceMutation>;
export type UpdateServiceMutationResult = Apollo.MutationResult<UpdateServiceMutation>;
export const UpdateServiceLocationInfoDocument = gql`
    mutation updateServiceLocationInfo($id: ID!, $input: ServiceLocationInfoInput!) {
  updateServiceLocationInfo(id: $id, data: $input) {
    data {
      ...ServiceLocationInfoMin
    }
  }
}
    ${ServiceLocationInfoMinFragmentDoc}
${ServiceMinFragmentDoc}
${FileFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}`;
export type UpdateServiceLocationInfoMutationFn = Apollo.MutationFunction<UpdateServiceLocationInfoMutation, UpdateServiceLocationInfoMutationVariables>;
export function useUpdateServiceLocationInfoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateServiceLocationInfoMutation, UpdateServiceLocationInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateServiceLocationInfoMutation, UpdateServiceLocationInfoMutationVariables>(UpdateServiceLocationInfoDocument, options);
      }
export type UpdateServiceLocationInfoMutationHookResult = ReturnType<typeof useUpdateServiceLocationInfoMutation>;
export type UpdateServiceLocationInfoMutationResult = Apollo.MutationResult<UpdateServiceLocationInfoMutation>;
export const UpdateServicePerformerDocument = gql`
    mutation updateServicePerformer($id: ID!, $input: ServicePerformerInput!) {
  updateServicePerformer(id: $id, data: $input) {
    data {
      ...ServicePerformer
    }
  }
}
    ${ServicePerformerFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${ServiceMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}`;
export type UpdateServicePerformerMutationFn = Apollo.MutationFunction<UpdateServicePerformerMutation, UpdateServicePerformerMutationVariables>;
export function useUpdateServicePerformerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateServicePerformerMutation, UpdateServicePerformerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateServicePerformerMutation, UpdateServicePerformerMutationVariables>(UpdateServicePerformerDocument, options);
      }
export type UpdateServicePerformerMutationHookResult = ReturnType<typeof useUpdateServicePerformerMutation>;
export type UpdateServicePerformerMutationResult = Apollo.MutationResult<UpdateServicePerformerMutation>;
export const ServiceByUuidDocument = gql`
    query serviceByUuid($uuid: String!) {
  services(filters: {uuid: {eq: $uuid}}) {
    data {
      ...Service
    }
  }
}
    ${ServiceFragmentDoc}
${ServiceMinFragmentDoc}
${FileFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${ServiceLocationInfoFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}
${ServicePerformerFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}`;
export function useServiceByUuidQuery(baseOptions: Apollo.QueryHookOptions<ServiceByUuidQuery, ServiceByUuidQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ServiceByUuidQuery, ServiceByUuidQueryVariables>(ServiceByUuidDocument, options);
      }
export function useServiceByUuidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ServiceByUuidQuery, ServiceByUuidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ServiceByUuidQuery, ServiceByUuidQueryVariables>(ServiceByUuidDocument, options);
        }
export type ServiceByUuidQueryHookResult = ReturnType<typeof useServiceByUuidQuery>;
export type ServiceByUuidLazyQueryHookResult = ReturnType<typeof useServiceByUuidLazyQuery>;
export type ServiceByUuidQueryResult = Apollo.QueryResult<ServiceByUuidQuery, ServiceByUuidQueryVariables>;
export const ServicePerformersDocument = gql`
    query servicePerformers($filters: ServicePerformerFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  servicePerformers(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...ServicePerformer
    }
    meta {
      ...Meta
    }
  }
}
    ${ServicePerformerFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${ServiceMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useServicePerformersQuery(baseOptions?: Apollo.QueryHookOptions<ServicePerformersQuery, ServicePerformersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ServicePerformersQuery, ServicePerformersQueryVariables>(ServicePerformersDocument, options);
      }
export function useServicePerformersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ServicePerformersQuery, ServicePerformersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ServicePerformersQuery, ServicePerformersQueryVariables>(ServicePerformersDocument, options);
        }
export type ServicePerformersQueryHookResult = ReturnType<typeof useServicePerformersQuery>;
export type ServicePerformersLazyQueryHookResult = ReturnType<typeof useServicePerformersLazyQuery>;
export type ServicePerformersQueryResult = Apollo.QueryResult<ServicePerformersQuery, ServicePerformersQueryVariables>;
export const ServicesDocument = gql`
    query services($filters: ServiceFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  services(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...Service
    }
    meta {
      ...Meta
    }
  }
}
    ${ServiceFragmentDoc}
${ServiceMinFragmentDoc}
${FileFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${ServiceLocationInfoFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}
${ServicePerformerFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useServicesQuery(baseOptions?: Apollo.QueryHookOptions<ServicesQuery, ServicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ServicesQuery, ServicesQueryVariables>(ServicesDocument, options);
      }
export function useServicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ServicesQuery, ServicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ServicesQuery, ServicesQueryVariables>(ServicesDocument, options);
        }
export type ServicesQueryHookResult = ReturnType<typeof useServicesQuery>;
export type ServicesLazyQueryHookResult = ReturnType<typeof useServicesLazyQuery>;
export type ServicesQueryResult = Apollo.QueryResult<ServicesQuery, ServicesQueryVariables>;
export const ServicesWithSoldRevenueDocument = gql`
    query servicesWithSoldRevenue($filters: ServiceFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = [], $startDate: DateTime!, $endDate: DateTime!, $businessLocationId: Int) {
  services(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...ServiceWithSoldRevenue
    }
    meta {
      ...Meta
    }
  }
}
    ${ServiceWithSoldRevenueFragmentDoc}
${ServiceFragmentDoc}
${ServiceMinFragmentDoc}
${FileFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${ServiceLocationInfoFragmentDoc}
${ServiceLocationInfoMinFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${ResourceCountFragmentDoc}
${ResourceInventoryItemFragmentDoc}
${ResourceMinFragmentDoc}
${ServicePerformerFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useServicesWithSoldRevenueQuery(baseOptions: Apollo.QueryHookOptions<ServicesWithSoldRevenueQuery, ServicesWithSoldRevenueQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ServicesWithSoldRevenueQuery, ServicesWithSoldRevenueQueryVariables>(ServicesWithSoldRevenueDocument, options);
      }
export function useServicesWithSoldRevenueLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ServicesWithSoldRevenueQuery, ServicesWithSoldRevenueQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ServicesWithSoldRevenueQuery, ServicesWithSoldRevenueQueryVariables>(ServicesWithSoldRevenueDocument, options);
        }
export type ServicesWithSoldRevenueQueryHookResult = ReturnType<typeof useServicesWithSoldRevenueQuery>;
export type ServicesWithSoldRevenueLazyQueryHookResult = ReturnType<typeof useServicesWithSoldRevenueLazyQuery>;
export type ServicesWithSoldRevenueQueryResult = Apollo.QueryResult<ServicesWithSoldRevenueQuery, ServicesWithSoldRevenueQueryVariables>;
export const DeleteSessionsDocument = gql`
    mutation deleteSessions($input: DeleteSessionsInput!) {
  deleteSessions(input: $input) {
    ok
  }
}
    `;
export type DeleteSessionsMutationFn = Apollo.MutationFunction<DeleteSessionsMutation, DeleteSessionsMutationVariables>;
export function useDeleteSessionsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSessionsMutation, DeleteSessionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSessionsMutation, DeleteSessionsMutationVariables>(DeleteSessionsDocument, options);
      }
export type DeleteSessionsMutationHookResult = ReturnType<typeof useDeleteSessionsMutation>;
export type DeleteSessionsMutationResult = Apollo.MutationResult<DeleteSessionsMutation>;
export const SessionsDocument = gql`
    mutation sessions {
  sessions {
    currentSession {
      device
      browser
      ip
    }
    otherSessions {
      device
      browser
      ip
    }
  }
}
    `;
export type SessionsMutationFn = Apollo.MutationFunction<SessionsMutation, SessionsMutationVariables>;
export function useSessionsMutation(baseOptions?: Apollo.MutationHookOptions<SessionsMutation, SessionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SessionsMutation, SessionsMutationVariables>(SessionsDocument, options);
      }
export type SessionsMutationHookResult = ReturnType<typeof useSessionsMutation>;
export type SessionsMutationResult = Apollo.MutationResult<SessionsMutation>;
export const CreateShipmentDocument = gql`
    mutation createShipment($input: ShipmentInput!) {
  createShipment(data: $input) {
    data {
      ...Shipment
    }
  }
}
    ${ShipmentFragmentDoc}
${OrderMinFragmentDoc}
${ShipmentCarrierFragmentDoc}
${ContactMinFragmentDoc}
${FileFragmentDoc}
${CompanyMinFragmentDoc}`;
export type CreateShipmentMutationFn = Apollo.MutationFunction<CreateShipmentMutation, CreateShipmentMutationVariables>;
export function useCreateShipmentMutation(baseOptions?: Apollo.MutationHookOptions<CreateShipmentMutation, CreateShipmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateShipmentMutation, CreateShipmentMutationVariables>(CreateShipmentDocument, options);
      }
export type CreateShipmentMutationHookResult = ReturnType<typeof useCreateShipmentMutation>;
export type CreateShipmentMutationResult = Apollo.MutationResult<CreateShipmentMutation>;
export const CreateShipmentCarrierDocument = gql`
    mutation createShipmentCarrier($input: ShipmentCarrierInput!) {
  createShipmentCarrier(data: $input) {
    data {
      ...ShipmentCarrier
    }
  }
}
    ${ShipmentCarrierFragmentDoc}`;
export type CreateShipmentCarrierMutationFn = Apollo.MutationFunction<CreateShipmentCarrierMutation, CreateShipmentCarrierMutationVariables>;
export function useCreateShipmentCarrierMutation(baseOptions?: Apollo.MutationHookOptions<CreateShipmentCarrierMutation, CreateShipmentCarrierMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateShipmentCarrierMutation, CreateShipmentCarrierMutationVariables>(CreateShipmentCarrierDocument, options);
      }
export type CreateShipmentCarrierMutationHookResult = ReturnType<typeof useCreateShipmentCarrierMutation>;
export type CreateShipmentCarrierMutationResult = Apollo.MutationResult<CreateShipmentCarrierMutation>;
export const UpdateShipmentDocument = gql`
    mutation updateShipment($id: ID!, $input: ShipmentInput!) {
  updateShipment(id: $id, data: $input) {
    data {
      ...Shipment
    }
  }
}
    ${ShipmentFragmentDoc}
${OrderMinFragmentDoc}
${ShipmentCarrierFragmentDoc}
${ContactMinFragmentDoc}
${FileFragmentDoc}
${CompanyMinFragmentDoc}`;
export type UpdateShipmentMutationFn = Apollo.MutationFunction<UpdateShipmentMutation, UpdateShipmentMutationVariables>;
export function useUpdateShipmentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateShipmentMutation, UpdateShipmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateShipmentMutation, UpdateShipmentMutationVariables>(UpdateShipmentDocument, options);
      }
export type UpdateShipmentMutationHookResult = ReturnType<typeof useUpdateShipmentMutation>;
export type UpdateShipmentMutationResult = Apollo.MutationResult<UpdateShipmentMutation>;
export const ShipmentByUuidDocument = gql`
    query shipmentByUuid($uuid: String!) {
  shipments(filters: {uuid: {eq: $uuid}}) {
    data {
      ...Shipment
    }
  }
}
    ${ShipmentFragmentDoc}
${OrderMinFragmentDoc}
${ShipmentCarrierFragmentDoc}
${ContactMinFragmentDoc}
${FileFragmentDoc}
${CompanyMinFragmentDoc}`;
export function useShipmentByUuidQuery(baseOptions: Apollo.QueryHookOptions<ShipmentByUuidQuery, ShipmentByUuidQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShipmentByUuidQuery, ShipmentByUuidQueryVariables>(ShipmentByUuidDocument, options);
      }
export function useShipmentByUuidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShipmentByUuidQuery, ShipmentByUuidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShipmentByUuidQuery, ShipmentByUuidQueryVariables>(ShipmentByUuidDocument, options);
        }
export type ShipmentByUuidQueryHookResult = ReturnType<typeof useShipmentByUuidQuery>;
export type ShipmentByUuidLazyQueryHookResult = ReturnType<typeof useShipmentByUuidLazyQuery>;
export type ShipmentByUuidQueryResult = Apollo.QueryResult<ShipmentByUuidQuery, ShipmentByUuidQueryVariables>;
export const ShipmentCarriersDocument = gql`
    query shipmentCarriers($filters: ShipmentCarrierFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  shipmentCarriers(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...ShipmentCarrier
    }
    meta {
      ...Meta
    }
  }
}
    ${ShipmentCarrierFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useShipmentCarriersQuery(baseOptions?: Apollo.QueryHookOptions<ShipmentCarriersQuery, ShipmentCarriersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShipmentCarriersQuery, ShipmentCarriersQueryVariables>(ShipmentCarriersDocument, options);
      }
export function useShipmentCarriersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShipmentCarriersQuery, ShipmentCarriersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShipmentCarriersQuery, ShipmentCarriersQueryVariables>(ShipmentCarriersDocument, options);
        }
export type ShipmentCarriersQueryHookResult = ReturnType<typeof useShipmentCarriersQuery>;
export type ShipmentCarriersLazyQueryHookResult = ReturnType<typeof useShipmentCarriersLazyQuery>;
export type ShipmentCarriersQueryResult = Apollo.QueryResult<ShipmentCarriersQuery, ShipmentCarriersQueryVariables>;
export const ShipmentsDocument = gql`
    query shipments($filters: ShipmentFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  shipments(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...Shipment
    }
    meta {
      ...Meta
    }
  }
}
    ${ShipmentFragmentDoc}
${OrderMinFragmentDoc}
${ShipmentCarrierFragmentDoc}
${ContactMinFragmentDoc}
${FileFragmentDoc}
${CompanyMinFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useShipmentsQuery(baseOptions?: Apollo.QueryHookOptions<ShipmentsQuery, ShipmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShipmentsQuery, ShipmentsQueryVariables>(ShipmentsDocument, options);
      }
export function useShipmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShipmentsQuery, ShipmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShipmentsQuery, ShipmentsQueryVariables>(ShipmentsDocument, options);
        }
export type ShipmentsQueryHookResult = ReturnType<typeof useShipmentsQuery>;
export type ShipmentsLazyQueryHookResult = ReturnType<typeof useShipmentsLazyQuery>;
export type ShipmentsQueryResult = Apollo.QueryResult<ShipmentsQuery, ShipmentsQueryVariables>;
export const StripeOnboardingLinkDocument = gql`
    mutation stripeOnboardingLink($input: stripeOnboardingLinkInput!) {
  stripeOnboardingLink(input: $input) {
    link
  }
}
    `;
export type StripeOnboardingLinkMutationFn = Apollo.MutationFunction<StripeOnboardingLinkMutation, StripeOnboardingLinkMutationVariables>;
export function useStripeOnboardingLinkMutation(baseOptions?: Apollo.MutationHookOptions<StripeOnboardingLinkMutation, StripeOnboardingLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StripeOnboardingLinkMutation, StripeOnboardingLinkMutationVariables>(StripeOnboardingLinkDocument, options);
      }
export type StripeOnboardingLinkMutationHookResult = ReturnType<typeof useStripeOnboardingLinkMutation>;
export type StripeOnboardingLinkMutationResult = Apollo.MutationResult<StripeOnboardingLinkMutation>;
export const GetStripeOnboardingDetailsDocument = gql`
    query getStripeOnboardingDetails($input: StripeOnboardingDetailsInput!) {
  getStripeOnboardingDetails(input: $input) {
    accountId
    accountStatus
  }
}
    `;
export function useGetStripeOnboardingDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetStripeOnboardingDetailsQuery, GetStripeOnboardingDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStripeOnboardingDetailsQuery, GetStripeOnboardingDetailsQueryVariables>(GetStripeOnboardingDetailsDocument, options);
      }
export function useGetStripeOnboardingDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStripeOnboardingDetailsQuery, GetStripeOnboardingDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStripeOnboardingDetailsQuery, GetStripeOnboardingDetailsQueryVariables>(GetStripeOnboardingDetailsDocument, options);
        }
export type GetStripeOnboardingDetailsQueryHookResult = ReturnType<typeof useGetStripeOnboardingDetailsQuery>;
export type GetStripeOnboardingDetailsLazyQueryHookResult = ReturnType<typeof useGetStripeOnboardingDetailsLazyQuery>;
export type GetStripeOnboardingDetailsQueryResult = Apollo.QueryResult<GetStripeOnboardingDetailsQuery, GetStripeOnboardingDetailsQueryVariables>;
export const CreateTaskDocument = gql`
    mutation createTask($input: TaskInput!) {
  createTask(data: $input) {
    data {
      ...Task
    }
  }
}
    ${TaskFragmentDoc}
${TaskMinFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${LeadMinFragmentDoc}
${CompanyMinFragmentDoc}
${ContactMinFragmentDoc}
${TaskLocationMinFragmentDoc}
${TaskTypeFragmentDoc}
${TaskTypeMinFragmentDoc}
${TaskStageFragmentDoc}
${TaskStageMinFragmentDoc}
${ApprovalMethodMinFragmentDoc}
${OrderMinFragmentDoc}`;
export type CreateTaskMutationFn = Apollo.MutationFunction<CreateTaskMutation, CreateTaskMutationVariables>;
export function useCreateTaskMutation(baseOptions?: Apollo.MutationHookOptions<CreateTaskMutation, CreateTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTaskMutation, CreateTaskMutationVariables>(CreateTaskDocument, options);
      }
export type CreateTaskMutationHookResult = ReturnType<typeof useCreateTaskMutation>;
export type CreateTaskMutationResult = Apollo.MutationResult<CreateTaskMutation>;
export const DeleteTaskDocument = gql`
    mutation deleteTask($id: ID!) {
  deleteTask(id: $id) {
    data {
      ...Task
    }
  }
}
    ${TaskFragmentDoc}
${TaskMinFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${LeadMinFragmentDoc}
${CompanyMinFragmentDoc}
${ContactMinFragmentDoc}
${TaskLocationMinFragmentDoc}
${TaskTypeFragmentDoc}
${TaskTypeMinFragmentDoc}
${TaskStageFragmentDoc}
${TaskStageMinFragmentDoc}
${ApprovalMethodMinFragmentDoc}
${OrderMinFragmentDoc}`;
export type DeleteTaskMutationFn = Apollo.MutationFunction<DeleteTaskMutation, DeleteTaskMutationVariables>;
export function useDeleteTaskMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTaskMutation, DeleteTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTaskMutation, DeleteTaskMutationVariables>(DeleteTaskDocument, options);
      }
export type DeleteTaskMutationHookResult = ReturnType<typeof useDeleteTaskMutation>;
export type DeleteTaskMutationResult = Apollo.MutationResult<DeleteTaskMutation>;
export const DeleteTaskAndFollowingActivityDocument = gql`
    mutation deleteTaskAndFollowingActivity($input: DeleteTaskAndFollowingActivityInput!) {
  deleteTaskAndFollowingActivity(input: $input)
}
    `;
export type DeleteTaskAndFollowingActivityMutationFn = Apollo.MutationFunction<DeleteTaskAndFollowingActivityMutation, DeleteTaskAndFollowingActivityMutationVariables>;
export function useDeleteTaskAndFollowingActivityMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTaskAndFollowingActivityMutation, DeleteTaskAndFollowingActivityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTaskAndFollowingActivityMutation, DeleteTaskAndFollowingActivityMutationVariables>(DeleteTaskAndFollowingActivityDocument, options);
      }
export type DeleteTaskAndFollowingActivityMutationHookResult = ReturnType<typeof useDeleteTaskAndFollowingActivityMutation>;
export type DeleteTaskAndFollowingActivityMutationResult = Apollo.MutationResult<DeleteTaskAndFollowingActivityMutation>;
export const NotifyCustomerOnCompletionDocument = gql`
    mutation notifyCustomerOnCompletion($input: NotifyCustomerOnCompletionInput!) {
  notifyCustomerOnCompletion(input: $input)
}
    `;
export type NotifyCustomerOnCompletionMutationFn = Apollo.MutationFunction<NotifyCustomerOnCompletionMutation, NotifyCustomerOnCompletionMutationVariables>;
export function useNotifyCustomerOnCompletionMutation(baseOptions?: Apollo.MutationHookOptions<NotifyCustomerOnCompletionMutation, NotifyCustomerOnCompletionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NotifyCustomerOnCompletionMutation, NotifyCustomerOnCompletionMutationVariables>(NotifyCustomerOnCompletionDocument, options);
      }
export type NotifyCustomerOnCompletionMutationHookResult = ReturnType<typeof useNotifyCustomerOnCompletionMutation>;
export type NotifyCustomerOnCompletionMutationResult = Apollo.MutationResult<NotifyCustomerOnCompletionMutation>;
export const UpdateTaskDocument = gql`
    mutation updateTask($id: ID!, $input: TaskInput!) {
  updateTask(id: $id, data: $input) {
    data {
      ...Task
    }
  }
}
    ${TaskFragmentDoc}
${TaskMinFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${LeadMinFragmentDoc}
${CompanyMinFragmentDoc}
${ContactMinFragmentDoc}
${TaskLocationMinFragmentDoc}
${TaskTypeFragmentDoc}
${TaskTypeMinFragmentDoc}
${TaskStageFragmentDoc}
${TaskStageMinFragmentDoc}
${ApprovalMethodMinFragmentDoc}
${OrderMinFragmentDoc}`;
export type UpdateTaskMutationFn = Apollo.MutationFunction<UpdateTaskMutation, UpdateTaskMutationVariables>;
export function useUpdateTaskMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTaskMutation, UpdateTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTaskMutation, UpdateTaskMutationVariables>(UpdateTaskDocument, options);
      }
export type UpdateTaskMutationHookResult = ReturnType<typeof useUpdateTaskMutation>;
export type UpdateTaskMutationResult = Apollo.MutationResult<UpdateTaskMutation>;
export const UpdateTaskAndFollowingActivityDocument = gql`
    mutation updateTaskAndFollowingActivity($input: UpdateTaskAndFollowingActivityInput!) {
  updateTaskAndFollowingActivity(input: $input)
}
    `;
export type UpdateTaskAndFollowingActivityMutationFn = Apollo.MutationFunction<UpdateTaskAndFollowingActivityMutation, UpdateTaskAndFollowingActivityMutationVariables>;
export function useUpdateTaskAndFollowingActivityMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTaskAndFollowingActivityMutation, UpdateTaskAndFollowingActivityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTaskAndFollowingActivityMutation, UpdateTaskAndFollowingActivityMutationVariables>(UpdateTaskAndFollowingActivityDocument, options);
      }
export type UpdateTaskAndFollowingActivityMutationHookResult = ReturnType<typeof useUpdateTaskAndFollowingActivityMutation>;
export type UpdateTaskAndFollowingActivityMutationResult = Apollo.MutationResult<UpdateTaskAndFollowingActivityMutation>;
export const TaskDocument = gql`
    query task($id: ID!) {
  task(id: $id) {
    data {
      ...Task
    }
  }
}
    ${TaskFragmentDoc}
${TaskMinFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${LeadMinFragmentDoc}
${CompanyMinFragmentDoc}
${ContactMinFragmentDoc}
${TaskLocationMinFragmentDoc}
${TaskTypeFragmentDoc}
${TaskTypeMinFragmentDoc}
${TaskStageFragmentDoc}
${TaskStageMinFragmentDoc}
${ApprovalMethodMinFragmentDoc}
${OrderMinFragmentDoc}`;
export function useTaskQuery(baseOptions: Apollo.QueryHookOptions<TaskQuery, TaskQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaskQuery, TaskQueryVariables>(TaskDocument, options);
      }
export function useTaskLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaskQuery, TaskQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaskQuery, TaskQueryVariables>(TaskDocument, options);
        }
export type TaskQueryHookResult = ReturnType<typeof useTaskQuery>;
export type TaskLazyQueryHookResult = ReturnType<typeof useTaskLazyQuery>;
export type TaskQueryResult = Apollo.QueryResult<TaskQuery, TaskQueryVariables>;
export const TasksDocument = gql`
    query tasks($filters: TaskFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  tasks(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...Task
    }
    meta {
      ...Meta
    }
  }
}
    ${TaskFragmentDoc}
${TaskMinFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${LeadMinFragmentDoc}
${CompanyMinFragmentDoc}
${ContactMinFragmentDoc}
${TaskLocationMinFragmentDoc}
${TaskTypeFragmentDoc}
${TaskTypeMinFragmentDoc}
${TaskStageFragmentDoc}
${TaskStageMinFragmentDoc}
${ApprovalMethodMinFragmentDoc}
${OrderMinFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useTasksQuery(baseOptions?: Apollo.QueryHookOptions<TasksQuery, TasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TasksQuery, TasksQueryVariables>(TasksDocument, options);
      }
export function useTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TasksQuery, TasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TasksQuery, TasksQueryVariables>(TasksDocument, options);
        }
export type TasksQueryHookResult = ReturnType<typeof useTasksQuery>;
export type TasksLazyQueryHookResult = ReturnType<typeof useTasksLazyQuery>;
export type TasksQueryResult = Apollo.QueryResult<TasksQuery, TasksQueryVariables>;
export const CreateTaskLocationDocument = gql`
    mutation createTaskLocation($input: TaskLocationInput!) {
  createTaskLocation(data: $input) {
    data {
      ...TaskLocation
    }
  }
}
    ${TaskLocationFragmentDoc}
${TaskLocationMinFragmentDoc}`;
export type CreateTaskLocationMutationFn = Apollo.MutationFunction<CreateTaskLocationMutation, CreateTaskLocationMutationVariables>;
export function useCreateTaskLocationMutation(baseOptions?: Apollo.MutationHookOptions<CreateTaskLocationMutation, CreateTaskLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTaskLocationMutation, CreateTaskLocationMutationVariables>(CreateTaskLocationDocument, options);
      }
export type CreateTaskLocationMutationHookResult = ReturnType<typeof useCreateTaskLocationMutation>;
export type CreateTaskLocationMutationResult = Apollo.MutationResult<CreateTaskLocationMutation>;
export const DeleteTaskLocationDocument = gql`
    mutation deleteTaskLocation($id: ID!) {
  deleteTaskLocation(id: $id) {
    data {
      ...TaskLocation
    }
  }
}
    ${TaskLocationFragmentDoc}
${TaskLocationMinFragmentDoc}`;
export type DeleteTaskLocationMutationFn = Apollo.MutationFunction<DeleteTaskLocationMutation, DeleteTaskLocationMutationVariables>;
export function useDeleteTaskLocationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTaskLocationMutation, DeleteTaskLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTaskLocationMutation, DeleteTaskLocationMutationVariables>(DeleteTaskLocationDocument, options);
      }
export type DeleteTaskLocationMutationHookResult = ReturnType<typeof useDeleteTaskLocationMutation>;
export type DeleteTaskLocationMutationResult = Apollo.MutationResult<DeleteTaskLocationMutation>;
export const UpdateTaskLocationDocument = gql`
    mutation updateTaskLocation($id: ID!, $input: TaskLocationInput!) {
  updateTaskLocation(id: $id, data: $input) {
    data {
      ...TaskLocation
    }
  }
}
    ${TaskLocationFragmentDoc}
${TaskLocationMinFragmentDoc}`;
export type UpdateTaskLocationMutationFn = Apollo.MutationFunction<UpdateTaskLocationMutation, UpdateTaskLocationMutationVariables>;
export function useUpdateTaskLocationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTaskLocationMutation, UpdateTaskLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTaskLocationMutation, UpdateTaskLocationMutationVariables>(UpdateTaskLocationDocument, options);
      }
export type UpdateTaskLocationMutationHookResult = ReturnType<typeof useUpdateTaskLocationMutation>;
export type UpdateTaskLocationMutationResult = Apollo.MutationResult<UpdateTaskLocationMutation>;
export const TaskLocationDocument = gql`
    query taskLocation($id: ID!) {
  taskLocation(id: $id) {
    data {
      ...TaskLocation
    }
  }
}
    ${TaskLocationFragmentDoc}
${TaskLocationMinFragmentDoc}`;
export function useTaskLocationQuery(baseOptions: Apollo.QueryHookOptions<TaskLocationQuery, TaskLocationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaskLocationQuery, TaskLocationQueryVariables>(TaskLocationDocument, options);
      }
export function useTaskLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaskLocationQuery, TaskLocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaskLocationQuery, TaskLocationQueryVariables>(TaskLocationDocument, options);
        }
export type TaskLocationQueryHookResult = ReturnType<typeof useTaskLocationQuery>;
export type TaskLocationLazyQueryHookResult = ReturnType<typeof useTaskLocationLazyQuery>;
export type TaskLocationQueryResult = Apollo.QueryResult<TaskLocationQuery, TaskLocationQueryVariables>;
export const TaskLocationsDocument = gql`
    query taskLocations($filters: TaskLocationFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  taskLocations(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...TaskLocation
    }
    meta {
      ...Meta
    }
  }
}
    ${TaskLocationFragmentDoc}
${TaskLocationMinFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useTaskLocationsQuery(baseOptions?: Apollo.QueryHookOptions<TaskLocationsQuery, TaskLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaskLocationsQuery, TaskLocationsQueryVariables>(TaskLocationsDocument, options);
      }
export function useTaskLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaskLocationsQuery, TaskLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaskLocationsQuery, TaskLocationsQueryVariables>(TaskLocationsDocument, options);
        }
export type TaskLocationsQueryHookResult = ReturnType<typeof useTaskLocationsQuery>;
export type TaskLocationsLazyQueryHookResult = ReturnType<typeof useTaskLocationsLazyQuery>;
export type TaskLocationsQueryResult = Apollo.QueryResult<TaskLocationsQuery, TaskLocationsQueryVariables>;
export const CreateTaskStageDocument = gql`
    mutation createTaskStage($input: TaskStageInput!) {
  createTaskStage(data: $input) {
    data {
      ...TaskStage
    }
  }
}
    ${TaskStageFragmentDoc}
${TaskStageMinFragmentDoc}`;
export type CreateTaskStageMutationFn = Apollo.MutationFunction<CreateTaskStageMutation, CreateTaskStageMutationVariables>;
export function useCreateTaskStageMutation(baseOptions?: Apollo.MutationHookOptions<CreateTaskStageMutation, CreateTaskStageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTaskStageMutation, CreateTaskStageMutationVariables>(CreateTaskStageDocument, options);
      }
export type CreateTaskStageMutationHookResult = ReturnType<typeof useCreateTaskStageMutation>;
export type CreateTaskStageMutationResult = Apollo.MutationResult<CreateTaskStageMutation>;
export const DeleteTaskStageDocument = gql`
    mutation deleteTaskStage($id: ID!) {
  deleteTaskStage(id: $id) {
    data {
      ...TaskStage
    }
  }
}
    ${TaskStageFragmentDoc}
${TaskStageMinFragmentDoc}`;
export type DeleteTaskStageMutationFn = Apollo.MutationFunction<DeleteTaskStageMutation, DeleteTaskStageMutationVariables>;
export function useDeleteTaskStageMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTaskStageMutation, DeleteTaskStageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTaskStageMutation, DeleteTaskStageMutationVariables>(DeleteTaskStageDocument, options);
      }
export type DeleteTaskStageMutationHookResult = ReturnType<typeof useDeleteTaskStageMutation>;
export type DeleteTaskStageMutationResult = Apollo.MutationResult<DeleteTaskStageMutation>;
export const UpdateTaskStageDocument = gql`
    mutation updateTaskStage($id: ID!, $input: TaskStageInput!) {
  updateTaskStage(id: $id, data: $input) {
    data {
      ...TaskStage
    }
  }
}
    ${TaskStageFragmentDoc}
${TaskStageMinFragmentDoc}`;
export type UpdateTaskStageMutationFn = Apollo.MutationFunction<UpdateTaskStageMutation, UpdateTaskStageMutationVariables>;
export function useUpdateTaskStageMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTaskStageMutation, UpdateTaskStageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTaskStageMutation, UpdateTaskStageMutationVariables>(UpdateTaskStageDocument, options);
      }
export type UpdateTaskStageMutationHookResult = ReturnType<typeof useUpdateTaskStageMutation>;
export type UpdateTaskStageMutationResult = Apollo.MutationResult<UpdateTaskStageMutation>;
export const TaskStageDocument = gql`
    query taskStage($id: ID!) {
  taskStage(id: $id) {
    data {
      ...TaskStage
    }
  }
}
    ${TaskStageFragmentDoc}
${TaskStageMinFragmentDoc}`;
export function useTaskStageQuery(baseOptions: Apollo.QueryHookOptions<TaskStageQuery, TaskStageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaskStageQuery, TaskStageQueryVariables>(TaskStageDocument, options);
      }
export function useTaskStageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaskStageQuery, TaskStageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaskStageQuery, TaskStageQueryVariables>(TaskStageDocument, options);
        }
export type TaskStageQueryHookResult = ReturnType<typeof useTaskStageQuery>;
export type TaskStageLazyQueryHookResult = ReturnType<typeof useTaskStageLazyQuery>;
export type TaskStageQueryResult = Apollo.QueryResult<TaskStageQuery, TaskStageQueryVariables>;
export const TaskStagesDocument = gql`
    query taskStages($filters: TaskStageFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  taskStages(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...TaskStage
    }
    meta {
      ...Meta
    }
  }
}
    ${TaskStageFragmentDoc}
${TaskStageMinFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useTaskStagesQuery(baseOptions?: Apollo.QueryHookOptions<TaskStagesQuery, TaskStagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaskStagesQuery, TaskStagesQueryVariables>(TaskStagesDocument, options);
      }
export function useTaskStagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaskStagesQuery, TaskStagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaskStagesQuery, TaskStagesQueryVariables>(TaskStagesDocument, options);
        }
export type TaskStagesQueryHookResult = ReturnType<typeof useTaskStagesQuery>;
export type TaskStagesLazyQueryHookResult = ReturnType<typeof useTaskStagesLazyQuery>;
export type TaskStagesQueryResult = Apollo.QueryResult<TaskStagesQuery, TaskStagesQueryVariables>;
export const CreateTaskTypeDocument = gql`
    mutation createTaskType($input: TaskTypeInput!) {
  createTaskType(data: $input) {
    data {
      ...TaskType
    }
  }
}
    ${TaskTypeFragmentDoc}
${TaskTypeMinFragmentDoc}`;
export type CreateTaskTypeMutationFn = Apollo.MutationFunction<CreateTaskTypeMutation, CreateTaskTypeMutationVariables>;
export function useCreateTaskTypeMutation(baseOptions?: Apollo.MutationHookOptions<CreateTaskTypeMutation, CreateTaskTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTaskTypeMutation, CreateTaskTypeMutationVariables>(CreateTaskTypeDocument, options);
      }
export type CreateTaskTypeMutationHookResult = ReturnType<typeof useCreateTaskTypeMutation>;
export type CreateTaskTypeMutationResult = Apollo.MutationResult<CreateTaskTypeMutation>;
export const DeleteTaskTypeDocument = gql`
    mutation deleteTaskType($id: ID!) {
  deleteTaskType(id: $id) {
    data {
      ...TaskType
    }
  }
}
    ${TaskTypeFragmentDoc}
${TaskTypeMinFragmentDoc}`;
export type DeleteTaskTypeMutationFn = Apollo.MutationFunction<DeleteTaskTypeMutation, DeleteTaskTypeMutationVariables>;
export function useDeleteTaskTypeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTaskTypeMutation, DeleteTaskTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTaskTypeMutation, DeleteTaskTypeMutationVariables>(DeleteTaskTypeDocument, options);
      }
export type DeleteTaskTypeMutationHookResult = ReturnType<typeof useDeleteTaskTypeMutation>;
export type DeleteTaskTypeMutationResult = Apollo.MutationResult<DeleteTaskTypeMutation>;
export const UpdateTaskTypeDocument = gql`
    mutation updateTaskType($id: ID!, $input: TaskTypeInput!) {
  updateTaskType(id: $id, data: $input) {
    data {
      ...TaskType
    }
  }
}
    ${TaskTypeFragmentDoc}
${TaskTypeMinFragmentDoc}`;
export type UpdateTaskTypeMutationFn = Apollo.MutationFunction<UpdateTaskTypeMutation, UpdateTaskTypeMutationVariables>;
export function useUpdateTaskTypeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTaskTypeMutation, UpdateTaskTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTaskTypeMutation, UpdateTaskTypeMutationVariables>(UpdateTaskTypeDocument, options);
      }
export type UpdateTaskTypeMutationHookResult = ReturnType<typeof useUpdateTaskTypeMutation>;
export type UpdateTaskTypeMutationResult = Apollo.MutationResult<UpdateTaskTypeMutation>;
export const TaskTypeDocument = gql`
    query taskType($id: ID!) {
  taskType(id: $id) {
    data {
      ...TaskType
    }
  }
}
    ${TaskTypeFragmentDoc}
${TaskTypeMinFragmentDoc}`;
export function useTaskTypeQuery(baseOptions: Apollo.QueryHookOptions<TaskTypeQuery, TaskTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaskTypeQuery, TaskTypeQueryVariables>(TaskTypeDocument, options);
      }
export function useTaskTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaskTypeQuery, TaskTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaskTypeQuery, TaskTypeQueryVariables>(TaskTypeDocument, options);
        }
export type TaskTypeQueryHookResult = ReturnType<typeof useTaskTypeQuery>;
export type TaskTypeLazyQueryHookResult = ReturnType<typeof useTaskTypeLazyQuery>;
export type TaskTypeQueryResult = Apollo.QueryResult<TaskTypeQuery, TaskTypeQueryVariables>;
export const TaskTypesDocument = gql`
    query taskTypes($filters: TaskTypeFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  taskTypes(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...TaskType
    }
    meta {
      ...Meta
    }
  }
}
    ${TaskTypeFragmentDoc}
${TaskTypeMinFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useTaskTypesQuery(baseOptions?: Apollo.QueryHookOptions<TaskTypesQuery, TaskTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaskTypesQuery, TaskTypesQueryVariables>(TaskTypesDocument, options);
      }
export function useTaskTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaskTypesQuery, TaskTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaskTypesQuery, TaskTypesQueryVariables>(TaskTypesDocument, options);
        }
export type TaskTypesQueryHookResult = ReturnType<typeof useTaskTypesQuery>;
export type TaskTypesLazyQueryHookResult = ReturnType<typeof useTaskTypesLazyQuery>;
export type TaskTypesQueryResult = Apollo.QueryResult<TaskTypesQuery, TaskTypesQueryVariables>;
export const CreateTaxDocument = gql`
    mutation createTax($data: TaxInput!) {
  createTax(data: $data) {
    data {
      ...Tax
    }
  }
}
    ${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}`;
export type CreateTaxMutationFn = Apollo.MutationFunction<CreateTaxMutation, CreateTaxMutationVariables>;
export function useCreateTaxMutation(baseOptions?: Apollo.MutationHookOptions<CreateTaxMutation, CreateTaxMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTaxMutation, CreateTaxMutationVariables>(CreateTaxDocument, options);
      }
export type CreateTaxMutationHookResult = ReturnType<typeof useCreateTaxMutation>;
export type CreateTaxMutationResult = Apollo.MutationResult<CreateTaxMutation>;
export const DeleteTaxDocument = gql`
    mutation deleteTax($id: ID!) {
  deleteTax(id: $id) {
    data {
      ...Tax
    }
  }
}
    ${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}`;
export type DeleteTaxMutationFn = Apollo.MutationFunction<DeleteTaxMutation, DeleteTaxMutationVariables>;
export function useDeleteTaxMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTaxMutation, DeleteTaxMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTaxMutation, DeleteTaxMutationVariables>(DeleteTaxDocument, options);
      }
export type DeleteTaxMutationHookResult = ReturnType<typeof useDeleteTaxMutation>;
export type DeleteTaxMutationResult = Apollo.MutationResult<DeleteTaxMutation>;
export const UpdateTaxDocument = gql`
    mutation updateTax($updateTaxId: ID!, $data: TaxInput!) {
  updateTax(id: $updateTaxId, data: $data) {
    data {
      ...Tax
    }
  }
}
    ${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}`;
export type UpdateTaxMutationFn = Apollo.MutationFunction<UpdateTaxMutation, UpdateTaxMutationVariables>;
export function useUpdateTaxMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTaxMutation, UpdateTaxMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTaxMutation, UpdateTaxMutationVariables>(UpdateTaxDocument, options);
      }
export type UpdateTaxMutationHookResult = ReturnType<typeof useUpdateTaxMutation>;
export type UpdateTaxMutationResult = Apollo.MutationResult<UpdateTaxMutation>;
export const TaxesDocument = gql`
    query taxes($filters: TaxFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  taxes(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...Tax
    }
    meta {
      ...Meta
    }
  }
}
    ${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useTaxesQuery(baseOptions?: Apollo.QueryHookOptions<TaxesQuery, TaxesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaxesQuery, TaxesQueryVariables>(TaxesDocument, options);
      }
export function useTaxesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaxesQuery, TaxesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaxesQuery, TaxesQueryVariables>(TaxesDocument, options);
        }
export type TaxesQueryHookResult = ReturnType<typeof useTaxesQuery>;
export type TaxesLazyQueryHookResult = ReturnType<typeof useTaxesLazyQuery>;
export type TaxesQueryResult = Apollo.QueryResult<TaxesQuery, TaxesQueryVariables>;
export const CreateTaxAuthorityDocument = gql`
    mutation createTaxAuthority($data: TaxAuthorityInput!) {
  createTaxAuthority(data: $data) {
    data {
      ...TaxAuthority
    }
  }
}
    ${TaxAuthorityFragmentDoc}`;
export type CreateTaxAuthorityMutationFn = Apollo.MutationFunction<CreateTaxAuthorityMutation, CreateTaxAuthorityMutationVariables>;
export function useCreateTaxAuthorityMutation(baseOptions?: Apollo.MutationHookOptions<CreateTaxAuthorityMutation, CreateTaxAuthorityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTaxAuthorityMutation, CreateTaxAuthorityMutationVariables>(CreateTaxAuthorityDocument, options);
      }
export type CreateTaxAuthorityMutationHookResult = ReturnType<typeof useCreateTaxAuthorityMutation>;
export type CreateTaxAuthorityMutationResult = Apollo.MutationResult<CreateTaxAuthorityMutation>;
export const TaxAuthoritiesDocument = gql`
    query taxAuthorities($filters: TaxAuthorityFiltersInput, $sort: [String]) {
  taxAuthorities(filters: $filters, sort: $sort) {
    data {
      ...TaxAuthority
    }
  }
}
    ${TaxAuthorityFragmentDoc}`;
export function useTaxAuthoritiesQuery(baseOptions?: Apollo.QueryHookOptions<TaxAuthoritiesQuery, TaxAuthoritiesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaxAuthoritiesQuery, TaxAuthoritiesQueryVariables>(TaxAuthoritiesDocument, options);
      }
export function useTaxAuthoritiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaxAuthoritiesQuery, TaxAuthoritiesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaxAuthoritiesQuery, TaxAuthoritiesQueryVariables>(TaxAuthoritiesDocument, options);
        }
export type TaxAuthoritiesQueryHookResult = ReturnType<typeof useTaxAuthoritiesQuery>;
export type TaxAuthoritiesLazyQueryHookResult = ReturnType<typeof useTaxAuthoritiesLazyQuery>;
export type TaxAuthoritiesQueryResult = Apollo.QueryResult<TaxAuthoritiesQuery, TaxAuthoritiesQueryVariables>;
export const CreateTaxReportDocument = gql`
    mutation createTaxReport($input: TaxReportInput!) {
  createTaxReport(data: $input) {
    data {
      ...TaxReport
    }
  }
}
    ${TaxReportFragmentDoc}
${TaxReportMinFragmentDoc}`;
export type CreateTaxReportMutationFn = Apollo.MutationFunction<CreateTaxReportMutation, CreateTaxReportMutationVariables>;
export function useCreateTaxReportMutation(baseOptions?: Apollo.MutationHookOptions<CreateTaxReportMutation, CreateTaxReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTaxReportMutation, CreateTaxReportMutationVariables>(CreateTaxReportDocument, options);
      }
export type CreateTaxReportMutationHookResult = ReturnType<typeof useCreateTaxReportMutation>;
export type CreateTaxReportMutationResult = Apollo.MutationResult<CreateTaxReportMutation>;
export const DeleteTaxReportDocument = gql`
    mutation deleteTaxReport($id: ID!) {
  deleteTaxReport(id: $id) {
    data {
      ...TaxReport
    }
  }
}
    ${TaxReportFragmentDoc}
${TaxReportMinFragmentDoc}`;
export type DeleteTaxReportMutationFn = Apollo.MutationFunction<DeleteTaxReportMutation, DeleteTaxReportMutationVariables>;
export function useDeleteTaxReportMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTaxReportMutation, DeleteTaxReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTaxReportMutation, DeleteTaxReportMutationVariables>(DeleteTaxReportDocument, options);
      }
export type DeleteTaxReportMutationHookResult = ReturnType<typeof useDeleteTaxReportMutation>;
export type DeleteTaxReportMutationResult = Apollo.MutationResult<DeleteTaxReportMutation>;
export const UpdateTaxReportDocument = gql`
    mutation updateTaxReport($id: ID!, $input: TaxReportInput!) {
  updateTaxReport(id: $id, data: $input) {
    data {
      ...TaxReport
    }
  }
}
    ${TaxReportFragmentDoc}
${TaxReportMinFragmentDoc}`;
export type UpdateTaxReportMutationFn = Apollo.MutationFunction<UpdateTaxReportMutation, UpdateTaxReportMutationVariables>;
export function useUpdateTaxReportMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTaxReportMutation, UpdateTaxReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTaxReportMutation, UpdateTaxReportMutationVariables>(UpdateTaxReportDocument, options);
      }
export type UpdateTaxReportMutationHookResult = ReturnType<typeof useUpdateTaxReportMutation>;
export type UpdateTaxReportMutationResult = Apollo.MutationResult<UpdateTaxReportMutation>;
export const TaxReportDocument = gql`
    query taxReport($id: ID!) {
  taxReport(id: $id) {
    data {
      ...TaxReport
    }
  }
}
    ${TaxReportFragmentDoc}
${TaxReportMinFragmentDoc}`;
export function useTaxReportQuery(baseOptions: Apollo.QueryHookOptions<TaxReportQuery, TaxReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaxReportQuery, TaxReportQueryVariables>(TaxReportDocument, options);
      }
export function useTaxReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaxReportQuery, TaxReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaxReportQuery, TaxReportQueryVariables>(TaxReportDocument, options);
        }
export type TaxReportQueryHookResult = ReturnType<typeof useTaxReportQuery>;
export type TaxReportLazyQueryHookResult = ReturnType<typeof useTaxReportLazyQuery>;
export type TaxReportQueryResult = Apollo.QueryResult<TaxReportQuery, TaxReportQueryVariables>;
export const TaxReportsDocument = gql`
    query taxReports($filters: TaxReportFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  taxReports(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...TaxReport
    }
    meta {
      ...Meta
    }
  }
}
    ${TaxReportFragmentDoc}
${TaxReportMinFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useTaxReportsQuery(baseOptions?: Apollo.QueryHookOptions<TaxReportsQuery, TaxReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaxReportsQuery, TaxReportsQueryVariables>(TaxReportsDocument, options);
      }
export function useTaxReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaxReportsQuery, TaxReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaxReportsQuery, TaxReportsQueryVariables>(TaxReportsDocument, options);
        }
export type TaxReportsQueryHookResult = ReturnType<typeof useTaxReportsQuery>;
export type TaxReportsLazyQueryHookResult = ReturnType<typeof useTaxReportsLazyQuery>;
export type TaxReportsQueryResult = Apollo.QueryResult<TaxReportsQuery, TaxReportsQueryVariables>;
export const CreateTenantDocument = gql`
    mutation createTenant($data: TenantInput!) {
  createTenant(data: $data) {
    data {
      ...Tenant
    }
  }
}
    ${TenantFragmentDoc}
${FileFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}`;
export type CreateTenantMutationFn = Apollo.MutationFunction<CreateTenantMutation, CreateTenantMutationVariables>;
export function useCreateTenantMutation(baseOptions?: Apollo.MutationHookOptions<CreateTenantMutation, CreateTenantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTenantMutation, CreateTenantMutationVariables>(CreateTenantDocument, options);
      }
export type CreateTenantMutationHookResult = ReturnType<typeof useCreateTenantMutation>;
export type CreateTenantMutationResult = Apollo.MutationResult<CreateTenantMutation>;
export const UpdateTenantDocument = gql`
    mutation updateTenant($id: ID!, $input: TenantInput!) {
  updateTenant(id: $id, data: $input) {
    data {
      ...Tenant
    }
  }
}
    ${TenantFragmentDoc}
${FileFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}`;
export type UpdateTenantMutationFn = Apollo.MutationFunction<UpdateTenantMutation, UpdateTenantMutationVariables>;
export function useUpdateTenantMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTenantMutation, UpdateTenantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTenantMutation, UpdateTenantMutationVariables>(UpdateTenantDocument, options);
      }
export type UpdateTenantMutationHookResult = ReturnType<typeof useUpdateTenantMutation>;
export type UpdateTenantMutationResult = Apollo.MutationResult<UpdateTenantMutation>;
export const TenantBySlugDocument = gql`
    query tenantBySlug($tenantSlug: String!) {
  tenants(filters: {slug: {eq: $tenantSlug}}) {
    data {
      ...Tenant
    }
  }
}
    ${TenantFragmentDoc}
${FileFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}`;
export function useTenantBySlugQuery(baseOptions: Apollo.QueryHookOptions<TenantBySlugQuery, TenantBySlugQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TenantBySlugQuery, TenantBySlugQueryVariables>(TenantBySlugDocument, options);
      }
export function useTenantBySlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TenantBySlugQuery, TenantBySlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TenantBySlugQuery, TenantBySlugQueryVariables>(TenantBySlugDocument, options);
        }
export type TenantBySlugQueryHookResult = ReturnType<typeof useTenantBySlugQuery>;
export type TenantBySlugLazyQueryHookResult = ReturnType<typeof useTenantBySlugLazyQuery>;
export type TenantBySlugQueryResult = Apollo.QueryResult<TenantBySlugQuery, TenantBySlugQueryVariables>;
export const CreateTodoDocument = gql`
    mutation createTodo($input: TodoInput!) {
  createTodo(data: $input) {
    data {
      ...Todo
    }
  }
}
    ${TodoFragmentDoc}
${TodoMinFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}`;
export type CreateTodoMutationFn = Apollo.MutationFunction<CreateTodoMutation, CreateTodoMutationVariables>;
export function useCreateTodoMutation(baseOptions?: Apollo.MutationHookOptions<CreateTodoMutation, CreateTodoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTodoMutation, CreateTodoMutationVariables>(CreateTodoDocument, options);
      }
export type CreateTodoMutationHookResult = ReturnType<typeof useCreateTodoMutation>;
export type CreateTodoMutationResult = Apollo.MutationResult<CreateTodoMutation>;
export const DeleteTodoDocument = gql`
    mutation deleteTodo($id: ID!) {
  deleteTodo(id: $id) {
    data {
      ...Todo
    }
  }
}
    ${TodoFragmentDoc}
${TodoMinFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}`;
export type DeleteTodoMutationFn = Apollo.MutationFunction<DeleteTodoMutation, DeleteTodoMutationVariables>;
export function useDeleteTodoMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTodoMutation, DeleteTodoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTodoMutation, DeleteTodoMutationVariables>(DeleteTodoDocument, options);
      }
export type DeleteTodoMutationHookResult = ReturnType<typeof useDeleteTodoMutation>;
export type DeleteTodoMutationResult = Apollo.MutationResult<DeleteTodoMutation>;
export const UpdateTodoDocument = gql`
    mutation updateTodo($id: ID!, $input: TodoInput!) {
  updateTodo(id: $id, data: $input) {
    data {
      ...Todo
    }
  }
}
    ${TodoFragmentDoc}
${TodoMinFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}`;
export type UpdateTodoMutationFn = Apollo.MutationFunction<UpdateTodoMutation, UpdateTodoMutationVariables>;
export function useUpdateTodoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTodoMutation, UpdateTodoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTodoMutation, UpdateTodoMutationVariables>(UpdateTodoDocument, options);
      }
export type UpdateTodoMutationHookResult = ReturnType<typeof useUpdateTodoMutation>;
export type UpdateTodoMutationResult = Apollo.MutationResult<UpdateTodoMutation>;
export const TodosDocument = gql`
    query todos($filters: TodoFiltersInput, $sort: [String] = ["createdAt_ASC"], $pagination: PaginationArg) {
  todos(filters: $filters, sort: $sort, pagination: $pagination) {
    data {
      ...Todo
    }
    meta {
      ...Meta
    }
  }
}
    ${TodoFragmentDoc}
${TodoMinFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useTodosQuery(baseOptions?: Apollo.QueryHookOptions<TodosQuery, TodosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TodosQuery, TodosQueryVariables>(TodosDocument, options);
      }
export function useTodosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TodosQuery, TodosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TodosQuery, TodosQueryVariables>(TodosDocument, options);
        }
export type TodosQueryHookResult = ReturnType<typeof useTodosQuery>;
export type TodosLazyQueryHookResult = ReturnType<typeof useTodosLazyQuery>;
export type TodosQueryResult = Apollo.QueryResult<TodosQuery, TodosQueryVariables>;
export const CreateTransferOrderDocument = gql`
    mutation createTransferOrder($input: TransferOrderInput!) {
  createTransferOrder(data: $input) {
    data {
      ...TransferOrder
    }
  }
}
    ${TransferOrderFragmentDoc}
${TransferOrderMinFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${TransferOrderItemFragmentDoc}
${TransferOrderItemMinFragmentDoc}
${ProductFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${SerializeFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${SubLocationItemFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ContactMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}`;
export type CreateTransferOrderMutationFn = Apollo.MutationFunction<CreateTransferOrderMutation, CreateTransferOrderMutationVariables>;
export function useCreateTransferOrderMutation(baseOptions?: Apollo.MutationHookOptions<CreateTransferOrderMutation, CreateTransferOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTransferOrderMutation, CreateTransferOrderMutationVariables>(CreateTransferOrderDocument, options);
      }
export type CreateTransferOrderMutationHookResult = ReturnType<typeof useCreateTransferOrderMutation>;
export type CreateTransferOrderMutationResult = Apollo.MutationResult<CreateTransferOrderMutation>;
export const CreateTransferOrderItemDocument = gql`
    mutation createTransferOrderItem($input: TransferOrderItemInput!) {
  createTransferOrderItem(data: $input) {
    data {
      ...TransferOrderItem
    }
  }
}
    ${TransferOrderItemFragmentDoc}
${TransferOrderItemMinFragmentDoc}
${ProductFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${SerializeFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${SubLocationItemFragmentDoc}`;
export type CreateTransferOrderItemMutationFn = Apollo.MutationFunction<CreateTransferOrderItemMutation, CreateTransferOrderItemMutationVariables>;
export function useCreateTransferOrderItemMutation(baseOptions?: Apollo.MutationHookOptions<CreateTransferOrderItemMutation, CreateTransferOrderItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTransferOrderItemMutation, CreateTransferOrderItemMutationVariables>(CreateTransferOrderItemDocument, options);
      }
export type CreateTransferOrderItemMutationHookResult = ReturnType<typeof useCreateTransferOrderItemMutation>;
export type CreateTransferOrderItemMutationResult = Apollo.MutationResult<CreateTransferOrderItemMutation>;
export const UpdateTransferOrderDocument = gql`
    mutation updateTransferOrder($id: ID!, $input: TransferOrderInput!) {
  updateTransferOrder(id: $id, data: $input) {
    data {
      ...TransferOrder
    }
  }
}
    ${TransferOrderFragmentDoc}
${TransferOrderMinFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${TransferOrderItemFragmentDoc}
${TransferOrderItemMinFragmentDoc}
${ProductFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${SerializeFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${SubLocationItemFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ContactMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}`;
export type UpdateTransferOrderMutationFn = Apollo.MutationFunction<UpdateTransferOrderMutation, UpdateTransferOrderMutationVariables>;
export function useUpdateTransferOrderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTransferOrderMutation, UpdateTransferOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTransferOrderMutation, UpdateTransferOrderMutationVariables>(UpdateTransferOrderDocument, options);
      }
export type UpdateTransferOrderMutationHookResult = ReturnType<typeof useUpdateTransferOrderMutation>;
export type UpdateTransferOrderMutationResult = Apollo.MutationResult<UpdateTransferOrderMutation>;
export const TransferOrdersDocument = gql`
    query transferOrders($filters: TransferOrderFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  transferOrders(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...TransferOrder
    }
    meta {
      ...Meta
    }
  }
}
    ${TransferOrderFragmentDoc}
${TransferOrderMinFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${TransferOrderItemFragmentDoc}
${TransferOrderItemMinFragmentDoc}
${ProductFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${SerializeFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${SubLocationItemFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ContactMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useTransferOrdersQuery(baseOptions?: Apollo.QueryHookOptions<TransferOrdersQuery, TransferOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TransferOrdersQuery, TransferOrdersQueryVariables>(TransferOrdersDocument, options);
      }
export function useTransferOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TransferOrdersQuery, TransferOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TransferOrdersQuery, TransferOrdersQueryVariables>(TransferOrdersDocument, options);
        }
export type TransferOrdersQueryHookResult = ReturnType<typeof useTransferOrdersQuery>;
export type TransferOrdersLazyQueryHookResult = ReturnType<typeof useTransferOrdersLazyQuery>;
export type TransferOrdersQueryResult = Apollo.QueryResult<TransferOrdersQuery, TransferOrdersQueryVariables>;
export const TransferOrderByUuidDocument = gql`
    query transferOrderByUuid($uuid: String!) {
  transferOrders(filters: {uuid: {eq: $uuid}}) {
    data {
      ...TransferOrder
    }
  }
}
    ${TransferOrderFragmentDoc}
${TransferOrderMinFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${TransferOrderItemFragmentDoc}
${TransferOrderItemMinFragmentDoc}
${ProductFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${SerializeFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${SubLocationItemFragmentDoc}
${UserFragmentDoc}
${UserMinFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ContactMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}`;
export function useTransferOrderByUuidQuery(baseOptions: Apollo.QueryHookOptions<TransferOrderByUuidQuery, TransferOrderByUuidQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TransferOrderByUuidQuery, TransferOrderByUuidQueryVariables>(TransferOrderByUuidDocument, options);
      }
export function useTransferOrderByUuidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TransferOrderByUuidQuery, TransferOrderByUuidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TransferOrderByUuidQuery, TransferOrderByUuidQueryVariables>(TransferOrderByUuidDocument, options);
        }
export type TransferOrderByUuidQueryHookResult = ReturnType<typeof useTransferOrderByUuidQuery>;
export type TransferOrderByUuidLazyQueryHookResult = ReturnType<typeof useTransferOrderByUuidLazyQuery>;
export type TransferOrderByUuidQueryResult = Apollo.QueryResult<TransferOrderByUuidQuery, TransferOrderByUuidQueryVariables>;
export const TransferOrderItemsDocument = gql`
    query transferOrderItems($filters: TransferOrderItemFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  transferOrderItems(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...TransferOrderItem
    }
    meta {
      ...Meta
    }
  }
}
    ${TransferOrderItemFragmentDoc}
${TransferOrderItemMinFragmentDoc}
${ProductFragmentDoc}
${ProductMinFragmentDoc}
${FileFragmentDoc}
${DimensionFragmentDoc}
${WeightFragmentDoc}
${ProductBrandFragmentDoc}
${ProductTypeFragmentDoc}
${RentableDataFragmentDoc}
${ProductInventoryItemFragmentDoc}
${ProductOrderItemMinFragmentDoc}
${CompanyMinFragmentDoc}
${SubLocationMinFragmentDoc}
${SubLocationItemMinFragmentDoc}
${SerializeFragmentDoc}
${BusinessLocationFragmentDoc}
${LocationFragmentDoc}
${TaxMinFragmentDoc}
${TaxFragmentDoc}
${TaxAuthorityMinFragmentDoc}
${ProductAttributeOptionFragmentDoc}
${ProductAttributeOptionMinFragmentDoc}
${ProductAttributeMinFragmentDoc}
${JewelryProductMinFragmentDoc}
${DesignStyleFragmentDoc}
${ShankStyleFragmentDoc}
${SizeFragmentDoc}
${JewelryProductTypeFragmentDoc}
${SpecificTypeFragmentDoc}
${EngravingTypeFragmentDoc}
${TimePeriodFragmentDoc}
${MetalFinishTypeFragmentDoc}
${MetalTypeFragmentDoc}
${MaterialGradeFragmentDoc}
${JewelryGenderTypeFragmentDoc}
${JewelryConditionTypeFragmentDoc}
${PlattingTypeFragmentDoc}
${ManufacturingProcessFragmentDoc}
${PieceFragmentDoc}
${SubLocationItemFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useTransferOrderItemsQuery(baseOptions?: Apollo.QueryHookOptions<TransferOrderItemsQuery, TransferOrderItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TransferOrderItemsQuery, TransferOrderItemsQueryVariables>(TransferOrderItemsDocument, options);
      }
export function useTransferOrderItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TransferOrderItemsQuery, TransferOrderItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TransferOrderItemsQuery, TransferOrderItemsQueryVariables>(TransferOrderItemsDocument, options);
        }
export type TransferOrderItemsQueryHookResult = ReturnType<typeof useTransferOrderItemsQuery>;
export type TransferOrderItemsLazyQueryHookResult = ReturnType<typeof useTransferOrderItemsLazyQuery>;
export type TransferOrderItemsQueryResult = Apollo.QueryResult<TransferOrderItemsQuery, TransferOrderItemsQueryVariables>;
export const CreateTwilioConnectionDocument = gql`
    mutation createTwilioConnection($input: TwilioConnectionInput!) {
  createTwilioConnection(data: $input) {
    data {
      id
    }
  }
}
    `;
export type CreateTwilioConnectionMutationFn = Apollo.MutationFunction<CreateTwilioConnectionMutation, CreateTwilioConnectionMutationVariables>;
export function useCreateTwilioConnectionMutation(baseOptions?: Apollo.MutationHookOptions<CreateTwilioConnectionMutation, CreateTwilioConnectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTwilioConnectionMutation, CreateTwilioConnectionMutationVariables>(CreateTwilioConnectionDocument, options);
      }
export type CreateTwilioConnectionMutationHookResult = ReturnType<typeof useCreateTwilioConnectionMutation>;
export type CreateTwilioConnectionMutationResult = Apollo.MutationResult<CreateTwilioConnectionMutation>;
export const TwilioAccessTokenDocument = gql`
    query twilioAccessToken($tenant: ID!) {
  twilioConnections(filters: {tenant: {id: {eq: $tenant}}}) {
    data {
      ...TwilioConnection
    }
  }
}
    ${TwilioConnectionFragmentDoc}`;
export function useTwilioAccessTokenQuery(baseOptions: Apollo.QueryHookOptions<TwilioAccessTokenQuery, TwilioAccessTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TwilioAccessTokenQuery, TwilioAccessTokenQueryVariables>(TwilioAccessTokenDocument, options);
      }
export function useTwilioAccessTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TwilioAccessTokenQuery, TwilioAccessTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TwilioAccessTokenQuery, TwilioAccessTokenQueryVariables>(TwilioAccessTokenDocument, options);
        }
export type TwilioAccessTokenQueryHookResult = ReturnType<typeof useTwilioAccessTokenQuery>;
export type TwilioAccessTokenLazyQueryHookResult = ReturnType<typeof useTwilioAccessTokenLazyQuery>;
export type TwilioAccessTokenQueryResult = Apollo.QueryResult<TwilioAccessTokenQuery, TwilioAccessTokenQueryVariables>;
export const TwilioConnectionByTenantDocument = gql`
    query twilioConnectionByTenant($tenant: ID!) {
  twilioConnections(filters: {tenant: {id: {eq: $tenant}}}) {
    data {
      ...TwilioConnectionMin
    }
  }
}
    ${TwilioConnectionMinFragmentDoc}`;
export function useTwilioConnectionByTenantQuery(baseOptions: Apollo.QueryHookOptions<TwilioConnectionByTenantQuery, TwilioConnectionByTenantQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TwilioConnectionByTenantQuery, TwilioConnectionByTenantQueryVariables>(TwilioConnectionByTenantDocument, options);
      }
export function useTwilioConnectionByTenantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TwilioConnectionByTenantQuery, TwilioConnectionByTenantQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TwilioConnectionByTenantQuery, TwilioConnectionByTenantQueryVariables>(TwilioConnectionByTenantDocument, options);
        }
export type TwilioConnectionByTenantQueryHookResult = ReturnType<typeof useTwilioConnectionByTenantQuery>;
export type TwilioConnectionByTenantLazyQueryHookResult = ReturnType<typeof useTwilioConnectionByTenantLazyQuery>;
export type TwilioConnectionByTenantQueryResult = Apollo.QueryResult<TwilioConnectionByTenantQuery, TwilioConnectionByTenantQueryVariables>;
export const CreateNewRoleDocument = gql`
    mutation createNewRole($input: CreateNewRoleInput!) {
  createNewRole(input: $input)
}
    `;
export type CreateNewRoleMutationFn = Apollo.MutationFunction<CreateNewRoleMutation, CreateNewRoleMutationVariables>;
export function useCreateNewRoleMutation(baseOptions?: Apollo.MutationHookOptions<CreateNewRoleMutation, CreateNewRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNewRoleMutation, CreateNewRoleMutationVariables>(CreateNewRoleDocument, options);
      }
export type CreateNewRoleMutationHookResult = ReturnType<typeof useCreateNewRoleMutation>;
export type CreateNewRoleMutationResult = Apollo.MutationResult<CreateNewRoleMutation>;
export const ResendConfirmationDocument = gql`
    mutation resendConfirmation($input: ResendConfirmationInput!) {
  resendConfirmation(input: $input)
}
    `;
export type ResendConfirmationMutationFn = Apollo.MutationFunction<ResendConfirmationMutation, ResendConfirmationMutationVariables>;
export function useResendConfirmationMutation(baseOptions?: Apollo.MutationHookOptions<ResendConfirmationMutation, ResendConfirmationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendConfirmationMutation, ResendConfirmationMutationVariables>(ResendConfirmationDocument, options);
      }
export type ResendConfirmationMutationHookResult = ReturnType<typeof useResendConfirmationMutation>;
export type ResendConfirmationMutationResult = Apollo.MutationResult<ResendConfirmationMutation>;
export const UpdateUserDocument = gql`
    mutation updateUser($id: ID!, $data: UsersPermissionsUserInput!) {
  updateUsersPermissionsUser(id: $id, data: $data) {
    data {
      ...User
    }
  }
}
    ${UserFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ContactMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${BusinessLocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}`;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export const UsersDocument = gql`
    query users($filters: UsersPermissionsUserFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  usersPermissionsUsers(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...User
    }
    meta {
      ...Meta
    }
  }
}
    ${UserFragmentDoc}
${UserMinFragmentDoc}
${FileFragmentDoc}
${SchedulingAppointmentMinFragmentDoc}
${ContactMinFragmentDoc}
${RoleFragmentDoc}
${TenantFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}
${MailTemplateFragmentDoc}
${MailTemplateMinFragmentDoc}
${BusinessLocationFragmentDoc}
${TaxMinFragmentDoc}
${SubLocationMinFragmentDoc}
${RateMinFragmentDoc}
${PayRateFragmentDoc}
${ReportsScheduleMinFragmentDoc}
${OnboardingUserFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const CreateWebsiteDocument = gql`
    mutation createWebsite($input: WebsiteInput!) {
  createWebsite(data: $input) {
    data {
      ...Website
    }
  }
}
    ${WebsiteFragmentDoc}
${TenantFragmentDoc}
${FileFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}
${ParagraphFragmentDoc}
${ReviewSectionFragmentDoc}
${SectionFragmentDoc}
${ExtendedSectionFragmentDoc}
${SectionsVisibilityFragmentDoc}
${ProductTypesVisibilityFragmentDoc}
${ShipmentCostFragmentDoc}
${WebsiteContactsFragmentDoc}
${WebsiteWorkingHoursFragmentDoc}`;
export type CreateWebsiteMutationFn = Apollo.MutationFunction<CreateWebsiteMutation, CreateWebsiteMutationVariables>;
export function useCreateWebsiteMutation(baseOptions?: Apollo.MutationHookOptions<CreateWebsiteMutation, CreateWebsiteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWebsiteMutation, CreateWebsiteMutationVariables>(CreateWebsiteDocument, options);
      }
export type CreateWebsiteMutationHookResult = ReturnType<typeof useCreateWebsiteMutation>;
export type CreateWebsiteMutationResult = Apollo.MutationResult<CreateWebsiteMutation>;
export const UpdateWebsiteDocument = gql`
    mutation updateWebsite($id: ID!, $input: WebsiteInput!) {
  updateWebsite(id: $id, data: $input) {
    data {
      ...Website
    }
  }
}
    ${WebsiteFragmentDoc}
${TenantFragmentDoc}
${FileFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}
${ParagraphFragmentDoc}
${ReviewSectionFragmentDoc}
${SectionFragmentDoc}
${ExtendedSectionFragmentDoc}
${SectionsVisibilityFragmentDoc}
${ProductTypesVisibilityFragmentDoc}
${ShipmentCostFragmentDoc}
${WebsiteContactsFragmentDoc}
${WebsiteWorkingHoursFragmentDoc}`;
export type UpdateWebsiteMutationFn = Apollo.MutationFunction<UpdateWebsiteMutation, UpdateWebsiteMutationVariables>;
export function useUpdateWebsiteMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWebsiteMutation, UpdateWebsiteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWebsiteMutation, UpdateWebsiteMutationVariables>(UpdateWebsiteDocument, options);
      }
export type UpdateWebsiteMutationHookResult = ReturnType<typeof useUpdateWebsiteMutation>;
export type UpdateWebsiteMutationResult = Apollo.MutationResult<UpdateWebsiteMutation>;
export const WebsiteIdsDocument = gql`
    query websiteIds($filters: WebsiteFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  websites(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      id
    }
    meta {
      ...Meta
    }
  }
}
    ${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useWebsiteIdsQuery(baseOptions?: Apollo.QueryHookOptions<WebsiteIdsQuery, WebsiteIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WebsiteIdsQuery, WebsiteIdsQueryVariables>(WebsiteIdsDocument, options);
      }
export function useWebsiteIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebsiteIdsQuery, WebsiteIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WebsiteIdsQuery, WebsiteIdsQueryVariables>(WebsiteIdsDocument, options);
        }
export type WebsiteIdsQueryHookResult = ReturnType<typeof useWebsiteIdsQuery>;
export type WebsiteIdsLazyQueryHookResult = ReturnType<typeof useWebsiteIdsLazyQuery>;
export type WebsiteIdsQueryResult = Apollo.QueryResult<WebsiteIdsQuery, WebsiteIdsQueryVariables>;
export const WebsiteDocument = gql`
    query website($filters: WebsiteFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  websites(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      ...Website
    }
    meta {
      ...Meta
    }
  }
}
    ${WebsiteFragmentDoc}
${TenantFragmentDoc}
${FileFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}
${ParagraphFragmentDoc}
${ReviewSectionFragmentDoc}
${SectionFragmentDoc}
${ExtendedSectionFragmentDoc}
${SectionsVisibilityFragmentDoc}
${ProductTypesVisibilityFragmentDoc}
${ShipmentCostFragmentDoc}
${WebsiteContactsFragmentDoc}
${WebsiteWorkingHoursFragmentDoc}
${MetaFragmentDoc}
${PaginationFragmentDoc}`;
export function useWebsiteQuery(baseOptions?: Apollo.QueryHookOptions<WebsiteQuery, WebsiteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WebsiteQuery, WebsiteQueryVariables>(WebsiteDocument, options);
      }
export function useWebsiteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebsiteQuery, WebsiteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WebsiteQuery, WebsiteQueryVariables>(WebsiteDocument, options);
        }
export type WebsiteQueryHookResult = ReturnType<typeof useWebsiteQuery>;
export type WebsiteLazyQueryHookResult = ReturnType<typeof useWebsiteLazyQuery>;
export type WebsiteQueryResult = Apollo.QueryResult<WebsiteQuery, WebsiteQueryVariables>;
export const WebsiteByIdDocument = gql`
    query websiteById($id: ID!) {
  website(id: $id) {
    data {
      ...Website
    }
  }
}
    ${WebsiteFragmentDoc}
${TenantFragmentDoc}
${FileFragmentDoc}
${LocationFragmentDoc}
${TwilioConnectionFragmentDoc}
${ParagraphFragmentDoc}
${ReviewSectionFragmentDoc}
${SectionFragmentDoc}
${ExtendedSectionFragmentDoc}
${SectionsVisibilityFragmentDoc}
${ProductTypesVisibilityFragmentDoc}
${ShipmentCostFragmentDoc}
${WebsiteContactsFragmentDoc}
${WebsiteWorkingHoursFragmentDoc}`;
export function useWebsiteByIdQuery(baseOptions: Apollo.QueryHookOptions<WebsiteByIdQuery, WebsiteByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WebsiteByIdQuery, WebsiteByIdQueryVariables>(WebsiteByIdDocument, options);
      }
export function useWebsiteByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebsiteByIdQuery, WebsiteByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WebsiteByIdQuery, WebsiteByIdQueryVariables>(WebsiteByIdDocument, options);
        }
export type WebsiteByIdQueryHookResult = ReturnType<typeof useWebsiteByIdQuery>;
export type WebsiteByIdLazyQueryHookResult = ReturnType<typeof useWebsiteByIdLazyQuery>;
export type WebsiteByIdQueryResult = Apollo.QueryResult<WebsiteByIdQuery, WebsiteByIdQueryVariables>;
export const WebsiteContactsDocument = gql`
    query websiteContacts($filters: WebsiteFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
  websites(filters: $filters, pagination: $pagination, sort: $sort) {
    data {
      attributes {
        subdomain
        websiteContacts {
          ...WebsiteContacts
        }
      }
    }
  }
}
    ${WebsiteContactsFragmentDoc}`;
export function useWebsiteContactsQuery(baseOptions?: Apollo.QueryHookOptions<WebsiteContactsQuery, WebsiteContactsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WebsiteContactsQuery, WebsiteContactsQueryVariables>(WebsiteContactsDocument, options);
      }
export function useWebsiteContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebsiteContactsQuery, WebsiteContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WebsiteContactsQuery, WebsiteContactsQueryVariables>(WebsiteContactsDocument, options);
        }
export type WebsiteContactsQueryHookResult = ReturnType<typeof useWebsiteContactsQuery>;
export type WebsiteContactsLazyQueryHookResult = ReturnType<typeof useWebsiteContactsLazyQuery>;
export type WebsiteContactsQueryResult = Apollo.QueryResult<WebsiteContactsQuery, WebsiteContactsQueryVariables>;